// model
import Model from '@/models/Model'

/**
 * This class describes a AComplete model.
 * @class AComplete (name)
 */
export default class ACompleteModel extends Model {

  /**
   * Define the AComplete resource.
   * @return {String} AComplete endpoint name.
   */
  resource() {
    return 'complete'
  }
}