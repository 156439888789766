//
import UsrUserModel from '@/models/User/UsrUser'
import UsrUserService from '@/services/api/User/UsrUser'
import UsrUserFields from '@/views/User/UsrUser/fields'
//
export default {
  computed: {

    /**
      * Gets the usr-user form props.
      * @return {Object} The form props.
      */
    formProps() {
      return {
        items: UsrUserFields.toAccount(),
      }
    }
  },
  data() {
    return {
      usrUser: new UsrUserModel({
        alternative_email: '',
        password: '',
        phone_number: '',
        repeat_password: '',
        rut: '',
        token: '',
      }),
    }
  },
  methods: {

    /**
      * Redirect to Auth index.
      */
    redirect() {
      this.goRoute('auth')
    },

    /**
     * Returns a reset usr-user form.
     * @return {Promise} A form reset process.
     */
    reset(event) {
      return this
        .toWait(true)
        .then(this.toRefresh)
        .then(this.toWait)
    },

    /**
     * 
     */
    rulePassword() {
      return this.usrUser.password == this.usrUser.repeat_password || 'Las contraseñas deben ser las mismas.'
    },

    /**
      * Returns a submit usr-user form.
      * @return {Promise} A form submit process.
      */
    submit() {
      return this
        .toWait(true)
        .then(this.toUpdate)
        .then(this.toWait)
    },

    /**
      * Returns a reset usr-user form process.
      * @return {Object} A reset process.
      */
    toRefresh() {
      this.usrUser.alternative_email = ''
      this.usrUser.password = ''
      this.usrUser.phone_number = ''
      this.usrUser.repeat_password = ''
      this.usrUser.rut = this.getQuery('rut')
      this.usrUser.token = this.getParam('token')
    },

    /**
     * Returns an activate account usr-user process.
     * @param  {UsrUserModel}  model - the usr-user model
     * @return {Promise} A update process.
     */
    toUpdate() {
      return UsrUserService
        .activateAccount(this.usrUser)
        .then(this.toSuccess)
        .catch(this.toError)
    },

    /**
     * Returns an update usr-user error process.
     * @param  {Object}  event - the event error payload
     */
    toError(event) {
      this.getErrorMessage(event).then(this.getErrorToast)
      // UsrUserModel
      //   .getCase('activate_account_error', this.getErrorMessage(event))
      //   .then(this.getErrorToast)
    },

    /**
     * Returns an update usr-user success process.
     */
    toSuccess(event) {
      this.getSuccessMessage('Cuenta activada exitosamente.').then(this.getSuccessToast).then(this.redirect)
      // UsrUserModel
      //   .getCase('activate_account_success')
      //   .then(this.getSuccessToast)
      //   .then(this.redirect)
    },
  },
  mounted() {
    this.reset()
  }
}