// required modules
import ActActivityFields from '@/views/Activity/ActActivity/fields'
import ActActivityModel from '@/models/Activity/ActActivity'
import ActActivityService from '@/services/api/Activity/ActActivity'
import UsrStudentFilters from '@/views/User/UsrStudent/filters'

// component
export default {
  name: 'act-activity-update',
  computed: {

    /**
     * Gets the ActActivity update-body form props.
     * @return {Object} The update-body form props.
     */
    bodyProps() {
      return {
        items: ActActivityFields.toBody(),
      }
    },

    /**
     * 
     */
    confirmProps() {
      return {
        items: ActActivityFields.toConfirm(),
      }
    },

    /**
     * Gets the ActActivity update-date form props.
     * @return {Object} The update-date form props.
     */
    dateProps() {
      return {
        items: ActActivityFields.toDate(),
      }
    },

    /**
     * Gets the ActActivity update-facilitators form props.
     * @return {Object} The update-facilitators form props.
     */
    facilitatorsProps() {
      return {
        items: ActActivityFields.toFacilitators(),
      }
    },

    /**
     * Gets the ActActivity update-general form props.
     * @return {Object} The update-general form props.
     */
    generalProps() {
      return {
        items: ActActivityFields.toGeneral(),
      }
    },

    /**
     * Gets the ActActivity update-image form props.
     * @return {Object} The update-image form props.
     */
    imageProps() {
      return {
        items: ActActivityFields.toImage(),
      }
    },

    /**
     * Gets the ActActivity send-notification form props.
     * @return {Object} The send-notification form props.
     */
    notificationProps() {
      return {
        items: ActActivityFields.toNotification(),
      }
    },
  },
  data() {
    return {
      actActivity: {
        title: '',
        body: '',
        notify_students: true,
        send_email: false,
        support_facilitators_ids: [],
        url_image_path: '',
        value: 'test',
      },
      card: {
        loading: false,
      },
      dialogs: {
        confirm: false,
        general: false,
        facilitators: false,
        date: false,
        body: false,
        image: false,
        notification: false,
        visualize: false,
      },
      loading: false,
    }
  },
  methods: {

    /**
     * Returns a submit ActActivity body form.
     */
    onBody() {
      this.$whereas(true, this.toBusy('body', 'wait'), () => {
        return ActActivityService.updateBody(this.actActivity).then(this.onSuccess).then(this.onReset).then(this.$toggle('body')).catch(this.onError)
      })
    },

    /**
     * Returns a submit ActActivity complete.
     */
    onComplete() {
      this.$toaster('¿Desea completar la actividad?', 'completar', () => {
        return ActActivityService.complete(this.actActivity).then(this.onSuccess).then(this.onReset).catch(this.onError)
      })
    },

    /**
     * Returns a submit ActActivity confirm.
     */
    onConfirm() {
      this.$whereas(true, this.toBusy('confirm', 'wait'), () => {
        return ActActivityService.confirm(this.actActivity).then(this.onSuccess).then(this.onReset).then(this.$toggle('confirm')).catch(this.onError)
      })
    },

    /**
     * Returns a submit ActActivity date form.
     */
    onDate() {
      this.$whereas(true, this.toBusy('date', 'wait'), () => {
        return ActActivityService.update(this.actActivity).then(this.onSuccess).then(this.onReset).then(this.$toggle('date')).catch(this.onError)
      })
    },

    /**
     * Returns a submit ActActivity facilitators form.
     */
    onFacilitators() {
      this.$whereas(true, this.toBusy('facilitators', 'wait'), () => {
        return ActActivityService.update(this.actActivity).then(this.onSuccess).then(this.onReset).then(this.$toggle('facilitators')).catch(this.onError)
      })
    },

    /**
     * Returns a submit ActActivity general form.
     */
    onGeneral() {
      this.$whereas(true, this.toBusy('general', 'wait'), () => {
        return ActActivityService.update(this.actActivity).then(this.onSuccess).then(this.onReset).then(this.$toggle('general')).catch(this.onError)
      })
    },

    /**
     * Returns a submit ActActivity image form.
     */
    onImage() {
      this.$whereas(true, this.toBusy('image', 'wait'), () => {
        return ActActivityService.uploadImage(this.actActivity).then(this.onSuccess).then(this.onReset).then(this.$toggle('image')).catch(this.onError)
      })
    },

    /**
     * Returns a submit ActActivity send notification form.
     */
    onNotification() {
      this.$whereas(true, this.toWait('notification'), () => {
        return ActActivityService.sendNotification(this.actActivity).then(this.onSuccess).then(this.onReset).then(this.$toggle('notification')).catch(this.onError)
      })
    },

    /**
     * Returns a reset ActActivity form.
     */
    onReset() {
      this.$whereas(true, this.$toggle('loading', 'card'), () => {
        return ActActivityService.toUpdate().find(this.$param('id')).then(actActivity => {
          this.actActivity = actActivity.reset()
        }) 
      })
    },

    /**
     * Returns a submit ActActivity start.
     */
    onStart() {
      this.$toaster('¿Desea iniciar la actividad?', 'iniciar', () => {
        return ActActivityService.start(this.actActivity).then(this.onSuccess).then(this.onReset).catch(this.onError)
      })
    },

    /**
     * Go to SrvSurvey related.
     */
    onSurvey() {
      this.goRoute('srv-survey-update', { id: this.actActivity.satisfaction_survey_id })
    },
  },
  mounted() {
    this.onReset()
  },
}