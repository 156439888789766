//
import UsrFacilitatorService from '@/services/api/User/UsrFacilitator'
import UsrFacilitatorFields from '@/views/User/UsrFacilitator/fields'

//
export default {
  computed: {

    /**
      * Gets the usr-facilitator form props.
      * @return {Object} The form props.
      */
    formProps() {
      return {
        items: UsrFacilitatorFields.toUpdate(),
      }
    }
  },
  data() {
    return {
      usrFacilitator: {},
    }
  },
  methods: {

    /**
      * Returns a reset usr-facilitator form.
      * @return {Promise} A form reset process.
      */
    reset(event) {
      return this
        .toWait(true)
        .then(this.toRefresh)
        .then(this.toWait)
    },

    /**
      * Returns a submit usr-facilitator form.
      * @return {Promise} A form submit process.
      */
    submit() {
      return this
        .toWait(true)
        .then(this.toUpdate)
        .then(this.toWait)
    },

    /**
      * Returns a reset usr-facilitator form process.
      * @return {Object} A reset process.
      */
    toRefresh() {
      return UsrFacilitatorService.model().include('specialities,attention_types').find(this.getParam('id')).then(usrFacilitator => {
        this.usrFacilitator = usrFacilitator.toEdit()
      })
    },

    /**
      * Returns an update usr-facilitator process.
      * 
      * @return {Promise} A update process.
      */
    toUpdate() {
      return UsrFacilitatorService
        .update(this.usrFacilitator)
        .then(this.toSuccess)
        .catch(this.toError)
    },

    /**
      * Returns an create UsrFacilitator error process.
      * @param  {Object}  event - error payload
      */
    toError(event) {
      this.getErrorMessage(event).then(this.getErrorToast)
    },

    /**
      * Returns an create UsrFacilitator success process.
      * @param  {Object}  event - success payload
      */
    toSuccess(event) {
      this.getSuccessMessage(event).then(this.getSuccessToast)
    },
  },
  mounted() {
    this.reset()
  },
}