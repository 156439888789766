// required modules
import NtMailRrssFields from '@/views/Notification/NtMailRrss/fields'
import NtMailRrssService from '@/services/api/Notification/NtMailRrss'

// component
export default {
  name: 'nt-mail-rrss-create',
  computed: {

    /**
      * Gets the NtMailRrss create form props.
      * 
      * @return {Object} The create form props.
      */
    formProps() {
      return {
        items: NtMailRrssFields.toCreate(),
      }
    }
  },
  data() {
    return {
      ntMailRrss: NtMailRrssService.shape({
        value: ''
      }),
    }
  },
  methods: {

    /**
      * Returns an create NtMailRrss error process.
      * 
      * @param  {Object}  event - the event error payload.
      */
    onError(event) {
      this.getErrorMessage(event).then(this.getErrorToast)
    },

    /**
      * Reset the NtMailRrss data.
      */
    onReset() {
      this.ntMailRrss.value = ''
    },

    /**
      * Returns an create NtMailRrss success process.
      * 
      * @param  {Object}  event - the event success payload.
      */
    onSuccessCreate(event) {
      this.getSuccessMessage(event).then(this.getSuccessToast).then(this.toRedirect)
    },

    /**
      * Returns a submit NtMailRrss create form.
      * 
      * @return {Promise} A form submit process.
      */
    onSubmit() {
      return this.toWait(true).then(this.toCreate).then(this.toWait)
    },

    /**
      * Returns an create NtMailRrss process.
      * 
      * @return {Promise} A create process.
      */
    toCreate() {
      return NtMailRrssService.create(this.ntMailRrss).then(this.onSuccessCreate).catch(this.onError)
    },

    /**
      * Redirect to NtMailRrss list
      */
    toRedirect() {
      this.goRoute('nt-mail-rrss-list')
    },
  },
}