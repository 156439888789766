//
import NtNotificationModel from '@/models/Notification/NtNotification'
import NtNotificationService from '@/services/api/Notification/NtNotification'
import NtNotificationFields from '@/views/Notification/NtNotification/fields'
//
export default {
  computed: {

    /**
      * Gets the NtNotification form props.
      * 
      * @return {Object} The form props.
      */
    formProps() {
      return {
        items: NtNotificationFields.toAccount(),
      }
    }
  },
  data() {
    return {
      ntNotification: NtNotificationService.shape({
        rut: ''
      }),
    }
  },
  methods: {

    /**
     * Returns an account notification error process.
     * 
     * @param  {Object}  event - the event error payload.
     */
    onError(event) {
      this.getErrorMessage(event).then(this.getErrorToast)
    },

    /**
      * Reset the NtNotification data.
      */
    onReset() {
      this.ntNotification.identifier = ''
    },

    /**
      * Returns a submit NtNotification form.
      * 
      * @return {Promise} A form submit process.
      */
    onSubmit() {
      return this.toWait(true).then(this.toAccountNotification).then(this.toWait)
    },

    /**
     * Returns an account notification success process.
     * 
     * @param  {Object}  event - the event success payload.
     */
    onSuccessAccount(event) {
      this.getSuccessMessage(event).then(this.getSuccessToast).then(this.toRedirect)
    },

    /**
     * Returns an account notification process.
     * 
     * @return {Promise} A account notification process.
     */
    toAccountNotification() {
      return NtNotificationService.accountNotification(this.ntNotification).then(this.onSuccessAccount).catch(this.onError)
    },

    /**
      * Redirect to Auth index.
      */
    toRedirect() {
      this.goRoute('auth')
    },
  },
}
