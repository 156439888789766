// required modules
import Model from '@/models/Model'

/**
 * This class describes a AQuantity model.
 *
 * @class AQuantity (name)
 */
export default class AQuantityModel extends Model {

  /**
   * Define the AQuantity resource.
   *
   * @return {String} AQuantity endpoint name.
   */
  resource() {
    return 'quantity'
  }
}