// model
import Model from '@/models/Model'

/**
 * This class describes a AUpdateDraft model.
 * @class AUpdateDraft (name)
 */
export default class AUpdateDraftModel extends Model {

  /**
   * Define the AUpdateDraft resource.
   * @return {String} AUpdateDraft endpoint name.
   */
  resource() {
    return 'update_draft'
  }
}