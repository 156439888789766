// required modules
import { Headers } from 'collection-wrapper'

/**
 * This class describes a RptReport headers collection.
 * @class 
 */
export default class RptReportHeaders extends Headers {

  /**
   * Returns the RptReport headers options.
   * @returns {Array} headers options.
   */
  headers() {
    return [
      {
        text: 'Titulo',
        value: 'title',
      },
    ]
  }

  /**
   * Returns the RptReport list headers.
   * @returns {Collection} headers collection.
   */
  static toList() {
    return this.reduce('value', [
      'title',
    ])
  }
}
