// layout
import LayoutCenter from '@/components/layouts/LayoutCenter'

// views
import SrvSurveyAnswerView from '@/views/Survey/SrvSurvey/answer'

// routes
export default {
  path: '/encuesta',
  component: LayoutCenter,
  children: [
    {
      name: 'srv-survey-answer',
      path: 'responder/:token',
      component: SrvSurveyAnswerView,
    },
  ],
}
