//
import { Fields } from 'collection-wrapper'

//
export default class MenuFields extends Fields {

  /**
   * 
   */
  fields() {
    return [
      {
        icon: "mdi-bulletin-board",
        link: "/actividades",
        text: "Actividades",
        data: [
          {
            icon: "mdi-view-list",
            link: "/actividades",
            text: "Todas"
          },
          {
            icon: "mdi-bulletin-board",
            link: "/actividades/buscar",
            text: "Tablero"
          }
        ]
      },
      {
        icon: "mdi-bulletin-board",
        link: "/actividades/buscar",
        text: "Actividades",
      },
      {
        icon: "mdi-account-clock",
        link: "/atenciones",
        text: "Atenciones",
        data: [
          {
            icon: "mdi-view-list",
            link: "/atenciones",
            text: "Mis atenciones"
          },
          {
            icon: "mdi-calendar-clock",
            link: "/horarios",
            text: "Horarios"
          }
        ]
      },
      {
        icon: "mdi-account-clock",
        link: "/atenciones/calendario",
        text: "Atenciones",
      },
      {
        icon: "mdi-comment-question",
        link: "/encuestas",
        text: "Encuestas",
        data: [
          {
            icon: "mdi-view-list",
            link: "/encuestas",
            text: "Todas"
          },
          {
            icon: "mdi-file-edit",
            link: "/encuestas-predeterminadas",
            text: "Encuestas predeterminadas"
          }
        ]
      },
      {
        icon: "mdi-teach",
        link: "/tutorias",
        text: "Tutorías",
        data: [
          {
            icon: "mdi-view-list",
            link: "/tutorias",
            text: "Asignaturas"
          },
          {
            icon: "mdi-teach",
            link: "/paralelos",
            text: "Paralelos"
          },
          {
            icon: "mdi-teach",
            link: "/tutores",
            text: "Tutores"
          },
          {
            icon: "mdi-file-edit",
            link: "/tutorias/horarios",
            text: "Horarios"
          },
          {
            icon: "mdi-newspaper-variant-multiple",
            link: "/tutorias/avisos",
            text: "Avisos "
          }
        ]
      },
      {
        icon: "mdi-teach",
        link: "/tutorias_estudiantes",
        text: "Tutorías",
        data: [
          {
            icon: "mdi-file-edit",
            link: "/tutorias/horarios/schedule",
            text: "Horarios"
          },
          {
            icon: "mdi-newspaper-variant-multiple",
            link: "/tutorias/avisos",
            text: "Avisos "
          }
        ]
      },
      {
        icon: "mdi-post",
        link: "/blog",
        text: "Noticias"
      },
      {
        icon: "mdi-file-chart",
        link: "/reportes",
        text: "Reportes",
        data: [
          {
            icon: "mdi-view-dashboard",
            link: "/reportes",
            text: "Tablero"
          },
          {
            icon: "mdi-file-chart-outline",
            link: "/reportes/actividades",
            text: "Actividades"
          },
          {
            icon: "mdi-file-chart-outline",
            link: "/reportes/atenciones",
            text: "Atenciones"
          },
          {
            icon: "mdi-file-chart-outline",
            link: "/reportes/aplicaciones",
            text: "Aplicación"
          }
        ]
      },
      {
        icon: "mdi-account-supervisor-circle",
        link: "/usuarios",
        text: "Usuarios",
        data: [
          {
            icon: "mdi-account",
            link: "/estudiantes",
            text: "Estudiantes"
          },
          {
            icon: "mdi-account-tie",
            link: "/facilitadores",
            text: "Facilitadores"
          },
          {
            icon: "mdi-account-circle",
            link: "/usuarios",
            text: "Usuarios"
          }
        ]
      },
      {
        icon: "mdi-cog-outline",
        link: "/administrar",
        text: "Administración",
        data: [
          {
            icon: "mdi-sign-text",
            link: "/programas",
            text: "Programas"
          },
          {
            icon: "mdi-newspaper",
            link: "/blog/lista",
            text: "Gestión de Noticias"
          },
          {
            icon: "mdi-calendar-blank-multiple",
            link: "/semestres",
            text: "Semestres"
          },
          {
            icon: "mdi-group",
            link: "/contactos",
            text: "Contactos"
          },
          {
            icon: "mdi-rss",
            link: "/rrss",
            text: "RRSS"
          }
        ]
      },      
      {
        icon: "mdi-cog-outline",
        link: "/administrar_facilitadores",
        text: "Administración",
        data: [
          {
            icon: "mdi-sign-text",
            link: "/programas",
            text: "Programas"
          },
          {
            icon: "mdi-newspaper",
            link: "/blog/lista",
            text: "Gestión de Noticias"
          },
        ]
      }
    ]
  }

  /**
   * 
   */
  static toAdministrator() {
    return this.reduce('link', [
      '/administrar',
      '/usuarios',
      '/actividades',
      '/atenciones',
      '/encuestas',
      '/tutorias',
      '/blog',
      '/reportes',
    ])
  }

  /**
   * 
   */
  static toFacilitator() {
    return this.reduce('link', [
      '/administrar_facilitadores',
      '/usuarios',
      '/actividades',
      '/atenciones',
      '/encuestas',
      '/tutorias',
      '/blog',
    ])    
  }

  /**
   * 
   */
  static toRol(rolName) {
    switch (rolName) {
      case 'administrator': return this.toSystem();
      case 'facilitator'  : return this.toFacilitator();
      case 'student'      : return this.toStudent();
      case 'system'       : return this.toSystem();
      default             : return [];
    }
  }

  /**
   * 
   */
  static toStudent() {
    return this.reduce('link', [
      '/actividades/buscar',
      '/atenciones/calendario',
      '/tutorias_estudiantes',
      '/blog',
    ])    
  }

  /**
   * 
   */
  static toSystem() {
    return this.reduce('link', [
      '/administrar',
      '/usuarios',
      '/actividades',
      '/atenciones',
      '/encuestas',
      '/tutorias',
      '/blog',
      '/reportes',
    ])
  }
}