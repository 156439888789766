// service
import Service from '@/services/api/Service'

// model
import SrvSurveyUserModel from '@/models/Survey/SrvSurveyUser'

/**
 * This class describes a SrvSurveyUser service.
 * @class SrvSurveyUser (name)
 */
export default class SrvSurveyUserService extends Service {

  /**
   * Define the SrvSurveyUser model.
   * @return {Function} SrvSurveyUser resource.
   */
  static model() {
    return SrvSurveyUserModel
  }

  /**
   * 
   */
  static toAnswer() {

    // includes
    const includes = [
      'survey.creator',	//Creador de la encuesta
      'survey.status',	//Estado de la encuesta
      'survey.sections.blocks.type',	//Tipo de bloque //(Información o Pregunta/Respuesta)
      'survey.sections.blocks.identifier',	//Identificador de bloque (es texto simple, rango, checkbox, ...)
      'survey.sections.blocks.options',	//Alternativas (Solo válido para selección simple o múltiple)
      'survey.sections.blocks.fields', //Campo por alternativa (formato tabla)
      'survey.sections.blocks.range',	
      'status',	
      'user',
      'user_blocks',
    ]

    // model
    return this.model().include(includes)
  }
}