// layout
import RLayoutModule from '@/components/layouts/LayoutModule'

// views
import BlgWebNewBlogView from '@/views/Blog/BlgWebNew/blog'
import BlgWebNewCreateView from '@/views/Blog/BlgWebNew/create'
import BlgWebNewListView from '@/views/Blog/BlgWebNew/list'
import BlgWebNewUpdateView from '@/views/Blog/BlgWebNew/update'

// routes
export default {
  path: '/blog',
  component: RLayoutModule,
  children: [
    {
      name: 'blg-web-new-blog',
      path: '',
      component: BlgWebNewBlogView,
    },
    {
      name: 'blg-web-new-create',
      path: 'crear',
      component: BlgWebNewCreateView,
      meta: {
        title: 'Crear blog'
      },
    },
    {
      name: 'blg-web-new-list',
      path: 'lista',
      component: BlgWebNewListView,
      meta: {
        title: 'Noticias'
      },
    },
    {
      name: 'blg-web-new-update',
      path: ':id',
      component: BlgWebNewUpdateView,
      meta: {
        title: 'Editar blog'
      },
    },
  ],
  meta: {
    title: 'Blog'
  },
  props: {
    indexText: 'Inicio',
  },
}
