<template lang='pug'>
  v-breadcrumbs( dark :items='items' )
</template>

<script>
  //
  import { VBreadcrumbs } from "vuetify/lib"
  //
  export default {
    name: "r-breadcrumbs-router",
    components: {
      VBreadcrumbs,
    },
    computed: {

      /**
       * Fetches the object.
       *
       * @return     {<type>}  { description_of_the_return_value }
       */
      fetchItems() {
        return this.$route.matched
          .filter(this.filterByMetaTitle)
          .map(this.mapMatchToItem)
      },

      /**
       * { function_description }
       *
       * @return     {Object}  { description_of_the_return_value }
       */
      firstItem() {
        return {
          exact: true,
          text: this.indexText,
          to: '/',
        }
      },
    },
    data() {
      return {
        items: []
      }
    },
    methods: {

      /**
       * { function_description }
       *
       * @param      {<type>}  match   The match
       * @return     {<type>}  { description_of_the_return_value }
       */
      filterByMetaTitle(item) {
        return item.hasOwnProperty('meta') && item.meta.hasOwnProperty('title')
      },

      /**
       * { function_description }
       *
       * @param      {<type>}  match   The match
       * @return     {Object}  { description_of_the_return_value }
       */
      mapMatchToItem(match) {
        return {
          disabled: match.regex.test(location.hash.replace('#', '')),
          exact: true,
          text: match.meta.title || '',
          to: match.path,
        }
      },

      /**
       * Resets the object.
       */
      reset() {
        this.items = [ this.firstItem, ...this.fetchItems ]
      },
    },
    mounted() {
      this.reset()
    },
    props: {

      /**
       * { item_description }
       */
      indexText: String,
    },
    watch: {
      
      /**
       * { function_description }
       */
      $route() {
        this.reset()
      },
    },
  }
</script>
