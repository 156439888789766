<template lang='pug'>
  // container
  v-container.fill-height.pa-6( fluid )
    v-layout( wrap )

      // breadcrumbs
      v-flex( xs12 pb-2 px-2 )
        r-breadcrumbs-router( class="pa-0" v-bind='breadcrumbsProps' )

      // router-view
      v-flex( xs12 py-3 )
        router-view
</template>

<script>
  import RBreadcrumbsRouter from '../breadcrumbs/BreadcrumbsRouter'
  export default {
    components: {
      RBreadcrumbsRouter,
    },
    computed: {
      breadcrumbsProps() {
        return {
          indexText: this.indexText,
        }
      },
    },    
    props: {      
      indexText: String,
    },
  }
</script>