// components
import UsrUniversityPeriodFields from '@/views/User/UsrUniversityPeriod/fields'
import UsrUniversityPeriodService from '@/services/api/User/UsrUniversityPeriod'
// component
export default {
  name: 'usr-university-period-update',
  computed: {

    /**
     * Gets the UsrUniversityPeriod update form props.
     *
     * @return {Object} The update form props.
     */
    formProps() {
      return {
        items: UsrUniversityPeriodFields.toUpdate(),
      }
    }
  },
  data() {
    return {
      usrUniversityPeriod: {
        value: 'test',
      },
    }
  },
  methods: {

    /**
     * Returns an update UsrUniversityPeriod success process.
     *
     * @param  {Object}  event - the event success payload.
     */
    onSuccessUpdate(event) {
      this.onSuccess(event)
    },

    /**
     * Returns a reset UsrUniversityPeriod form.
     *
     * @return {Promise} A form reset promise.
     */
    onReset() {
      return this.toWait(true).then(this.toRefresh).then(this.toWait)
    },

    /**
     * Returns a submit UsrUniversityPeriod form.
     *
     * @return {Promise} A form submit promise.
     */
    onSubmit() {
      return this.toWait(true).then(this.toUpdate).then(this.toWait)
    },

    /**
     * Returns a refresh UsrUniversityPeriod form.
     *
     * @return {Promise} A reset promise.
     */
    toRefresh() {
      return UsrUniversityPeriodService.model().find(this.getParam('id')).then(usrUniversityPeriod => {
        this.usrUniversityPeriod = usrUniversityPeriod//.reset()
      })
    },

    /**
     * Returns an update UsrUniversityPeriod promise.
     *
     * @param  {Object}  model - the usr-university-period model
     * @return {Promise} A update promise.
     */
    toUpdate() {
      return UsrUniversityPeriodService.update(this.usrUniversityPeriod).then(this.onSuccessUpdate).catch(this.onError)
    },
  },
  mounted() {
    this.onReset()
  },
}