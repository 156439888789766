// required modules
import { Fields } from 'collection-wrapper'
import ActActivityGroupModel from '@/models/Activity/ActActivityGroup'
import ActActivityStatusModel from '@/models/Activity/ActActivityStatus'
import ActActivityTypeModel from '@/models/Activity/ActActivityType'
import UsrFacilitatorModel from '@/models/User/UsrFacilitator'
import UsrUniversityPeriodService from '@/services/api/User/UsrUniversityPeriod'
import UsrUniversityCampusService from '@/services/api/User/UsrUniversityCampus'

/**
 * This class describes a ActActivity filters collection.
 * @class ActActivityFilters (name)
 */
export default class ActActivityFilters extends Fields {

  /**
   * Returns the ActActivity filters options.
   * @returns {Array} filters options.
   */
  fields() {
    return [
      {
        name: 'university_period_id',
        type: 'select-model',
        info: 'Tipo de atención que posee el facilitador.',
        props: {
          // autoSelectFirst: true,
          fetch: UsrUniversityPeriodService.toList(),
          selectProps: {
            clearable: true,
            itemText: 'identifier',
            itemValue: 'id',
            label: 'Semestre',
            menuProps: {
              contentClass: 'elevation-2 mt-2 v-list--dense',
            },
            placeholder: 'Semestre',
          },
        },
      },
      {
        name: 'title',
        type: 'text-field',
        props: {
          label: 'Titulo',
          placeholder: 'Titulo',
        },
      },
      {
        name: 'event_place',
        type: 'text-field',
        props: {
          label: 'Lugar',
          placeholder: 'Lugar',
        },
      },
      {
        name: 'activity_group_id',
        type: 'select-model',
        props: {
          fetch: () => ActActivityGroupModel.get(),
          selectProps: {
            itemText: 'title',
            itemValue: 'id',
            label: 'Grupo',
            menuProps: {
              contentClass: 'elevation-2 v-list--dense mt-2',
            },
            placeholder: 'Grupo',
          },
        },
      },
      {
        name: 'activity_type_id',
        type: 'select-model',
        props: {
          fetch: () => ActActivityTypeModel.get(),
          selectProps: {
            itemText: 'description',
            itemValue: 'id',
            label: 'Tipo',
            menuProps: {
              contentClass: 'elevation-2 v-list--dense mt-2',
            },
            placeholder: 'Tipo',
          },
        },
      },
      {
        name: 'activity_status_id',
        type: 'select-model',
        props: {
          fetch: () => ActActivityStatusModel.get(),
          selectProps: {
            itemText: 'description',
            itemValue: 'id',
            label: 'Estado',
            menuProps: {
              contentClass: 'elevation-2 v-list--dense mt-2',
            },
            placeholder: 'Estado',
          },
        },
      },      
      {
        name: 'university_campus_id',
        type: 'autocomplete-model',
        props: {
          clearable: true,
          fetch: UsrUniversityCampusService.toList(),
          limit: 15,
          autocompleteProps: {
            clearable: true,
            itemText: 'description',
            itemValue: 'id',
            label: 'Sede',
            multiple: false,
            placeholder: 'Sede',
          },
          menuProps: {
            contentClass: 'elevation-2 v-list--dense mt-2',
          },
        },
      },
      {
        name: 'owner_facilitator',
        type: 'select-model',
        props: {
          fetch: () => UsrFacilitatorModel.get(),
          selectProps: {
            itemText: 'name_case',
            itemValue: 'id',
            label: 'Responsable de la actividad',
            menuProps: {
              contentClass: 'elevation-2 v-list--dense mt-2',
            },
            placeholder: 'Responsable de la actividad',
          },
        },
      },
      {
        name: 'support_facilitator',
        type: 'select-model',
        props: {
          fetch: () => UsrFacilitatorModel.get(),
          selectProps: {
            itemText: 'name_case',
            itemValue: 'id',
            label: 'Facilitador de apoyo',
            menuProps: {
              contentClass: 'elevation-2 v-list--dense mt-2',
            },
            placeholder: 'Facilitador de apoyo',
          },
        },
      },
    ]
  }

  /**
   * Returns the ActActivity list filters.
   * @returns {Collection} filters collection.
   */
  static toList(props = {}) {

    // administrator & system filters
    if (props.isAdministrator) {
      return this.reduce('name', [
        'university_period_id',
        'title',
        'event_place',
        'activity_group_id',
        'activity_type_id',
        'activity_status_id',
        'activity_campus_id',
        'owner_facilitator',
        'support_facilitator',
      ])
    }

    // default filters
    return this.reduce('name', [
      'title',
      'event_place',
      'activity_group_id',
      'activity_type_id',
      'activity_status_id',
      'activity_campus_id',
      'support_facilitator',
    ])
  }

  /**
   * Returns the ActActivity search filters.
   * @returns {Collection} filters collection.
   */
  static toSearch() {
    return this.init().whereIn('name', [
      'title',
      'event_place',
      'activity_group_id',
      'activity_type_id',
      'activity_status_id',
      'support_facilitator',
    ])
    .each(item => {
      if(item.name == 'activity_status_id') {
        item.props.fetch = () => ActActivityStatusModel.whereIn('description', ['Confirmado', 'En Curso', 'Completado']).get()
      }
    })
    .all()
  }
}
