// required modules
import UsrFacilitatorFilters from '@/views/User/UsrFacilitator/filters'
import UsrFacilitatorHeaders from '@/views/User/UsrFacilitator/headers'
import UsrFacilitatorService from '@/services/api/User/UsrFacilitator'

// component
export default {
  name: 'usr-facilitator-list',
  computed: {

    /**
     *
     */
    tableProps() {
      return {
        filters: UsrFacilitatorFilters.toList(),
        headers: UsrFacilitatorHeaders.toList(),
        service: UsrFacilitatorService.toList(),
      }
    }
  },
  methods: {

    /**
     * Redirect to the UsrUser create.
     */
    onClickCreate() {
      this.goRoute('usr-facilitator-create')
    },

    /**
     * Set the selected UsrFacilitator table.
     *
     * @param  {Object}  event - the click item payload.
     */
    onClickItem(event) {
      this.selected = event.item
    },

    /**
     * Show a delete UsrFacilitator confirm toast.
     */
    onClickItemDelete() {
      this.$toaster('¿Confirma la eliminación del facilitador seleccionado?', 'confirmar', this.toDelete)
    },

    /**
     * Redirect to the UsrUser update.
     */
    onClickUpdate(event) {
      this.goRoute('usr-facilitator-update', { id: event.item.id })
    },

    /**
     * The delete UsrFacilitator error process.
     *
     * @param  {Object}  event - the error delete payload.
     */
    onError(event) {
      this.getErrorMessage(event).then(this.getErrorToast)      
    },

    /**
     * The delete UsrFacilitator success process.
     *
     * @param  {Object}  event - the success delete payload.
     */
    onSuccessDelete(event) {
      this.getSuccessMessage(event).then(this.getSuccessToast).then(this.toRefresh)    
    },

    /**
     * Return a delete UsrFacilitator promise.
     *
     * @param  {UsrFacilitatorModel}  model - the UsrFacilitator model.
     * @return {Promise} A delete process.
     */
    toDelete() {
      return UsrFacilitatorService.delete(this.selected).then(this.onSuccessDelete).catch(this.onError)
    },

    /**
     * Refresh the UsrFacilitator list.
     */
    toRefresh() {
      this.getRef('table').refresh()
    },
  },
}