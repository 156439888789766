// components
import SrvSurveyAnonymousModel from '@/models/Survey/SrvSurveyAnonymous'
import SrvSurveyAnonymousFields from '@/views/Survey/SrvSurveyAnonymous/fields'
import SrvSurveyAnonymousHeaders from '@/views/Survey/SrvSurveyAnonymous/headers'
import SrvSurveyFields from '@/views/Survey/SrvSurvey/fields'
import SrvSurveyService from '@/services/api/Survey/SrvSurvey'

// component
export default {
  name: 'srv-survey-answers',
  computed: {

    /**
     * 
     */
    formProps() {
      return {
        items: SrvSurveyAnonymousFields.toDetail(),
      }
    },

    /**
     * Gets the SrvSurvey answers form props.
     * @return {Object} The answers form props.
     */
    tableProps() {
      return {
        headers: SrvSurveyAnonymousHeaders.toList(),
        service: SrvSurveyService.from(this.srvSurveyAnonymous)
      }
    },
  },
  data() {
    return {
      dialogs: {
        detail: false,
      },
      selected: {},
      srvSurvey: {
        value: 'test',
      },
      srvSurveyAnonymous: [],
    }
  },
  methods: {

    /**
     * 
     */
    onUpdate() {
      this.goRoute('srv-survey-update', { id: this.getParam('id') })
    },

    /**
     * 
     */
    onClickDetail(event) {
      this.dialogs.detail = true
      this.selected = event.item
    },

    /**
     * 
     */
    onClickDownload() {
      SrvSurveyService.excelDownload(this.srvSurvey)
    },

    /**
     * Returns a reset SrvSurvey form.
     * @return {Promise} A form reset promise.
     */
    onReset() {
      return this.toWait(true).then(this.toRefresh).then(this.toWait)
    },

    /**
     * Returns a refresh SrvSurvey form.
     * @return {Promise} A reset promise.
     */
    toRefresh() {
      return SrvSurveyService.model().find(this.getParam('id')).then(srvSurvey => {
        srvSurvey.anonymous().get().then(srvSurveyAnonymous => {
          console.log({
            srvSurveyAnonymous,
          })
          this.srvSurveyAnonymous = srvSurveyAnonymous.map(e => SrvSurveyAnonymousModel.from(e).reset())
        })
        this.srvSurvey = srvSurvey//.reset()
      })
    },
  },
  mounted() {
    this.toRefresh()
  },
}