// required modules
import Model from '@/models/Model'
import AAssignQuotaModel from '@/models/Actions/AAssignQuota'
import ACloseInscriptionsModel from '@/models/Actions/ACloseInscriptions'
import ACompleteModel from '@/models/Actions/AComplete'
import AConfirmModel from '@/models/Actions/AConfirm'
import AEnrollModel from '@/models/Actions/AEnroll'
import AEnrollStudentModel from '@/models/Actions/AEnrollStudent'
import ALeaveModel from '@/models/Actions/ALeave'
import ASendNotificationModel from '@/models/Actions/ASendNotification'
import ASendSatisfactionSurveyModel from '@/models/Actions/ASendSatisfactionSurvey'
import AStartModel from '@/models/Actions/AStart'
import ATakeAssistanceModel from '@/models/Actions/ATakeAssistance'
import AUpdateBodyModel from '@/models/Actions/AUpdateBody'
import AUpdateConfirmedModel from '@/models/Actions/AUpdateConfirmed'
import AUpdateDraftModel from '@/models/Actions/AUpdateDraft'
import AUploadImageModel from '@/models/Actions/AUploadImage'

/**
 * This class describes a ActActivity model.
 * @class 
 */
export default class ActActivityModel extends Model {

  /**
   * 
   */
  assignQuota() {
    return this.hasMany(AAssignQuotaModel)
  }

  /**
   * 
   */
  get body_body() {
    return this.getProperty('body.body', '')
  }

  /**
   * 
   */
  get can_attendance() {
    return this.is_start || this.is_complete
  }

  /**
   * 
   */
  get can_close_inscriptions() {
    return !this.prop('closed_inscriptions', false)
  }

  /**
   * 
   */
  get can_complete() {
    return this.getProperty('activity_status_id', 0) === 3 && this.im_owner
  }

  /**
   * 
   */
  get can_confirm() {
    return this.getProperty('activity_status_id', 0) === 1 && this.im_owner
  }

  /**
   * 
   */
  get can_delete() {
    return this.im_owner
  }

  /**
   * 
   */
  get can_edit() {
    return this.getProperty('activity_status_id', 0) <= 2 && this.im_owner
  }

  /**
   * 
   */
  get can_edit_confirm() {
    return this.getProperty('activity_status_id', 0) <= 2 && this.im_owner
  }

  /**
   * 
   */
  get can_notify() {
    return this.im_owner
  }

  /**
   * 
   */
  get can_open_inscriptions() {
    return this.prop('closed_inscriptions', false)
  }

  /**
   * 
   */
  get can_start() {
    return this.getProperty('activity_status_id', 0) === 2 && this.im_owner
  }

  /**
   * 
   */
  get can_survey() {
    return this.prop('satisfaction_survey_id', null) !== null
  }

  /**
   * 
   */
  get can_update_confirmed() {
    return this.getProperty('activity_status_id', 0) === 2
  }

  /**
   * 
   */
  get can_update_draft() {
    return this.getProperty('activity_status_id', 0) === 1
  }

  /**
   * 
   */
  get closed_inscriptions_label() {
    return this.prop('closed_inscriptions', false) ? 'Cerradas' : 'Abiertas'
  }

  /**
   * 
   */
  closeInscriptions() {
    return this.hasMany(ACloseInscriptionsModel)
  }

  /**
   * 
   */
  complete() {
    return this.hasMany(ACompleteModel)
  }

  /**
   * 
   */
  confirm() {
    return this.hasMany(AConfirmModel)
  }

  /**
   * 
   */
  get created_at_short() {
    return this.prop('created_at', '').split(' ').pop()
  }

  /**
   * 
   */
  enroll() {
    return this.hasMany(AEnrollModel)
  }

  /**
   * 
   */
  enrollStudent() {
    return this.hasMany(AEnrollStudentModel)
  }

  /**
   * 
   */
  get external_facilitator_name() {
    return this.getProperty('external_facilitator.name', '')//.reduce((res, item) => item.names, '')
  }

  /**
   * @description
   * Event datetime formatted.
   * 
   * @type {string}
   */
  get event_datetime_short() {

    // required data
    const time = this.prop('event_datetime', '').split(' ').pop().split(':').slice(0, 2).join(':')
    const date = this.prop('event_datetime', '').split(' ').shift().split('-').reverse().join('-')

    // formatted data
    return `${ date } ${ time }`
  }

  /**
   * @description
   * Event datetime formatted.
   * 
   * @type {string}
   */
  get event_finish_datetime_short() {

    // required data
    const time = this.prop('event_finish_datetime', '').split(' ').pop().split(':').slice(0, 2).join(':')
    const date = this.prop('event_finish_datetime', '').split(' ').shift().split('-').reverse().join('-')

    // formatted data
    return `${ date } ${ time }`
  }

  /**
   * 
   */
  get facilitator_full_name() {

    //
    const first_names = this.getProperty('facilitator.first_names', '')
    const last_name1 = this.getProperty('facilitator.last_name1', '')

    //
    return `${ first_names } ${ last_name1 }`
  }

  /**
   * 
   */
  get facilitator_name() {
    return `${ this.getProperty('facilitator.first_names', '') } ${ this.getProperty('facilitator.last_name1', '') }`
  }

  /**
   * 
   */
  get has_url_image_path() {
    return !!this.url_image_path
  }

  /**
   * 
   */
  get im_owner() {
    return this.facilitator_user_id === this.store().getters.userId
  }

  /**
   * 
   */
  get is_complete() {
    return this.activity_status_id === 4
  }

  /**
   * 
   */
  get is_confirm() {
    return this.activity_status_id === 2
  }

  /**
   * 
   */
  get is_draft() {
    return this.activity_status_id === 1
  }

  /**
   * 
   */
  get is_start() {
    return this.activity_status_id === 3
  }

  /**
   * 
   */
  leave(){
    return this.hasMany(ALeaveModel)
  }

  mapStudentCareers(careers = []) {
    return careers.reduce((result, career) => {

      let careerId    = career.career_id || career.university_career_id
      let careerYear  = career.career_year || career.university_career_year || career.year

      if (Array.isArray(careerYear)) {
        careerYear.forEach(year => {
          result.push({
            career_id: careerId,
            career_year: year,
          })
        })
      } else {
        result.push({
          career_id: careerId,
          career_year: careerYear,
        })
      }

      return result
    }, [])
  }

  get quotasFormatted() {
    return this.quotas.map(quota => {
      quota.career_rules_formatted = quota.career_rules.reduce((t, career) => {
        
        let careerFind  = t.find(e => e.university_career_id == career.university_career_id)
        let careerNew   = { year: [] } 
        let careerYear  = career.career_year || career.university_career_year || career.year

        Object.assign(careerNew, careerFind, career)

        if (!career.year) {
          career.year = []
        }
  
        if (careerFind) {
          if (Array.isArray(careerYear)) {
            careerNew['career_year'].concat(careerYear)
            careerNew['year'].concat(careerYear)
            career['year'].concat(careerYear)
          } else {
            careerNew['career_year'].push(careerYear)
            careerNew['year'].push(careerYear)
            if (Array.isArray(career['year'])) {
              career['year'].push(careerYear)
            } else {
              career['year'] = [careerYear]
            }
          }
        } else {
          if (Array.isArray(careerYear)) { 
            careerNew['career_year'] = careerYear
            careerNew['year'] = careerYear
            career['year'] = careerYear
          } else {
            careerNew['career_year'] = [careerYear]
            careerNew['year'] = [careerYear]
            career['year'] = [careerYear]
          }
          t.push(careerNew)
        }

        return t
      }, [])
      return quota
    })
  }

  /**
   * Define the ActActivity reset values.
   * @return {ActActivityModel} ActActivity reset.
   */
  reset() {
    
    //
    this.body = this.getProperty('body.body', '')
    this.support_facilitators_ids = this.getProperty('support_facilitators', []).map(({ facilitator_id }) => facilitator_id)
    this.send_email = false
    this.notify_students = true
    
    //
    return this
  }

  /**
   * Define the ActActivity resource.
   * @return {String} ActActivity endpoint name.
   */
  resource() {
    return 'activity'
  }

  /**
   * 
   */
  sendNotification() {
    return this.hasMany(ASendNotificationModel)
  }

  /**
   * 
   */
  sendSatisfactionSurvey() {
    return this.hasMany(ASendSatisfactionSurveyModel)
  }

  /**
   * 
   */
  sortAttendance() {
    if (this.attendance_view) {
      this.attendance_view.sort((current, next) => {
        return current.student.first_names.localeCompare(next.student.first_names)
      })
    }
  }

  /**
   * 
   */
  start() {
    return this.hasMany(AStartModel)
  }

  /**
   * 
   */
  get status_description() {
    return this.getProperty('status.description', '')
  }

  /**
   * 
   */
  get support_facilitators_names() {
    return this.getProperty('support_facilitators', []).map(({ facilitator }) => `${ facilitator.first_names } ${ facilitator.last_name1 }`).join(', ')
  }

  /**
   * { property_description }
   * @type       {<type>}
   */
  get type_description() {
    return this.getProperty('type.description', '')
  }

  /**
   * { function_description }
   * @return     {ATakeAssistanceModel}  { description_of_the_return_value }
   */
  takeAssistance() {
    return this.hasMany(ATakeAssistanceModel)
  }

  /**
   * 
   */
  toAssignQuota() {

    //
    var clone = this.reduce([
      'quotas',
    ])

    console.log({ clone })

    //
    clone.quotas = clone.quotas.map(item => {

      let studentQuota = Number(item.student_quota)
      let studentCareers = this.mapStudentCareers(item.career_rules)

      if (item.user_rules && item.user_rules.length) {
        studentQuota = item.user_rules.length
      }

      return {
        //...item,
        activity_quota_id: Number(item.id),
        is_public: item.is_public,
        student_quota: studentQuota,
        careers: studentCareers,
        // careers: [
        //   ...(item.career_rules || []).map(item => ({
        //     career_id: item.career_id || item.university_career_id,
        //     career_year: item.career_year || item.university_career_year || item.year,
        //   }))
        // ],
        programs: [
          ...(item.program_rules || []).map(item => item.program_id || item.university_program_id)
        ],
        users: [
          ...(item.user_rules || []).map(item => item.user_id || item.student_user_id)
        ],
      }
    })

    //
    return clone
  }

  /**
   * 
   */
  toCloseInscriptions() {

    //
    this.closed_inscriptions = true

    //
    return this.reduce([
      'closed_inscriptions'
    ])
  }

  /**
   * Return a complete representation.
   * @return {ActActivity} ActActivity complete model.
   */
  toComplete() {
    return this.reduce([
      'id'
    ])
  }

  /**
   * Return a confirm representation.
   * @return {ActActivity} ActActivity confirm model.
   */
  toConfirm() {
    return this.reduce([
      'id',
      'notify_students',
    ])
  }

  /**
   * Return a create representation.
   * @return {ActActivity} ActActivity create model.
   */
  toCreate() {

    //
    var clone = this.clone()

    //
    clone.external_facilitator_id = clone.getProperty('external_facilitator.id', null)
    clone.support_facilitators = this.support_facilitators_ids || []

    //
    return clone.reduce([
      'title', 
      'event_datetime',
      'event_finish_datetime',
      'event_place',
      'body',
      'activity_group_id',
      'activity_type_id',
      'support_facilitators',
      'external_facilitator_id',
      'closing_subscription_minutes',
    ])
  }

  /**
   * Return a enroll representation.
   * @return {ActActivity} ActActivity enroll model.
   */
  toEnroll() {
    return this.reduce([
      'id',
    ])
  }

  /**
   * Return a enroll student representation.
   * @return {ActActivity} ActActivity enroll student model.
   */
  toEnrollStudent() {

    // define the enroll student payload
    return this.reduce(['attendance_view']).attendance_view.reduce((inscriptions, item) => { 

      //
      if (item.is_new) {

        // define required index
        let index  = inscriptions.findIndex(subitem => subitem.activity_quota_id === item.activity_quota_id)
        let is_new = 0 > index

        // determine the populate payload method
        if (is_new) {
          inscriptions.push({
            activity_quota_id: item.activity_quota_id,
            users: [ item.student.id ],
          })
        } else {
          inscriptions[index] = {
            ...inscriptions[index],
            users: [
              ...inscriptions[index].users,
              item.student.id,
            ]
          }
        }
      }

      // enroll student iterable payload
      return inscriptions
    }, [])
  }

  /**
   * Return a leave representation.
   * 
   * @return {ActActivity} ActActivity leave model.
   */
  toLeave() {
    return this.reduce([
      'id',
    ])
  }

  /**
   * 
   */
  toOpenInscriptions() {

    //
    this.closed_inscriptions = false

    //
    return this.reduce([
      'closed_inscriptions'
    ])
  }

  /**
   * Return a send notification representation.
   * 
   * @return {ActActivity} ActActivity send notification model.
   */
  toSendNotification() {
    //
    this.send_email = this.send_email === null ? false : this.send_email
    //
    return this.reduce([
      'id',
      'send_email',
      'target',
      'message_subject',
      'message_body',
    ])
  }

  /**
   * Return a start representation.
   * 
   * @return {ActActivity} ActActivity start model.
   */
  toStart() {
    return this.reduce([
      'id'
    ])
  }

  /**
   * Return a take assistance representation.
   * 
   * @return {ActActivity} ActActivity take assistance model.
   */
  toTakeAssistance() {
    return this.reduce([
      'attendance',
    ])
  }

  /**
   * Return a update representation.
   * 
   * @return {ActActivity} ActActivity update model.
   */
  toUpdate() {
    return this.reduce([
      'title', 
      'event_datetime',
      'event_finish_datetime',
      'event_place',
      'body',
      'id',
      'activity_type_id',
      'support_facilitators',
      'external_facilitator_id',
      'closing_subscription_minutes',
    ])
  }

  /**
   * Return a update representation.
   * 
   * @return {ActActivity} ActActivity update body model.
   */
  toUpdateBody() {
    return this.reduce([
      'body',
    ])
  }

  /**
   * Return a update representation.
   * 
   * @return {ActActivity} ActActivity update confirmed model.
   */
  toUpdateConfirmed() {
    return this.reduce([
      'body',
    ])
  }

  /**
   * Return a update representation.
   * @return {ActActivity} ActActivity update draft model.
   */
  toUpdateDraft() {

    //
    var clone = this.clone()

    //
    clone.external_facilitator_id = clone.getProperty('external_facilitator.id', null)
    clone.support_facilitators = this.support_facilitators_ids || []

    //
    return clone.reduce([
      'title',
      'event_datetime',
      'event_finish_datetime',
      'event_place',
      'activity_type_id',
      'external_facilitator_id',
      'support_facilitators',
    ])
  }

  /**
   * Return a update representation.
   * @return {ActActivity} ActActivity update confirmed model.
   */
  toUploadImage() {
    return this.toFormData([
      'image',
    ])
  }

  /**
   * 
   */
  get type_description() {
    return this.getProperty('type.description', '')
  }

  /**
   * Define the ActActivity/UpdateBody endpoint.
   * @return {AUpdateBodyModel} a AUpdateBodyModel model.
   */
  updateBody() {
    return this.hasMany(AUpdateBodyModel)
  }

  /**
   * Define the ActActivity/UpdateConfirmed endpoint.
   * @return {AUpdateConfirmedModel} a AUpdateConfirmedModel model.
   */
  updateConfirmed() {
    return this.hasMany(AUpdateConfirmedModel)
  }

  /**
   * Define the ActActivity/UpdateDraft endpoint.
   * @return {AUpdateDraftModel} a AUpdateDraftModel model.
   */
  updateDraft() {
    return this.hasMany(AUpdateDraftModel)
  }

  /**
   * Define the ActActivity/UploadImage endpoint.
   * @return {AUploadImageModel} a AUploadImageModel model.
   */
  uploadImage() {
    return this.hasMany(AUploadImageModel)
  }
}