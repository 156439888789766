// app layouts
import AppLayoutBlog from '@/components/layouts/blog'
import AppLayoutBoard from '@/components/layouts/board'
import AppLayoutCalendar from '@/components/layouts/calendar'
import AppLayoutForm from '@/components/layouts/form'
import AppLayoutList from '@/components/layouts/list'
import AppLayoutTable from '@/components/layouts/table'

// app components
import AppBtnIcon from '@/components/buttons/icon'
import AppDialogForm from 'vuetify2-wrappers/src/components/dialogs/form'

// app plugin
export default {
  install: function (Vue, options) {
    Vue.mixin({
      components: {
        AppLayoutBlog,
        AppLayoutBoard,
        AppLayoutCalendar,
        AppLayoutForm,
        AppLayoutList,
        AppLayoutTable,
        AppBtnIcon,
        AppDialogForm,
      },
      computed: {

        /**
         * Gets the error toast.
         * @return  {function}  The error toast.
         */
        getErrorToast() {
          return this.getToast('error')
        },

        /**
         * Gets the success toast.
         * @return  {function}  The success toast.
         */
        getSuccessToast() {
          return this.getToast('success')
        },
      },
      methods: {

        /**
         * Gets the error.
         * @param   {object}  error - The error.
         * @return  {object}          The error object.
         */
        getError(error) {
          if (error.config && error.config.url.includes('upload_image')) return 'La imagen es demasiado grande (máximo: 2 MB).'
          if (!error.response) return error
          if (!error.response.data) return error.response
          if (!error.response.dat)
          return error.response.data
        },

        /**
         * 
         */
        getErrorImageOverload() {

        },

        /**
         * Gets the error message.
         * @param   {object}  error - The error.
         * @return  {Promise}         The error message getter promise.
         */
        getErrorMessage(error) {
          return Promise.resolve(error).then(this.getError).then(this.getMessage)
        },

        /**
         * Gets the message.
         * @param   {object}  error - The error.
         * @return  {string}          The message string.
         */
        getMessage(error) {
          if (error.errors) return Object.values(error.errors).map(error => error.join()).join('\n')
          if (error.message) return error.message
          if (error.error) return error.error
          return error
        },

        /**
         * Gets the parameter.
         * @param   {string}  param - The parameter.
         * @return  {*}               The parameter.
         */
        getParam(param) {
          return this.$route.params[param]
        },

        /**
         * Gets the query.
         * @param   {string}  query - The query.
         * @return  {*}               The query.
         */
        getQuery(query) {
          return this.$route.query[query]
        },

        /**
         * Gets the reference.
         * @param   {string}  ref - The reference.
         * @return  {object}        The reference.
         */
        getRef(ref) {
          return this.$refs[ref]
        },

        /**
         * Gets the success message.
         * @param   {object}  response -  The response.
         * @return  {string}              The success message.
         */
        getSuccessMessage(response) {
          return Promise.resolve({ response }).then(this.getError).then(this.getMessage)
        },

        /**
         * Gets the toast.
         * @param   {string}    toast - The toast.
         * @return  {function}          The toast.
         */
        getToast(toast) {
          return this.$toast ? this.$toast[toast] : null
        },

        /**
         * Go to the specified route.
         * @param {string}  route - The route.
         * @param {object}  params - The parameters.
         */
        goRoute(route, params) {
          this.$router.push({ name: route, params })
        },

        /**
         * Returns an create ActActivity error process.
         * @param  {object}  event - the event error payload.
         */
        onError(event) {
          this.getErrorMessage(event).then(this.getErrorToast)
        },

        /**
         * Returns an create ActActivity success process.
         * @param  {object}  event - the event success payload.
         */
        onSuccess(event) {
          this.getSuccessMessage(event).then(this.getSuccessToast)
        },

        /**
         * Remove the background.
         * @param {Array} color - The color.
         */
        removeBackground(...color) {
          document.querySelector('.v-application--wrap').classList.remove(...color)
        },

        /**
         * Sets the background.
         * @param {Array} color - The color.
         */
        setBackground(...color) {
          document.querySelector('.v-application--wrap').classList.add(...color)
        },

        /**
         * Helper functionality to manage functions of sub-components
         */
        toBusy(ref = 'form', func = 'busy') {

          // required data
          const reference = this.getRef(ref)
          const subfunction = reference ? reference[func] : null

          // result
          return subfunction
        },

        /**
         * 
         */
        $toggle(target = 'info', wrap = 'dialogs') {
          return (value = false) => {
            this[wrap][target] = value
          }
        },

        /**
         * Helper functionality to manage functions between another function
         */
        $whereas(init = true, between = () => {}, process = () => {}) {
          Promise
            .resolve(init)
            .then(between)
            .then(process)
            .then(between)
        },

        /**
         * 
         * @param  {...any} args 
         */
        $param(...args) {
          return this.getParam(...args)
        },
      },
    })
  }
}
