// required modules
import { Headers } from 'collection-wrapper'

/**
 * This class describes a NtMailRrss headers collection.
 *
 * @class NtMailRrssHeaders (name)
 */
export default class NtMailRrssHeaders extends Headers {

  /**
   * Returns the NtMailRrss headers options.
   *
   * @returns {Array} headers options.
   */
  headers() {
    return [
      {
        text: 'Orden',
        value: 'order',
      },
      {
        text: 'Enlace',
        value: 'link',
      },
    ]
  }

  /**
   * Returns the NtMailRrss list headers.
   *
   * @returns {Collection} headers collection.
   */
  static toList() {
    return this.reduce('value', [
      'order',
      'link',
    ])
  }
}
