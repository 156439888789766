// required modules
import Model from '@/models/Model'
import AMoveModel from '@/models/Actions/AMove'

/**
 * This class describes a BlgWebNewImage model.
 * @class
 */
export default class BlgWebNewImageModel extends Model {

  /**
   * 
   */
  move() {
    return this.hasMany(AMoveModel)
  }

  /**
   * 
   */
  moves(position = 0) {
    this.new_position = this.order + position
  }

  /**
   * Define the BlgWebNewImage resource.
   * @return {String} BlgWebNewImage endpoint name.
   */
  resource() {
    return 'web_new_image'
  }

  /**
   * 
   */
  toMoveBack() {
    this.moves(-1)
    return this.reduce([
      'id',
      'new_position'
    ])
  }

  /**
   * 
   */
  toMoveForward() {
    console.log(this)
    this.moves(1)
    return this.reduce([
      'id',
      'new_position'
    ])
  }
}