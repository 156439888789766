// required modules
import ActActivityModel from '@/models/Activity/ActActivity'
import StaStudentAttentionModel from '@/models/StudentAttention/StaAttention'
import UsrCareerModel from '@/models/User/UsrCareer'
import UsrProgramModel from '@/models/User/UsrProgram'
import UsrUserModel from '@/models/User/UsrUser'
import Model from '@/models/Model'

/**
 * This class describes a SrvSurveyTarget model.
 * @class
 */
export default class SrvSurveyTargetModel extends Model {

  /**
   * 
   */
  deleteIdentifierByIdentifier(identifier) {
    let items = this.identifiers.slice()
    let index = this.identifiers.findIndex(e => e.identifier == identifier)
    items.splice(index, 1)
    this.identifiers = items
  }

  /**
   * 
   */
  deleteTargetIdentifierByIdentifier(identifier) {
    let items = this.target_identifiers.slice()
    let index = this.target_identifiers.findIndex(e => e.identifier == identifier)
    items.splice(index, 1)
    this.target_identifiers = items
  }

  /**
   * 
   */
  get identifiers_length() {
    return this.getProperty('identifiers.length', 0) || this.getProperty('target_identifiers.length', 0)
  }

  /**
   * 
   */
  mapIdentifier = identifier => {
    return typeof identifier === "number" ? identifier : identifier.identifier
  }

  /**
   * 
   */
  populateIdentifiers() {
    this.getProperty('identifiers', []).forEach(item => {
      this.target_model.find(item.identifier).then(related => {
        switch(this.model) {
          case "Modules\\ActivityModule\\Entities\\Activity": 
            item['name'] = related.title || 'Desconocido'
            break;
          case "Modules\\UserModule\\Entities\\Career": 
            item['name'] = related.description || 'Desconocido'
            break;
          case "Modules\\StudentAttentionModule\\Entities\\Attention": 
            item['name'] = related.attention_type.description || 'Desconocido'
            break;
          case "Modules\\UserModule\\Entities\\Program": 
            item['name'] = related.description || 'Desconocido'
            break;
          case "Modules\\UserModule\\Entities\\User": 
            item['name'] = related.name_case || 'Desconocido'
            break;
        }        
      })
      return item
    })
  }

  /**
   * Define the SrvSurveyTarget resource.
   * @return {String} SrvSurveyTarget endpoint name.
   */
  resource() {
    return 'survey_target'
  }

  /**
   * 
   */
  reset() {
    this.getProperty('target_identifiers', []).forEach(item => {
      item['name'] = ''
    })
    return this
  }

  /**
   * 
   */
  get target_model() {
    switch(this.model) {
      case "Modules\\ActivityModule\\Entities\\Activity": 
        return this.target_model_activity
      case "Modules\\StudentAttentionModule\\Entities\\Attention": 
        return this.target_model_attention
      case "Modules\\UserModule\\Entities\\Career": 
        return this.target_model_career
      case "Modules\\UserModule\\Entities\\Program": 
        return this.target_model_program
      case "Modules\\UserModule\\Entities\\User": 
        return this.target_model_user
    }
    return null
  }

  /**
   * 
   */
  get target_model_activity() {
    return ActActivityModel
  }

  /**
   * 
   */
  get target_model_attention() {
    return StaStudentAttentionModel.include('attention_type')
  }

  /**
   * 
   */
  get target_model_career() {
    return UsrCareerModel
  }

  /**
   * 
   */
  get target_model_program() {
    return UsrProgramModel
  }

  /**
   * 
   */
  get target_model_user() {
    return UsrUserModel
  }

  /**
   * 
   */
  toUpdate() {
    
    //
    var clone = this.clone()
    var cloneIdentifiers = this.clone().getProperty('identifiers', []).map(this.mapIdentifier)

    //
    clone.identifiers = cloneIdentifiers
    
    //
    return clone.reduce([
      'model',
      'name',
      'target_type',
      'identifiers',
      'career_year',
    ])
  }
}