// required modules
import { Headers } from 'collection-wrapper'

/**
 * This class describes a SrvSurveySection headers collection.
 *
 * @class SrvSurveySectionHeaders (name)
 */
export default class SrvSurveySectionHeaders extends Headers {

  /**
   * Returns the SrvSurveySection headers options.
   *
   * @returns {Array} headers options.
   */
  headers() {
    return [
      {
        text: '#',
        value: 'id',
      },
      {
        text: 'Titulo',
        value: 'title',
      },
      {
        text: 'Descripción',
        value: 'description',
      },
      {
        text: 'N° de bloques',
        value: 'blocks_length',
      },
    ]
  }

  /**
   * Returns the SrvSurveySection list headers.
   *
   * @returns {Collection} headers collection.
   */
  static toList() {
    return this.reduce('value', [
      // 'id',
      'title',
      // 'description',
      'blocks_length',
    ])
  }
}
