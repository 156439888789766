// required modules
import Service from '@/services/api/Service'
import RptReportActivityQuantityModel from '@/models/Report/RptReportActivityQuantity'

/**
 * This class describes a RptReportActivityQuantity service.
 * @class
 */
export default class RptReportActivityQuantityService extends Service {

  /**
   * Define the RptReportActivityQuantity model.
   * @return {Function} RptReportActivityQuantity resource.
   */
  static model() {
    return RptReportActivityQuantityModel
  }
}