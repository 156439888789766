// layouts
// import RLayoutTableNew from '@/components/layouts/LayoutTable'
// components
import ActActivityFields from '@/views/Activity/ActActivity/fields'
import ActActivityFilters from '@/views/Activity/ActActivity/filters'
import ActActivityHeaders from '@/views/Activity/ActActivity/headers'
// headers
import UsrCareerHeaders from '@/views/User/UsrCareer/headers'
import UsrUserHeaders from '@/views/User/UsrUser/headers'
// service
import ActActivityService from '@/services/api/Activity/ActActivity'
import UsrCareerService from '@/services/api/User/UsrCareer'
import UsrProgramService from '@/services/api/User/UsrProgram'
import UsrUserService from '@/services/api/User/UsrUser'
// career
import UsrCareerFilters from '@/views/User/UsrCareer/filters'
// program
import UsrProgramFilters from '@/views/User/UsrProgram/filters'
import UsrProgramHeaders from '@/views/User/UsrProgram/headers'
// student
import UsrStudentFilters from '@/views/User/UsrStudent/filters'
import UsrStudentHeaders from '@/views/User/UsrStudent/headers'
import UsrStudentService from '@/services/api/User/UsrStudent'
// component
export default {
  name: 'act-activity-quotas',
  components: {
    // RLayoutTableNew,
  },
  computed: {

    /**
     * 
     */
    createProps() {
      return {
        items: ActActivityFields.toQuota(this.actActivity, this.actActivityQuota),
        submitButtonProps: {
          color: 'secondary',
          type: 'button'
        },
      }
    },

    /**
     * 
     */
    updateProps() {
      return {
        items: ActActivityFields.toQuota(this.actActivity, this.actActivityQuotaSelected)
      }
    },

    /**
     * 
     */
    careerTableProps() {
      return {
        filters: UsrCareerFilters.toList(),
        headers: UsrCareerHeaders.toList(),
        service: UsrCareerService.toList(),
      }
    },

    /**
     * 
     */
    programTableProps() {
      return {
        filters: UsrProgramFilters.toList(),
        headers: UsrProgramHeaders.toList(),
        service: UsrProgramService.toList(),
      }
    },

    /**
     * 
     */
    userTableProps() {
      return {
        filters: UsrStudentFilters.toList(),
        headers: UsrStudentHeaders.toList(),
        service: UsrStudentService.toList(),
      }
    },
  },
  data() {
    return {
      actActivity: {
        quotas: [],
      },
      actActivityQuota: {
        student_quota: 0,
        type_quota: 'Pública',
      },
      actActivityQuotaSelected: {
        student_quota: 0,
        type_quota: 'Pública',
      },
      loading: false,
      selectedIndex: null,
      selectedSubindex: null,
      dialogs: {
        create: false,
        sub_create: false,
        update: false,
      }
    }
  },
  methods: {

    /**
     * 
     */
    reset() {
      this.toWaitCard(true).then(this.refresh).then(this.toWaitCard)
    },

    /**
     * 
     */
    resetActivityQuota() {
      this.actActivityQuota = {
        student_quota: 0,
        type_quota: 'Pública',
      }
    },

    /**
     * Refresh the ActActivity list
     */
    refresh() {
      return ActActivityService.model().include('quotas.careerRules.career,quotas.programRules.program,quotas.userRules.student').find(this.getParam('id')).then(res => {
        this.actActivity = res.reset()
      })
    },

    /**
     * 
     */
    submit() {
      this.toWaitCard(true).then(this.toAssignQuota).then(this.toWaitCard)
    },

    /**
     * Show a delete ActActivity confirm toast
     */
    submitDelete() {
      this.$toaster('¿Confirma la eliminación de la cuota seleccionada?', 'confirmar', this.toDelete)
    },

    /**
     * Show a delete ActActivity confirm toast
     */
    submitDeleteCareer() {
      let toast = this.$toaster('¿Confirma la eliminación de la carrera seleccionada?', 'confirmar', () => {
        let cmp = this.$toast.getCmp()
        // console.log('ola', cmp)
        this.toDeleteCareer()
        cmp.close()
      })
    },

    /**
     * Show a delete ActActivity confirm toast
     */
    submitDeleteProgram() {
      this.$toaster('¿Confirma la eliminación del programa seleccionado?', 'confirmar', this.toDeleteProgram)
    },

    /**
     * Show a delete ActActivity confirm toast
     */
    submitDeleteUser() {
      this.$toaster('¿Confirma la eliminación del usuario seleccionado?', 'confirmar', this.toDeleteUser)
    },

    /**
     * Set the selected ActActivity table
     * @param  {Number}  index - the click item index
     * @param  {Number}  subindex - the click item subindex
     */
    submitItem(index, subindex) {
      this.selectedIndex = index
      this.selectedSubindex = subindex
    },

    /**
     * 
     * @param {*} event 
     */
    submitItemUpdate(quota) {
      
      //
      this.actActivityQuotaSelected = {
        ...quota,
        type_quota: quota.is_public ? 'Pública' : quota.user_rules.length ? 'Usuarios' : quota.career_rules.length ? 'Carreras' : 'Programas'
      }

      //
      let careerTable   = this.getRef('career_selected')
      let programTable  = this.getRef('program_selected')
      let userTable     = this.getRef('user_selected')

      //
      // console.log(careerTable)
      if (careerTable) {
        let insideTable = careerTable.$children.slice().shift()
        insideTable.$watch('items', () => {

          // let careerRules = quota.career_rules.reduce((t, i) => {

          //   let careerId = i.university_career_id
          //   let careerYear = i.university_career_year
          //   let careerQuota = t.find(q => q.university_career_id == careerId)

          //   if (!careerQuota) {
          //     careerQuota = i
          //     careerQuota['year'] = []
          //   }

          //   if (careerQuota) {
          //     careerQuota['year'].push(careerYear)
          //   }

          //   t.push(careerQuota)

          //   return t
          // }, [])
          
          // console.log({ careerRules })

          quota.career_rules_formatted.forEach(rule => {
            let career = insideTable.items.find(career =>  career.id == rule.university_career_id)
            console.log({ career, rule })
            career['year'] = rule.career_year
            insideTable.select(career)
          })
        })
      }
      // careerTable.selected = quota.career_rules.map(item => {
        // item.career.year = item.university_career_year || item.career_year || item.year
        // console.log({ item })
        // return item.career
      // })

      if (programTable) {
        programTable.selected = quota.program_rules.map(item => item.program)
      }

      if (userTable) {
        userTable.selected = quota.user_rules.map(item => item.user)
      }
    },

    /**
     * Set the new ActActivity quota
     * @param  {Object}  event - the new quota
     */
    submitQuota(event) {

      //
      let is_public = this.actActivityQuota.type_quota === 'Pública'
      let career_rules = []
      let program_rules = []
      let user_rules = []
      let career = this.getRef('career')
      let program = this.getRef('program')
      let user = this.getRef('user')

      //
      //console.log({ career })

      //
      if (this.actActivityQuota.type_quota === 'Carreras') {
        career_rules = career.getSelected().map(career => {
          return {
            career,
            career_id: career.id,
            career_year: career.year || 0,
          }
        })
      }

      //
      if (this.actActivityQuota.type_quota === 'Programas') {
        program_rules = program.getSelected().map(program => {
          return {
            program,
            program_id: program.id,
          }
        })
      }

      //
      if (this.actActivityQuota.type_quota === 'Usuarios') {
        user_rules = user.getSelected().map(user => {
          return {
            user,
            user_id: user.id,
          }
        })
      }

      //
      this.actActivity.quotas = [
        ...this.actActivity.quotas,
        {
          activity_quota_id: null,
          student_quota: this.actActivityQuota.student_quota,
          is_public,
          career_rules,
          program_rules,
          user_rules,
          //
          careers: career_rules,
          programs: program_rules,
          users: user_rules,
        }
      ]

      //
      this.resetActivityQuota()
    },

    /**
     * 
     * @param {*} event 
     */
    submitUpdate() {

      //
      const activity_quota_id = this.actActivityQuotaSelected.id
      const index = this.actActivity.quotas.findIndex(item => item.id = activity_quota_id)

      //
      let is_public = this.actActivityQuotaSelected.type_quota === 'Pública'
      let career_rules = []
      let program_rules = []
      let user_rules = []
      let career = this.getRef('career_selected')
      let program = this.getRef('program_selected')
      let user = this.getRef('user_selected')

      //
      if (this.actActivityQuotaSelected.type_quota === 'Carreras') {
        console.log(career.getSelected(), 'selected')
        career_rules = career.getSelected().map(career => {
          return {
            career,
            career_id: career.id,
            university_career_id: career.id,
            career_year: career.year || 0,
            year: career.year || 0,
          }
        })
      }

      //
      if (this.actActivityQuotaSelected.type_quota === 'Programas') {
        program_rules = program.getSelected().map(program => {
          return {
            program,
            program_id: program.id,
          }
        })
      }

      //
      if (this.actActivityQuotaSelected.type_quota === 'Usuarios') {
        user_rules = user.getSelected().map(user => {
          return {
            user,
            user_id: user.id,
          }
        })
      }

      console.log({ career_rules })

      //
      this.actActivity.quotas[index] = {
        id: activity_quota_id,
        activity_quota_id: activity_quota_id,
        student_quota: this.actActivityQuotaSelected.student_quota,
        is_public,
        career_rules,
        program_rules,
        user_rules,
        //
        careers: career_rules,
        programs: program_rules,
        users: user_rules,
      }
    },

    /**
     * 
     */
    submitUserSelected(event) {

      //
      const actActivityQuotaStudentQuota      = Number(this.actActivityQuota.student_quota)
      const actActivityQuotaStudentQuotaUp    = actActivityQuotaStudentQuota + 1
      const actActivityQuotaStudentQuotaDown  = actActivityQuotaStudentQuota - 1

      //
      this.actActivityQuota.student_quota = Math.max(0, event.value ? actActivityQuotaStudentQuotaUp : actActivityQuotaStudentQuotaDown)
    },

    /**
     * 
     */
    submitUserSelectedAll(event) {
      this.actActivityQuota.student_quota = event.value ? event.items.length : 0
    },

    /**
     * 
     */
    toAssignQuota() {
      return ActActivityService
        .assignQuota(this.actActivity)
        .then(this.toSuccess)
        .catch(this.toError)
    },

    /**
     * Delete a ActActivity quotas.
     */
    toDelete() {
      this.actActivity.quotas = [
        ...this.actActivity.quotas.slice(0, this.selectedIndex),
        ...this.actActivity.quotas.slice(this.selectedIndex + 1),
      ]
    },

    /**
     * Delete a ActActivity career quotas.
     */
    toDeleteCareer() {

      //
      let quota = this.actActivity.quotas[this.selectedIndex]

      //
      quota.career_rules = [
        ...quota.career_rules.slice(0, this.selectedSubindex),
        ...quota.career_rules.slice(this.selectedSubindex + 1),
      ]

      //
      if (!quota.career_rules.length) {
        this.toDelete()
      }
    },

    /**
     * Delete a ActActivity program quotas.
     */
    toDeleteProgram() {

      //
      let quota = this.actActivity.quotas[this.selectedIndex]

      //
      quota.program_rules = [
        ...quota.program_rules.slice(0, this.selectedSubindex),
        ...quota.program_rules.slice(this.selectedSubindex + 1),
      ]

      //
      if (!quota.program_rules.length) {
        this.toDelete()
      }
    },

    /**
     * Delete a ActActivity user quotas.
     */
    toDeleteUser() {

      //
      let quota = this.actActivity.quotas[this.selectedIndex]

      //
      quota.user_rules = [
        ...quota.user_rules.slice(0, this.selectedSubindex),
        ...quota.user_rules.slice(this.selectedSubindex + 1),
      ]

      //
      if (!quota.user_rules.length) {
        this.toDelete()
      }
    },

    /**
     * The delete ActActivity error process.
     * @param  {Object}  event - the error delete payload
     */
    toError(event) {
      this.getErrorMessage(event).then(this.getErrorToast)      
    },

    /**
     * The delete ActActivity success process.
     * @param  {Object}  event - the success delete payload
     */
    toSuccess(event) {
      this.getSuccessMessage(event).then(this.getSuccessToast).then(this.refresh)    
    },

    /**
     * 
     */
    async toWaitCard(value = false) {
      this.loading = value
    },
  },
  mounted() {
    this.refresh() 
  },
  watch: {
    'actActivityQuota.type_quota'(val) {
      this.actActivityQuota.student_quota = 0
      // this.createProps.items[0].props.disabled = this.actActivityQuota.type_quota === 'Usuarios'
    },
    'actActivityQuotaSelected.type_quota'(val) {
      this.actActivityQuotaSelected.student_quota = 0
      // this.updateProps.items[0].props.disabled = this.actActivityQuotaSelected.type_quota === 'Usuarios'
    },
  }
}