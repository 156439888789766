// required modules
import Model from '@/models/Model'

/**
 * This class describes a ARegisteredQuantity model.
 *
 * @class ARegisteredQuantity (name)
 */
export default class ARegisteredQuantityModel extends Model {

  /**
   * Define the ARegisteredQuantity resource.
   *
   * @return {String} ARegisteredQuantity endpoint name.
   */
  resource() {
    return 'registered_quantity'
  }
}