// layout
import RLayoutModule from '@/components/layouts/LayoutModule'

// views
import TtsTutorCreateView from '@/views/Tutorship/TtsTutor/create'
import TtsTutorListView from '@/views/Tutorship/TtsTutor/list'
import TtsTutorUpdateView from '@/views/Tutorship/TtsTutor/update'

// routes
export default {
  path: '/tutores',
  component: RLayoutModule,
  children: [
    {
      name: 'tts-tutor-list',
      path: '',
      component: TtsTutorListView,
    },
    {
      name: 'tts-tutor-create',
      path: 'crear',
      component: TtsTutorCreateView,
      meta: {
        title: 'Crear tutor'
      },
    },
    {
      name: 'tts-tutor-update',
      path: 'editar',
      component: TtsTutorUpdateView,
      meta: {
        title: 'Editar tutor'
      },
    },
  ],
  meta: {
    title: 'Tutores'
  },
  props: {
    indexText: 'Inicio',
  },
}
