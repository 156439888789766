// components
import StaAttentionFacilitatorScheduleFields from '@/views/StudentAttention/StaAttentionFacilitatorSchedule/fields'
import StaAttentionFacilitatorScheduleModel from '@/models/StudentAttention/StaAttentionFacilitatorSchedule'
import StaAttentionFacilitatorScheduleService from '@/services/api/StudentAttention/StaAttentionFacilitatorSchedule'
// component
export default {
  name: 'sta-attention-facilitator-schedule-update',
  computed: {

    /**
      * Gets the StaAttentionFacilitatorSchedule update form props.
      * @return {Object} The update form props.
      */
    formProps() {
      return {
        items: StaAttentionFacilitatorScheduleFields.toUpdate(),
      }
    }
  },
  data() {
    return {
      staAttentionFacilitatorSchedule: {
        value: 'test',
      },
    }
  },
  methods: {

    /**
      * Returns a reset StaAttentionFacilitatorSchedule form.
      * @return {Promise} A form reset promise.
      */
    reset() {
      return this.toWait(true).then(this.toRefresh).then(this.toWait)
    },

    /**
      * Returns a submit StaAttentionFacilitatorSchedule form.
      * @return {Promise} A form submit promise.
      */
    submit() {
      return this.toWait(true).then(this.toUpdate).then(this.toWait)
    },

    /**
      * Returns a refresh StaAttentionFacilitatorSchedule form.
      * @return {Promise} A reset promise.
      */
    toRefresh() {
      return StaAttentionFacilitatorScheduleModel.find(this.getParam('id')).then(schedule => {

        schedule.attention_end_time   = schedule.attention_end_time.split(':').slice(0, 2).join(':')
        schedule.attention_init_time  = schedule.attention_init_time.split(':').slice(0, 2).join(':')
        
        this.staAttentionFacilitatorSchedule = schedule
      })
    },

    /**
      * Returns an update StaAttentionFacilitatorSchedule promise.
      * @return {Promise} A update promise.
      */
    toUpdate() {
      return StaAttentionFacilitatorScheduleService.update(this.staAttentionFacilitatorSchedule).then(this.toSuccess).catch(this.toError)
    },

    /**
      * Returns an update StaAttentionFacilitatorSchedule error process.
      * @param  {Object}  event - the event error payload.
      */
    toError(event) {
      this.getErrorMessage(event).then(this.getErrorToast)
    },

    /**
      * Returns an update StaAttentionFacilitatorSchedule success process.
      * @param  {Object}  event - the event success payload.
      */
    toSuccess(event) {
      this.getSuccessMessage(event).then(this.getSuccessToast)
      this.$router.push('/horarios')
    },
  },
  mounted() {
    this.reset()
  },
}