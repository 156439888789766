// model
import Model from '@/models/Model'

/**
 * This class describes a UsrClassroom model.
 * @class UsrClassroom (name)
 */
export default class UsrClassroomModel extends Model {

  /**
   * Define the UsrClassroom resource.
   * @return {String} UsrClassroom endpoint name.
   */
  resource() {
    return 'classroom'
  }
}