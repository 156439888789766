//
import NtNotificationModel from '@/models/Notification/NtNotification'
import NtNotificationService from '@/services/api/Notification/NtNotification'
import NtNotificationFields from '@/views/Notification/NtNotification/fields'
//
export default {
  computed: {

    /**
      * Gets the NtNotification form props.
      * 
      * @return {Object} The form props.
      */
    formProps() {
      return {
        items: NtNotificationFields.toPassword(),
      }
    }
  },
  data() {
    return {
      ntNotification: NtNotificationService.shape({
        identifier: ''
      }),
    }
  },
  methods: {

    /**
     * Returns an password NtNotification error process.
     * 
     * @param  {Object}  event - the event error payload
     */
    onError(event) {
      this.getErrorMessage(event).then(this.getErrorToast)
    },
    
    /**
     * Returns an password NtNotification success process.
     * 
     * @param  {Object}  event - success payload
     */
    onSuccessPassword(event) {
      this.getSuccessMessage(event).then(this.getSuccessToast).then(this.toRedirect)
    },

    /**
     * Reset the NtNotification data.
     */
    onReset() {
      this.ntNotification.identifier = ''
    },

    /**
     * Returns a submit NtNotification form.
     * 
     * @return {Promise} A form submit process.
     */
    onSubmit() {
      return this.toWait(true).then(this.toPassword).then(this.toWait)
    },

    /**
     * Returns an password NtNotification process.
     * 
     * @return {Promise} A create process.
     */
    toPassword() {
      return NtNotificationService.password(this.ntNotification).then(this.onSuccessPassword).catch(this.onError)
    },

    /**
     * Redirect to Auth index.
     */
    toRedirect() {
      this.goRoute('auth')
    },
  },
}
