// required modules
// import SrvSurveyFields from '@/views/Survey/SrvSurvey/SrvSurveyFields'
import SrvSurveyModel from '@/models/Survey/SrvSurvey'
import SrvSurveyBlockService from '@/services/api/Survey/SrvSurveyBlock'
import SrvSurveySectionService from '@/services/api/Survey/SrvSurveySection'
import SrvSurveyUserService from '@/services/api/Survey/SrvSurveyUser'
import SrvSurveyService from '@/services/api/Survey/SrvSurvey'

// component
export default {
  name: 'srv-survey-answer',
  computed: {

    /**
     * 
     */
    formItems() {
      return this.srvSurveyItems.filter(this.filterByIndex).reduce(this.reduceItems, []).filter(this.filterInput)
    },

    /**
     * Gets the SrvSurvey answer form props.
     * @return {Object} The answer form props.
     */
    formProps() {
      return {
        items: this.formItems,
        noActions: !this.srvSurvey.survey.id || !this.srvSurvey.is_available,
        resetText: this.inFirst ? 'reiniciar' : 'anterior',
        submitText: this.inLast ? 'responder' : 'siguiente',
        title: this.srvSurvey.survey.id ? this.srvSurvey.survey.title : 'Error al obtener encuesta',
      }
    },

    /**
     * 
     */
    inFirst() {
      return this.itemIndex === 0
    },

    /**
     * 
     */
    inLast() {
      return this.itemIndex === this.srvSurveyItems.length - 1
    },

    /**
     * 
     */
    leftProps() {
      return {
        disabled: this.itemIndex < 1
      }
    },

    /**
     * 
     */
    rightProps() {
      return {
        disabled: this.inLast
      }
    },
  },
  data() {
    return {
      itemIndex: 0,
      srvSurvey: {
        survey: {
          title: '',
          answers: {

          },
        }
      },
      srvSurveyItems: [],
      srvSurveySectionIndex: 0,
    }
  },
  methods: {

    /**
     * 
     */
    filterInput(item) {
      return !! item //&& !Array.isArray(item)
    },

    /**
     * 
     */
    filterByIndex(item, index) {
      return index === this.itemIndex
    },

    /**
     * 
     */
    onNext() {
      this.itemIndex++;
    },

    /**
     * 
     */
    onPrevious() {
      this.itemIndex--;
    },

    /**
     * 
     */
    onReset() {

      // go reset
      if (this.inFirst) {
        return this.$toaster('¿Esta seguro en reiniciar la encuesta?', 'confirmar', () => {
          this.reset()
        })
      }

      // go back
      this.onPrevious()
    },

    /**
     * 
     */
    onSubmit() {

      // go send
      if (this.inLast) {
        return this.$toaster('¿Esta seguro en enviar la encuesta?', 'confirmar', () => {
          this.submit()
        })
      }

      // go next
      this.onNext()
    },

    /**
     * 
     */
    redirect() {
      this.pushRoute('home')
    },

    /**
     * 
     */
    reduceItems(items, item) {
      return [ ...items, ...item ]
    },

    /**
     * Returns a reset SrvSurvey form.
     * @return {Promise} A form reset promise.
     */
    reset() {
      this.toWait(true).then(this.toRefresh).then(this.toWait)
    },

    /**
     * Set a new block in survey items.
     */
    setBlock(srvSurveyBlock) {
      this.srvSurveyItems[this.srvSurveySectionIndex+1].push(SrvSurveyBlockService.genDivider())
      this.srvSurveyItems[this.srvSurveySectionIndex+1].push(SrvSurveyBlockService.genBlockTitle(srvSurveyBlock))
      this.srvSurveyItems[this.srvSurveySectionIndex+1].push(SrvSurveyBlockService.genInput(srvSurveyBlock))
      if(srvSurveyBlock.survey_block_identifier_id == 7){
        this.srvSurveyItems[this.srvSurveySectionIndex+1].push(SrvSurveyBlockService.genBlockLegend(srvSurveyBlock))
      }
      if(srvSurveyBlock.survey_block_identifier_id == 8){
        this.srvSurveyItems[this.srvSurveySectionIndex+1].push(SrvSurveyBlockService.genBlockLegend(srvSurveyBlock))
      }
    },

    /**
     * 
     */
    setIndex(srvSurvey) {

      // required data
      let title     = SrvSurveyBlockService.genSurveyTitle(srvSurvey)
      let subtitle  = SrvSurveyBlockService.genSurveySubtitle(srvSurvey)

      // set items
      this.srvSurveyItems.push([ title, subtitle ])
    },

    /**
     * 
     */
    setResume(srvSurvey) {

      // required data
      let title = SrvSurveyBlockService.genTitle('Resumen', 'Antes de enviar la encuesta revise sus respuestas. Una vez que esté seguro haga click en "responder".')

      // set items
      this.srvSurveyItems.push([ title ])
    },

    /**
     * Set a new section in survey items.
     */
    setSection(srvSurveySection, index) {

      // required data
      let title = SrvSurveyBlockService.genSectionTitle(srvSurveySection)

      // set initial section data
      this.srvSurveyItems.push([title])
      this.srvSurveySectionIndex = index

      // set section items
      srvSurveySection.prop('blocks', []).map(block => { block.section_index = index + 1; return block }).forEach(this.setBlock)
    },

    /**
     * Returns a submit SrvSurvey form.
     * @return {Promise} A form submit promise.
     */
    submit() {
      this.toWait(true).then(this.toAnswer).then(this.toWait)
    },

    /**
     * Returns a refresh SrvSurvey form.
     * @return {Promise} A reset promise.
     */
    toRefresh() {
      return SrvSurveyUserService.toAnswer().find(this.getParam('token')).then(srvSurveyUser => {
        
        // initialize view
        this.itemIndex = 0
        this.srvSurveyItems = []
        this.srvSurvey = srvSurveyUser.reset()

        // initialize answers
        this.srvSurvey.survey.initAnswers()

        // hast survey
        if (this.srvSurvey.survey.id) {
          
          // set initial page
          this.setIndex(this.srvSurvey.survey)

          // set sections
          this.srvSurvey.survey.id = this.getParam('token')
          this.srvSurvey.survey.sections.forEach(this.setSection) 
          
          // set last page
          this.setResume(this.srvSurvey.survey)
        }

        // if not available
        if (!this.srvSurvey.is_available) {
          this.$toast.error('Esta encuesta no esta disponible para ser respondida.')
        }
      })
      .catch(error => {
        this.onError(error)
      })
    },

    /**
     * Returns an answer SrvSurvey promise.
     * @param  {Object}  model - the srv-survey model
     * @return {Promise} A answer promise.
     */
    toAnswer() {
      return SrvSurveyService.answer(this.srvSurvey.survey).then(this.onSuccess).catch(this.onError)
    },

    /**
     * 
     */
    toRedirect() {
      this.goRoute('/')
    }
  },
  mounted() {
    this.reset()
  },
}