// required modules
import { Fields } from 'collection-wrapper'
import { ruleRequired } from 'vuetify2-wrappers/src/rules'

/**
 * This class describes a SrvSurveyBlock fields collection.
 * @class SrvSurveyBlockFields
 */
export default class SrvSurveyBlockFields extends Fields {

  /**
   * Returns the SrvSurveyBlock fields options.
   * @returns {Array} fields options.
   */
  fields() {
    return [
      {
        name: 'title',
        type: 'text-field',
        info: 'Título del Bloque.',
        props: {
          label: 'Título',
          placeholder: 'Título',
          rules: [ruleRequired],
        },
      },
      {
        name: 'placeholder',
        type: 'text-field',
        info: 'Marcador de texto, solo válido para inputs de texto, textarea, fecha y hora.',
        props: {
          label: 'Marcador',
          placeholder: 'Marcador',
        },
      },
      {
        name: 'survey_block_type_id',
        type: 'select',
        info: 'Tipo de Bloque (Información ó Pregunta).',
        props: {
          items: [
            {
              text: 'Información',
              value: 1,
            },
            {
              text: 'Pregunta',
              value: 2,
            },
          ],
          label: 'Tipo de bloque',
          placeholder: 'Tipo de bloque',
          rules: [ruleRequired],
        },
      },
      {
        name: 'survey_block_identifier_id',
        type: 'select',
        info: 'Tipo de identificador del bloque.',
        props: {
          items: [
            {
              text: 'Información',
              value: 1,
            },
            {
              text: 'Respuesta breve',
              value: 2,
            },
            {
              text: 'Párrafo',
              value: 3,
            },
            {
              text: 'Opción Múltiple',
              value: 4,
            },
            {
              text: 'Casillas de Verificación',
              value: 5,
            },
            {
              text: 'Lista desplegable',
              value: 6,
            },
            {
              text: 'Escala Lineal',
              value: 7,
            },
            {
              text: 'Calificación por Estrella',
              value: 8,
            },
            {
              text: 'Cuadrícula de Opción Múltiple',
              value: 9,
            },
            {
              text: 'Cuadrícula de Casillas de Verificación',
              value: 10,
            },
            {
              text: 'Fecha',
              value: 11,
            },
            {
              text: 'Hora',
              value: 12,
            },
          ],
          label: 'Identificador de bloque',
          placeholder: 'Identificador de bloque',
          rules: [ruleRequired],
        },
      },
      {
        name: 'description',
        type: 'textarea',
        info: 'Descripción del bloque.',
        flex: {
          cols: 12,
        },
        props: {
          label: 'Descripción',
          placeholder: 'Descripción',
          rows: 2,
        },
      },
      {
        name: 'min_range',
        type: 'float-field',
        info: 'Rango mínimo. Dos decimales (Solo si el input es de rango).',
        props: {
          label: 'Rango mínimo',
          placeholder: 'Rango mínimo',
          rules: [ruleRequired],
        },
      },
      {
        name: 'min_range_label',
        type: 'text-field',
        info: 'Etiqueta del rango mínimo (Solo si el input es de rango).',
        props: {
          label: 'Etiqueta del rango mínimo',
          placeholder: 'Etiqueta del rango mínimo',
          rules: [ruleRequired],
        },
      },
      {
        name: 'max_range',
        type: 'float-field',
        info: 'Rango máximo. Dos decimales (Solo si el input es de rango).',
        props: {
          label: 'Rango máximo',
          placeholder: 'Rango máximo',
          rules: [ruleRequired],
        },
      },
      {
        name: 'max_range_label',
        type: 'text-field',
        info: 'Etiqueta del rango máximo (Solo si el input es de rango).',
        props: {
          label: 'Etiqueta del rango máximo',
          placeholder: 'Etiqueta del rango máximo',
          rules: [ruleRequired],
        },
      },
      {
        name: 'options',
        type: 'combobox',
        info: 'Alternativas de opción multiple (Solo si es input de opción múltiple).',
        flex: {
          cols: 12,
          sm: 6,
        },
        props: {
          deletableChips: true,
          items: [],
          label: 'Alternativas de opción multiple',
          multiple: true,
          placeholder: 'Alternativas de opción multiple',
          rules: [this.requiredAtLEastOne],
          smallChips: true,
        },
      },
      {
        name: 'fields',
        type: 'combobox',
        info: 'Filas de opción de tablas (Solo si es input de opción múltiple con tablas).',
        flex: {
          cols: 12,
          sm: 6,
        },
        props: {
          deletableChips: true,
          items: [],
          label: 'Filas de opción de tablas',
          multiple: true,
          placeholder: 'Filas de opción de tablas',
          smallChips: true,
        },
      },
    ]
  }

  /**
   * Returns the SrvSurveyBlock create fields.
   * @returns {Collection} fields collection.
   */
  static toCreate(props = {}) {

    //
    if (props.survey_block_type_id === 2) {

      //
      let fields = this.init()
      let result = fields.whereIn('name', [
        'title',
        'placeholder',
        'survey_block_type_id',
        'survey_block_identifier_id',
        'description',
      ])

      //
      if (props.survey_block_identifier_id === 4 || props.survey_block_identifier_id === 5 || props.survey_block_identifier_id === 6) {
        result = fields.whereIn('name', [
          'title',
          'placeholder',
          'survey_block_type_id',
          'survey_block_identifier_id',
          'description',
          'options',
        ])
      }

      //
      if (props.survey_block_identifier_id === 7 || props.survey_block_identifier_id === 8) {
        result = fields.whereIn('name', [
          'title',
          'placeholder',
          'survey_block_type_id',
          'survey_block_identifier_id',
          'description',
          'min_range',
          'max_range',
          'min_range_label',
          'max_range_label',
        ])
      }

      //
      if (props.survey_block_identifier_id === 9 || props.survey_block_identifier_id === 10) {
        result = fields.whereIn('name', [
          'title',
          'placeholder',
          'survey_block_type_id',
          'survey_block_identifier_id',
          'description',
          'options',
          'fields',
        ])
      }

      //
      result = result.all()
      result[3].props.items.splice(0, 1)

      //
      return result
    }

    //
    return this.reduce('name', [
      'title',
      'placeholder',
      'survey_block_type_id',
      'description',
    ]) 
  }

  /**
   * Returns the SrvSurveyBlock update fields.
   * @returns {Collection} fields collection.
   */
  static toUpdate(props = {}) {

    //
    if (props.survey_block_type_id === 2) {

      //
      let fields = this.init()
      let result = fields.whereIn('name', [
        'title',
        'placeholder',
        'survey_block_type_id',
        'survey_block_identifier_id',
        'description',
      ])

      //
      if (props.survey_block_identifier_id === 4 || props.survey_block_identifier_id === 5 || props.survey_block_identifier_id === 6) {
        result = fields.whereIn('name', [
          'title',
          'placeholder',
          'survey_block_type_id',
          'survey_block_identifier_id',
          'description',
          'options',
        ])
      }

      //
      if (props.survey_block_identifier_id === 7 || props.survey_block_identifier_id === 8) {
        result = fields.whereIn('name', [
          'title',
          'placeholder',
          'survey_block_type_id',
          'survey_block_identifier_id',
          'description',
          'min_range',
          'max_range',
          'min_range_label',
          'max_range_label',
        ])
      }

      //
      if (props.survey_block_identifier_id === 9 || props.survey_block_identifier_id === 10) {
        result = fields.whereIn('name', [
          'title',
          'placeholder',
          'survey_block_type_id',
          'survey_block_identifier_id',
          'description',
          'options',
          'fields',
        ])
      }

      //
      result = result.all()
      result[3].props.items.splice(0, 1)

      //
      return result
    }

    //
    return this.reduce('name', [
      'title',
      'placeholder',
      'survey_block_type_id',
      'description',
    ]) 
  }

  /**
   * 
   */
  requiredAtLEastOne(val) {
    return Array.isArray(val) && val.length > 0 || 'Necesita al menos 1 item.'
  }
}
