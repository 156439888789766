// required modules
import Model from '@/models/Model'

/**
 * This class describes a AStudentQuantity model.
 *
 * @class AStudentQuantity (name)
 */
export default class AStudentQuantityModel extends Model {

  /**
   * Define the AStudentQuantity resource.
   *
   * @return {String} AStudentQuantity endpoint name.
   */
  resource() {
    return 'student_quantity'
  }
}