// model
import Model from '@/models/Model'

/**
 * This class describes a AStart model.
 * @class AStart (name)
 */
export default class AStartModel extends Model {

  /**
   * Define the AStart resource.
   * @return {String} AStart endpoint name.
   */
  resource() {
    return 'start'
  }
}