// layout
import RLayoutModule from '@/components/layouts/LayoutModule'

// views
import TtsTutorshipCreateView from '@/views/Tutorship/TtsTutorship/create'
import TtsTutorshipDashboardView from '@/views/Tutorship/TtsTutorship/dashboard'
import TtsTutorshipListView from '@/views/Tutorship/TtsTutorship/list'
import TtsTutorshipUpdateView from '@/views/Tutorship/TtsTutorship/update'

// routes
export default {
  path: '/tutorias',
  component: RLayoutModule,
  children: [
    {
      name: 'tts-tutorship-list',
      path: '',
      component: TtsTutorshipListView,
    },
    {
      name: 'tts-tutorship-create',
      path: 'crear',
      component: TtsTutorshipCreateView,
      meta: {
        title: 'Crear tutoría'
      },
    },
    {
      name: 'tts-tutorship-dashboard',
      path: 'tablero',
      component: TtsTutorshipDashboardView,
      meta: {
        title: 'Dashboard'
      },
    },
    {
      name: 'tts-tutorship-update',
      path: 'editar',
      component: TtsTutorshipUpdateView,
      meta: {
        title: 'Editar tutoría'
      },
    },
  ],
  meta: {
    title: 'Tutorias'
  },
  props: {
    indexText: 'Inicio',
  },
}
