// required modules
import Service from '@/services/api/Service'
import TtsTutorshipParallelModel from '@/models/Tutorship/TtsTutorshipParallel'

/**
 * This class describes a TtsTutorshipParallel service.
 * @class
 */
export default class TtsTutorshipParallelService extends Service {

  /**
   * Define the TtsTutorshipParallel model.
   * @return {Function} TtsTutorshipParallel resource.
   */
  static model() {
    return TtsTutorshipParallelModel
  }

  /**
   * 
   */
  static toList() {
    return this.fetcher({
      includes: [
        'tutorship.campus',
      ],
    })
  }

  /**
   * 
   */
  static toListByTutorship(props) {
    return this.fetcher({
      filters: {
        tutorship_id: props.tutorship_id || undefined,
      },
    })
  }
}