// required modules
import { Fields } from 'collection-wrapper'
import TtsTutorService from '@/services/api/Tutorship/TtsTutor'
import TtsTutorshipService from '@/services/api/Tutorship/TtsTutorship'
import TtsTutorshipParallelService from '@/services/api/Tutorship/TtsTutorshipParallel'
import UsrCareerService from '@/services/api/User/UsrCareer'
import UsrClassroomService from '@/services/api/User/UsrClassroom'
import UsrUniversityTimeBlockService from '@/services/api/User/UsrUniversityTimeBlock'
import UsrUniversityCampusService from '@/services/api/User/UsrUniversityCampus'
/**
 * This class describes a TtsTutorshipSchedule fields collection.
 * @class 
 */
export default class TtsTutorshipScheduleFields extends Fields {

  /**
   * Returns the TtsTutorshipSchedule fields options.
   * @returns {Array} fields options.
   */
  fields() {
    return [
      {
        name: 'tutorship_id',
        type: 'select-model',
        props: {
          fetch: TtsTutorshipService.toList(),
          limit: 15,
          selectProps: {
            itemText: 'description',
            itemValue: 'id',
            label: 'Tutoría',
            placeholder: 'Tutoría',
          },
        },
      },
      {
        name: 'tutorship_parallel_id',
        type: 'select-model',
        props: {
          fetch: TtsTutorshipParallelService.toList(),
          limit: 15,
          selectProps: {
            itemText: 'description',
            itemValue: 'id',
            label: 'Paralelo',
            placeholder: 'Paralelo',
          },
        },
      },
      {
        name: 'tutor_id',
        type: 'select-model',
        props: {
          fetch: TtsTutorService.toList(),
          limit: 15,
          selectProps: {
            itemText: 'name_case',
            itemValue: 'id',
            label: 'Tutor',
            placeholder: 'Tutor',
          },
        },
      },
      {
        name: 'careers',
        type: 'autocomplete-model',
        props: {
          fetch: UsrCareerService.toSelectListbyCampus(),
          limit: 15,
          autocompleteProps: {
            itemText: 'description',
            itemValue: 'id',
            label: 'Carrera(s)',
            multiple: true,
            placeholder: 'Carrera(s)',
          },
        },
      },
      {
        name: 'weekday',
        type: 'select',
        props: {
          items: [
            { text: 'Lunes', value: 1 },
            { text: 'Martes', value: 2 },
            { text: 'Miércoles', value: 3 },
            { text: 'Jueves', value: 4 },
            { text: 'Viernes', value: 5 },
            { text: 'Sábado', value: 6 },
            { text: 'Domingo', value: 7 },
          ],
          label: 'Día de la semana',
          placeholder: 'Día de la semana',
        },
      },
      {
        name: 'university_time_block_id',
        type: 'select-model',
        props: {
          fetch: UsrUniversityTimeBlockService.toList(),
          limit: 15,
          selectProps: {
            itemText: 'description',
            itemValue: 'id',
            label: 'Bloque de horario',
            placeholder: 'Bloque de horario',
          },
        },
      },
      {
        name: 'university_classroom_id',
        type: 'autocomplete-model',
        props: {
          fetch: UsrClassroomService.toListAllByCurrentUniversityCampus(),
          limit: 15,
          autocompleteProps: {
            itemText: 'description',
            itemValue: 'id',
            label: 'Sala de Clases',
            placeholder: 'Sala de Clases',
          },
        },
      },
      {
        name: 'event_room',
        type: 'text-field',
        props: {
          hint: 'Ej: Zoom, Teams, etc...',
          label: 'Lugar y/o plataforma',
          placeholder: 'Lugar y/o plataforma',
        },
      },
    ]
  }

  /**
   * Returns the TtsTutorshipSchedule create fields.
   * @returns {Collection} fields collection.
   */
  static toCreate(props = {}) {
    return this.init().whereIn('name', [
      'weekday',
      'university_time_block_id',
      'tutorship_id',
      'tutorship_parallel_id',
      'tutor_id',
      'university_classroom_id',
      'careers',
      'event_room',
    ])
    .each(item => {
      if (item.name === 'tutorship_parallel_id') {
        item.props.fetch = TtsTutorshipParallelService.toListByTutorship(props)
        item.props.selectProps.readonly = !props.tutorship_id
        console.log('fetch')
      }
    })
    .all()
  }

  /**
   * Returns the TtsTutorshipSchedule create fields.
   * @returns {Collection} fields collection.
   */
  static toDetail() {
    return this.init().whereIn('name', [
      'weekday',
      'university_time_block_id',
      'tutorship_id',
      'tutorship_parallel_id',
      'tutor_id',
      'university_classroom_id',
      'careers',
      'event_room',
    ])
    .each(item => {
      item.flex = {
        cols: 12,
        sm: 6,
      }
      if(item.name !== 'weekday') {
        if (item.props.selectProps) {
          item.props.selectProps.readonly = true
        }
        if (item.props.autocompleteProps) {
          item.props.autocompleteProps.readonly = true
        }
      } else {
        item.props.readonly = true
      }
    })
  }

  /**
   * Returns the TtsTutorshipSchedule update fields.
   * @returns {Collection} fields collection.
   */
  static toUpdate(props = {}) {
    return this.init().whereIn('name', [
      'weekday',
      'university_time_block_id',
      'tutorship_id',
      'tutorship_parallel_id',
      'tutor_id',
      'university_classroom_id',
      'careers',
      'event_room',
    ])
    .each(item => {
      if (item.name === 'tutorship_parallel_id') {
        item.props.fetch = TtsTutorshipParallelService.toListByTutorship(props)
        item.props.selectProps.readonly = !props.tutorship_id
      }
    })
    .all()
  }
}
