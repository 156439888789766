// required components
import BlgWebNewFields from '@/views/Blog/BlgWebNew/fields'
import BlgWebNewService from '@/services/api/Blog/BlgWebNew'

// component
export default {
  name: 'blg-web-new-create',
  computed: {

    /**
     * Gets the BlgWebNew create form props.
     * @return {Object} The create form props.
     */
    formProps() {
      return {
        items: BlgWebNewFields.toCreate(),
      }
    }
  },
  data() {
    return {
      blgWebNew: BlgWebNewService.shape({
        link_instagram: '',
        link_twitter: '',
        link_facebook: '',
      }),
    }
  },
  methods: {

    /**
     * Reset the BlgWebNew data.
     */
    onReset() {
      this.blgWebNew.value = ''
    },

    /**
     * Returns a submit BlgWebNew create form.
     * @return {Promise} A form submit process.
     */
    onSubmit() {
      return this.toWait(true).then(this.toCreate).then(this.toWait)
    },

    /**
     * Returns an create BlgWebNew success process.
     * @param  {Object}  event - the event success payload.
     */
    onSuccessCreate(event) {
      this.onSuccess('Noticia creada exitosamente.')
    },

    /**
     * Returns an create BlgWebNew process.
     * @return {Promise} A create process.
     */
    toCreate() {
      return BlgWebNewService.create(this.blgWebNew).then(this.onSuccessCreate).then(this.toRedirect).catch(this.onError)
    },

    /**
     * Redirect to BlgWebNew list
     */
    toRedirect() {
      this.goRoute('blg-web-new-list')
    },
  },
}