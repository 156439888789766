// required modules
import SrvSurveyTemplateFilters from '@/views/Survey/SrvSurveyTemplate/filters'
import SrvSurveyTemplateHeaders from '@/views/Survey/SrvSurveyTemplate/headers'
import SrvSurveyTemplateService from '@/services/api/Survey/SrvSurveyTemplate'

// component
export default {
  name: 'srv-survey-list',
  computed: {

    /**
     *
     */
    tableProps() {
      return {
        filters: SrvSurveyTemplateFilters.toList(),
        headers: SrvSurveyTemplateHeaders.toList(),
        service: SrvSurveyTemplateService.toList(),
      }
    }
  },
  methods: {

    /**
     * 
     */
    onClickCreate() {
      this.goRoute('srv-survey-template-create')
    },

    /**
     * Show a delete SrvSurveyTemplate confirm toast.
     */
    onClickDelete(event) {
      this.selected = event.item
      this.$toaster('¿Confirma la eliminación de la encuesta predeterminada seleccionada?', 'confirmar', this.toDelete)
    },

    /**
     * 
     */
    onClickUpdate(event) {
      this.goRoute('srv-survey-template-update', { id: event.item.id })
    },

    /**
     * The delete SrvSurveyTemplate error process.
     *
     * @param  {Object}  event - the error delete payload.
     */
    onError(event) {
      this.getErrorMessage(event).then(this.getErrorToast)      
    },

    /**
     * The delete SrvSurveyTemplate success process.
     *
     * @param  {Object}  event - the success delete payload.
     */
    onSuccessDelete(event) {
      this.getSuccessMessage(event).then(this.getSuccessToast).then(this.toRefresh)    
    },

    /**
     * Return a delete SrvSurveyTemplate promise.
     *
     * @param  {SrvSurveyTemplateModel}  model - the SrvSurveyTemplate model.
     * @return {Promise} A delete process.
     */
    toDelete() {
      return SrvSurveyTemplateService.delete(this.selected).then(this.onSuccessDelete).catch(this.onError)
    },

    /**
     * Refresh the SrvSurveyTemplate list.
     */
    toRefresh() {
      this.getRef('table').refresh()
    },
  },
}