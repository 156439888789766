// model
import Model from '@/models/Model'

/**
 * This class describes a ASendNotification model.
 * @class ASendNotification (name)
 */
export default class ASendNotificationModel extends Model {

  /**
   * Define the ASendNotification resource.
   * @return {String} ASendNotification endpoint name.
   */
  resource() {
    return 'send_notification'
  }
}