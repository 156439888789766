// required modules
import Service from '@/services/api/Service'
import StaAttentionFacilitatorScheduleModel from '@/models/StudentAttention/StaAttentionFacilitatorSchedule'

/**
 * This class describes a StaAttentionFacilitatorSchedule service.
 * @class 
 */
export default class StaAttentionFacilitatorScheduleService extends Service {

  /**
   * 
   */
  static deletes(data) {

    // console.log({ data })
    // return

    //
    if (Array.isArray(data)) {
      let deletes = data.map(item => Promise.resolve(this.delete(item)) )
      console.log({ deletes })
      return Promise.all(deletes).then(() => {
        return 'Eventos eliminados exitosamente.'
      })
    }
    
    //
    return this.delete(data)
  }

  /**
   * 
   */
  static disable(data) {

    //
    if (Array.isArray(data)) {
      return Promise.all(data.map(item => this.disabledSchedule(item))).then(() => {
        return 'Eventos deshabilitados exitosamente.'
      })
    }
    
    //
    return this.disabledSchedule(data)
  }

  static disableAttention(attention, startAt, finishAt) {
    return attention.disableSchedule().attach({
      finish_at: finishAt,
      start_at: startAt,
    })
  }

  static disableMany(attentions = []) {
    
    let disables = attentions.map((attention) => {
      let finishAt = attention.attention_end_date_temporally
      let startAt = attention.attention_init_date_temporally
      return this.disableAttention(attention, startAt, finishAt)
    })
    
    return Promise.all(disables)
  }

  /**
   * 
   */
  static disableSchedule(schedule, payload) {
    return schedule.disableSchedule().attach(payload)
  }

  /**
   * 
   */
  static disables(schedules = [], payload = {}) {
    return Promise.all(schedules.map(schedule => this.disableSchedule(schedule, payload))).then(() => {
      return 'Eventos deshabilitados exitosamente.'
    })
  }

  /**
   * 
   */
  static disabledSchedule(staAttentionFacilitatorSchedule) {
    return staAttentionFacilitatorSchedule.enableSchedule().attach({
      enable_schedule: false,
    })
  }

  /**
   * 
   */
  static enable(data) {

    //
    if (Array.isArray(data)) {
      return Promise.all(data.map(item => this.enabledSchedule(item))).then(() => {
        return 'Eventos habilitados exitosamente.'
      })
    }
    
    //
    return this.enabledSchedule(data)
  }

  /**
   * 
   */
  static enabledSchedule(staAttentionFacilitatorSchedule) {
    return staAttentionFacilitatorSchedule.enableSchedule().attach({
      enable_schedule: true,
    })
  }

  /**
   * 
   */
  static getByTypeId(staAttentionTypeId) {
    return this
      .model()
      .where('attention_type_id', staAttentionTypeId)
      .get()
  }

  static async getNextByTypeId(staAttentionTypeId) {

    try {

      let response = await this.getByTypeId(staAttentionTypeId)
      let attentions = response.data
      let now = new Date(Date.now())
      let weekday = now.getDay()

      let approach = attentions.reduce((next, item) => {

        if (item.weekday < weekday) {
          item = item.getCloneWithIncrementByDays(7)
        }
        
        if (next) {
          if ((item.weekday - weekday) < 1) {
            return next
          }
        }
        
        return item
      }, null)

      return approach
    } catch(e) {
      return ''
    }
  }

  /**
   * Define the StaAttentionFacilitatorSchedule model.
   * @return {Function} StaAttentionFacilitatorSchedule resource.
   */
  static model() {
    return StaAttentionFacilitatorScheduleModel
  }

  /**
   *  
   */
  static get store_facilitator_user_id() {
    return this.store().getters.userId
  }

  /**
   * 
   */
  static toCreate(opts) {
    return this.shape({
      ...opts,
      attention_init_time: '',
      attention_end_time: '',
    })
  }

  /**
   * 
   */
  static toList() {
    return this.fetcher({
      includes: [
        'attention_type',
        'disablements',
        'facilitator',
      ],
    })
  }

  /**
   * 
   */
  static toListSchedule(props = {}) {

    //
    let query = this
      .model()
      // .params({ get_all_results: "1" })
      .limit(100)
      .include('attention_type,facilitator,period,campus,disablements')

    //
    if (props.owner) query.where('facilitator_user_id', this.store_facilitator_user_id)
    if (props.period) query.where('active_period', props.period.id)

    //
    return query
  }

  /**
   * 
   */
  static toSchedule() {
    return this.fetcher({
      includes: [
        'attention_type',
      ],
    })
  }
}