// required modules
import Service from '@/services/api/Service'
import TtsTutoringNewModel from '@/models/Tutorship/TtsTutoringNew'

/**
 * This class describes a TtsTutoringNew service.
 * @class
 */
export default class TtsTutoringNewService extends Service {

  /**
   * Define the TtsTutoringNew model.
   * @return {Function} TtsTutoringNew resource.
   */
  static model() {
    return TtsTutoringNewModel
  }

  /**
   * 
   */
  static publish(ttsTutoringNew) {
    return ttsTutoringNew.publish().attach({})
  }

  /**
   * 
   */
  static toList() {
    return this.fetcher({
      includes: [
        'status',
      ],
    })
  }
}