// required modules
import { Fields } from 'collection-wrapper'

// services
import TtsTutoringNewStatusService from '@/services/api/Tutorship/TtsTutoringNewStatus'
import UsrFacilitatorService from '@/services/api/User/UsrFacilitator'

/**
 * This class describes a TtsTutoringNew filters collection.
 * @class TtsTutoringNewFilters
 */
export default class TtsTutoringNewFilters extends Fields {

  /**
   * Returns the TtsTutoringNew filters options.
   * @returns {Array} filters options.
   */
  fields() {
    return [
      {
        name: 'title',
        type: 'text-field',
        props: {
          label: 'Título del aviso ',
          placeholder: 'Título del aviso ',
        },
      },
      {
        name: 'facilitator_user_id',
        type: 'select-model',
        props: {
          fetch: UsrFacilitatorService.toList(),
          selectProps: {
            itemText: 'name_case',
            itemValue: 'id',
            label: 'Creador del aviso',
            placeholder: 'Creador del aviso',
          },
        },
      },
      {
        name: 'tutoring_new_status_id',
        type: 'select',
        props: {
          items: [
            {
              text: 'Publicado',
              value: '2',
            },
            {
              text: 'Borrador',
              value: 1,
            },
          ],
          label: 'Estado del aviso ',
          placeholder: 'Estado del aviso ',
        },
      },
    ]
  }

  /**
   * Returns the TtsTutoringNew list filters.
   * @returns {Collection} filters collection.
   */
  static toList() {
    return this.reduce('name', [
      'title',
      'facilitator_user_id',
      'tutoring_new_status_id',
    ])
  }
}
