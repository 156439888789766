// model
import Model from '@/models/Model'

/**
 * This class describes a AChangeRole model.
 * @class AChangeRole (name)
 */
export default class AChangeRoleModel extends Model {

  /**
   * Define the AChangeRole resource.
   * @return {String} AChangeRole endpoint name.
   */
  resource() {
    return 'change_role'
  }
}