// required modules
import { Headers } from 'collection-wrapper'

/**
 * This class describes a TtsTutoringNew headers collection.
 * @class
 */
export default class TtsTutoringNewHeaders extends Headers {

  /**
   * Returns the TtsTutoringNew headers options.
   * @returns {Array} headers options.
   */
  headers() {
    return [
      {
        text: '#',
        value: 'id',
        sortable: 'id',
      },
      {
        text: 'Titulo',
        value: 'title',
        sortable: 'title',
      },
      {
        text: 'Estado',
        value: 'status_description',
        align: 'center',
      },
      {
        text: 'Creado',
        value: 'created_at_short',
        align: 'right',
      },
      {
        text: 'Modificado',
        value: 'updated_at_short',
        align: 'right',
      },
    ]
  }

  /**
   * Returns the TtsTutoringNew list headers.
   * @returns {Collection} headers collection.
   */
  static toList() {
    return this.reduce('value', [
      'id',
      'title',
      'status_description',
      'created_at_short',
      'updated_at_short',
    ])
  }
}
