// model
import Model from '@/models/Model'
// import AActivateAccountCases from '@/services/cases/Actions/AActivateAccount'

/**
 * This class describes a AActivateAccount model.
 * @class AActivateAccount (name)
 */
export default class AActivateAccountModel extends Model {

  /**
   * Define the AActivateAccount cases.
   * @return {String} AActivateAccount cases.
   */
  // cases() {
  //   return AActivateAccountCases
  // }

  /**
   * Define the AActivateAccount resource.
   * @return {String} AActivateAccount endpoint name.
   */
  resource() {
    return 'activate_account'
  }
}