// required modules
import { Fields } from 'collection-wrapper'

// services
import TtsTutorService from '@/services/api/Tutorship/TtsTutor'
import TtsTutorshipService from '@/services/api/Tutorship/TtsTutorship'
import UsrClassroomService from '@/services/api/User/UsrClassroom'
import UsrUniversityPeriodService from '@/services/api/User/UsrUniversityPeriod'
import UsrUniversityTimeBlockService from '@/services/api/User/UsrUniversityTimeBlock'
import UsrUniversityCampusService from '@/services/api/User/UsrUniversityCampus'

/**
 * This class describes a TtsTutorshipSchedule filters collection.
 * @class TtsTutorshipScheduleFilters
 */
export default class TtsTutorshipScheduleFilters extends Fields {

  /**
   * Returns the TtsTutorshipSchedule filters options.
   * @returns {Array} filters options.
   */
  fields() {
    return [
      {
        name: 'active_period',
        type: 'text-field',
        props: {
          label: 'Horarios de tutoría del periodo actual ',
          placeholder: 'Horarios de tutoría del periodo actual ',
        },
      },
      {
        name: 'university_period_id',
        type: 'select-model',
        props: {
          fetch: UsrUniversityPeriodService.toList(),
          selectProps: {
            itemText: 'identifier',
            itemValue: 'id',
            label: ' Periodo semestral ',
            placeholder: ' Periodo semestral ',
          },
        },
      },
      {
        name: 'tutorship_id',
        type: 'select-model',
        props: {
          fetch: TtsTutorshipService.toList(),
          selectProps: {
            itemText: 'description',
            itemValue: 'id',
            label: 'Tutoría o clase específica ',
            placeholder: 'Tutoría o clase específica ',
          },
        },
      },
      {
        name: 'weekday',
        type: 'select',
        props: {
          items: [
            {
              text: 'Lunes',
              value: 1,
            },
            {
              text: 'Martes',
              value: 2,
            },
            {
              text: 'Miércoles',
              value: 3,
            },
            {
              text: 'Jueves',
              value: 4,
            },
            {
              text: 'Viernes',
              value: 5,
            },
            {
              text: 'Sábado',
              value: 6,
            },
            {
              text: 'Domingo',
              value: 7,
            },
          ],
          label: 'Día de la semana',
          placeholder: 'Día de la semana',
        },
      },
      {
        name: 'university_time_block_id',
        type: 'select-model',
        props: {
          fetch: UsrUniversityTimeBlockService.toList(),
          selectProps: {
            itemText: 'description',
            itemValue: 'id',
            label: 'Bloque de horario ',
            placeholder: 'Bloque de horario ',
          },
        },
      },
      {
        name: 'university_classroom_id',
        type: 'select-model',
        props: {
          fetch: UsrClassroomService.toList(),
          selectProps: {
            itemText: 'description',
            itemValue: 'id',
            label: 'Sala de Clases',
            placeholder: 'Sala de Clases',
          },
        },
      },
      {
        name: 'university_campus_id',
        type: 'autocomplete-model',
        props: {
          clearable: true,
          fetch: UsrUniversityCampusService.toList(),
          limit: 15,
          autocompleteProps: {
            clearable: true,
            itemText: 'description',
            itemValue: 'id',
            label: 'Sede asociada',
            multiple: false,
            placeholder: 'Sede asociada',
          },
        },
      },
      {
        name: 'tutor_id',
        type: 'select-model',
        props: {
          fetch: TtsTutorService.toList(),
          selectProps: {
            itemText: 'name_case',
            itemValue: 'id',
            label: 'Tutor asociado',
            placeholder: 'Tutor asociado',
          },
        },
      },
    ]
  }

  /**
   * Returns the TtsTutorshipSchedule list filters.
   * @returns {Collection} filters collection.
   */
  static toList() {
    return this.reduce('name', [
      'active_period',
      'university_period_id',
      'tutorship_id',
      'weekday',
      'university_time_block_id',
      'university_classroom_id',
      'university_campus_id',
      'tutor_id',
    ])
  }
}
