// required modules
import Service from '@/services/api/Service'
import BlgWebNewDashboardModel from '@/models/Blog/BlgWebNewDashboard'

/**
 * This class describes a BlgWebNewDashboard service.
 * @class
 */
export default class BlgWebNewDashboardService extends Service {

  /**
   * Define the BlgWebNewDashboard model.
   * @return {Function} BlgWebNewDashboard resource.
   */
  static model() {
    return BlgWebNewDashboardModel
  }
}