// required modules
import BlgWebNewDashboardFields from '@/views/Blog/BlgWebNewDashboard/fields'
import BlgWebNewFilters from '@/views/Blog/BlgWebNew/filters'
import BlgWebNewHeaders from '@/views/Blog/BlgWebNew/headers'
import BlgWebNewService from '@/services/api/Blog/BlgWebNew'
import BlgWebNewDashboardService from '@/services/api/Blog/BlgWebNewDashboard'

// component
export default {
  name: 'blg-web-new-blog',
  computed: {

    /**
     *
     */
    blogProps() {
      return {
        loading: this.loading,
        items: this.blgWebNewDashboardList,
      }
    },

    /**
     * 
     */
    formProps() {
      return {
        items: BlgWebNewDashboardFields.toDetail(this.blgWebNewDashboard),
        title: this.blgWebNewDashboard.title,
      }
    },

    /**
     * 
     */
    previewProps() {
      return {
        items: BlgWebNewDashboardFields.toPreview(),
      }
    },
  },
  data() {
    return {
      blgWebNewDashboard: {
        title: '',
      },
      blgWebNewDashboardList: [],
      dialogs: {
        detail: false,
      },
      limit: 5,
      loading: false,
      page: 1,
    }
  },
  methods: {

    /**
     * 
     */
    onDetail(event) {
      this.dialogs.detail = true
      BlgWebNewDashboardService.model().include('body,images').find(event.item.id).then(res => {
        this.blgWebNewDashboard = res
      })
    },

    /**
     * 
     */
    onRefresh() {
      this.loading = 'secondary'
      BlgWebNewDashboardService.model().include('principal_image,images').limit(this.limit).page(this.page).get().then(res => {
        this.blgWebNewDashboardList = res.data
        this.loading = false
      })
    },

    /**
     * Refresh the BlgWebNew list.
     */
    toRefresh() {
      this.getRef('table').refresh()
    },
  },
  mounted() {
    this.onRefresh()
  },
}