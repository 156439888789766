// required modules
import { Fields } from 'collection-wrapper'

//
export default class StaAttentionFacilitatorScheduleFilters extends Fields {

  /**
   * 
   */
  fields() {
    return [
      {
        name: 'weekday',
        type: 'select',
        props: {
          items: [
            {
              text: 'Lunes',
              value: 1,
            },
            {
              text: 'Martes',
              value: 2,
            },
            {
              text: 'Miércoles',
              value: 3,
            },
            {
              text: 'Jueves',
              value: 4,
            },
            {
              text: 'Viernes',
              value: 5,
            },
            {
              text: 'Sábado',
              value: 6,
            },
            {
              text: 'Domingo',
              value: 7,
            },
          ],
          label: 'Día de la semana',
          placeholder: 'Día de la semana',
        },
      },
    ]
  }

  /**
   * 
   */
  static toList() {
    return this.reduce('name', [
      'weekday',
    ])
  }
}
