// required modules
import Model from '@/models/Model'
import AMoveModel from '@/models/Actions/AMove'
import APublishModel from '@/models/Actions/APublish'
import AUploadImageModel from '@/models/Actions/AUploadImage'

/**
 * This class describes a BlgWebNew model.
 * @class
 */
export default class BlgWebNewModel extends Model {

  /**
   * 
   */
  move() {
    return this.hasMany(AMoveModel)
  }

  /**
   * 
   */
  publish() {
    return this.hasMany(APublishModel)
  }

  /**
   * Define the BlgWebNew resource.
   * @return {String} BlgWebNew endpoint name.
   */
  resource() {
    return 'web_new'
  }

  /**
   * 
   */
  get status() {
    return this.prop('published_at', null) ? 'Publicado' : 'Borrador'
  }

  /**
   * 
   */
  toCreate() {
    return this.reduce([
      'title',
      'short_description',
      'body',
      'link_instagram',
      'link_twitter',
      'link_facebook',
      'campus_visibilities',
    ])
  }

  /**
   * 
   */
  toUpdate() {
     //
    var clone = this.clone()

     //
    console.log("clone",clone);
    console.log("this",this.campus_visibilities);
    if(typeof this.campus_visibilities[0] === 'object'){

    let campus_visibilities_ids = clone.campus_visibilities.map(function(e){
          return e.id
      })
      clone.campus_visibilities=campus_visibilities_ids
    }
    this.campus_visibilities = clone.campus_visibilities
    console.log("es array",this.campus_visibilities);

     //var campus_visibilities_ids = this.getProperty('campus_visibilities', []).map(({ id }) => id)
     //clone.campus_visibilities = campus_visibilities_ids || []

    return this.reduce([
      'id',
      'title',
      'short_description',
      'body',
      'link_instagram',
      'link_twitter',
      'link_facebook',
      'campus_visibilities',
    ])
  }

  /**
   * 
   */
  toUploadImage() {
    //
    this.image = this.new_image
    //
    return this.toFormData([
      'image',
    ])
  }

  /**
   * 
   */
  uploadImage() {
    return this.hasMany(AUploadImageModel)
  }
}