// service
import Service from '@/services/api/Service'

// model
import SrvSurveyTargetModel from '@/models/Survey/SrvSurveyTarget'

/**
 * This class describes a SrvSurveyTarget service.
 * @class SrvSurveyTarget (name)
 */
export default class SrvSurveyTargetService extends Service {

  /**
   * Define the SrvSurveyTarget model.
   * @return {Function} SrvSurveyTarget resource.
   */
  static model() {
    return SrvSurveyTargetModel
  }
}