<template lang='pug'>
  app-layout-dashboard( v-bind='boardProps' )
    //- r-layout-board( v-bind='boardProps' )
    template( slot='items' )
      vw-btn-survey( bottom icon large tip='Encuestas' @refresh='onRefreshSurveys' )
        v-icon( size="24" ) mdi-alert-decagram
      v-btn-notification(
        v-bind='notificationProps'
        @click-item='submitNotification'
        @refresh='toRefreshNotification'
        )
      //- app-btn-survey(  )
      //-   template( slot='actions' )
      //-     app-btn( color='error' text @click='onRefreshSurveys' )
      //-       v-icon mdi-refresh
      //- app-btn-notify(  )
      //-   template( slot='actions' )
      //-     app-btn( color='error' text @click='onRefreshNotifications' )
      //-       v-icon mdi-refresh
      //- app-btn-user( v-bind='userProps' )
      //-   template( slot='actions' )
      //-     app-btn( color='error' text @click='onSignOut' )
      //-       v-icon mdi-close
      v-btn-user(
        v-bind='buttonUserProps'
        @click-card-action='submitSignOut'
        @click-switch='submitSwitch'
        )
    template( slot='prepend' )
      #prev-content( class="primary" style="position:absolute;width:100%;height:256px;" )
</template>

<script>
  //
  import { VBtnNotification, VBtnTooltip, VBtnUser } from '@/components/buttons'
  import VwBtnSurvey from '@/components/buttons/ButtonSurvey'
  //
  import NtNotificationModel from '@/models/Notification/NtNotification'
  import NtNotificationService from '@/services/api/Notification/NtNotification'
  import PushService from '@/services/push'
  // import menuItems from '@/services/menu'
  //
  // import AppBtnNotify from '@/components/buttons/notify'
  // import AppBtnSurvey from '@/components/buttons/survey'
  // import AppBtnUser from '@/components/buttons/user'
  import AppLayoutDashboard from '@/components/layouts/dashboard'
  //
  import MenuService from '@/services/menu'
  //
  export default {
    components: {
      AppLayoutDashboard,
      VBtnTooltip,
      VBtnUser,
      VBtnNotification,
      VwBtnSurvey,
    },
    computed: {

      /**
       * 
       */
      boardProps() {
        return {
          drawerProps: {
            navigationProps: {
              app: true,
              width: 300,
            },
            listProps: this.listProps
          },
        }
      },

      /**
       * 
       */
      buttonUserProps() {
        return {
          cardProps: {
            title: this.userName,
            subtitle: this.userRol,
          }
        }
      },

      /**
       * 
       */
      listProps() {
        return {
          items: MenuService.toRol(this.$store.getters.userRolName).map(this.mapMenu),
          title: this.userName,
          subtitle: this.userRol,
        }
      },

      /**
       * 
       */
      notificationProps() {
        return {
          badgeContent: this.notifications.filter(e => !e.read_at).length,
          cardProps: {
            loading: this.loading,
            itemDisabled: 'read_at',
            items: this.notifications,
          }
        }
      },
    },
    data() {
      return {
        // menuItems: menuItems,
        notifications: [],
        context: false,
        menue: false,
        menu: [],
        on: null,
        loading: false,
        service: new PushService(),
        userName: 'Desconocido',
        userRol: 'Desconocido',
      }
    },
    methods: {

      /**
       * 
       */
      onRefreshSurveys(res) {
        if (res.data.length) {
          this.menuItems = []
          this.$store.dispatch('setPending', {
            pending: true,
          })
        } else {
          this.menuItems = MenuService.toRol(this.$store.getters.userRolName)
          this.$store.dispatch('setPending', {
            pending: false,
          })
        }
      },

      /**
       * 
       */
      pushNotification(notification) {
        this.notifications = [
          new NtNotificationModel(notification),
          ...this.notifications,
        ]
      },

      /**
       * 
       */
      reset(){
        this.toRefreshNotification()
        this.toResetPushs()
      },

      /**
       * 
       */
      toConnectPushs() {
        if (!this.service.connection) {
          this.service.connect(this.authStore.notification_access_token)
        }
      },

      /**
       * 
       */
      toRefreshNotification() {
        this.loading = true
        NtNotificationService.model().get().then(res=> {
          this.loading = false
          this.notifications = res.data
        })
      },

      /**
       * 
       */
      toResetPushs() {
        this.toConnectPushs()
        this.toStartPushs()
        this.service.on('onNewNotification', this.pushNotification)
      },

      /**
       * 
       */
      toStartPushs() {
        return this.service.start().catch(this.toErrorPush)
      },

      /**
       * 
       */
      mapMenu(item) {
        return {
          icon: item.icon,
          text: item.text,
          link: item.link,
          data: item.data ? Object.values(item.data).map(this.mapMenu) : undefined,
        }
      },

      /**
       * 
       */
      submitActivity(item) {

        // required id
        const $routeName = this.$route.name
        const $routeParamId = item.model_id
        const $routeIsEqual = $routeName === 'act-activity-search'

        // route payload
        const $routePush = {
          name: 'act-activity-search', 
          query: { 
            id: $routeParamId
          }
        }

        // route payload
        const $routeReplace = {
          query: { 
            id: $routeParamId
          }
        }

        // route modification
        if ($routeIsEqual) {
          this.$router.replace($routeReplace)
        } else {
          this.$router.push($routePush)
        }
      },

      /**
       * 
       */
      submitMark(ntNotification) {
        NtNotificationService.mark(ntNotification).then(this.toSuccessMark).then(this.toRefreshNotification)
      },

      /**
       * 
       */
      submitNotification(event) {
        //
        var item = JSON.parse(event.item.data)
        //
        switch (event.item.notification_type) {
          case 'Actividad': this.submitActivity(item)
            break;
        }
        //
        this.submitMark(event.item)
      },

      /**
       * 
       */
      submitSignOut() {
        this.$toast.info('Sesion cerrada.')
        this.$router.push('/auth')
        this.$store.dispatch('setAuthData', { data: {}, remember: true })
      },

      /**
       * 
       */
      submitSwitch(event) {
        switch (event.item.name) {
          case 'notify-state': this.$toast.info('Opcion de notificaciones modificada.'); 
            break;
          case 'message-state': this.$toast.info('Opcion de mensajes modificada.'); 
            break;
        }        
      },

      /**
       * 
       */
      toErrorPush() {
        this.getErrorToast('Error al conectar notificaciones.')
      },

      /**
       * 
       */
      toSuccessMark() {
        this.getSuccessToast('Notificacion leida.')
      },

      /**
       * 
       */
      toSuccessPush() {
        this.getSuccessToast('Notificaciones conectadas.')
      }
    },
    mounted() {

      //
      this.userName = this.$store.getters.userFullName
      this.userRol = this.$store.getters.userRol

      //
      this.reset()
    },
  }
</script>