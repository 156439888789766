// components
import UsrProgramFields from '@/views/User/UsrProgram/fields'
import UsrProgramModel from '@/models/User/UsrProgram'
import UsrProgramService from '@/services/api/User/UsrProgram'
// component
export default {
  name: 'usr-program-create',
  computed: {

    /**
      * Gets the UsrProgram create form props.
      * @return {Object} The create form props.
      */
    formProps() {
      return {
        items: UsrProgramFields.toCreate(),
      }
    }
  },
  data() {
    return {
      usrProgram: UsrProgramService.shape({
        value: ''
      }),
    }
  },
  methods: {

    /**
      * Redirect to UsrProgram list
      */
    redirect() {
      this.goRoute('usr-program-list')
    },

    /**
      * Reset the UsrProgram data.
      */
    reset() {
      this.usrProgram.value = ''
    },

    /**
      * Returns a submit UsrProgram create form.
      * @return {Promise} A form submit process.
      */
    submit() {
      return this.toWait(true).then(this.toCreate).then(this.toWait)
    },

    /**
      * Returns an create UsrProgram process.
      * @return {Promise} A create process.
      */
    toCreate() {
      return UsrProgramService.create(this.usrProgram).then(this.toSuccess).catch(this.toError)
    },

    /**
      * Returns an create UsrProgram error process.
      * @param  {Object}  event - the event error payload.
      */
    toError(event) {
      this.getErrorMessage(event).then(this.getErrorToast)
    },

    /**
      * Returns an create UsrProgram success process.
      * @param  {Object}  event - the event success payload.
      */
    toSuccess(event) {
      this.getSuccessMessage('Programa creado exitosamente.').then(this.getSuccessToast).then(this.redirect)
    },
  },
}