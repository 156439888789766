// service
import Service from '@/services/api/Service'
import SrvSurveyTemplateModel from '@/models/Survey/SrvSurveyTemplate'

/**
 * This class describes a SrvSurveyTemplate service.
 * @class SrvSurveyTemplate (name)
 */
export default class SrvSurveyTemplateService extends Service {

  /**
   * Define the SrvSurveyTemplate model.
   * @return {Function} SrvSurveyTemplate resource.
   */
  static model() {
    return SrvSurveyTemplateModel
  }

  /**
   * 
   */
  static toCreate() {
    return this.shape({
      title: '',
      description: '',
      init_datetime: null,
      is_required: false,
      limit_datetime: null,
      sections: [],
    })
  }

  /**
   * 
   */
  static toDetailList() {
    return this.fetcher({
      includes: [
        'sections.blocks.fields',
        'sections.blocks.options',
        'sections.blocks.range',
      ],
    })
  }

  /**
   * 
   */
  static toPreview() {
    return this.model().include([
      'sections.blocks.fields',
      'sections.blocks.options',
      'sections.blocks.range',
    ].join())
  }

  /**
   * 
   */
  static toUpdate() {
    return this.model().include([
      'sections.blocks.fields',
      'sections.blocks.options',
      'sections.blocks.range',
    ].join())
  }
}