// required modules
import { Fields } from 'collection-wrapper'
import UsrUniversityCampusService from '@/services/api/User/UsrUniversityCampus'

/**
 * This class describes a UsrCareer filters collection.
 * @class
 */
export default class UsrCareerFilters extends Fields {

  /**
   * Returns the UsrCareer filters options.
   * @returns {Array} filters options.
   */
  fields() {
    return [
      {
        name: 'university_campus_id',
        type: 'autocomplete-model',
        props: {
          clearable: true,
          fetch: UsrUniversityCampusService.toList(),
          limit: 15,
          autocompleteProps: {
            clearable: true,
            itemText: 'description',
            itemValue: 'id',
            label: 'Sede',
            multiple: false,
            placeholder: 'Sede',
          },
        },
      },
      {
        name: 'code',
        type: 'text-field',
        props: {
          label: 'Codigo',
          placeholder: 'Codigo',
        },
      },
      {
        name: 'description',
        type: 'text-field',
        flex: {
          cols: 12,
          sm: 6,
        },
        props: {
          label: 'Descripción',
          placeholder: 'Descripción',
        },
      },
    ]
  }

  /**
   * Returns the UsrCareer list filters.
   * @returns {Collection} filters collection.
   */
  static toList() {
    return this.reduce('name', [
      'university_campus_id',
      'code',
      'description',
    ])
  }
}
