// required modules
import Service from '@/services/api/Service'
import StaAttentionTypeOrientationListModel from '@/models/StudentAttention/StaAttentionTypeOrientationList'

/**
 * This class describes a StaAttentionTypeOrientationList service.
 * @class StaAttentionTypeOrientationListService
 */
export default class StaAttentionTypeOrientationListService extends Service {

  /**
   * Define the StaAttentionTypeOrientationList model.
   * @return {Function} StaAttentionTypeOrientationList resource.
   */
  static model() {
    return StaAttentionTypeOrientationListModel
  }

  /**
   * 
   */
  static toListAll() {
    return this.model().params({ get_all_results: 1 }).get().then(res => ({
      data: res
    }))
  }
}