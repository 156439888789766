// model
import Model from '@/models/Model'

/**
 * This class describes a ASendSatisfactionSurvey model.
 * @class ASendSatisfactionSurvey (name)
 */
export default class ASendSatisfactionSurveyModel extends Model {

  /**
   * Define the ASendSatisfactionSurvey resource.
   * @return {String} ASendSatisfactionSurvey endpoint name.
   */
  resource() {
    return 'send_satisfaction_survey'
  }
}