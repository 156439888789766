//
import VwTable from 'vuetify2-wrappers/src/components/table'

//
export default {
  extends: VwTable,
  props: {

    /**
     * 
     */
    filterProps: {
      type: Object,
      default: () => ({

        /**
         * 
         */
        actionsProps: {
          class: 'primary justify-space-between',
        },
      
        buttonsProps: {
          class: 'px-4',
          depressed: true,
          small: true,
        },

        /**
         * 
         */
        cardProps: {
          elevation: 0,
          tile: true,
        },

        /**
         *
         */
        submitButtonProps: {
          color: 'secondary',
          type: 'submit',
        },

        /**
         * 
         */
        toolbarProps: {
          color: 'primary',
          dark: true,
          dense: true,
          flat: true,
        },
      })
    },

    /**
     * 
     */
    resItems: {
      type: String,
      default: 'data',
    },

    /**
     * 
     */
    resLength: {
      type: String,
      default: 'total',
    },
  },
}