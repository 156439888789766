// required modules
import { Headers } from 'collection-wrapper'

/**
 * This class describes a SrvSurveyBlock headers collection.
 *
 * @class SrvSurveyBlockHeaders (name)
 */
export default class SrvSurveyBlockHeaders extends Headers {

  /**
   * Returns the SrvSurveyBlock headers options.
   *
   * @returns {Array} headers options.
   */
  headers() {
    return [
      // {
      //   text: '#',
      //   value: 'id',
      // },
      {
        text: 'Titulo',
        value: 'title',
      },
      {
        text: 'Tipo',
        value: 'survey_block_type_description',
      },
      {
        text: 'Requerido',
        value: 'is_required',
      },
    ]
  }

  /**
   * Returns the SrvSurveyBlock list headers.
   *
   * @returns {Collection} headers collection.
   */
  static toList() {
    return this.reduce('value', [
      'id',
      'title',
      // 'survey_block_type_description',
      // 'is_required',
    ])
  }
}
