// model
import Model from '@/models/Model'

/**
 * This class describes a ActActivityAttendanceStatus model.
 * @class ActActivityAttendanceStatus (name)
 */
export default class ActActivityAttendanceStatusModel extends Model {

  /**
   * Define the ActActivityAttendanceStatus resource.
   * @return {String} ActActivityAttendanceStatus endpoint name.
   */
  resource() {
    return 'activity_attendance_status'
  }
}