// components
import StaAttentionFacilitatorScheduleFields from '@/views/StudentAttention/StaAttentionFacilitatorSchedule/fields'
import StaAttentionFacilitatorScheduleModel from '@/models/StudentAttention/StaAttentionFacilitatorSchedule'
import StaAttentionFacilitatorScheduleService from '@/services/api/StudentAttention/StaAttentionFacilitatorSchedule'

// component
export default {
  name: 'sta-attention-facilitator-schedule-create',
  computed: {

    /**
     * Gets the StaAttentionFacilitatorSchedule create form props.
     * 
     * @return {Object} The create form props.
     */
    formProps() {
      return {
        items: StaAttentionFacilitatorScheduleFields.toCreate(),
      }
    }
  },
  data() {
    return {
      staAttentionFacilitatorSchedule: new StaAttentionFacilitatorScheduleModel({
        attention_init_time: '',
        attention_end_time: '',
      }),
    }
  },
  methods: {

    /**
     * Redirect to StaAttentionFacilitatorSchedule list
     */
    redirect() {
      this.goRoute('sta-attention-facilitator-schedule-list')
    },

    /**
     * Reset the StaAttentionFacilitatorSchedule data.
     */
    reset() {
      this.staAttentionFacilitatorSchedule.value = ''
    },

    /**
     * Returns a submit StaAttentionFacilitatorSchedule create form.
     * 
     * @return {Promise} A form submit process.
     */
    submit() {
      return this.toWait(true).then(this.toCreate).then(this.toWait)
    },

    /**
     * 
     */
    success() {
      this.onSuccess('Atención creada exitosamente.')
      this.redirect()
    },

    /**
     * Returns an create StaAttentionFacilitatorSchedule process.
     * 
     * @return {Promise} A create process.
     */
    toCreate() {
      return StaAttentionFacilitatorScheduleService.create(this.staAttentionFacilitatorSchedule).then(this.success).catch(this.onError)
    },
  },
}