// required components
import StaAttentionFacilitatorScheduleFields from '@/views/StudentAttention/StaAttentionFacilitatorSchedule/fields'
import StaAttentionFacilitatorScheduleFilters from '@/views/StudentAttention/StaAttentionFacilitatorSchedule/filters'
import StaAttentionFacilitatorScheduleHeaders from '@/views/StudentAttention/StaAttentionFacilitatorSchedule/headers'
import StaAttentionFacilitatorScheduleService from '@/services/api/StudentAttention/StaAttentionFacilitatorSchedule'

// component
export default {
  name: 'sta-attention-facilitator-schedule-list',
  computed: {

    /**
     * 
     */
    disableProps() {
      return {
        items: StaAttentionFacilitatorScheduleFields.toDisable(),
      }
    },

    /**
     * 
     */
    tableProps() {
      return {
        actionsProps: {
          width: '15%'
        },
        filters: StaAttentionFacilitatorScheduleFilters.toList(),
        headers: StaAttentionFacilitatorScheduleHeaders.toList(),
        service: StaAttentionFacilitatorScheduleService.toList(),
      }
    }
  },
  data() {
    return {
      dialogs: {
        disable: false,
      },
      disable: {
        start_at: '',
        finish_at: '',
      },
      selected: null,
    }
  },
  methods: {

    /**
     * 
     */
    onClickDisabledSchedule(event) {
      this.selected = event.item
      this.dialogs.disable = true
    },

    /**
     * 
     */
    onClickEnabledSchedule(event) {
      this.$toaster('¿Confirma la habilitación del horario seleccionado?', 'Confirmar', () => {
        this.toEnabledSchedule(event.item)
      })
    },

    /**
     * 
     */
    onClickSchedule() {
      this.goRoute('sta-attention-facilitator-schedule-schedule')
    },

    /**
     * 
     */
    onDisable() {
      this.toWaitDisable(true).then(this.toDisable).then(this.toWaitDisable)
    },

    /**
     * Refresh the StaAttentionFacilitatorSchedule list
     */
    refresh() {
      this.getRef('table').refresh()
    },

    /**
     * 
     */
    submitCreate() {
      this.goRoute('sta-attention-facilitator-schedule-create')
    },

    /**
     * Show a delete StaAttentionFacilitatorSchedule confirm toast
     */
    submitDelete() {
      this.$toaster('¿Confirma la eliminación del horario de atención seleccionado?', 'confirmar', this.toDelete)
    },

    /**
     * Set the selected StaAttentionFacilitatorSchedule table
     * @param  {Object}  event - the click item payload
     */
    submitItem(event) {
      this.selected = event.item
    },

    /**
     * 
     */
    submitUpdate(event) {
      this.goRoute('sta-attention-facilitator-schedule-update', { id: event.item.id })
    },

    /**
     * Return a delete StaAttentionFacilitatorSchedule promise.
     * @param  {StaAttentionFacilitatorScheduleModel}  model - the StaAttentionFacilitatorSchedule model
     * @return {Promise} A delete process.
     */
    toDelete() {
      return StaAttentionFacilitatorScheduleService.delete(this.selected).then(this.toSuccess).catch(this.toError)
    },

    /**
     * 
     */
    toDisabledSchedule(staAttentionFacilitatorSchedule) {
      
    },

    /**
     * 
     */
    toEnabledSchedule(staAttentionFacilitatorSchedule) {
      StaAttentionFacilitatorScheduleService.enabledSchedule(staAttentionFacilitatorSchedule).then(this.onSuccess).then(this.toRefresh).catch(this.onError)
    },

    /**
     * 
     */
    toDisable() {
      return StaAttentionFacilitatorScheduleService
        .disableSchedule(this.selected, this.disable)
        .then(this.onSuccess)
        .then(this.toRefresh)
        .then(() => {
          this.dialogs.disable = false
          this.disable = {
            start_at: '',
            finish_at: '',
          }
        })
        .catch(this.onError)
    },

    /**
     * The delete StaAttentionFacilitatorSchedule error process.
     * @param  {Object}  event - the error delete payload
     */
    toError(event) {
      this.getErrorMessage(event).then(this.getErrorToast)      
    },

    /**
     * 
     */
    toRefresh() {
      return this.getRef('table').refresh()
    },

    /**
     * The delete StaAttentionFacilitatorSchedule success process.
     * @param  {Object}  event - the success delete payload
     */
    toSuccess(event) {
      this.getSuccessMessage(event).then(this.getSuccessToast).then(this.refresh)    
    },

    /**
     * 
     */
    toWaitDisable(val = false) {
      return this.getRef('disable').setWait(val)
    },
  },
}