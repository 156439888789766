// required modules
import Service from '@/services/api/Service'
import UsrUniversityTimeBlockModel from '@/models/User/UsrUniversityTimeBlock'

/**
 * This class describes a UsrUniversityTimeBlock service.
 * @class
 */
export default class UsrUniversityTimeBlockService extends Service {

  /**
   * Define the UsrUniversityTimeBlock model.
   * @return {Function} UsrUniversityTimeBlock resource.
   */
  static model() {
    return UsrUniversityTimeBlockModel
  }
}