// model
import Model from '@/models/Model'

/**
 * This class describes a AUploadImage model.
 * @class AUploadImage (name)
 */
export default class AUploadImageModel extends Model {

  /**
   * Define the AUploadImage resource.
   * @return {String} AUploadImage endpoint name.
   */
  resource() {
    return 'upload_image'
  }
}