// required modules
import VwForm from 'vuetify2-wrappers/src/components/form'

// component
export default {
  extends: VwForm,
  props: {

    /**
     * 
     */
    actionsProps: {
      type: Object,
      default: () => ({
        class: 'primary justify-space-between',
      }),
    },
    
    /**
     * 
     */
    buttonsProps: {
      type: Object,
      default: () => ({
        class: 'px-4',
        depressed: true,
      }),
    },

    /**
     * 
     */
    contentClass: {
      type: String,
      default: 'elevation-0',
    },

    /**
     * 
     */
    dialogCardSubtitle: {
      type: String,
      default: '',
    },

    /**
     * 
     */
    loadingColor: {
      type: String,
      default: 'secondary',
    },

    primaryType: {
      type: String,
      default: 'submit',
    },

    /**
     *
     */
    submitButtonProps: {
      type: Object,
      default: () => ({
        color: 'secondary',
        type: 'submit',
      }),
    },

    subtitleProps: {
      type: Object,
      default: () => ({
        class: 'grey lighten-2',
      }),
    },
    textProps: {
      type: Object,
      default: () => ({
        class: 'pa-4',
      }),
    },
    titleProps: {
      type: Object,
      default: () => ({
        class: 'body-2 font-weight-medium text-uppercase',
      }),
    },
    toolbarProps: {
      type: Object,
      default: () => ({
        color: 'primary',
        dark: true,
        dense: true,
        flat: true,
      }),
    },
    value: {
      type: Object,
      default: () => ({
      }),
    },
  },
}