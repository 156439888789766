// model
import Model from '@/models/Model'

/**
 * This class describes a ACancelReservation model.
 * @class ACancelReservation (name)
 */
export default class ACancelReservationModel extends Model {

  /**
   * Define the ACancelReservation resource.
   * @return {String} ACancelReservation endpoint name.
   */
  resource() {
    return 'cancel_reservation'
  }
}