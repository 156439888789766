// model
import Model from '@/models/Model'

/**
 * This class describes a UsrSpeciality model.
 * @class UsrSpeciality (name)
 */
export default class UsrSpecialityModel extends Model {

  /**
   * Define the UsrSpeciality resource.
   * @return {String} UsrSpeciality endpoint name.
   */
  resource() {
    return 'speciality'
  }
}