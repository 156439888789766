// required modules
import Model from '@/models/Model'
import AAttendanceLevelModel from '@/models/Actions/AAttendanceLevel'
import AAttendancePercentageModel from '@/models/Actions/AAttendancePercentage'
import AQuantityModel from '@/models/Actions/AQuantity'
import ASatisfactionIndicatorModel from '@/models/Actions/ASatisfactionIndicator'
import ASessionQuantityModel from '@/models/Actions/ASessionQuantity'
import AStudentQuantityModel from '@/models/Actions/AStudentQuantity'
import AAverageSessionsModel from '@/models/Actions/AAverageSessions'
import AMonthlyQuantityModel from '@/models/Actions/AMonthlyQuantity'
import ARegisteredQuantityModel from '@/models/Actions/ARegisteredQuantity'
import AUsageQuantityModel from '@/models/Actions/AUsageQuantity'
import AUsageQuantityStackedModel from '@/models/Actions/AUsageQuantityStacked'

// reports - activity
import ActActivityAttendanceLevelReport from '@/services/report/Activity/ActActivityAttendanceLevel'
import ActActivityQuantityReport from '@/services/report/Activity/ActActivityQuantity'
import ActActivitySatisfactionIndicator from '@/services/report/Activity/ActActivitySatisfactionIndicator'

// reports - attention
import StaAttentionAttendancePercentage from '@/services/report/StudentAttention/StaAttentionAttendancePercentage'
import StaAttentionSessionQuantity from '@/services/report/StudentAttention/StaAttentionSessionQuantity'
import StaAttentionStudentQuantity from '@/services/report/StudentAttention/StaAttentionStudentQuantity'
import StaAttentionAverageSessions from '@/services/report/StudentAttention/StaAttentionAverageSessions'
import StaAttentionMonthlyQuantity from '@/services/report/StudentAttention/StaAttentionMonthlyQuantity'

// reports - application
import AppApplicationRegisteredQuantity from '@/services/report/Application/AppApplicationRegisteredQuantity'
import AppApplicationUsageQuantity from '@/services/report/Application/AppApplicationUsageQuantity'
import AppApplicationUsageQuantityStacked from '@/services/report/Application/AppApplicationUsageQuantityStacked'

/**
 * This class describes a RptReport model.
 * @class 
 */
export default class RptReportModel extends Model {

  /**
   * 
   */
  options = {}

  /**
   * 
   */
  attendanceLevel() {
    return this.hasMany(AAttendanceLevelModel)
  }

  /**
   * 
   */
  attendancePercentage() {
    return this.hasMany(AAttendancePercentageModel)
  }

  /**
   * 
   */
  averageSessions() {
    return this.hasMany(AAverageSessionsModel)
  }

  /**
   * 
   */
  get componentType() {
    return 'g-chart'
  }

  /**
   * 
   */
  define = (data = {}) => {
    this.options = data.options
    this.type = data.type
    this.filters = this.filters || {}
  }

  /**
   * 
   */
  monthlyQuantity() {
    return this.hasMany(AMonthlyQuantityModel)
  }

  /**
   * 
   */
  postDefine = () => {
    if (this.report_identifier === 'report_app_usage_quantity') {
      this.options.hAxis.title = `Gráfico registrado de una cantidad total máxima de ${ this.report.total_students } estudiantes`
    }
  }

  /**
   * 
   */
  quantity() {
    return this.hasMany(AQuantityModel)
  }

  /**
   * 
   */
  reduceActivitiesAttendanceLevel(items, item) {
    return items.concat([[ item.type, item.total_enrolled, item.total_assistance, item.attendance_level ]])
  }

  /**
   * 
   */
  reduceActivitiesQuantity(items, item) {
    return items.concat([[ item.type, item.quantity ]])
  }

  /**
   * 
   */
  reduceActivitySatisfactionIndicator(items, item) {
    return items.concat([
      [ 
        item.type,
        item.total_surveys,
        item.total_score,
        item.satisfaction_indicator,
      ]
    ])
  }

  /**
   * 
   */
  reduceApplicationRegisteredQuantity(items, item) {
    return items.concat([
      [ 
        item.month_str,
        item.registered_students
      ]
    ])
  }

  /**
   * 
   */
  reduceApplicationUsageQuantity(items, item) {
    return items.concat([
      [ 
        item.module,
        item.total_usage
      ]
    ])
  }

  /**
   * 
   */
  reduceApplicationUsageQuantityStacked(items, item) {
    return items.concat([
      [ 
        item.module,
        item.mobile_usage_percentage * 100,
        item.web_usage_percentage * 100
      ]
    ])
  }

  /**
   * 
   */
  reduceAttentionAttendancePercentage(items, item) {
    return items.concat([
      [ 
        item.type,
        item.total_attentions, 
        item.assistance, 
        item.percentage 
      ]
    ])
  }

  /**
   * 
   */
  reduceAttentionAverageSessions(items, item) {
    return items.concat([
      [ 
        item.type,
        item.total_attentions,
        item.sample_students,
        item.avg_attention_per_sample_students,
        item.total_attention_students,
        item.avg_attention_per_total_attention_students,
        item.total_students,
        item.avg_attention_per_total_students
      ]
    ])
  }

  /**
   * 
   */
  reduceMonthlyQuantity(items, item, idx) {
    return items.concat(
      item.months.map(subitem => [ 
        subitem.quantity.toString(), 
        subitem.month, 
        item.id, 
        subitem.quantity 
      ], [])
      // item.months.map(subitem => [ 0, 0, 0, 0 ], [])
    )
  }

  /**
   * 
   */
  reduceQuantity(items, item) {
    return items.concat([[ item.type, item.quantity ]])
  }

  /**
   * 
   */
  registeredQuantity() {
    return this.hasMany(ARegisteredQuantityModel)
  }

  /**
   * 
   */
  get report_data() {
    switch(this.report_identifier) {
      case 'report_activity_quantity'               : return this.report_detail_activity_quantity;
      case 'report_activity_attendance_level'       : return this.report_detail_activity_attendance_level;
      case 'report_app_registered_quantity'         : return this.report_detail_application_registered_quantity;
      case 'report_app_usage_quantity'              : return this.report_detail_application_usage_quantity;
      case 'report_app_usage_quantity_stacked'      : return this.report_detail_application_usage_quantity_stacked;
      case 'report_attention_attendance_percentage' : return this.report_detail_attention_attendance_percentage;
      case 'report_attention_session_quantity'      : return this.report_detail_attention_session_quantity;
      case 'report_attention_student_quantity'      : return this.report_detail_attention_student_quantity;
      case 'report_attention_average_sessions'      : return this.report_detail_attention_average_sessions;
      case 'report_attention_monthly_quantity'      : return this.report_detail_attention_monthly_quantity;
      default: return []
    }
  }

  /**
   * 
   */
  get report_detail_activity_attendance_level() {
    return this.prop('report.detail', []).reduce(this.reduceActivitiesAttendanceLevel, [["Tipo", "Inscritos", "Asistencia", "Nivel"]])
  }

  /**
   * 
   */
  get report_detail_activity_quantity() {
    return this.prop('report.detail', []).reduce(this.reduceActivitiesQuantity, [["Tipo", "Actividades"]])
  }

  /**
   * 
   */
  get report_detail_activity_satisfaction_indicator() {
    return this.prop('report.detail', []).reduce(this.reduceActivitySatisfactionIndicator, [this.report_headers_activity_satisfaction_indicator])
  }

  /**
   * 
   */
  get report_detail_application_registered_quantity() {
    return this.prop('report.detail', []).reduce(this.reduceApplicationRegisteredQuantity, [this.report_headers_application_registered_quantity])
  }

  /**
   * 
   */
  get report_detail_application_usage_quantity() {
    return this.prop('report.detail', []).reduce(this.reduceApplicationUsageQuantity, [this.report_headers_application_usage_quantity])
  }

  /**
   * 
   */
  get report_detail_application_usage_quantity_stacked() {
    return this.prop('report.detail', []).reduce(this.reduceApplicationUsageQuantityStacked, [this.report_headers_application_usage_quantity_stacked])
  }

  /**
   * 
   */
  get report_detail_attention_attendance_percentage() {
    return this.prop('report.detail', []).reduce(this.reduceAttentionAttendancePercentage, [ this.report_headers_attention_attendance_percentage ])
  }

  /**
   * 
   */
  get report_detail_attention_session_quantity() {
    return this.prop('report.detail', []).reduce(this.reduceQuantity, [this.report_headers_attention_session_quantity])
  }

  /**
   * 
   */
  get report_detail_attention_student_quantity() {
    return this.prop('report.detail', []).reduce(this.reduceQuantity, [this.report_headers_attention_student_quantity])
  }

  /**
   * 
   */
  get report_detail_attention_average_sessions() {
    return this.prop('report.detail', []).reduce(this.reduceAttentionAverageSessions, [this.report_headers_attention_average_sessions])
  }

  /**
   * 
   */
  get report_detail_attention_monthly_quantity() {
    return this.prop('report.detail', []).reduce(this.reduceMonthlyQuantity, [this.report_headers_attention_monthly_quantity])
  }

  /**
   * 
   */
  get report_file_name() {
    switch(this.report_identifier) {
      case 'report_activity_attendance_level'       : return 'REPORTE_ACTIVIDADES_NIVEL_ASISTENCIA';
      case 'report_activity_satisfaction_indicator' : return 'REPORTE_ACTIVIDADES_INDICADOR_SATISFACCIÓN';
      case 'report_app_registered_quantity'         : return 'REPORTE_APLICACION_CANTIDAD_REGISTRADOS';
      case 'report_app_usage_quantity'              : return 'REPORTE_APLICACION_CANTIDAD_USOS';
      case 'report_app_usage_quantity_stacked'      : return 'REPORTE_APLICACION_CANTIDAD_USOS_STACKED';
      case 'report_attention_attendance_percentage' : return 'REPORTE_ATENCIONES_PORCENTAJE_ASISTENCIA';
      case 'report_attention_session_quantity'      : return 'REPORTE_ATENCIONES_CANTIDAD_SESIONES';
      case 'report_attention_student_quantity'      : return 'REPORTE_ATENCIONES_CANTIDAD_ESTUDIANTES';
      case 'report_attention_average_sessions'      : return 'REPORTE_ATENCIONES_PROMEDIO_SESIONES';
      case 'report_attention_monthly_quantity'      : return 'REPORTE_ATENCIONES_CANTIDAD_MENSUAL';
      default: return 'REPORTE_ACTIVIDADES_CANTIDAD'
    }
  }

  /**
   * 
   */
  get report_headers() {
    switch(this.report_identifier) {
      case 'report_activity_quantity'               : return this.report_headers_activity_quantity;
      case 'report_activity_attendance_level'       : return this.report_headers_activity_attendance_level;
      case 'report_activity_satisfaction_indicator' : return this.report_headers_activity_satisfaction_indicator;
      case 'report_app_registered_quantity'         : return this.report_headers_application_registered_quantity;
      case 'report_app_usage_quantity'              : return this.report_headers_application_usage_quantity;
      case 'report_app_usage_quantity_stacked'      : return this.report_headers_application_usage_quantity_stacked;
      case 'report_attention_attendance_percentage' : return this.report_headers_attention_attendance_percentage;
      case 'report_attention_session_quantity'      : return this.report_headers_attention_session_quantity;
      case 'report_attention_student_quantity'      : return this.report_headers_attention_student_quantity;
      case 'report_attention_average_sessions'      : return this.report_headers_attention_average_sessions;
      case 'report_attention_monthly_quantity'      : return this.report_headers_attention_monthly_quantity;
      default: return []
    }
  }

  /**
   * 
   */
  get report_headers_activity_quantity() {
    return [
      "Tipo", 
      "Actividades"
    ]
  }

  /**
   * 
   */
  get report_headers_activity_attendance_level() {
    return [
      "Tipo", 
      "Inscritos", 
      "Asistencia", 
      "Nivel"
    ]
  }

  /**
   * 
   */
  get report_headers_activity_satisfaction_indicator() {
    return [
      "Tipo", 
      "Encuestas", 
      "Puntaje", 
      "Indicador"
    ]
  }

  /**
   * 
   */
  get report_headers_application_registered_quantity() {
    return [
      "Mes",
      "Estudiantes"
    ]
  }

  /**
   * 
   */
  get report_headers_application_usage_quantity() {
    return [
      "Modulo",
      "Usos: Cantidad de peticiones exitosas de un estudiante hacia el sistema."
    ]
  }

  /**
   * 
   */
  get report_headers_application_usage_quantity_stacked() {
    return [
      "Modulo",
      "Web",
      "Movil",
    ]
  }

  /**
   * 
   */
  get report_headers_attention_attendance_percentage() {
    return [
      "Tipo", 
      "Total",
      "Asistencia",
      "Porcentaje",
    ]
  }

  /**
   * 
   */
  get report_headers_attention_average_sessions() {
    return [
      "Tipo", 
      "Total",
      "Estudiantes",
      "Porcentaje de estudiantes",
      "Atenciones",
      "Porcentaje de atenciones",
      "Total de estudiantes",
      "Porcentaje total de estudiantes"
    ]
  }

  /**
   * 
   */
  get report_headers_attention_monthly_quantity() {
    return [
      "ID",
      "Mes",
      "Tipo de actividad",
      "Cantidad"
    ]
  }

  /**
   * 
   */
  get report_headers_attention_session_quantity() {
    return [
      "Tipo", 
      "Sesiones",
    ]
  }

  /**
   * 
   */
  get report_headers_attention_student_quantity() {
    return [
      "Tipo", 
      "Estudiantes",
    ]
  }

  /**
   * 
   */
  get report_identifier() {
    return this.url_identifier.split('/').join('_')
  }

  /**
   * 
   */
  get report_options() {
    return this.prop('options', {})
  }

  /**
   * 
   */
  get report_title() {
    return this.prop(`report_titles.${ this.report_identifier }`, 'Sin titulo')
  }

  /**
   * 
   */
  get report_type() {
    return this.prop('type', 'BarChart')
  }

  /**
   * 
   */
  get report_titles() {
    return {
      report_activity_quantity              : 'Cantidad de Actividades realizadas (general y por tipo de atención)',
      report_activity_attendance_level      : 'Nivel de Asistencia (general y por tipo de atención)',
      report_activity_satisfaction_indicator: 'Indicador de Satisfacción (general y por tipo de atención)',
      report_app_registered_quantity        : 'Cantidad de estudiantes registrados vs matriculados',
      report_app_usage_quantity             : 'Cantidad de usos por estudiante (general y por módulo)',
      report_app_usage_quantity_stacked     : 'Cantidad de usos por estudiante (general y por módulo)',
      report_attention_attendance_percentage: 'Porcentaje de Asistencia (general y por tipo de atención)',
      report_attention_average_sessions     : 'Promedio de Sesiones realizadas por Estudiante por Tipo de Atención',
      report_attention_monthly_quantity     : 'Cantidad de Sesiones realizadas por Mes por Tipo de Atención',
      report_attention_session_quantity     : 'Cantidad de Sesiones realizadas (general y por tipo de atención)',
      report_attention_student_quantity     : 'Cantidad de Estudiantes Atendidos (general y por tipo de atención)',
    }
  }

  /**
   * 
   */
  get report_unique_value() {
    return this.prop(`report.${ this.options.indicator }`, 0) * this.options.multiply
  }

  /**
   * 
   */
  reset() {

    // define the report
    Promise.resolve(this.definer).then(this.define).then(this.postDefine)

    // return the reset instance
    return this
  }

  /**
   * Define the RptReport resource.
   * @return {String} RptReport endpoint name.
   */
  resource() {
    return 'report'
  }

  /**
   * 
   */
  satisfactionIndicator() {
    return this.hasMany(ASatisfactionIndicatorModel)
  }

  /**
   * 
   */
  sessionQuantity() {
    return this.hasMany(ASessionQuantityModel)
  }

  /**
   * 
   */
  studentQuantity() {
    return this.hasMany(AStudentQuantityModel)
  }

  /**
   * 
   */
  get definer() {
    return this.prop(`definers.${ this.report_identifier }`, {
      options: {},
      height: 400,
    })
  }

  /**
   * 
   */
  get definers() {
    return {
      report_activity_attendance_level      : ActActivityAttendanceLevelReport,
      report_activity_quantity              : ActActivityQuantityReport,
      report_activity_satisfaction_indicator: ActActivitySatisfactionIndicator,
      report_app_registered_quantity        : AppApplicationRegisteredQuantity,
      report_app_usage_quantity             : AppApplicationUsageQuantity,
      report_app_usage_quantity_stacked     : AppApplicationUsageQuantityStacked,
      report_attention_attendance_percentage: StaAttentionAttendancePercentage,
      report_attention_session_quantity     : StaAttentionSessionQuantity,
      report_attention_student_quantity     : StaAttentionStudentQuantity,
      report_attention_average_sessions     : StaAttentionAverageSessions,
      report_attention_monthly_quantity     : StaAttentionMonthlyQuantity,
    }
  }

  /**
   * 
   */
  toAppendChart() {

    //
    if (this.report_identifier === 'report_app_usage_quantity') {
      var clone = RptReportModel.from(this)
      //clone.id = 'app'
      clone.url = clone.url.split('/').slice(0, -1).join('/') + '/usage_quantity_stacked'
      //clone.define(clone.definers.report_app_usage_quantity_stacked)
      clone.reset()
      return clone
    }

    //
    return null
  }

  /**
   * 
   */
  get url_identifier() {
    return this.prop('url', '?/api/?').split('/api/').pop()
  }

  /**
   * 
   */
  usageQuantity() {
    return this.hasMany(AUsageQuantityModel)
  }
}