// service
import Service from '@/services/api/Service'

// model
import StaAttentionStudentHistoryModel from '@/models/StudentAttention/StaAttentionStudentHistory'

/**
 * This class describes a StaAttentionStudentHistory service.
 * 
 * @class StaAttentionStudentHistory (name)
 */
export default class StaAttentionStudentHistoryService extends Service {

  /**
   * Define the StaAttentionStudentHistory model.
   * 
   * @return {Function} StaAttentionStudentHistory resource.
   */
  static model() {
    return StaAttentionStudentHistoryModel
  }

  /**
   * Define the StaAttentionStudentHistory model.
   * 
   * @return {Function} StaAttentionStudentHistory resource.
   */
  static toList() {
    return this.fetcher({
      includes: [
        'period',
        'facilitator',
        'attention_type',
        'campus',
        'status',
        'student',
        'student_status',
      ],
    })
  }
}