// required modules
import { Fields } from 'collection-wrapper'
import StaAttentionTypeService from '@/services/api/StudentAttention/StaAttentionType'
import UsrUniversityPeriodService from '@/services/api/User/UsrUniversityPeriod'

/**
 * Define an attention facilitator schedule fields.
 * @class
 */
export default class StaAttentionFacilitatorScheduleFields extends Fields {

  /**
   * Define the fields options.
   * @returns {Array} the fields options.
   */
  fields() {
    return [
      {
        name: 'university_period_id',
        type: 'select-model',
        info: 'Periodo universitario.',
        props: {
          selectProps: {
            itemText: 'identifier',
            itemValue: 'id',
            label: 'Periodo universitario',
            noDataText: 'Sin periodos universitarios',
            placeholder: 'Periodo universitario',
          },
          fetch: UsrUniversityPeriodService.toList(),
        },
      },
      {
        name: 'attention_type_id',
        type: 'select-model',
        info: 'Tipo de Atención (El usuario debe tener este tipo de atención asignado).',
        props: {
          fetch: () => StaAttentionTypeService.toListByLoggedUser().get(),
          // fetch: StaAttentionTypeService.toList(),
          selectProps: {
            itemText: 'description',
            itemValue: 'id',
            label: 'Tipo de Atención',
            noDataText: 'Sin tipos de atención',
            placeholder: 'Tipo de Atención',
          },
        },
      },
      {
        name: 'weekday',
        type: 'select',
        info: 'Día de la semana (1: Lunes, 7: Domingo).',
        props: {
          items: [
            {
              text: 'Lunes',
              value: 1,
            },
            {
              text: 'Martes',
              value: 2,
            },
            {
              text: 'Miércoles',
              value: 3,
            },
            {
              text: 'Jueves',
              value: 4,
            },
            {
              text: 'Viernes',
              value: 5,
            },
            {
              text: 'Sábado',
              value: 6,
            },
            {
              text: 'Domingo',
              value: 7,
            },
          ],
          label: 'Día de la semana',
          placeholder: 'Día de la semana',
        },
      },
      {
        name: 'attention_init_time',
        type: 'time-field',
        info: 'Inicio del horario (HH:mm, ej: 08:00).',
        props: {
          label: 'Inicio del horario',
          fieldProps: {
            label: 'Inicio del horario',
            placeholder: 'Inicio del horario',
          },
          pickerProps: {
            useSeconds: false,
          },
        },
      },
      {
        name: 'attention_end_time',
        type: 'time-field',
        info: 'Fin del horario (HH:mm, ej: 09:00).',
        props: {
          label: 'Inicio del horario',
          fieldProps: {
            label: 'Fin del horario',
            placeholder: 'Fin del horario',
          },
        },
      },
      {
        name: 'start_at',
        type: 'date-field',
        flex: {
          cols: 12,
          sm: 6,
        },
        props: {
          fieldProps: {
            label: 'Inicio',
          },
        },
      },
      {
        name: 'finish_at',
        type: 'date-field',
        flex: {
          cols: 12,
          sm: 6,
        },
        props: {
          fieldProps: {
            label: 'Termino', 
          },         
        },
      },
    ]
  }

  /**
   * Returns a create fields collection.
   * @return {Collection} a fields collection.
   */
  static toCreate() {
    return this.reduce('name', [
      'university_period_id',
      'attention_type_id',
      'weekday',
      'attention_init_time',
      'attention_end_time',
    ])
  }

  /**
   * Returns a create multiple fields collection.
   * @return {Collection} a fields collection.
   */
  static toCreateMultiple() {
    return this
      .init()
      .each(item => {
        if (item.name == 'weekday') {
          item.props.smallChips = true
          item.props.multiple = true
        }
      })
      .whereIn('name', [
        'attention_type_id',
        'weekday',
        'attention_init_time',
        'attention_end_time',
      ])
  }

  /**
   * 
   */
  static toDisable() {
    return this.reduce('name', [
      'start_at',
      'finish_at',
    ])
  }

  /**
   * Returns a update fields collection.
   * @return {Collection} a fields collection.
   */
  static toUpdate() {
    return this.reduce('name', [
      'university_period_id',
      'attention_type_id',
      'weekday',
      'attention_init_time',
      'attention_end_time',
    ])
  }
}
