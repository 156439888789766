// required modules
import StaAttentionFacilitatorScheduleFields from '@/views/StudentAttention/StaAttentionFacilitatorSchedule/fields'
import StaAttentionFacilitatorScheduleFilters from '@/views/StudentAttention/StaAttentionFacilitatorSchedule/filters'
import StaAttentionFacilitatorScheduleHeaders from '@/views/StudentAttention/StaAttentionFacilitatorSchedule/headers'
import StaAttentionFacilitatorScheduleService from '@/services/api/StudentAttention/StaAttentionFacilitatorSchedule'
import UsrUniversityPeriodService from '@/services/api/User/UsrUniversityPeriod'

// component
export default {
  name: 'sta-attention-facilitator-schedule-schedule',
  computed: {

    /**
     * 
     */
    calendarItems() {
      //console.log("this.items.reduce(this.reduceEvents, []).map(this.mapEvent)", this.items.reduce(this.reduceEvents, []).map(this.mapEvent))
      return this.items.reduce(this.reduceEvents, []).map(this.mapEvent)
    },

    /**
     *
     */
    calendarProps() {
      return {
        calendarProps: {
          eventColor: event => event.calendar_color,
          // eventEnd: 'attention_end_datetime_temporally',
          eventHeight: 128,
          eventMarginBottom: 8,
          eventMore: false,
          // eventName: 'start',
          eventStart: 'attention_init_datetime_temporally',
          events: this.calendarItems,
          weekdays: [ 1, 2, 3, 4, 5, 6, 0 ],
          // type: 'week'
        },
        loading: this.loading,
        toolbarProps: {
          color: 'primary',
          dark: true,
          dense: true,
          flat: true,
          style: {
            marginBottom: 2,
          }
        },
      }
    },

    /**
     * 
     */
    createProps() {
      return {
        items: StaAttentionFacilitatorScheduleFields.toCreateMultiple(),
      }
    },

    /**
     * 
     */
    disableProps() {
      return {
        items: StaAttentionFacilitatorScheduleFields.toDisable(),
      }
    },

    /**
     * 
     */
    params() {
      return {
        filter: {
          owner: this.owner,
          period: this.period,
        }
      }
    },

    /**
     * 
     */
    periodProps() {
      return {
        items: this.periods,
      }
    },
    
    hasDisableSelected() {
      return this.selected.some(schedule => {
        return schedule.disabled_schedule
      })
    },
    
    hasEnableSelected() {
      return this.selected.some(schedule => {
        return schedule.enabled_schedule
      })
    },
    
    hasSelected() {
      return this.selected.length > 0
    },
  },
  data() {
    return {
      dialogs: {
        create: false,
        disable: false,
        disabled: false,
        enabled: false,
        period: false,
      },
      disable: {
        start_at: '',
        finish_at: '',
      },
      events: [],
      items: [],
      loading: false,
      owner: true,
      period: {
        id: 1,
      },
      periods: [],
      selected: [],
      staAttentionDisable: {
        finish_at: "",
        start_at: "",
      },
      months: ['Enero','Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
      'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
      //se usa para capturar el mes actual cuando se monta el componente
      actualMonth: new Date(),
      numberActualMonth: 0,
      staAttentionFacilitatorSchedule: StaAttentionFacilitatorScheduleService.toCreate(),
    }
  },
  methods: {
    /**
     * Método que se llama cuando al calendario cambia de página a la siguiente
     * en este caso cada página contiene un mes
     */
    pageChangeNext () {
      this.numberActualMonth = this.numberActualMonth == 11? 0: this.numberActualMonth + 1
      this.actualMonth = this.months[  this.numberActualMonth]   
    },
    /**
     * Método que se llama cuando al calendario cambia de página a la anterior
     * en este caso cada página contiene un mes y se incializa en el actual
     */
    pageChangePrev () {
      this.numberActualMonth = this.numberActualMonth == 0? 11: this.numberActualMonth - 1
      this.actualMonth = this.months[  this.numberActualMonth]   
    },

    /**
     * 
     */
    isSelected(props) {
      return this.selected.some(selected => selected.index === props.event.index)
    },

    /**
     * 
     */
    mapEvent(event, index) {
      event.index = index
      return event
    },

    /**
     * 
     */
    onClickCreate() {
      this.toResetCreate({ weekday: [] })
      this.toShowCreate()
    },

    /**
     * 
     */
    onClickDate(event) {
      this.toResetCreate({ weekday:[ event.weekday] || [7] })
      this.toShowCreate()
    },
    
    onClickNext() {
      this.events = this.items.reduce((t, event) => {
        t.push(event)
        t.push(event.getCloneWithIncrementByDays(7))
        t.push(event.getCloneWithIncrementByDays(14))
        t.push(event.getCloneWithIncrementByDays(21))
        t.push(event.getCloneWithIncrementByDays(28))
        return t
      }, [])
    },
    
    onClickPrev() {
      this.events = this.items.reduce((t, event) => {
        t.push(event)
        t.push(event.getCloneWithIncrementByDays(7))
        t.push(event.getCloneWithIncrementByDays(14))
        t.push(event.getCloneWithIncrementByDays(21))
        t.push(event.getCloneWithIncrementByDays(28))
        return t
      }, [])
    },

    /**
     * 
     */
    onDelete() {
      this.$toaster('¿Confirma eliminar los eventos seleccionados?', 'confirmar', () => {
        StaAttentionFacilitatorScheduleService
          .deletes(this.selected)
          .then(this.onSuccess)
          .then(this.onSuccessDelete)
          .then(this.onClickRefresh)
          .catch(this.onError)
      })
    },

    /**
     * 
     */
    onDisable() {
      this.dialogs.disabled = true
    },

    onDisableDialogReset() {
      this.dialogs.disabled = false
    },

    async onDisableDialogSubmit() {
      try {
        let response = await StaAttentionFacilitatorScheduleService.disableMany(this.selected)
        this.onSuccess('Horarios de atención deshabilitados.')
      } catch(e) {
        this.onError('Ha ocurrido un error al deshabilitar uno de los horarios.')
      }
      this.dialogs.disabled = false
    },

    /**
     * 
     */
    onDisables() {
      this.toWaitDisable(true).then(this.toDisables).then(this.toWaitDisable).then(this.onClickRefresh)
    },
    
    onEnable() {
      this.dialogs.enabled = true
      // this.$toaster('¿Confirma habilitar los eventos seleccionados?', 'confirmar', () => {
      //   StaAttentionFacilitatorScheduleService.enable(this.selected).then(this.onSuccess).then(this.onClickRefresh).catch(this.onError)
      // })
    },

    onEnableDialogReset() {
      this.dialogs.enabled = false
    },

    async onEnableDialogSubmit() {
      try {
        let response = await StaAttentionFacilitatorScheduleService.enables(this.selected)
        this.onSuccess('Horarios de atención habilitados.')
      } catch(e) {
        this.onError('Ha ocurrido un error al habilitar uno de los horarios.')
      }
      this.dialogs.enabled = false
    },

    /**
     * 
     */
    onEvent(props) {

      //
      const eventIndex = this.selected.findIndex(selected => selected.index === props.event.index)
      const eventAvailable = eventIndex < 0
      
      //
      if (eventAvailable) {
        this.selected.push(props.event)
      } else {
        this.selected.splice(eventIndex, 1)
      }
    },

    /**
     * Set the selected StaAttentionFacilitatorSchedule calendar.
     * @param  {Object}  event - the click item payload.
     */
    onClickItem(event) {
      this.selected = event.item
    },

    /**
     * 
     */
    onClickRefresh() {
      if (this.owner) {
        Promise.resolve(true).then(this.toLoading).then(this.toOwner).then(this.toLoading)
      } else {
        Promise.resolve(true).then(this.toLoading).then(this.toRefresh).then(this.toLoading)
      }
    },

    /**
     * 
     */
    onOwner() {
      if (!this.owner) {
        Promise.resolve(true).then(this.toLoading).then(this.toOwner).then(this.toLoading)
      } else {
        Promise.resolve(true).then(this.toLoading).then(this.toRefresh).then(this.toLoading)
      }
    },

    /**
     * 
     */
    onPeriod(event) {
      this.period = event.item
      this.dialogs.period = false
      this.onClickRefresh()
    },

    /**
     * 
     */
    onReset() {
      this.staAttentionFacilitatorSchedule = StaAttentionFacilitatorScheduleService.toCreate()
    },

    /**
     * 
     */
    onRefreshPeriods() {
      UsrUniversityPeriodService.model().get().then(res => {
        this.periods = res.data
      })
    },

    /**
     * 
     */
    onSuccessCreate() {
      this.onSuccess('Horario de atención agregado.')
    },

    /**
     * 
     */
    onSuccessCreateMultiple() {
      this.onSuccessCreate()
      this.onClickRefresh()
      this.toShowCreate(false)
    },

    onSuccessDelete() {
      this.selected = []
    },

    /**
     * 
     */
    onSubmit() {
      this.toWait(true).then(this.toCreateMultiple).then(this.toWait)
    },

    reduceEvents(events, event) {
      //console.log("event: ",event)
      //si el evento de la semana es mayor o igual al dia de hoy  se muestra
      let hoy = new Date().getDay();
      if(event.weekday >= hoy) events.push(event)
      events.push(event.getCloneWithIncrementByDays(7))
      events.push(event.getCloneWithIncrementByDays(14))
      events.push(event.getCloneWithIncrementByDays(21))
      events.push(event.getCloneWithIncrementByDays(28))
      return events
    },

    /**
     * 
     */
    toCreate(staAttentionFacilitatorSchedule) {
      return StaAttentionFacilitatorScheduleService.create(staAttentionFacilitatorSchedule)
    },

    /**
     * 
     */
    toCreateMultiple() {
      return Promise.all(this.staAttentionFacilitatorSchedule.weekday.map(item => {
        let clone = this.staAttentionFacilitatorSchedule.clone()
        clone.weekday = item
        return this.toCreate(clone)
      })).then(this.onSuccessCreateMultiple).catch(this.onError)
    },

    /**
     * 
     */
    toDisables() {
      return StaAttentionFacilitatorScheduleService.disables(this.selected, this.disable).then(this.onSuccess).catch(this.onError)
    },

    /**
     * 
     */
    toLoading(loading = false) {
      this.loading = loading
    },

    /**
     * 
     */
    toOwner() {
      return StaAttentionFacilitatorScheduleService.toListSchedule({ period: this.period, owner: this.owner }).get().then(res => {
        this.items = res.data
      })
    },

    /**
     * 
     */
    toResetCreate(opts = {}) {
      this.staAttentionFacilitatorSchedule = StaAttentionFacilitatorScheduleService.toCreate(opts)
    },

    /**
     * Refresh the StaAttentionFacilitatorSchedule calendar.
     */
    toRefresh() {
      StaAttentionFacilitatorScheduleService.toListSchedule(this.params).get().then(res => {
        this.items = res.data
        this.toLoading() 
        this.toShowCreate(false)
      })
    },

    /**
     * 
     */
    toShowCreate(create = true) {
      this.dialogs.create = create
    },

    /**
     * 
     */
    toWaitDisable(val = false) {
      return this.getRef('disable').setWait(val)
    },
  }, 
  mounted() {
    //se cambia el número de mes actual para renderizar el mes actual
    this.numberActualMonth = this.actualMonth.getMonth()
    //se cambia el nombre del mes actual
    this.actualMonth = this.months[this.actualMonth.getMonth()]
    this.onRefreshPeriods()
    this.onClickRefresh()
    //console.log('this.$refs.calendar clock',this.$refs.calendar)
  },
}