// layout
import RLayoutModule from '@/components/layouts/LayoutModule'

// views
import UsrFacilitatorCreateView from '@/views/User/UsrFacilitator/create'
import UsrFacilitatorListView from '@/views/User/UsrFacilitator/list'
// import UsrUserFacilitatorView from '@/views/User/UsrUser/facilitator'
import UsrFacilitatorUpdateView from '@/views/User/UsrFacilitator/update'

// routes
export default {
  path: '/facilitadores',
  component: RLayoutModule,
  children: [
    {
      name: 'usr-facilitator-list',
      path: '',
      component: UsrFacilitatorListView,
    },
    {
      name: 'usr-facilitator-create',
      path: 'crear',
      component: UsrFacilitatorCreateView,
      meta: {
        title: 'Crear Facilitador'
      },
    },
    {
      name: 'usr-facilitator-update',
      path: ':id/editar',
      component: UsrFacilitatorUpdateView,
      meta: {
        title: 'Editar Facilitador'
      },
    },
  ],
  meta: {
    title: 'Facilitadores'
  },
  props: {
    indexText: 'Inicio',
  },
}
