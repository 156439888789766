//
import UsrUserModel from '@/models/User/UsrUser'
import UsrUserService from '@/services/api/User/UsrUser'
import UsrUserFields from '@/views/User/UsrUser/fields'
//
export default {
  computed: {

    /**
      * Gets the UsrUser form props.
      * @return {Object} The form props.
      */
    formProps() {
      return {
        items: UsrUserFields.toCreate(),
      }
    }
  },
  data() {
    return {
      usrUser: new UsrUserModel({
        value: ''
      }),
    }
  },
  methods: {

    /**
      * Redirect to UsrUser list
      */
    redirect() {
      this.pushRoute('usr-user-list')
    },

    /**
      * Reset the UsrUser data.
      */
    reset() {
      this.usrUser.value = ''
    },

    /**
      * Returns a submit UsrUser form.
      * @return {Promise} A form submit process.
      */
    submit() {
      return this
        .toWait(true)
        .then(this.toCreate)
        .then(this.toWait)
    },

    /**
      * Returns an create UsrUser process.
      * @return {Promise} A create process.
      */
    toCreate() {
      return UsrUserService
        .create(this.usrUser)
        .then(this.toSuccess)
        .catch(this.toError)
    },

    /**
      * Returns an create UsrUser error process.
      * @param  {Object}  event - the event error payload
      */
    toError(event) {
      UsrUserModel
        .getCase('error_create', event)
        .then(this.getErrorToast)
    },

    /**
      * Returns an create UsrUser success process.
      */
    toSuccess() {
      UsrUserModel
        .getCase('success_create')
        .then(this.getSuccessToast)
        .then(this.redirect)
    },
  },
}
