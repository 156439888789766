// required modules
import { Fields } from 'collection-wrapper'
import { ruleRequired } from 'vuetify2-wrappers/src/rules'

// service
import NtMailRrssLogoService from '@/services/api/Notification/NtMailRrssLogo'

/**
 * This class describes a NtMailRrss fields collection.
 * @class NtMailRrssFields
 */
export default class NtMailRrssFields extends Fields {

  /**
   * Returns the NtMailRrss fields options.
   * @returns {Array} fields options.
   */
  fields() {
    return [
      {
        name: 'order',
        type: 'float-field',
        prop: {
          label: 'Orden de aparición',
          placeholder: 'Orden de aparición',
          rules: [ruleRequired],
        },
        info: 'Orden de aparición del canal RRSS.',
      },
      {
        name: 'mail_rrss_logo_id',
        type: 'select-model',
        prop: {
          fetch: NtMailRrssLogoService.toList(),
          selectProps: {
            itemText: 'description',
            itemValue: 'id',
            label: 'ID del logo asociado a esta RRSS',
            placeholder: 'ID del logo asociado a esta RRSS',
            rules: [ruleRequired],
          },
        },
      },
      {
        name: 'link',
        type: 'text-field',
        prop: {
          label: 'Enlace',
          placeholder: 'Enlace',
          rules: [ruleRequired],
        },
      },
    ]
  }

  /**
   * Returns the NtMailRrss create fields.
   * @returns {Collection} fields collection.
   */
  static toCreate() {
    return new this().collect().whereIn('name', [
      'order',
      'mail_rrss_logo_id',
      'link',
    ])
  }

  /**
   * Returns the NtMailRrss update fields.
   * @returns {Collection} fields collection.
   */
  static toUpdate() {
    return new this().collect().whereIn('name', [
      'order',
      'mail_rrss_logo_id',
      'link',
    ])
  }
}
