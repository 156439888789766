// required modules
import UsrProgramFilters from '@/views/User/UsrProgram/filters'
import UsrProgramHeaders from '@/views/User/UsrProgram/headers'
import UsrProgramService from '@/services/api/User/UsrProgram'

// component
export default {
  name: 'usr-program-list',
  computed: {

    /**
     * 
     */
    tableProps() {
      return {
        filters: UsrProgramFilters.toList(),
        headers: UsrProgramHeaders.toList(),
        service: UsrProgramService.toList(),
      }
    }
  },
  methods: {

    /**
     * Go to create UsrProgram view
     */
    onClickCreate() {
      this.goRoute('usr-program-create')
    },

    /**
     * Show a delete UsrProgram confirm toast
     */
    onClickDelete(event) {
      this.selected = event.item
      this.$toaster('¿Confirma la eliminación del programa seleccionado?', 'confirmar', this.toDelete)
    },

    /**
     * Go to update UsrProgram view
     */
    onClickUpdate(event) {
      this.goRoute('usr-program-update', { id: event.item.id })
    },

    /**
     * The delete UsrProgram error process.
     * @param  {Object}  event - the error delete payload
     */
    onError(event) {
      this.getErrorMessage(event).then(this.getErrorToast)      
    },

    /**
     * The delete UsrProgram success process.
     * @param  {Object}  event - the success delete payload
     */
    onSuccess(event) {
      this.getSuccessMessage(event).then(this.getSuccessToast).then(this.refresh)    
    },

    /**
     * Return a delete UsrProgram promise.
     * @param  {UsrProgramModel}  model - the UsrProgram model
     * @return {Promise} A delete process.
     */
    toDelete() {
      return UsrProgramService.delete(this.selected).then(this.onSuccess).catch(this.onError)
    },

    /**
     * Refresh the UsrProgram list
     */
    toRefresh() {
      this.getRef('table').refresh()
    },
  },
}