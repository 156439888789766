// required modules
import Service from '@/services/api/Service'
import SrvSurveyBlockModel from '@/models/Survey/SrvSurveyBlock'

/**
 * This class describes a SrvSurveyBlock service.
 * @class
 */
export default class SrvSurveyBlockService extends Service {

  /**
   * Generate a SrvSurveyBlock title properties.
   * @param   {object}  SrvSurveyBlock -  the survey block model.
   * @returns {object}                    the title input properties.
   */
  static genBlockTitle(srvSurveyBlock) {
    return {
      name: `block_title`,
      type: 'title-field',
      flex: {
        cols: 12,
      },
      props: {
        noTitle: false,
        subtitleText: `${ srvSurveyBlock.description_prevent }`,
        subtitleProps: {
          class: ' pb-4 grey--text text--darken-3',
        },
        titleText: `${ srvSurveyBlock.title }`,
        titleProps: {
          class: 'primary--text subtitle-1 mb-1',          
        },
      },
    }
  }

  /**
   * Generate a SrvSurveyBlock legend to stars properties.
   * @param   {object}  SrvSurveyBlock -  the survey block model.
   * @returns {object}                    the legend input properties.
   */
   static genBlockLegend(srvSurveyBlock) {
    console.log("srvSurveyBlock",srvSurveyBlock)
    if(srvSurveyBlock.survey_block_identifier_id == 8){
      srvSurveyBlock.min_range = parseInt(srvSurveyBlock.min_range)
      srvSurveyBlock.max_range = parseInt(srvSurveyBlock.max_range)
      return {
        name: `block_title`,
        type: 'title-field',
        flex: {
          cols: 12,
        },
        props: {
          noTitle: true,
          subtitleText: `${srvSurveyBlock.min_range}★: ${srvSurveyBlock.min_range_label} - ${srvSurveyBlock.max_range}★: ${srvSurveyBlock.max_range_label}`,
          subtitleProps: {
            class: ' mb-1 primary--text font-weight-bold',
          },
        },
      }
    }
    if(srvSurveyBlock.survey_block_identifier_id == 7){
      srvSurveyBlock.min_range = parseInt(srvSurveyBlock.min_range)
      srvSurveyBlock.max_range = parseInt(srvSurveyBlock.max_range)
      return {
        name: `block_title`,
        type: 'title-field',
        flex: {
          cols: 12,
        },
        props: {
          noTitle: true,
          subtitleText: `${srvSurveyBlock.min_range}: ${srvSurveyBlock.min_range_label} - ${srvSurveyBlock.max_range}: ${srvSurveyBlock.max_range_label}`,
          subtitleProps: {
            class: ' mb-1 primary--text font-weight-bold',
          },
        },
      }
    }
  }
  /**
   * Generate a date properties
   * @param   {object}  srvSurveyBlock -  the survey block model.
   * @returns {object}                    the date input properties.
   */
  static genDate(srvSurveyBlock) {
    return {
      name: `block_response_${srvSurveyBlock.id}`,
      type: 'date-field',
      flex: {
        cols: 12,
      },
      props: {
        class: 'px-0',
        fieldProps: {
          filled: true,
          label: srvSurveyBlock.title,
          placeholder: srvSurveyBlock.placeholder,
        },
      },
    }
  }

  /**
   * 
   */
  static genDivider() {
    return {
      name: `block_divider`,
      type: 'divider',
      flex: {
        cols: 12,
      },
      props: {
        class: 'my-3',        
      },
    }
  }

  /**
   * Generate a input properties
   * @param   {object}  srvSurveyBlock -  the survey block model.
   * @returns {object}                    the input properties.
   */
  static genInput(srvSurveyBlock) {
    switch(srvSurveyBlock.survey_block_identifier_id) {
      case 2  : return this.genTextInput(srvSurveyBlock);
      case 3  : return this.genTextareaInput(srvSurveyBlock);
      case 4  : return this.genMultipleOption(srvSurveyBlock);
      case 5  : return this.genMultipleCheckbox(srvSurveyBlock);
      case 6  : return this.genList(srvSurveyBlock);
      case 7  : return this.genRange(srvSurveyBlock);
      case 8  : return this.genRangeStar(srvSurveyBlock);
      case 9  : return this.genTableOption(srvSurveyBlock);
      case 10 : return this.genTableCheckbox(srvSurveyBlock);
      case 11 : return this.genDate(srvSurveyBlock);
      case 12 : return this.genTime(srvSurveyBlock);
      default : return null;
    }
  }

  /**
   * Generate a list properties
   * @param   {object}  srvSurveyBlock - the survey block model.
   * @returns {object}  the list input properties.
   */
  static genList(srvSurveyBlock) {
    return {
      name: `block_response_${srvSurveyBlock.id}`,
      type: 'select',
      flex: {
        cols: 12,
      },
      props: {
        class: 'px-0',
        filled: true,
        items: srvSurveyBlock._options.map(this.mapListOption),
        itemText: 'description',
        itemValue: 'index',
        label: srvSurveyBlock.title,
        placeholder: srvSurveyBlock.placeholder,
      },
    }
  }

  /**
   * Generate a multiple checkbox properties
   * @param   {object}  srvSurveyBlock - the survey block model.
   * @returns {object}  the multiple checkbox input properties.
   */
  static genMultipleCheckbox(srvSurveyBlock) {
    return {
      name: `block_response_${srvSurveyBlock.id}`,
      type: 'checkbox-group-field',
      flex: {
        cols: 12,
      },
      props: {
        checkboxProps: {
          class: 'ma-0 pa-0'
        },
        class: 'px-0',
        items: srvSurveyBlock._options.map(this.mapListOption),
        itemText: 'description',
        itemValue: 'index',
        rowProps: {
          class: 'mx-0',
          style: {
            flexFlow: 'column',
          },
        },
      },
    }
  }

  /**
   * Generate a multiple option properties
   * @param   {object}  srvSurveyBlock - the survey block model.
   * @returns {object}  the multiple option input properties.
   */
  static genMultipleOption(srvSurveyBlock) {
    return {
      name: `block_response_${srvSurveyBlock.id}`,
      type: 'radio-group-field',
      flex: {
        cols: 12,
      },
      props: {
        class: 'px-0',
        items: srvSurveyBlock._options,
        itemText: 'description',
        // itemValue: 'description',
        rowProps: {
          justify: 'space-around',
        },
      },
    }
  }

  /**
   * Generate a range properties
   * @param   {object}  srvSurveyBlock - the survey block model.
   * @returns {object}  the range input properties.
   */
  static genRange(srvSurveyBlock) {
    let range = []
    // console.log("srvSurveyBlockRange",srvSurveyBlock)
    // for (let i = srvSurveyBlock.min_range; i <= srvSurveyBlock.max_range; i++) {
    //   if(i==srvSurveyBlock.min_range) range.push(srvSurveyBlock.min_range_label)
    //   else if(i==srvSurveyBlock.max_range) range.push(srvSurveyBlock.max_range_label)
    //   else 
    //   range.push(i)
    // }
    // console.log("range!!",range)
    return {
      name: `block_response_${srvSurveyBlock.id}`,
      type: 'slider',
      flex: {
        cols: 12,
      },
      props: {
        class: 'px-0 pt-5',
        max: srvSurveyBlock.max_range,
        min: srvSurveyBlock.min_range,
        //subtitleText: `${srvSurveyBlock.min_range}★: ${srvSurveyBlock.min_range_label} - ${srvSurveyBlock.max_range}★: ${srvSurveyBlock.max_range_label}`,
        //tickLabels:[...range],
        thumbLabel: 'always',
      },
    }
  }

  /**
   * Generate a range star properties
   * @param   {object}  srvSurveyBlock - the survey block model.
   * @returns {object}  the range star input properties.
   */
  static genRangeStar(srvSurveyBlock) {
    return {
      name: `block_response_${srvSurveyBlock.id}`,
      type: 'rating',
      flex: {
        cols: 12,
      },
      props: {
        class: 'px-0',
        length: srvSurveyBlock.max_range - srvSurveyBlock.min_range + 1,
        // min: srvSurveyBlock.min_range,
      },
    }
  }

  /**
   * Generate a SrvSurveySection subtitle properties.
   * @param   {object}  srvSurveySection -  the survey section model.
   * @returns {object}                      the subtitle input properties.
   */
  static genSectionSubtitle(srvSurveySection) {
    return {
      name: `block_subtitle`,
      type: 'title-field',
      flex: {
        cols: 12,
      },
      props: {
        subtitleProps: {
          class: 'grey--text text--darken-2 subtitle-2',
        },
        subtitleText: `Cantidad de preguntas: ${ srvSurveySection.blocks.length }`,
        titleText: `Tipo: Sección.`,
        titleProps: {
          class: 'grey--text text--darken-2 subtitle-2',          
        },
      },
    }
  }

  /**
   * Generate a SrvSurveySection title properties.
   * @param   {object}  srvSurveySection -  the survey section model.
   * @returns {object}                      the title input properties.
   */
  static genSectionTitle(srvSurveySection) {
    return {
      name: `block_title`,
      type: 'title-field',
      flex: {
        cols: 12,
      },
      props: {
        noTitle: false,
        subtitleText: `${ srvSurveySection.description_prevent }`,
        subtitleProps: {
          class: ' py-4 grey--text text--darken-3 ma-0 pb-0 pt-0 text-center',
        },
        titleText: `${ srvSurveySection.title }`,
        titleProps: {
          class: 'primary--text title text-center text-uppercase',          
        },
      },
    }
  }

  /**
   * Generate a subheader properties.
   * @param   {object}  srvSurveyBlock - the survey block model.
   * @returns {object}  the subheader input properties.
   */
  static genSubheader(srvSurveyBlock, title = 'title') {
    return {
      name: `block_response_${srvSurveyBlock.id}`,
      type: 'subheader',
      flex: {
        cols: 12,
      },
      props: {
        class: 'px-0 grey--text text--darken-4',
      },
      html: `${ srvSurveyBlock.section_index }.${ srvSurveyBlock.order } ${ srvSurveyBlock[title] } *`,
    }
  }

  /**
   * Generate a SrvSurvey subtitle properties.
   * @param   {object}  srvSurvey - the survey model.
   * @returns {object}              the subtitle input properties.
   */
  static genSurveySubtitle(srvSurvey) {
    let fechaLimite= ''

    if(srvSurvey.limit_date_prevent !== 'Sin fecha límite.'){

      let limit = srvSurvey.limit_date_prevent

      fechaLimite = `${ limit.substr(8,2) }-${ limit.substr(5,2)}-${ limit.substr(0,4) } ${ limit.substr(11,5)}:00`

    }else{

      fechaLimite = srvSurvey.limit_date_prevent

    }
    return {
      name: `block_response_${ srvSurvey.id }`,
      type: 'title-field',
      flex: {
        cols: 12,
      },
      props: {
        subtitleProps: {
          class: 'grey--text text--darken-2 subtitle-2',
        },
        subtitleText: `Cantidad de preguntas: ${ srvSurvey.blocks.length }`,
        titleText: `Fecha límite: ${ fechaLimite }`,
        titleProps: {
          class: 'grey--text text--darken-2 subtitle-2',          
        },

      },
    }
  }

  /**
   * Generate a SrvSurvey title properties.
   * @param   {object}  srvSurvey - the survey model.
   * @returns {object}              the title input properties.
   */
  static genSurveyTitle(srvSurvey) {
    return {
      name: `block_response_${srvSurvey.id}`,
      type: 'title-field',
      flex: {
        cols: 12,
      },
      props: {
        subtitleText: `${ srvSurvey.description_prevent }`,
        subtitleProps: {
          class: ' py-4 grey--text text--darken-4',
        },
        titleText: `${ srvSurvey.title }`,
        titleProps: {
          class: 'primary--text headline',          
        },
      },
    }
  }

  /**
   * Generate a table checkbox properties.
   * @param   {object}  srvSurveyBlock - the survey block model.
   * @returns {object}  the table checkbox input properties.
   */
  static genTableCheckbox(srvSurveyBlock) {
    return {
      name: `block_response_${srvSurveyBlock.id}`,
      type: 'checkbox-grid-field',
      flex: {
        cols: 12,
      },
      props: {
        checkboxProps: {
          class: 'pa-0 ma-0',
          dense: true,
          hideDetails: true,
        },
        headers: srvSurveyBlock._options,
        headerText: 'description',
        headerValue: 'id',
        items: srvSurveyBlock._fields,
        itemText: 'description',
        itemValue: 'id',
        rowProps: {
          justify: 'space-around',
        },
        tableProps: {
          class:'grey lighten-4'
        },
      },
    }
  }

  /**
   * Generate a table option properties.
   * @param   {object}  srvSurveyBlock - the survey block model.
   * @returns {object}  the table option input properties.
   */
  static genTableOption(srvSurveyBlock) {
    return {
      name: `block_response_${srvSurveyBlock.id}`,
      type: 'radio-grid-field',
      flex: {
        cols: 12,
      },
      props: {
        headers: srvSurveyBlock._options,
        headerText: 'description',
        headerValue: 'id',
        items: srvSurveyBlock._fields,
        itemText: 'description',
        itemValue: 'id',
        radioGroupProps: {
          class: 'pa-0 ma-0',
          dense: true,
          hideDetails: true,
        },
        rowProps: {
          justify: 'space-around',
        },
        tableProps: {
          class:'grey lighten-4'
        },
      },
    }
  }

  /**
   * Generate a text input properties.
   * @param   {object}  srvSurveyBlock - the survey block model.
   * @returns {object}  the text input properties.
   */
  static genTextInput(srvSurveyBlock) {
    return {
      name: `block_response_${ srvSurveyBlock.id }`,
      type: 'text-field',
      flex: {
        cols: 12,
      },
      props: {
        filled: true,
        label: srvSurveyBlock.title,
        placeholder: srvSurveyBlock.placeholder,
      },
    }
  }

  /**
   * Generate a textarea input properties.
   * @param   {object}  srvSurveyBlock - the survey block model.
   * @returns {object}  the textarea input properties.
   */
  static genTextareaInput(srvSurveyBlock) {
    return {
      name: `block_response_${ srvSurveyBlock.id }`,
      type: 'textarea',
      flex: {
        cols: 12,
      },
      props: {
        filled: true,
        label: srvSurveyBlock.title,
        placeholder: srvSurveyBlock.placeholder,
      },
    }
  }

  /**
   * Generate a time properties
   * @param   {object}  srvSurveyBlock -  the survey block model.
   * @returns {object}                    the date time properties.
   */
  static genTime(srvSurveyBlock) {
    return {
      name: `block_response_${ srvSurveyBlock.id }`,
      type: 'time-field',
      flex: {
        cols: 12,
      },
      props: {
        class: 'px-0',
        fieldProps: {
          filled: true,
          label: srvSurveyBlock.title,
          placeholder: srvSurveyBlock.placeholder,
        },
      },
    }
  }

  /**
   * Generate a title properties.
   * @param   {object}  title     - the title string.
   * @param   {object}  subtitle  - the subtitle string.
   * @returns {object}              the title properties.
   */
  static genTitle(title = '', subtitle = '') {
    return {
      name: `block_title`,
      type: 'title-field',
      flex: {
        cols: 12,
      },
      props: {
        subtitleProps: {
          class: ' py-4 grey--text text--darken-4',         
        },
        subtitleText: `${ subtitle }`,
        titleProps: {
          class: 'primary--text title',          
        },
        titleText: `${ title }`,
      },
    }
  }

  /**
   * 
   */
  static mapListOption(item, index) {
    
    //
    item.index = index

    //
    return item
  }

  /**
   * Define the SrvSurveyBlock model.
   * @return {Function} SrvSurveyBlock resource.
   */
  static model() {
    return SrvSurveyBlockModel
  }

  /**
   * 
   */
  static toCreate() {
    return this.shape({
      title: '',
      description: '',
      survey_block_type_id: '',
      survey_block_identifier_id: '',
      placeholder: '',
      is_required: false,
      // min_range: '',
      // max_range: '',
      // min_range_label: '',
      // max_range_label: '',
      // options: [],
      // fields: [],
      rules: [],
      validators: [],
    })
  }
}