// layout
import RLayoutModule from '@/components/layouts/LayoutModule'

// views
import UsrUniversityPeriodCreateView from '@/views/User/UsrUniversityPeriod/create'
import UsrUniversityPeriodListView from '@/views/User/UsrUniversityPeriod/list'
import UsrUniversityPeriodUpdateView from '@/views/User/UsrUniversityPeriod/update'

// routes
export default {
  path: 'semestres',
  component: RLayoutModule,
  children: [
    {
      name: 'usr-university-period-list',
      path: '',
      component: UsrUniversityPeriodListView,
    },
    {
      name: 'usr-university-period-create',
      path: 'crear',
      component: UsrUniversityPeriodCreateView,
      meta: {
        title: 'Crear Semestre'
      },
    },
    {
      name: 'usr-university-period-update',
      path: 'editar',
      component: UsrUniversityPeriodUpdateView,
      meta: {
        title: 'Editar Semestre'
      },
    },
  ],
  meta: {
    title: 'Semestres'
  },
  props: {
    indexText: 'Inicio',
  },
}
