<template lang="pug">
  v-card.v-card-user
    v-list
      v-list-item
        v-list-item-avatar
          v-avatar( class="text-uppercase" color='success darken-1') {{ avatarChar }}
        v-list-item-content
          v-list-item-title {{ title }}
          v-list-item-subtitle( class="text-capitalize" ) {{ subtitle }}
        //v-list-item-action
          v-btn( color='primary' icon @click='$emit("click-item-action")' )
            v-icon {{ actionIcon }}
    //v-divider
    //v-list
      template( v-for='item in items' )
        v-list-item
          v-list-item-action
            v-switch( color='primary' @click.passive='submitSwitch(item)' )
          v-list-item-title {{ item.title }}
    v-divider
    v-card-actions
      v-spacer
      v-btn( color='error' text @click='$emit("click-card-action")' ) {{ actionText }}
</template>

<script>
  export default {
    computed: {      

      /**
       * 
       */
      avatarChar() {
        return this.title.slice(0, 1)
      }
    },
    methods: {

      /**
       * 
       */
      submitSwitch(item) {
        this.$emit('click-switch', { item })
      },
    },
    props: {

      /**
       * 
       */
      actionIcon: {
        type: String,
        default: 'mdi-pencil',
      },

      /**
       * 
       */
      actionText: {
        type: String,
        default: 'salir',
      },

      /**
       * 
       */
      items: {
        type: Array,
        default: () => [
          {
            name: 'notify-state',
            title: 'Habilitar notificaciones',
          },
          {
            name: 'messages-state',
            title: 'Recordar mensajes',
          },
        ],
      },

      /**
       * 
       */
      subtitle: {
        type: String,
        default: 'Rol',
      },

      /**
       * 
       */
      title: {
        type: String,
        default: 'Nombre Usuario',
      },
    }
  }
</script>

<style>
  .v-card-user {
    min-width: 300px;
  }
</style>