// required modules
import Model from '@/models/Model'

/**
 * This class describes a NtMailRrss model.
 * 
 * @class NtMailRrss (name)
 */
export default class NtMailRrssModel extends Model {

  /**
   * Define the NtMailRrss resource.
   * 
   * @return {String} NtMailRrss endpoint name.
   */
  resource() {
    return 'mail_rrss'
  }

  /**
   * 
   */
  toCreate() {
    return this.reduce([
      'order',
      'mail_rrss_logo_id',
      'link',
    ])
  }

  /**
   * 
   */
  toUpdate() {
    return this.reduce([
      'id',
      'order',
      'mail_rrss_logo_id',
      'link',
    ])
  }
}