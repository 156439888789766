<template lang='pug'>
  v-menu( bottom :close-on-content-click="false" content-class='elevation-2 mr-4' v-model='menu' )
    template( slot='activator' slot-scope='props' )
      v-btn( icon large v-on='props.on' )
        v-badge( color='secondary' :content='badgeContent' style='z-index:1;' )
        v-icon( size="24" ) mdi-bell
    v-card-notification(
      v-bind='cardProps'
      @click-item='submit($event)'
      @refresh='onClickRefresh'
      )
</template>

<script>
  //
  import { VCardNotification } from '../cards'
  //
  export default {
    components: {
      VCardNotification,
    },
    data() {
      return {
        badge: false,
        menu: false,
      }
    },
    methods: {

      /**
       * This method is called when the refresh button on the notification 
       * card is clicked.
       * 
       * @fires event:refresh
       * @listens event:onclick
       */
      onClickRefresh() {
        this.$emit('refresh')
      },

      /**
       * 
       */
      submit(event) {
        this.$emit('click-item', event)
      },
    },
    props: {

      /**
       * 
       */
      badgeContent: Number,

      /**
       * 
       */
      cardProps: {
        type: Object,
        default: () => ({})
      },
    },
  }
</script>