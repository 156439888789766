// required components
import SrvSurveyBlockFields from '@/views/Survey/SrvSurveyBlock/fields'
import SrvSurveyBlockService from '@/services/api/Survey/SrvSurveyBlock'
import SrvSurveyFields from '@/views/Survey/SrvSurvey/fields'
import SrvSurveySectionFields from '@/views/Survey/SrvSurveySection/fields'
import SrvSurveySectionService from '@/services/api/Survey/SrvSurveySection'
import SrvSurveyService from '@/services/api/Survey/SrvSurvey'

//
import SrvSurveyTemplateFilters from '@/views/Survey/SrvSurveyTemplate/filters'
import SrvSurveyTemplateHeaders from '@/views/Survey/SrvSurveyTemplate/headers'
import SrvSurveyTemplateService from '@/services/api/Survey/SrvSurveyTemplate'

// component
export default {
  name: 'srv-survey-create',
  computed: {

    /**
     * Gets the SrvSurveyBlock create form props.
     * @return {Object} The create form props.
     */
    createBlockFormProps() {
      return {
        items: SrvSurveyBlockFields.toCreate(this.srvSurveyBlock),
      }
    },

    /**
     * Gets the SrvSurveyBlock create form props.
     * @return {Object} The create form props.
     */
    updateBlockFormProps() {
      return {
        items: SrvSurveyBlockFields.toUpdate(this.srvSurveyBlockSelected),
      }
    },

    /**
     * Gets the SrvSurveySection create form props.
     * @return {Object} The create form props.
     */
    sectionFormProps() {
      return {
        items: SrvSurveySectionFields.toCreate(),
      }
    },

    /**
     * Gets the SrvSurvey create form props.
     * @return {Object} The create form props.
     */
    formProps() {
      return {
        items: SrvSurveyFields.toCreate(),
      }
    },

    /**
     * 
     */
    templatesProps() {
      return {
        filters: SrvSurveyTemplateFilters.toList(),
        headers: SrvSurveyTemplateHeaders.toList(),
        service: SrvSurveyTemplateService.toDetailList(),
      }
    }
  },
  data() {
    return {
      dialogs: {
        createBlock: false,
        createSection: false,
        createSelectedBlock: false,
        import: false,
        updateSection: false,
        updateBlock: false,
      },
      srvSurvey: SrvSurveyService.toCreate(),
      srvSurveyBlock: SrvSurveyBlockService.toCreate(),
      srvSurveyBlockSelected: {},
      srvSurveySection: SrvSurveySectionService.toCreate(),
      srvSurveySectionSelected: {},
    }
  },
  methods: {

    /**
     * Returns a submit SrvSurveyBlock create form.
     * @return {Promise} A form submit process.
     */
    onCreateBlock() {
      return this.toWaitCreateBlock(true).then(this.toCreateBlock).then(this.toWaitCreateBlock)
    },

    /**
     * Returns a submit SrvSurveySection create form.
     * @return {Promise} A form submit process.
     */
    onCreateSection() {
      return this.toWaitCreateSection(true).then(this.toCreateSection).then(this.toWaitCreateSection)
    },

    /**
     * 
     */
    onCreateSelectedBlock() {
      return this.toWaitCreateSelectedBlock(true).then(this.toCreateSelectedBlock).then(this.toWaitCreateSelectedBlock)
    },

    /**
     * 
     */
    onDeleteBlock(event) {
      this.srvSurveySection.blocks.splice(this.srvSurveySection.blocks.findIndex(item => item.id === event.item.id), 1)
    },

    /**
     * 
     */
    onDeleteSection(event) {
      this.srvSurvey.sections.splice(this.srvSurvey.sections.findIndex(item => item.id === event.item.id), 1)
    },

    /**
     * 
     */
    onDeleteSelectedBlock(event) {
      this.srvSurveySectionSelected.blocks.splice(this.srvSurveySectionSelected.blocks.findIndex(item => item.id === event.item.id), 1)
    },

    /**
     * Reset the SrvSurvey data.
     */
    onReset() {
      this.srvSurvey = SrvSurveyService.toCreate()
    },

    /**
     * Reset the SrvSurvey data.
     */
    onResetBlock() {
      this.srvSurveyBlock = SrvSurveyBlockService.toCreate()
    },

    /**
     * Reset the SrvSurvey data.
     */
    onResetSection() {
      this.srvSurveySection = SrvSurveySectionService.toCreate()
    },

    /**
     * Returns a submit SrvSurvey create form.
     * @return {Promise} A form submit process.
     */
    onSubmit() {
      return this.toWait(true).then(this.toCreate).then(this.toWait)
    },

    /**
     * 
     */
    onSubmitTemplate() {

      // get selected
      const srvSurveyTemplateSelected = this.getRef('table').getSelected()

      // check selected template
      if (srvSurveyTemplateSelected) {

        // prevent previous data
        var title = this.srvSurvey.title
        var init_datetime = this.srvSurvey.init_datetime
        var limit_datetime = this.srvSurvey.limit_datetime

        // set template
        this.srvSurvey = srvSurveyTemplateSelected.slice().shift().toSurvey()
        this.srvSurvey.title = title
        this.srvSurvey.init_datetime = init_datetime
        this.srvSurvey.limit_datetime = limit_datetime
        this.srvSurvey.is_required = false
      }

      // this.srvSurvey = this.getRef()
      this.dialogs.import = false
    },

    /**
     * Returns an create SrvSurvey success process.
     * @param  {Object}  event - the event success payload.
     */
    onSuccessCreate(event) {
      this.onSuccess('Encuesta creada exitosamente.')
      this.toRedirect()
    },

    /**
     * 
     */
    onUpdateSection(event) {
      this.srvSurveySectionSelected = event.item
      this.dialogs.updateSection = true
    },

    /**
     * 
     */
    onUpdateSelectedBlock(event) {
      this.srvSurveyBlockSelected = event.item
      this.dialogs.updateBlock = true
    },

    /**
     * Returns an create SrvSurvey process.
     * @return {Promise} A create process.
     */
    toCreate() {
      return SrvSurveyService.create(this.srvSurvey).then(this.onSuccessCreate).catch(this.onError)
    },

    /**
     * Returns an create SrvSurvey process.
     * @return {Promise} A create process.
     */
    toCreateBlock() {
      console.log("this.srvSurveyBlock.survey_block_type_id",this.srvSurveyBlock.survey_block_type_id)
      if (this.srvSurveyBlock.survey_block_type_id == 1) {
        this.srvSurveyBlock.survey_block_identifier_id = 1
      }
      this.srvSurveySection.blocks.push(this.srvSurveyBlock.toTimeStampID())
      this.dialogs.createBlock = false
      this.onResetBlock()
    },

    /**
     * Returns an create SrvSurvey process.
     * @return {Promise} A create process.
     */
    toCreateSection() {
      this.srvSurvey.sections.push(this.srvSurveySection.toTimeStampID())
      this.dialogs.createSection = false
      this.onResetSection()
    },

    /**
     * 
     */
    toCreateSelectedBlock() {
      this.srvSurveySectionSelected.blocks.push(this.srvSurveyBlock.toTimeStampID())
      this.dialogs.createSelectedBlock = false
      this.onResetBlock()
    },

    /**
     * Redirect to SrvSurvey list
     */
    toRedirect() {
      this.goRoute('srv-survey-list')
    },

    /**
     * 
     */
    toWaitCreateBlock(val = false) {
      return this.getRef('createBlock').setWait(val)
    },

    /**
     * 
     */
    toWaitCreateSection(val = false) {
      return this.getRef('createSection').setWait(val)
    },

    /**
     * 
     */
    toWaitCreateSelectedBlock(val = false) {
      return this.getRef('createSelectedBlock').setWait(val)
    },
  },
}