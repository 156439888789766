// model
import Model from '@/models/Model'

/**
 * This class describes a UsrAttentionType model.
 * 
 * @class UsrAttentionType (name)
 */
export default class UsrAttentionTypeModel extends Model {

  /**
   * Define the UsrAttentionType resource.
   * 
   * @return {String} UsrAttentionType endpoint name.
   */
  resource() {
    return 'attention_type'
  }
}