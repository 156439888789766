// model
import Model from '@/models/Model'

/**
 * This class describes a AMark model.
 * @class AMark (name)
 */
export default class AMarkModel extends Model {

  /**
   * Define the AMark resource.
   * @return {String} AMark endpoint name.
   */
  resource() {
    return 'mark'
  }
}