// layout
import RLayoutModule from '@/components/layouts/LayoutModule'

// views
import TtsTutorshipScheduleCreateView from '@/views/Tutorship/TtsTutorshipSchedule/create'
import TtsTutorshipScheduleListView from '@/views/Tutorship/TtsTutorshipSchedule/list'
import TtsTutorshipScheduleScheduleView from '@/views/Tutorship/TtsTutorshipSchedule/schedule'
import TtsTutorshipScheduleUpdateView from '@/views/Tutorship/TtsTutorshipSchedule/update'

// routes
export default {
  path: 'tutorias/horarios',
  component: RLayoutModule,
  children: [
    {
      name: 'tts-tutorship-schedule-list',
      path: '',
      component: TtsTutorshipScheduleListView,
    },
    {
      name: 'tts-tutorship-schedule-create',
      path: 'crear',
      component: TtsTutorshipScheduleCreateView,
      meta: {
        title: 'Crear Horario'
      },
    },
    {
      name: 'tts-tutorship-schedule-schedule',
      path: 'schedule',
      component: TtsTutorshipScheduleScheduleView,
      meta: {
        title: 'Calendarios de horarios de tutorias'
      },
    },
    {
      name: 'tts-tutorship-schedule-update',
      path: ':id',
      component: TtsTutorshipScheduleUpdateView,
      meta: {
        title: 'Editar Horario'
      },
    },
  ],
  meta: {
    title: 'Horarios'
  },
  props: {
    indexText: 'Inicio',
  },
}
