// required components
import TtsTutorshipFields from '@/views/Tutorship/TtsTutorship/fields'
import TtsTutorshipService from '@/services/api/Tutorship/TtsTutorship'

// component
export default {
  name: 'tts-tutorship-create',
  computed: {

    /**
     * Gets the TtsTutorship create form props.
     *
     * @return {Object} The create form props.
     */
    formProps() {
      return {
        items: TtsTutorshipFields.toCreate(),
      }
    }
  },
  data() {
    return {
      ttsTutorship: TtsTutorshipService.shape({
        description: '',
        long_description: '',
      }),
    }
  },
  methods: {

    /**
     * Reset the TtsTutorship data.
     */
    onReset() {
      this.ttsTutorship.value = ''
    },

    /**
     * Returns a submit TtsTutorship create form.
     *
     * @return {Promise} A form submit process.
     */
    onSubmit() {
      return this.toWait(true).then(this.toCreate).then(this.toWait)
    },

    /**
     * Returns an create TtsTutorship success process.
     *
     * @param  {Object}  event - the event success payload.
     */
    onSuccessCreate(event) {
      this.onSuccess('Tutoría creada exitosamente.')
    },

    /**
     * Returns an create TtsTutorship process.
     *
     * @return {Promise} A create process.
     */
    toCreate() {
      return TtsTutorshipService.create(this.ttsTutorship).then(this.onSuccessCreate).then(this.toRedirect).catch(this.onError)
    },

    /**
     * Redirect to TtsTutorship list
     */
    toRedirect() {
      this.goRoute('tts-tutorship-list')
    },
  },
}