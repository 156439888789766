// required modules
import Service from '@/services/api/Service'
import RptReportAttentionSessionQuantityModel from '@/models/Report/RptReportAttentionSessionQuantity'

/**
 * This class describes a RptReportAttentionSessionQuantity service.
 * @class
 */
export default class RptReportAttentionSessionQuantityService extends Service {

  /**
   * Define the RptReportAttentionSessionQuantity model.
   * @return {Function} RptReportAttentionSessionQuantity resource.
   */
  static model() {
    return RptReportAttentionSessionQuantityModel
  }
}