// components
import ActActivityFilters from '@/views/Activity/ActActivity/filters'
import ActActivityHeaders from '@/views/Activity/ActActivity/headers'
import ActActivityService from '@/services/api/Activity/ActActivity'
// component
export default {
  name: 'act-activity-surveys',
  computed: {
    tableProps() {
      return {
        filters: ActActivityFilters.collection('surveys'),
        // headers: ActActivityHeaders.collection('surveys'),
        service: ActActivityService//.toList(),
      }
    }
  },
  methods: {

    /**
     * Refresh the ActActivity list
     */
    refresh() {
      this.getRef('table').refresh()
    },

    /**
     * Show a delete ActActivity confirm toast
     */
    submitDelete() {
      this.$toaster('¿Confirma la eliminación del ActActivity seleccionado?', 'confirmar', this.toDelete)
    },

    /**
     * Set the selected ActActivity table
     * @param  {Object}  event - the click item payload
     */
    submitItem(event) {
      this.selected = event.item
    },

    /**
     * Return a delete ActActivity promise.
     * @param  {ActActivityModel}  model - the ActActivity model
     * @return {Promise} A delete process.
     */
    toDelete() {
      return ActActivityService.delete(this.selected).then(this.toSuccess).catch(this.toError)
    },

    /**
     * The delete ActActivity error process.
     * @param  {Object}  event - the error delete payload
     */
    toError(event) {
      this.getErrorMessage(event).then(this.getErrorToast)      
    },

    /**
     * The delete ActActivity success process.
     * @param  {Object}  event - the success delete payload
     */
    toSuccess(event) {
      this.getSuccessMessage(event).then(this.getSuccessToast).then(this.refresh)    
    },
  },
}