// required modules
import { Headers } from 'collection-wrapper'

/**
 * This class describes a ActActivity headers collection.
 * @class ActActivityHeaders
 */
export default class ActActivityHeaders extends Headers {

  /**
   * Returns the ActActivity headers options.
   * @returns {Array} headers options.
   */
  headers() {
    return [
      {
        text: '#',
        value: 'id',
        sortable: true,
      },
      {
        text: 'Titulo',
        value: 'title',
      },
      {
        text: 'Tipo',
        value: 'type_description',
        align: 'center',
      },
      {
        text: 'Estado',
        value: 'status_description',
        align: 'center',
      },
      {
        text: 'Facilitador',
        value: 'facilitator_full_name',
      },
      {
        text: 'Creación',
        value: 'created_at_short',
        align: 'right',
      },
      {
        text: 'Fecha',
        value: 'event_datetime_short',
        align: 'right',
      },
      {
        text: 'Fecha',
        value: 'event_datetime',
        component: 'v-datetime-field',
        props: {
          fieldProps: {
            dense: true,
            flat: true,
            hideDetails: true,
            solo: true,
          },
          reverse: true,
        },
      }
    ]
  }

  /**
   * Returns the ActActivity list headers.
   * @returns {Collection} headers collection.
   */
  static toList() {
    return new this().collect().whereIn('value', [
      'id',
      'title',
      'type_description',
      'status_description',
      'facilitator_full_name',
      'event_datetime_short',
    ]).sortBy(item => ['id', 'title', 'facilitator_full_name', 'type_description', 'status_description', 'event_datetime_short'].findIndex(subitem => subitem === item.value)).all()
  }

  /**
   * Returns the ActActivity group headers.
   * @returns {Collection} headers collection.
   */
  static toGroup() {
    return this.reduce('value', [
      'title',
      'type_description',
      'status_description',
    ])
  }

  /**
   * Returns the ActActivity new group headers.
   * @returns {Collection} headers collection.
   */
  static toNewGroup() {
    return this.reduce('value', [
      'title',
      'type_description',
      'event_datetime',
    ])
  }
}
