// required modules
import VwBtn from 'vuetify2-wrappers/src/components/buttons/button'

// component
export default {
  extends: VwBtn,
  beforeCompile() {
    this.$attrs['bottom'] = true
    this.$attrs['icon'] = true
  }
}