// required components
import NtMailRrssFilters from '@/views/Notification/NtMailRrss/filters'
import NtMailRrssHeaders from '@/views/Notification/NtMailRrss/headers'
import NtMailRrssService from '@/services/api/Notification/NtMailRrss'

// component
export default {
  name: 'nt-mail-rrss-list',
  computed: {

    /**
     * The table props.
     *
     * @return  {Object}  The table props.
     */
    tableProps() {
      return {
        filters: NtMailRrssFilters.toList(),
        headers: NtMailRrssHeaders.toList(),
        service: NtMailRrssService.toList(),
      }
    }
  },
  methods: {

    /**
     * Set the selected NtMailRrss table.
     * 
     * @param  {Object}  event - the click item payload.
     */
    onClickCreate() {
      this.goRoute('nt-mail-rrss-create')
    },

    /**
     * Set the selected NtMailRrss table.
     * 
     * @param  {Object}  event - the click item payload.
     */
    onClickItem(event) {
      this.selected = event.item
    },

    /**
     * Show a delete NtMailRrss confirm toast
     */
    onClickItemDelete() {
      this.$toaster('¿Confirma la eliminación del NtMailRrss seleccionado?', 'confirmar', this.toDelete)
    },

    /**
     * Set the selected NtMailRrss table.
     * 
     * @param  {Object}  event - the click item payload.
     */
    onClickItemUpdate(event) {
      this.goRoute('nt-mail-rrss-update', { id: event.item.id })
    },

    /**
     * The delete NtMailRrss error process.
     * 
     * @param  {Object}  event - the error delete payload
     */
    onError(event) {
      this.getErrorMessage(event).then(this.getErrorToast)      
    },

    /**
     * The delete NtMailRrss success process.
     * 
     * @param  {Object}  event - the success delete payload
     */
    onSuccessDelete(event) {
      this.getSuccessMessage(event).then(this.getSuccessToast).then(this.toRefresh)    
    },

    /**
     * Return a delete NtMailRrss promise.
     * 
     * @param  {NtMailRrssModel}  model - the NtMailRrss model
     * @return {Promise} A delete process.
     */
    toDelete() {
      return NtMailRrssService.delete(this.selected).then(this.onSuccessDelete).catch(this.onError)
    },

    /**
     * Refresh the NtMailRrss list
     */
    toRefresh() {
      this.getRef('table').refresh()
    },
  },
}