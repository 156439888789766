// components
import TtsTutorshipParallelFields from '@/views/Tutorship/TtsTutorshipParallel/fields'
import TtsTutorshipParallelService from '@/services/api/Tutorship/TtsTutorshipParallel'
// component
export default {
  name: 'tts-tutorship-parallel-update',
  computed: {

    /**
     * Gets the TtsTutorshipParallel update form props.
     *
     * @return {Object} The update form props.
     */
    formProps() {
      return {
        items: TtsTutorshipParallelFields.toUpdate(),
      }
    }
  },
  data() {
    return {
      ttsTutorshipParallel: {
        value: 'test',
      },
    }
  },
  methods: {

    /**
     * Returns a reset TtsTutorshipParallel form.
     *
     * @return {Promise} A form reset promise.
     */
    onReset() {
      return this.toWait(true).then(this.toRefresh).then(this.toWait)
    },

    /**
     * Returns a submit TtsTutorshipParallel form.
     *
     * @return {Promise} A form submit promise.
     */
    onSubmit() {
      return this.toWait(true).then(this.toUpdate).then(this.toWait)
    },

    /**
     * Returns a refresh TtsTutorshipParallel form.
     *
     * @return {Promise} A reset promise.
     */
    toRefresh() {
      return TtsTutorshipParallelService.model().find(this.getParam('id')).then(ttsTutorshipParallel => {
        this.ttsTutorshipParallel = ttsTutorshipParallel//.reset()
      })
    },

    /**
     * Returns an update TtsTutorshipParallel promise.
     *
     * @param  {Object}  model - the tts-tutorship-parallel model
     * @return {Promise} A update promise.
     */
    toUpdate() {
      return TtsTutorshipParallelService.update(this.ttsTutorshipParallel).then(this.onSuccess).catch(this.toError)
    },
  },
  mounted() {
    this.onReset()
  },
}