// required modules
import Model from '@/models/Model'
import SrvSurveyBlockModel from '@/models/Survey/SrvSurveyBlock'

/**
 * This class describes a SrvSurveySection model.
 *
 * @class SrvSurveySection (name)
 */
export default class SrvSurveySectionModel extends Model {

  /**
   * 
   */
  get blocks_length() {
    return this.getProperty('blocks', []).length
  }

  /**
   * 
   */
  get description_prevent() {
    return this.prop('description', '').trim().length ? this.description : ''
  }

  /**
   * 
   */
  mapBlock = srvSurveyBlock => {
    return SrvSurveyBlockModel.from(srvSurveyBlock).reset()
  }

  /**
   * 
   */
  reset() {
    //
    this.blocks = this.blocks.map(this.mapBlock)
    //
    return this
  }

  /**
   * Define the SrvSurveySection resource.
   *
   * @return {String} SrvSurveySection endpoint name.
   */
  resource() {
    return 'survey_section'
  }

  /**
   * 
   */
  toTimeStampID() {
    this.id = Date.now(); return this
  }
}