// required modules
import { Fields } from 'collection-wrapper'
import SrvSurveyTargetHeaders from '@/views/Survey/SrvSurveyTarget/headers'
import { ruleRequired } from 'vuetify2-wrappers/src/rules'

/**
 * This class describes a SrvSurveyTarget fields collection.
 * @class SrvSurveyTargetFields (name)
 */
export default class SrvSurveyTargetFields extends Fields {

  /**
   * Returns the SrvSurveyTarget fields options.
   * @returns {Array} fields options.
   */
  fields() {
    return [
      {
        name: 'name',
        type: 'select',
        info: 'Nombre del modelo o entidad asociada al objetivo de envío encuesta.',
        props: {
          items: [
            'Actividad',
            'Atención',
            'Carrera',
            'Programa',
            'Usuario',
          ],
          label: 'Nombre',
          placeholder: 'Nombre',
          rules: [ruleRequired],
        },
      },
      {
        name: 'target_type',
        type: 'select',
        info: 'Tipo de objetivo. (Por ej en actividad puede ser Inscritos o Con asistencia).',
        props: {
          items: [],
          label: 'Tipo de objetivo',
          placeholder: 'Tipo de objetivo',
          rules: [ruleRequired],
        },
      },
      {
        name: 'career_year',
        type: 'float-field',
        info: 'Año de carrera mínimo que debe tener el estudiante. (Solo si es Carrera).',
        props: {
          label: 'Año de carrera mínimo',
          placeholder: 'Año de carrera mínimo',
          rules: [ruleRequired],
        },
      },
      {
        name: 'identifiers',
        type: 'table-field',
        info: 'Establece si es requerida la encuesta para poder seguir usando la aplicación.',
        flex: {
          cols: 12,
        },
        prop: {
          label: 'Lista de público objetivo',
          tableProps: {
            dense: true,
            headers: SrvSurveyTargetHeaders.toIdentifiers(),
            style: 'border: 1px solid #ccc; overflow: hidden;',
          },
          titleProps: {
            class: 'subtitle-2 mr-2 text-uppercase',
          },
          toolbarProps: {
            color: 'primary',
            dark: true,
            dense: true,
            flat: true,
            small: true,
          },
          //headers: SrvSurveyHeaders.toSections(),
        },
        slots: {
          actions: 'identifiers.actions',
          items: 'identifiers.items',
        },
      },
    ]
  }

  /**
   * 
   * @param {*} props 
   */
  static mapByName = (name = '') => (item) => {
    //
    if(item.name === 'target_type') {
      switch (name){
        case 'Actividad':
          item.props.items = [
            'Inscritos',
            'Con asistencia',
          ]
          break;
        case 'Atención':
          item.props.items = [
            'Inscritos',
            'Atendidos',
          ]
          break;
        case 'Carrera':
          item.props.items = [
            'Todos',            
          ]
          break;
        case 'Programa':
          item.props.items = [
            'Inscritos',            
          ]
          break;
        case 'Usuario':
          item.props.items = [
            'Personalizado',            
          ]
          break;
      }
    }
    return item
  }

  /**
   * 
   * @param {*} props 
   * @returns 
   */
  static mapDisabled(item) {
    if (item.props) {
      item.props['readonly'] = true      
    }
    if (item.prop) {
      item.prop['readonly'] = true      
    }
    return item
  }

  /**
   * Returns the SrvSurveyTarget create fields.
   * @returns {Collection} fields collection.
   */
  static toCreate(props = {}) {

    //
    if (props.name === 'Carrera') {
      return this.reduce('name', [
        'name',
        'target_type',
        'career_year',
        'identifiers',
      ]).map(this.mapByName(props.name))
    }

    //
    // if (props.name === 'Usuario' && props.target_type == 'Personalizado') {
    //   return this.reduce('name', [
    //     'name',
    //     'target_type',
    //   ]).map(this.mapByName(props.name))
    // }

    //
    return this.reduce('name', [
      'name',
      'target_type',
      'identifiers',
    ]).map(this.mapByName(props.name))
  }

  /**
   * Returns the SrvSurveyTarget update fields.
   * @returns {Collection} fields collection.
   */
  static toUpdate(props) {

    //
    if (props.name === 'Carrera') {
      return this.reduce('name', [
        'name',
        'target_type',
        'career_year',
        'identifiers',
      ])
      .map(this.mapByName(props.name))
      .map(this.mapDisabled)
    }

    //
    // if (props.name === 'Usuario' && props.target_type !== 'Personalizado') {
    //   return this.reduce('name', [
    //     'name',
    //     'target_type',
    //   ])
    //   .map(this.mapByName(props.name))
    //   .map(this.mapDisabled)
    // }

    //
    return this.reduce('name', [
      'name',
      'target_type',
      'identifiers',
    ])
    .map(this.mapByName(props.name))
    .map(this.mapDisabled)
  }
}
