// required modules
import { Fields } from 'collection-wrapper'

/**
 * This class describes a BlgWebNewDashboard fields collection.
 * @class
 */
export default class BlgWebNewDashboardFields extends Fields {

  /**
   * Returns the BlgWebNewDashboard fields options.
   * @returns {Array} fields options.
   */
  fields() {
    return [
      {
        name: 'title',
        type: 'text-field',
        flex: {
          cols: 12,
        },
        props: {
          label: '',
          readonly: true,
        },
      },
      {
        name: 'images',
        type: 'carousel-field',
        flex: {
          cols: 12,
          style: {
            margin: '-27px -24px 0 -24px',
            maxWidth: 'calc(100% + 48px)',
            width: 'calc(100% + 48px)',
            flex: '0 0 auto',
            padding: '0',
          },
        },
        props: {
          carouselProps: {
            height: 350,
          },
          imageProps: {
            aspectRatio: 1,
          },
          itemSrc: 'url_image_path',
        },
      },
      {
        name: 'body_body',
        type: 'native',
        flex: {
          cols: 12,
        },
        props: {
          class: 'body-1 pt-5',
        },
      },
      {
        name: 'publisher_label',
        type: 'native',
        flex: {
          cols: 12,
        },
        props: {
          class: 'body-1 text-right',
        },
      },
    ]
  }

  /**
   * Returns the BlgWebNewDashboard create fields.
   * @returns {Collection} fields collection.
   */
  static toDetail(props = {}) {

    //
    if (!props.images || !props.images.length) {
      return this.reduce('name', [
        'body_body',
        'publisher_label',
      ])
    }

    //
    return this.reduce('name', [
      'images',
      'body_body',
      'publisher_label',
    ])
  }

  /**
   * Returns the BlgWebNewDashboard preview fields.
   * @returns {Collection} fields collection.
   */
  static toPreview() {
    return this.reduce('name', [
      'images',
    ])
  }
}
