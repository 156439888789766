// model
import Model from '@/models/Model'

/**
 * This class describes a UsrFacilitator model.
 * @class UsrFacilitator (name)
 */
export default class UsrFacilitatorModel extends Model {

  /**
   * 
   */
  get created_at_short() {

    // required data
    const time = this.prop('created_at', '').split(' ').pop()//.split(':').slice(0, 2).join(':')
    const date = this.prop('created_at', '').split(' ').shift().split('-').reverse().join('-')

    // formatted data
    return `${ date } ${ time }`
  }

  /**
   * 
   */
  get updated_at_short() {

    // required data
    const time = this.prop('updated_at', '').split(' ').pop()//.split(':').slice(0, 2).join(':')
    const date = this.prop('updated_at', '').split(' ').shift().split('-').reverse().join('-')

    // formatted data
    return `${ date } ${ time }`
  }

  /**
   * 
   */
  get full_name() {
    return `${ this.getProperty('first_names', '') } ${ this.getProperty('last_name1', '') }`
  }

  /**
   * 
   */
  get name_case() {
    return `${ this.first_names } ${ this.last_name1 }`
  }

  /**
   * Define the UsrFacilitator resource.
   * @return {String} UsrFacilitator endpoint name.
   */
  resource() {
    return 'facilitator'
  }

  /**
   * Return a create representation.
   * @return {UsrStudentModel} usr-user create representation.
   */
  toCreate() {
    return this.reduce([
      'rut',
      'first_names',
      'last_name1',
      'last_name2',
      'email',
      'alternative_email',
      'phone_number',
      'gender',
      'university_campus_id',
      'specialities',
      'attention_types',
    ])
  }

  /**
   * 
   */
  toEdit() {
    //
    this.attention_types = this.attention_types.map(({ id }) => id)
    this.specialities = this.specialities.map(({ id }) => id)
    //
    return this
  }

  /**
   * Return a update representation.
   * @return {UsrStudentModel} usr-user update representation.
   */
  toUpdate() {
    return this.reduce([
      'id',
      'rut',
      'first_names',
      'last_name1',
      'last_name2',
      'email',
      'alternative_email',
      'phone_number',
      'gender',
      'university_campus_id',
      'specialities',
      'attention_types',
    ])
  }
}