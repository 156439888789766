// required components
import TtsTutoringNewFields from '@/views/Tutorship/TtsTutoringNew/fields'
import TtsTutoringNewService from '@/services/api/Tutorship/TtsTutoringNew'

// component
export default {
  name: 'tts-tutoring-new-create',
  computed: {

    /**
     * Gets the TtsTutoringNew create form props.
     *
     * @return {Object} The create form props.
     */
    formProps() {
      return {
        items: TtsTutoringNewFields.toCreate(),
      }
    }
  },
  data() {
    return {
      ttsTutoringNew: TtsTutoringNewService.shape({
        value: ''
      }),
    }
  },
  methods: {

    /**
     * Reset the TtsTutoringNew data.
     */
    onReset() {
      this.ttsTutoringNew.value = ''
    },

    /**
     * Returns a submit TtsTutoringNew create form.
     *
     * @return {Promise} A form submit process.
     */
    onSubmit() {
      return this.toWait(true).then(this.toCreate).then(this.toWait)
    },

    /**
     * Returns an create TtsTutoringNew success process.
     *
     * @param  {Object}  event - the event success payload.
     */
    onSuccessCreate(event) {
      this.onSuccess('Aviso de tutoría creado exitosamente.')
    },

    /**
     * Returns an create TtsTutoringNew process.
     *
     * @return {Promise} A create process.
     */
    toCreate() {
      return TtsTutoringNewService.create(this.ttsTutoringNew).then(this.onSuccessCreate).then(this.toRedirect).catch(this.onError)
    },

    /**
     * Redirect to TtsTutoringNew list
     */
    toRedirect() {
      this.goRoute('tts-tutoring-new-list')
    },
  },
}