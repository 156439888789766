// components
import TtsTutorFields from '@/views/Tutorship/TtsTutor/fields'
import TtsTutorService from '@/services/api/Tutorship/TtsTutor'
// component
export default {
  name: 'tts-tutor-update',
  computed: {

    /**
     * Gets the TtsTutor update form props.
     *
     * @return {Object} The update form props.
     */
    formProps() {
      return {
        items: TtsTutorFields.toUpdate(),
      }
    }
  },
  data() {
    return {
      ttsTutor: {
        value: 'test',
      },
    }
  },
  methods: {

    /**
     * Returns an update TtsTutor success process.
     *
     * @param  {Object}  event - the event success payload.
     */
    onSuccessUpdate(event) {
      this.onSuccess(event)
    },

    /**
     * Returns a reset TtsTutor form.
     *
     * @return {Promise} A form reset promise.
     */
    onReset() {
      return this.toWait(true).then(this.toRefresh).then(this.toWait)
    },

    /**
     * Returns a submit TtsTutor form.
     *
     * @return {Promise} A form submit promise.
     */
    onSubmit() {
      return this.toWait(true).then(this.toUpdate).then(this.toWait)
    },

    /**
     * Returns a refresh TtsTutor form.
     *
     * @return {Promise} A reset promise.
     */
    toRefresh() {
      return TtsTutorService.model().find(this.getParam('id')).then(ttsTutor => {
        this.ttsTutor = ttsTutor//.reset()
      })
    },

    /**
     * Returns an update TtsTutor promise.
     *
     * @param  {Object}  model - the tts-tutor model
     * @return {Promise} A update promise.
     */
    toUpdate() {
      return TtsTutorService.update(this.ttsTutor).then(this.onSuccessUpdate).catch(this.onError)
    },
  },
  mounted() {
    this.onReset()
  },
}