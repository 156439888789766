// required components
import SrvSurveyBlockFields from '@/views/Survey/SrvSurveyBlock/fields'
import SrvSurveyBlockService from '@/services/api/Survey/SrvSurveyBlock'
import SrvSurveySectionFields from '@/views/Survey/SrvSurveySection/fields'
import SrvSurveySectionService from '@/services/api/Survey/SrvSurveySection'
import SrvSurveyTemplateFields from '@/views/Survey/SrvSurveyTemplate/fields'
import SrvSurveyTemplateService from '@/services/api/Survey/SrvSurveyTemplate'

// component
export default {
  name: 'srv-survey-template-create',
  computed: {

    /**
     * Gets the SrvSurveyBlock create form props.
     * @return {Object} The create form props.
     */
    createBlockFormProps() {
      return {
        items: SrvSurveyBlockFields.toCreate(this.srvSurveyBlock),
      }
    },

    /**
     * Gets the SrvSurveyBlock create form props.
     * @return {Object} The create form props.
     */
    updateBlockFormProps() {
      return {
        items: SrvSurveyBlockFields.toUpdate(this.srvSurveyBlockSelected),
      }
    },

    /**
     * Gets the SrvSurveySection create form props.
     * @return {Object} The create form props.
     */
    sectionFormProps() {
      return {
        items: SrvSurveySectionFields.toCreate(),
      }
    },

    /**
     * Gets the SrvSurvey create form props.
     * @return {Object} The create form props.
     */
    formProps() {
      return {
        items: SrvSurveyTemplateFields.toCreate(),
      }
    },
  },
  data() {
    return {
      dialogs: {
        createBlock: false,
        createSection: false,
        createSelectedBlock: false,
        updateSection: false,
        updateBlock: false,
      },
      srvSurveyTemplate: SrvSurveyTemplateService.toCreate(),
      srvSurveyBlock: SrvSurveyBlockService.toCreate(),
      srvSurveyBlockSelected: {
        survey_block_identifier_id: null, 
      },
      srvSurveySection: SrvSurveySectionService.toCreate(),
      srvSurveySectionSelected: {},
    }
  },
  methods: {

    /**
     * Returns a submit SrvSurveyBlock create form.
     * @return {Promise} A form submit process.
     */
    onCreateBlock() {
      return this.toWaitCreateBlock(true).then(this.toCreateBlock).then(this.toWaitCreateBlock)
    },

    /**
     * Returns a submit SrvSurveySection create form.
     * @return {Promise} A form submit process.
     */
    onCreateSection() {
      return this.toWaitCreateSection(true).then(this.toCreateSection).then(this.toWaitCreateSection)
    },

    /**
     * 
     */
    onCreateSelectedBlock() {
      return this.toWaitCreateSelectedBlock(true).then(this.toCreateSelectedBlock).then(this.toWaitCreateSelectedBlock)
    },

    /**
     * 
     */
    onDeleteBlock(event) {
      this.srvSurveySection.blocks.splice(this.srvSurveySection.blocks.findIndex(item => item.id === event.item.id), 1)
    },

    /**
     * 
     */
    onDeleteSection(event) {
      this.srvSurveyTemplate.sections.splice(this.srvSurveyTemplate.sections.findIndex(item => item.id === event.item.id), 1)
    },

    /**
     * 
     */
    onDeleteSelectedBlock(event) {
      this.srvSurveySectionSelected.blocks.splice(this.srvSurveySectionSelected.blocks.findIndex(item => item.id === event.item.id), 1)
    },

    /**
     * Reset the SrvSurvey data.
     */
    onReset() {
      this.srvSurveyTemplate = SrvSurveyTemplateService.toCreate()
    },

    /**
     * Reset the SrvSurvey data.
     */
    onResetBlock() {
      this.srvSurveyBlock = SrvSurveyBlockService.toCreate()
    },

    /**
     * Reset the SrvSurvey data.
     */
    onResetSection() {
      this.srvSurveySection = SrvSurveySectionService.toCreate()
    },

    /**
     * Returns a submit SrvSurvey create form.
     * @return {Promise} A form submit process.
     */
    onSubmit() {
      return this.toWait(true).then(this.toCreate).then(this.toWait)
    },

    /**
     * Returns an create SrvSurvey success process.
     * @param  {Object}  event - the event success payload.
     */
    onSuccessCreate(event) {
      this.onSuccess('Encuesta predeterminada creada exitosamente.')
      this.toRedirect()
    },

    /**
     * 
     * @param {*} event 
     */
    onUpdateSection(event) {
      console.log("section",{ event })
      this.srvSurveySectionSelected = event.item
      this.dialogs.updateSection = true
    },

    /**
     * 
     * @param {*} event 
     */
    onUpdateSelectedBlock(event) {
      //this.onCreateSection()
      console.log("block", event )
      this.dialogs.updateBlock = false
      console.log("updateBlock",this.dialogs.updateBlock)
      console.log("dialogs.updateSection",this.dialogs.updateSection)
      this.srvSurveyBlockSelected = event.item
      this.dialogs.updateBlock = true
      console.log("updateBlock2",this.dialogs.updateBlock)
    },

    /**
     * Returns an create SrvSurvey process.
     * @return {Promise} A create process.
     */
    toCreate() {
      return SrvSurveyTemplateService.create(this.srvSurveyTemplate).then(this.onSuccessCreate).catch(this.onError)
    },

    /**
     * Returns an create SrvSurvey process.
     * @return {Promise} A create process.
     */
    toCreateBlock() {
      if (this.srvSurveyBlock.survey_block_type_id == 1) {
        this.srvSurveyBlock.survey_block_identifier_id = 1
      }
      this.srvSurveySection.blocks.push(this.srvSurveyBlock.toTimeStampID())
      this.dialogs.createBlock = false
      this.onResetBlock()
    },

    /**
     * Returns an create SrvSurvey process.
     * @return {Promise} A create process.
     */
    toCreateSection() {
      this.srvSurveyTemplate.sections.push(this.srvSurveySection.toTimeStampID())
      this.dialogs.createSection = false
      this.onResetSection()
    },

    /**
     * 
     */
    toCreateSelectedBlock() {
      this.srvSurveySectionSelected.blocks.push(this.srvSurveyBlock.toTimeStampID())
      this.dialogs.createSelectedBlock = false
      this.onResetBlock()
    },

    /**
     * Redirect to SrvSurvey list
     */
    toRedirect() {
      this.goRoute('srv-survey-template-list')
    },

    /**
     * 
     */
    toWaitCreateBlock(val = false) {
      return this.getRef('createBlock').setWait(val)
    },

    /**
     * 
     */
    toWaitCreateSection(val = false) {
      return this.getRef('createSection').setWait(val)
    },

    /**
     * 
     */
    toWaitCreateSelectedBlock(val = false) {
      return this.getRef('createSelectedBlock').setWait(val)
    },
  },
}