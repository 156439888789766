// layout
import RLayoutModule from '@/components/layouts/LayoutModule'

// views
import NtMailRrssCreateView from '@/views/Notification/NtMailRrss/create'
import NtMailRrssListView from '@/views/Notification/NtMailRrss/list'
import NtMailRrssUpdateView from '@/views/Notification/NtMailRrss/update'

// routes
export default {
  path: 'rrss',
  component: RLayoutModule,
  children: [
    {
      name: 'nt-mail-rrss-list',
      path: '',
      component: NtMailRrssListView,
    },
    {
      name: 'nt-mail-rrss-create',
      path: 'crear',
      component: NtMailRrssCreateView,
      meta: {
        title: 'Crear RRSS'
      },
    },
    {
      name: 'nt-mail-rrss-update',
      path: 'editar',
      component: NtMailRrssUpdateView,
      meta: {
        title: 'Editar RRSS'
      },
    },
  ],
  meta: {
    title: 'RRSS'
  },
  props: {
    indexText: 'Inicio',
  },
}
