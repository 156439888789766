// model
import Model from '@/models/Model'
import AUpdateEmailModel from '@/models/Actions/AUpdateEmail'
import AUploadModel from '@/models/Actions/AUpload'

/**
 * This class describes a UsrStudent model.
 * @class UsrStudent (name)
 */
export default class UsrStudentModel extends Model {

  /**
   * 
   */
  get activated_account_at_short() {

    // required data
    const time = this.prop('activated_account_at', '').split(' ').pop()//.split(':').slice(0, 2).join(':')
    const date = this.prop('activated_account_at', '').split(' ').shift().split('-').reverse().join('-')

    // formatted data
    return `${ date } ${ time }`
  }

  /**
   * 
   */
  get created_at_short() {

    // required data
    const time = this.prop('created_at', '').split(' ').pop()//.split(':').slice(0, 2).join(':')
    const date = this.prop('created_at', '').split(' ').shift().split('-').reverse().join('-')

    // formatted data
    return `${ date } ${ time }`
  }

  /**
   * 
   */
  get updated_at_short() {

    // required data
    const time = this.prop('updated_at', '').split(' ').pop()//.split(':').slice(0, 2).join(':')
    const date = this.prop('updated_at', '').split(' ').shift().split('-').reverse().join('-')

    // formatted data
    return `${ date } ${ time }`
  }

  /**
   * 
   */
  get alternative_email_readonly() {
    return this.getProperty('alternative_email', '')
  }

  /**
   * Get the UsrStudent case.
   * @return {UsrStudentCase} UsrStudent case.
   */
  get campus_name() {
    return this.getProperty('campus.description', 'Sin sede')
  }

  /**
   * Get the UsrStudent case.
   * @return {UsrStudentCase} UsrStudent case.
   */
  get careers_name() {
    return this.getProperty('careers', []).reduce((result, { description }) => description, 'Sin carrera')
  }

  /**
   * Get the UsrStudent email in readonly mode. 
   * @returns {String} usr student email
   */
  get email_readonly() {
    return this.getProperty('email')
  }

  /**
   * Get the UsrStudent case.
   * @return {UsrStudentCase} UsrStudent case.
   */
  get gender_full() {
    return this.getProperty('gender', '') === 'M' ? 'Masculino' : this.getProperty('gender', 'F') === 'F' ? 'Femenino' : ''
  }

  /**
   * 
   */
  get phone_number_readonly() {
    return this.getProperty('phone_number', '')
  }

  /**
   * Get the UsrStudent case.
   * @return {UsrStudentCase} UsrStudent case.
   */
  get programs_name() {
    return this.getProperty('programs', []).reduce((result, { description }) => description, 'Sin programas')
  }

  /**
   * Define the UsrStudent resource.
   * @return {String} UsrStudent endpoint name.
   */
  resource() {
    return 'student'
  }

  /**
   * Return a email representation.
   * @return {UsrStudentModel} usr-user email representation.
   */
  toEmail() {
    return this.reduce(['email', 'id'])
  }

  /**
   * Return a update representation.
   * @return {UsrStudentModel} usr-user update representation.
   */
  toUpdate() {
    return this.reduce(['alternative_email', 'id', 'phone_number'])
  }

  /**
   * Return a upload representation.
   * 
   * @return {FormData} usr-user upload representation.
   */
  toUpload() {
    return this.toFormData(['file'])
  }

  /**
   * 
   */
  updateEmail() {
    return this.hasMany(AUpdateEmailModel)
  }

  /**
   * 
   */
  upload() {
    return this.hasOne(AUploadModel)
  }
}