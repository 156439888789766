// model
import Model from '@/models/Model'
import AAddStudentModel from '@/models/Actions/AAddStudent'
import ARemoveStudentModel from '@/models/Actions/ARemoveStudent'

/**
 * This class describes a UsrProgram model.
 * @class UsrProgram (name)
 */
export default class UsrProgramModel extends Model {

  /**
   * 
   */
  get created_at_short() {

    // required data
    const time = this.prop('created_at', '').split(' ').pop()//.split(':').slice(0, 2).join(':')
    const date = this.prop('created_at', '').split(' ').shift().split('-').reverse().join('-')

    // formatted data
    return `${ date } ${ time }`
  }

  /**
   * 
   */
  get updated_at_short() {

    // required data
    const time = this.prop('updated_at', '').split(' ').pop()//.split(':').slice(0, 2).join(':')
    const date = this.prop('updated_at', '').split(' ').shift().split('-').reverse().join('-')

    // formatted data
    return `${ date } ${ time }`
  }

  /**
   * 
   */
  addStudent() {
    return this.hasMany(AAddStudentModel)
  }

  /**
   * 
   */
  removeStudent() {
    return this.hasMany(ARemoveStudentModel)
  }

  /**
   * 
   */
  reset() {
    this.users = this.users.map(user => {
      let userCareersNames = user.careers.map(career => {
        return career.description
      })
      user["careers_name"] = userCareersNames.join(', ')
      return user
    })
    return this
  }

  /**
   * Define the UsrProgram resource.
   * @return {String} UsrProgram endpoint name.
   */
  resource() {
    return 'program'
  }

  /**
   * 
   */
  toAddStudent() {
    return this.reduce('users')
  }

  /**
   * Return a create representation.
   * @return {UsrProgramModel} usr-program create representation.
   */
  toCreate() {
    return this.reduce([
      'university_campus_id',
      'description',
    ])
  }

  /**
   * 
   */
  toRemoveStudent() {
    return this.reduce('users')
  }

  /**
   * Return a create representation.
   * @return {UsrProgramModel} usr-program create representation.
   */
  toUpdate() {
    return this.reduce([
      'university_campus_id',
      'description',
      'id',
    ])
  }
}