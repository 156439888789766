// required modules
import { Model as BaseModel } from 'model-service-wrapper'
import store from '@/store'

/**
 * This class describes a model.
 * @class   Model (name)
 * @extends BaseModel
 */
export default class Model extends BaseModel {

  /**
   * Define a base url for a REST API
   * @return  {String}  The base url
   */
  baseURL() {
    return store.getters.apiUrl
  }

  /**
   * 
   */
  store() {
    return store
  }

  static from(data = {}) {
    return new this(data)
  }
}
