// required modules
import Model from '@/models/Model'

/**
 * This class describes a TtsTutorship model.
 *
 * @class TtsTutorship (name)
 */
export default class TtsTutorshipModel extends Model {

  /**
   * 
   */
  get created_at_short() {

    // required data
    const time = this.prop('created_at', '').split(' ').pop()//.split(':').slice(0, 2).join(':')
    const date = this.prop('created_at', '').split(' ').shift().split('-').reverse().join('-')

    // formatted data
    return `${ date } ${ time }`
  }

  /**
   * 
   */
  get parallels_length() {
    return this.prop('parallels.length', '0')
  }

  /**
   * 
   */
  get updated_at_short() {

    // required data
    const time = this.prop('updated_at', '').split(' ').pop()//.split(':').slice(0, 2).join(':')
    const date = this.prop('updated_at', '').split(' ').shift().split('-').reverse().join('-')

    // formatted data
    return `${ date } ${ time }`
  }

  /**
   * Define the TtsTutorship resource.
   *
   * @return {String} TtsTutorship endpoint name.
   */
  resource() {
    return 'tutorship'
  }

  /**
   * 
   */
  toCreate() {
    return this.reduce([
      'description',
      'long_description',
    ])
  }

  /**
   * 
   */
  toUpdate() {
    return this.reduce([
      'description',
      'id',
      'long_description',
    ])
  }

  /**
   * Return a upload representation.
   * 
   * @return {FormData} usr-user upload representation.
   */
  toUpload() {
    return this.toFormData(['file'])
  }
}