// model
import Model from '@/models/Model'

/**
 * This class describes a AConfirm model.
 * @class AConfirm (name)
 */
export default class AConfirmModel extends Model {

  /**
   * Define the AConfirm resource.
   * @return {String} AConfirm endpoint name.
   */
  resource() {
    return 'confirm'
  }
}