// required modules
import { Fields } from 'collection-wrapper'

/**
 * This class describes a RptReport filters collection.
 *
 * @class RptReportFilters (name)
 */
export default class RptReportFilters extends Fields {

  /**
   * Returns the RptReport filters options.
   *
   * @returns {Array} filters options.
   */
  fields() {
    return [
      {
        name: 'value',
        type: 'text-field',
        props: {
          label: 'test',
        },
      },
    ]
  }

  /**
   * Returns the RptReport list filters.
   *
   * @returns {Collection} filters collection.
   */
  static toList() {
    return this.reduce('name', [
      'value',
    ])
  }
}
