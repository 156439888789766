// required modules
import { Fields } from 'collection-wrapper'
import UsrCareerService from '@/services/api/User/UsrCareer'

/**
 * This class describes a TtsTutor fields collection.
 * @class
 */
export default class TtsTutorFields extends Fields {

  /**
   * Returns the TtsTutor fields options.
   * @returns {Array} fields options.
   */
  fields() {
    return [
      {
        enum: 'rut',
        name: 'rut',
        type: 'rut-field',
        props: {
          fieldProps: {
            label: 'Rut',
            placeholder: 'Rut',
          },
        },
      },
      {
        enum: 'first_names',
        name: 'first_names',
        type: 'text-field',
        props: {
          label: 'Nombre',
          placeholder: 'Nombre',
        },
      },
      {
        enum: 'first_names_readonly',
        name: 'first_names',
        type: 'text-field',
        flex: {
          cols: 12,
          sm: 6,
        },
        props: {
          label: 'Nombre',
          placeholder: 'Nombre',
          readonly: true,
        },
      },
      {
        enum: 'last_name1',
        name: 'last_name1',
        type: 'text-field',
        props: {
          label: 'Primer apellido',
          placeholder: 'Primer apellido',
        },
      },
      {
        enum: 'last_name1_readonly',
        name: 'last_name1',
        type: 'text-field',
        flex: {
          cols: 12,
          sm: 6,
        },
        props: {
          label: 'Primer apellido',
          placeholder: 'Primer apellido',
          readonly: true,
        },
      },
      {
        enum: 'last_name2',
        name: 'last_name2',
        type: 'text-field',
        props: {
          label: 'Segundo apellido',
          placeholder: 'Segundo apellido',
        },
      },
      {
        enum: 'email',
        name: 'email',
        type: 'text-field',
        props: {
          label: 'Correo electrónico',
          placeholder: 'Correo electrónico',
        },
      },
      {
        enum: 'email_readonly',
        name: 'email',
        type: 'text-field',
        flex: {
          cols: 12,
        },
        props: {
          label: 'Correo electrónico',
          placeholder: 'Correo electrónico',
        },
      },
      {
        enum: 'university_career_id',
        name: 'university_career_id',
        type: 'autocomplete-model',
        props: {
          fetch: UsrCareerService.toSelectList(),
          limit: 15,
          autocompleteProps: {
            itemText: 'description',
            itemValue: 'id',
            label: 'Carrera',
            placeholder: 'Carrera',
          },
        },
      },
      {
        enum: 'university_career_id_readonly',
        name: 'university_career_id',
        type: 'select-model',
        flex: {
          cols: 12,
        },
        props: {
          fetch: UsrCareerService.toList(),
          limit: 15,
          selectProps: {
            itemText: 'description',
            itemValue: 'id',
            label: 'Carrera',
            placeholder: 'Carrera',
            readonly: true,
          },
        },
      },

      /**
       * UPLOAD
       */
      {
        name: 'file',
        type: 'file-input',
        flex: {
          cols: 12
        },
        props: {
          accept: ".xlsx",
          label: 'Archivo en formato Excel',
          showSize: true,
        },
      },
    ]
  }

  /**
   * Returns the TtsTutor create fields.
   * @returns {Collection} fields collection.
   */
  static toCreate() {
    return this.reduce('enum', [
      'rut',
      'first_names',
      'last_name1',
      'last_name2',
      'email',
      'university_career_id',
    ])
  }

  /**
   * Returns the TtsTutor create fields.
   * @returns {Collection} fields collection.
   */
  static toDetail() {
    return this.reduce('enum', [
      'first_names_readonly',
      'last_name1_readonly',
      'email_readonly',
      'university_career_id_readonly',
    ])
  }

  /**
   * Returns the TtsTutor update fields.
   * @returns {Collection} fields collection.
   */
  static toUpdate() {
    return this.reduce('enum', [
      'rut',
      'first_names',
      'last_name1',
      'last_name2',
      'email',
      'university_career_id',
    ])
  }

  /**
   * Returns the TtsTutor upload fields.
   * @returns {Collection} fields collection.
   */
  static toUpload() {
    return this.reduce('name', [
      'file',
    ])
  }
}
