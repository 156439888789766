// model
import Model from '@/models/Model'

/**
 * This class describes a UsrUniversityCampus model.
 * 
 * @class UsrUniversityCampus (name)
 */
export default class UsrUniversityCampusModel extends Model {

  /**
   * Define the UsrUniversityCampus resource.
   * 
   * @return {String} UsrUniversityCampus endpoint name.
   */
  resource() {
    return 'campus'
  }
}