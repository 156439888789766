// required modules
import StaAttentionFields from '@/views/StudentAttention/StaAttention/fields'
import StaAttentionService from '@/services/api/StudentAttention/StaAttention'
import StaAttentionFacilitatorScheduleService from '@/services/api/StudentAttention/StaAttentionFacilitatorSchedule'
import UsrStudentHeaders from '@/views/User/UsrStudent/headers'
import UsrStudentFilters from '@/views/User/UsrStudent/filters'
import UsrStudentService from '@/services/api/User/UsrStudent'

// component
export default {
  name: 'sta-attention-create',
  computed: {

    /**
     * Gets the StaAttention create form props.
     * @return {Object} The create form props.
     */
    formProps() {
      return {
        items: StaAttentionFields.toCreate({
          allowedDates: this.handleAllowedDates,
          allowedHours: this.staAttentionAllowedHours,
          allowedMinutes: this.handleAllowedMinutes,
        }),
        noSubmit: !this.staAttention.students.length,
      }
    },

    /**
     * 
     */
    tableDialogProps() {
      return {
        filters: UsrStudentFilters.toList(),
        headers: UsrStudentHeaders.toPickerList(),
        service: UsrStudentService.toList(),
        // service: UsrStudentService.toListSortByFirstNames(),
      }
    },

    /**
     * 
     */
    tableFormDialogs() {
      return {
        headers: UsrStudentHeaders.toList(),
        service: UsrStudentService.from(this.staAttention.students)
      }
    },
  },
  data() {
    return {
      dialogs: {
        students: false,
      },
      staAttention: StaAttentionService.initial(),
      staAttentionAllowedHours: [],
      staAttentionFacilitatorScheduleList: [],
    }
  },
  methods: {

    /**
     * 
     */
    calcAllowedHours(datetime) {

      let formatted = datetime.split(' ').shift().split('-').reverse().join('/')
      let date      = new Date(formatted)
      let weekday   = date.getDay()
      let list      = this.staAttentionFacilitatorScheduleList

      // console.log( formatted )
      // console.log( date )

      let schedules = list.filter(schedule => {
        return schedule.weekday == weekday
      })

      let hours = schedules.reduce((result, schedule) => {
        let ini = schedule.attention_init_time
        let end = schedule.attention_end_time
        let min = parseInt(ini.split(':').shift())
        let max = parseInt(end.split(':').shift())
        result.min = result.min > min ? min : result.min
        result.max = result.max < max ? max : result.max
        return result
      }, {
        min: 23,
        max: 0,
      })
      
      let allowed = []

      for (let i = 0; i < 24; i++) {
        // console.log(i)
        if (i <= hours.max && i >= hours.min) {
          allowed.push(i)
        }
      }

      // console.log({ hours })
      // console.log({ allowed })

      this.staAttentionAllowedHours = allowed
    },

    async getAttentionDatetime() {

      let type = this.staAttention.attention_type_id
      let approach = await StaAttentionFacilitatorScheduleService.getNextByTypeId(type)

      if (approach) {
        this.staAttention.attention_datetime = approach.attention_init_datetime_temporally.split(':').slice(0, -1).join(':')
      } else {
        this.staAttention.attention_datetime = StaAttentionService.getInitial()
      }
    },

    /**
     * 
     */
    getAttentionScheduleByType() {
      StaAttentionFacilitatorScheduleService.getByTypeId(this.staAttention.attention_type_id).then(res => {
        this.staAttentionFacilitatorScheduleList = res.data
        // this.staAttention.attention_datetime = res.data.reduce(this.reduceAttentionDatetime, '')
      })
    },

    /**
     * 
     */
    handleAllowedDates(val) {
      
      //
      const nowDateTime           = new Date(Date.now())
      const staAttentionDateTime  = new Date(val)
      const staAttentionWeekday   = staAttentionDateTime.getDay()
      const staAttentionAvailable = nowDateTime <= staAttentionDateTime

      //
      return this.staAttentionFacilitatorScheduleList.some(item => {
        return (item.weekday - 1) === staAttentionWeekday && staAttentionAvailable
      })
    },

    /**
     * 
     */
    handleAllowedMinutes(val) {
      return this.staAttentionFacilitatorScheduleList.some(item => {

        //
        const staAttentionInitTime  = item.attention_init_time.split(':')[1]
        const staAttentionEndTime   = item.attention_end_time.split(':')[1]

        //
        return parseInt(staAttentionInitTime) <= val //&& val <= parseInt(staAttentionEndTime)
      })
    },

    /**
     * 
     */
    handleAllowedHours(val) {
      console.log('handleAllowedHours')
      return this.staAttentionFacilitatorScheduleList.some(item => {

        //
        const staAttentionCreatedWeekDay  = this.staAttention.weekday
        const staAttentionWeekDay         = item.weekday
        const staAttentionInitTime        = item.attention_init_time.split(':')[0]
        const staAttentionEndTime         = item.attention_end_time.split(':')[0]
        
        // equals
        const equalWeekday = staAttentionCreatedWeekDay == staAttentionWeekDay
        const moreInitTime = parseInt(staAttentionInitTime) <= val
        const lessEndsTime = val <= parseInt(staAttentionEndTime)

        //
        return equalWeekday && moreInitTime && lessEndsTime
      })
    },

    /**
     * 
     */
    onClickDialogAccept() {

      //
      const oldStudents = this.staAttention.students
      const newStudents = this.getRef('tableDialog').getSelected()
      const addStudents = newStudents.filter(item => oldStudents.every(student => student.id != item.id))

      //
      this.staAttention.students = [
        ...oldStudents,
        ...addStudents,
      ]

      //
      this.staAttention.sortStudents()

      //
      this.dialogs.students = false
    },

    /**
     * 
     */
    onClickTableDelete(event) {

      //
      const students  = this.staAttention.students
      const itemIndex = students.findIndex(item => item.id === event.item.id)

      //
      this.staAttention.students = [
        ...students.slice(0, itemIndex),
        ...students.slice(itemIndex + 1),
      ]             
    },

    /**
     * Called when click the reset of form component.
     */
    onReset() {
      this.staAttention = StaAttentionService.initial()
    },

    /**
     * 
     */
    onSuccessCreate() {
      this.onSuccess('Atención agendada exitosamente.')
    },

    reduceAttentionDatetime(datetime, item) {

      const nowDate = new Date(Date.now())
      const itmDate = new Date(`${ datetime }`)
      const nowWeekday = nowDate.getDay()
      const itmWeekday = itmDate.getDay()

      if (isNaN(itmDate)) {
        
        let tmwDate = nowDate
        tmwDate.setDate(nowDate.getDate() + 1)

        return tmwDate.toLocaleString('es-CL')
      }
      
      if (nowWeekday > itmWeekday) {
        
        return nowDate.toLocaleDateString()
      }
      
      if (itmWeekday < item.weekday) {
        
        return ``
      }
      
      return itmDate
    },

    /**
     * Reset the StaAttention data.
     */
    reset() {
      this.staAttention.value = ''
    },

    /**
     * Returns a submit StaAttention create form.
     * @return {Promise} A form submit process.
     */
    submit() {
      return this.toWait(true).then(this.toCreate).then(this.toWait)
    },

    /**
     * Returns an create StaAttention process.
     * @return {Promise} A create process.
     */
    toCreate() {
      var isCareerChange  = this.staAttention.attention_type_id == 2
      var isManyStudent   = this.staAttention.students.length > 1
      if (isCareerChange && isManyStudent) {
        this.onError('Por cambio de política en la universidad, por favor solo 1 estudiante para el horario de "Cambio de carrera".')
        return 
      }
      return StaAttentionService.schedule(this.staAttention).then(this.onSuccessCreate).then(this.toRedirect).catch(this.onError)
    },

    /**
     * Redirect to StaAttention list
     */
    toRedirect() {
      this.goRoute('sta-attention-list')
    },
  },
  mounted() {
    let calcAllowedHours = (datetime) => this.calcAllowedHours(datetime)
    let formComponent = this.getRef('form')
    let datetimeInput = this.getRef('form').getRef('fieldset').getRef('attention_datetime').slice().shift()

    // datetimeInput.$on('input')
    
    datetimeInput.$watch('datetime', function(newValue, oldValue) {
      calcAllowedHours(newValue)
    })

    this.onReset()
  },
  watch: {

    /**
     * 
     */
    'staAttention.attention_type_id'() {
      this.getAttentionScheduleByType()
      this.getAttentionDatetime()
    },

    // /**
    //  * 
    //  */
    // 'staAttention.attention_datetime'() {
    //   // console.log('change datetime')
    // }
  },
}