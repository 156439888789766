// layout
import RLayoutModule from '@/components/layouts/LayoutModule'

// views
import TtsTutoringNewCreateView from '@/views/Tutorship/TtsTutoringNew/create'
import TtsTutoringNewListView from '@/views/Tutorship/TtsTutoringNew/list'
import TtsTutoringNewUpdateView from '@/views/Tutorship/TtsTutoringNew/update'

// routes
export default {
  path: 'tutorias/avisos',
  component: RLayoutModule,
  children: [
    {
      name: 'tts-tutoring-new-list',
      path: '',
      component: TtsTutoringNewListView,
    },
    {
      name: 'tts-tutoring-new-create',
      path: 'crear',
      component: TtsTutoringNewCreateView,
      meta: {
        title: 'Crear Aviso'
      },
    },
    {
      name: 'tts-tutoring-new-update',
      path: 'editar',
      component: TtsTutoringNewUpdateView,
      meta: {
        title: 'Editar Aviso'
      },
    },
  ],
  meta: {
    title: 'Avisos de tutoría'
  },
  props: {
    indexText: 'Inicio',
  },
}
