// service
import Service from '@/services/api/Service'
import { ruleHtmlMax } from 'vuetify2-wrappers/src/rules'

// model
import ActActivityModel from '@/models/Activity/ActActivity'

/**
 * This class describes a ActActivity service.
 * @class ActActivity (name)
 */
export default class ActActivityService extends Service {

  /**
   * Assign Quota a ActActivity.
   * @param {ActActivityModel} actActivity - the actActivity/assignQuota.
   */
  static assignQuota(actActivity) {
    return actActivity.assignQuota().attach(actActivity.toAssignQuota())
  }

  static cloneData() {

  }

  /**
   * Clona la imagen de una actividad en otra, especificando la id de la 
   * actividad de origen y la id de la actividad de destino donde se copiara
   * la imagen.
   * 
   * @param {*} activityOriginId 
   * @param {*} activityDestinationId 
   * @returns resultado de la solicitud a la api
   */
  static cloneImage(activityOriginId, activityDestinationId) {
    return this.shape({ id: "clone_image" }).attach({
      original: activityOriginId,
      destination: activityDestinationId,
    })
  }

  /**
   * 
   * @param {*} actActivity 
   */
  static closeInscriptions(actActivity) {
    return actActivity.closeInscriptions().attach(actActivity.toCloseInscriptions())
  }

  /**
   * Complete a ActActivity.
   * @param {ActActivityModel} actActivity - the actActivity/Complete.
   */
  static complete(actActivity) {
    return actActivity.complete().attach(actActivity.toComplete())
  }

  /**
   * Confirm a ActActivity.
   * @param {ActActivityModel} actActivity - the actActivity/Confirm.
   */
  static confirm(actActivity) {

    // required data
    let absences = []
    let message = 'Falta completar información para confirmar la actividad:'

    // check quota
    if (!actActivity.quotas || !actActivity.quotas.length) {
      absences.push('Cuota(s)')
    }

    // check body
    if (actActivity.body.trim() === '') {
      absences.push('Descripción')
    }

    // return reject
    if (absences.length) {
      return Promise.reject(`${ message } ${ absences.join(' y ') }.`)
    }
    
    // return confirm promise
    return actActivity.confirm().attach(actActivity.toConfirm())
  }

  /**
   * 
   */
  static createGroup(actActivities) {

    // modify end time
    actActivities.forEach(activity => {
      let inputsTime = activity.event_datetime.split('-').join('/')
      let inputsDate = new Date(inputsTime)
      let finishTime = activity.event_finish_datetime.split('-').join('/')
      let finishDate = new Date(finishTime)
      let originTime = activity.event_datetime_origin.split('-').join('/')
      let originDate = new Date(originTime)

      let finishMs   = finishDate.getTime()
      let difference = inputsDate - originDate
      let endingTime = new Date(finishMs + difference)
      let endingStr  = endingTime.toLocaleString().split('/').map(c => c.length < 2 ? `0${c}` : c).join('-')
      let endingDate = endingStr.split(' ').shift().split('-').reverse().join('-')
      let endingHour = endingStr.split(' ').pop()
      
      // console.log(originTime, inputsTime)
      // console.log(originDate, inputsDate)
      // console.log(difference)
      // console.log(endingTime)
      activity.event_finish_datetime = `${ endingDate } ${ endingHour }`
    })

    const assignQuota = pyl => ActActivityService.assignQuota(pyl)

    return Promise.all(actActivities.map(this.create))
      .then((res) => {
        return Promise.all(res.map((response, index) => {

          let originId =  actActivities[index].id
          let destinationId = response.id
          ActActivityService.cloneImage(originId, destinationId)

          if (actActivities[index].quotas.length) {
            
            actActivities[index].id = response.id
            actActivities[index].quotas.forEach(quota => {
              quota.id = undefined
            })
            return assignQuota(actActivities[index])
          }
        }))
      })
      .then(() => {
        return 'Actividades creadas exitosamente.'
      })
  }

  /**
   * Enroll in ActActivity.
   * @param {ActActivityModel} actActivity - the actActivity/Enroll.
   */
  static enroll(actActivity) {
    return actActivity.enroll().attach(actActivity.toEnroll())
  }

  /**
   * Enroll Student a ActActivity.
   * @param {ActActivityModel} actActivity - the actActivity/Enroll Student.
   */
  static enrollStudent(actActivity) {
    return Promise.all(actActivity.toEnrollStudent().map(payload => actActivity.enrollStudent().attach(payload))).then(Promise.resolve('Estudiantes agregados exitosamente.'))
  }

  /**
   * Download a activity assistance excel (xlsx).
   * @param   {ActActivityModel} actActivity - a actActivity instance
   * @return  {Promise} 
   */
  static excelAssistance(actActivity) {
    return actActivity.excel('excel_assistance').then(actActivity.file('excel', 'xlsx'))
  }

  /**
   * Leave a ActActivity.
   * @param {ActActivityModel} actActivity - the actActivity/Leave.
   */
  static leave(actActivity) {
    return actActivity.leave().attach(actActivity.toLeave())
  }

  /**
   * Define the ActActivity model.
   * @return {Function} ActActivity resource.
   */
  static model() {
    return ActActivityModel
  }

  /**
   * 
   * @param {*} actActivity 
   */
  static openInscriptions(actActivity) {
    return actActivity.closeInscriptions().attach(actActivity.toOpenInscriptions())
  }
  
  /**
   * Send Notification a ActActivity.
   * @param {ActActivityModel} actActivity - the ActActivity/SendNotification.
   */
  static sendNotification(actActivity) {
    return actActivity.sendNotification().attach(actActivity.toSendNotification())
  }

  /**
   * 
   */
  static sendSatisfactionSurvey(actActivity) {
    return actActivity.sendSatisfactionSurvey().attach({})
  }
  
  /**
   * Start a ActActivity.
   * @param {ActActivityModel} actActivity - the actActivity/Start.
   */
  static start(actActivity) {
    return actActivity.start().attach(actActivity.toStart())
  }
  
  /**
   * Take Assistance an ActActivity.
   * @param {ActActivityModel} actActivity - the actActivity/TakeAssistance.
   */
  static takeAssistance(actActivity) {
    return actActivity.takeAssistance().attach(actActivity.toTakeAssistance())
  }

  static formatDateTime() {
    return new Intl.DateTimeFormat('es-CL', {
    // formatMatcher: 'best fit',
      year: 'numeric',
      day: '2-digit',
      month: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
      // timeStyle: 'short',
    })
  }

  /**
   * 
   * @param {*} opts 
   */
  static getStart() {
    
    //
    let newDate = this.getToday()

    //
    newDate.setTime(this.getToday().getTime())

    //
    return newDate
  }
  /**
   * 
   * @param {*} opts 
   */
   static getEnd() {
    
    //
    let newDate = this.getToday()

    //
    newDate.setTime(this.getToday().getTime() + 3600000)

    //
    return newDate
  }

  /**
   * 
   * @param {*} opts 
   */
  static getInitial() {

    //
    const initialDatetime = this.formatDateTime().format(this.getStart())
    const initialDate = initialDatetime.split(' ').shift().split('-').reverse().join('-')
    const initialTime = initialDatetime.split(' ').pop()

    //
    return `${ initialDate } ${ initialTime }`
  }

  /**
   * 
   * @param {*} opts 
   */
   static getFinal() {

    //
    const finalDatetime = this.formatDateTime().format(this.getEnd())
    const finalDate = finalDatetime.split(' ').shift().split('-').reverse().join('-')
    const finalTime = finalDatetime.split(' ').pop()

    //
    return `${ finalDate } ${ finalTime }`
  }

  /**
   * 
   * @param {*} opts 
   */
  static getToday() {
    return new Date()
  }

  /**
   * 
   */
  static toCreate() {
    //
    const todayDate = new Date(Date.now())
    let afterDate = new Date(Date.now())
    afterDate.setHours(todayDate.getHours()+1)
    const todayDateString = new Date().toLocaleString('es-CL').split(', ')[0].split('-').reverse().join('-')
    const todayDateStringReverse = todayDate.toLocaleDateString('es-CL')
    const todayHourString = todayDate.toLocaleTimeString('es-CL').split('T').pop().split('.').shift()
    const afterHourString = afterDate.toLocaleTimeString('es-CL').split('T').pop().split('.').shift()
    const eventDatetime = `${ todayDateString } ${ todayHourString }`
    const eventAfterDatetime = `${ todayDateString } ${ afterHourString }`

    //
    return this.shape({
      body: '',
      event_datetime: eventDatetime,
      event_finish_datetime: eventAfterDatetime,
      support_facilitators: [],
      closing_subscription_minutes: 60,
      closing_unsubscription_minutes: 60,
    })
  }

  /**
   * Return a list representation.
   * @return {UsrUserService} list representation.
   */
  static toGroup(actActivityGroupId) {
    return this.fetcher({
      includes: [
        'facilitator', 
        'status', 
        'type',
      ],
      filters: {
        activity_group_id: actActivityGroupId,
      }
    })
  }

  /**
   * Return a list representation.
   * @return {UsrUserService} list representation.
   */
  static toList() {
    return this.fetcher({
      includes: [
        'facilitator',
        'status',
        'type',
      ],
    })
  }

  /**
   * Return a update representation.
   * @param {ActActivityModel} actActivity update representation
   */
  static toUpdate() {
    return this.model().include('body,status,type,support_facilitators.facilitator,externalFacilitator,facilitator,quotas')
  }

  /**
   * Update the draft of a ActActivity.
   * @param {ActActivityModel} actActivity - the actActivity to update draft.
   */
  static update(actActivity) {
    
    //
    if (actActivity.can_update_confirmed) {
      return this.updateConfirmed(actActivity)
    }
    
    //
    if (actActivity.can_update_draft) {
      return this.updateDraft(actActivity)
    }

    //
    return Promise.reject('No se puede editar la actividad.')
  }

  /**
   * Update the body of a ActActivity.
   * @param {ActActivityModel} actActivity - the actActivity to update body.
   */
  static updateBody(actActivity) {

    //
    const msg = ruleHtmlMax(500, { ignoreImage: true })(actActivity.body)
    
    // 
    if (typeof msg === 'string') {
      return Promise.reject(msg)
    }
    
    //
    return actActivity.updateBody().sync(actActivity.toUpdateBody())
  }

  /**
   * Update the confirmed of a ActActivity.
   * @param {ActActivityModel} actActivity - the actActivity to update confirmed.
   */
  static updateConfirmed(actActivity) {
    return actActivity.updateConfirmed().sync(actActivity.toUpdateConfirmed())
  }

  /**
   * Update the draft of a ActActivity.
   * @param {ActActivityModel} actActivity - the actActivity to update draft.
   */
  static updateDraft(actActivity) {
    return actActivity.updateDraft().sync(actActivity.toUpdateDraft())
  }

  /**
   * Update the image of a ActActivity.
   * @param {ActActivityModel} actActivity - the actActivity to upload image.
   */
  static uploadImage(actActivity) {
    return actActivity.uploadImage().transfer(actActivity.toUploadImage())
  }
}