// components
import RBtn from '@/components/buttons/ButtonTooltip'
// import RLayoutTableNew from '@/components/layouts/LayoutTable'

// components
import ActActivityFields from '@/views/Activity/ActActivity/fields'
import ActActivityFilters from '@/views/Activity/ActActivity/filters'
import ActActivityHeaders from '@/views/Activity/ActActivity/headers'

// services
import ActActivityModel from '@/models/Activity/ActActivity'
import ActActivityService from '@/services/api/Activity/ActActivity'

// component
export default {
  name: 'act-activity-group',
  components: {
    RBtn,
    // RLayoutTableNew,
  },
  computed: {

    /**
     * 
     */
    formProps() {
      return {
        items: ActActivityFields.toCreate(),
      }
    },

    /**
     * 
     */
    groupProps() {
      return {
        items: ActActivityFields.toGroup()
      }
    },

    /**
     * 
     */
    tableProps() {
      return {
        filters: ActActivityFilters.toList(),
        headers: ActActivityHeaders.toGroup(),
        service: ActActivityService.toGroup(this.actActivity.activity_group_id),
      }
    },

    /**
     * 
     */
    tableGroupProps() {
      return {
        headers: ActActivityHeaders.toNewGroup(),
        service: ActActivityService.from(this.actActivities.group_items),
      }
    },
  },
  data() {
    return {
      actActivities: {
        group_length: 0,
        group_items: [],
      },
      actActivity: ActActivityService.shape({
        body: '',
        event_datetime: '',
        support_facilitators: [],
        activity_group_id: null,
      }),
      dialogs: {
        create: false,
        group: false,
      },
    }
  },
  methods: {

    /**
     * Refresh the ActActivity list
     */
    refresh() {

      //
      ActActivityService.toUpdate().find(this.getParam('id')).then(res => {
        this.actActivity = res.reset()
      })

      //
      this.getRef('table').refresh()

      //
      this.dialogs.create = false
    },

    /**
     * 
     */
    submit() {
      this.toWait(true).then(this.toCreate).then(this.toWait)
    },

    /**
     * Show a delete ActActivity confirm toast
     */
    submitDelete() {
      this.$toaster('¿Confirma la eliminación del ActActivity seleccionado?', 'confirmar', this.toDelete)
    },

    /**
     * Go to ActActivity detail
     */
    submitDetail(event) {
      this.goRoute('act-activity-update', { id: event.item.id })
    },

    /**
     * Set the selected ActActivity table
     * @param  {Object}  event - the click item payload
     */
    submitItem(event) {
      this.selected = event.item
    },

    /**
     * 
     */
    submitGroup() {
      this.toWaitGroup(true).then(this.toCreateGroup).then(this.toWaitGroup)
    },

    /**
     * Return a create ActActivity promise.
     * @return {Promise} A create promise.
     */
    toCreate() {
      return ActActivityService.create(this.actActivity).then(this.toSuccess).catch(this.toError)
    },

    /**
     * 
     */
    toCreateGroup() {
      return ActActivityService.createGroup(this.actActivities.group_items).then(this.toSuccessGroup).catch(this.toError)
    },

    /**
     * Return a delete ActActivity promise.
     * @param  {ActActivityModel}  model - the ActActivity model
     * @return {Promise} A delete process.
     */
    toDelete() {
      return ActActivityService.delete(this.selected).then(this.toSuccess).catch(this.toError)
    },

    /**
     * The delete ActActivity error process.
     * @param  {Object}  event - the error delete payload
     */
    toError(event) {
      this.getErrorMessage(event).then(this.getErrorToast)      
    },

    /**
     * The delete ActActivity success process.
     * @param  {Object}  event - the success delete payload
     */
    toSuccess(event) {
      this.getSuccessMessage('Actividad agregada exitosamente.').then(this.getSuccessToast).then(this.refresh)    
    },

    /**
     * The delete ActActivity success process.
     * @param  {Object}  event - the success delete payload
     */
    toSuccessGroup(event) {
      this.getSuccessMessage(event).then(this.getSuccessToast).then(this.refresh).then(() => {
        this.dialogs.group = false
      })
    },

    /**
     * 
     */
    async toWaitGroup(value = false) {
      this.$refs.formGroup.wait(value)
    },

    /**
     * 
     */
    triggerGroupLength() {

      // new item
      var item = new ActActivityModel(this.actActivity)

      item.event_datetime_origin = item.event_datetime
      
      // required data
      const items   = this.actActivities.group_items
      const limit   = this.actActivities.group_length
      const length  = this.actActivities.group_items.length

      // remove or add new items
      if (length > limit) {
        this.actActivities.group_items = items.slice(0, limit)
      } else {
        for (let index = 0; index < limit; index++) {
          if (length < limit) {
            let activity = new ActActivityModel(item)
            activity.quotas = item.quotas
            this.actActivities.group_items.push(activity)
          }
        }
      }
    },
  },
  mounted() {
    this.refresh()
  },
  watch: {

    /**
     * 
     */
    'actActivities.group_length'() {
      this.triggerGroupLength();
    },
  },
}