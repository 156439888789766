// required components
import TtsTutorshipParallelFields from '@/views/Tutorship/TtsTutorshipParallel/fields'
import TtsTutorshipParallelService from '@/services/api/Tutorship/TtsTutorshipParallel'

// component
export default {
  name: 'tts-tutorship-parallel-create',
  computed: {

    /**
     * Gets the TtsTutorshipParallel create form props.
     *
     * @return {Object} The create form props.
     */
    formProps() {
      return {
        items: TtsTutorshipParallelFields.toCreate(),
      }
    }
  },
  data() {
    return {
      ttsTutorshipParallel: TtsTutorshipParallelService.shape({
        value: ''
      }),
    }
  },
  methods: {

    /**
     * Reset the TtsTutorshipParallel data.
     */
    onReset() {
      this.ttsTutorshipParallel.value = ''
    },

    /**
     * Returns a submit TtsTutorshipParallel create form.
     *
     * @return {Promise} A form submit process.
     */
    onSubmit() {
      return this.toWait(true).then(this.toCreate).then(this.toWait)
    },

    /**
     * 
     */
    onSuccessCreate() {
      this.onSuccess('Paralelo de tutoría creado exitosamente.')
    },

    /**
     * Returns an create TtsTutorshipParallel process.
     *
     * @return {Promise} A create process.
     */
    toCreate() {
      return TtsTutorshipParallelService.create(this.ttsTutorshipParallel).then(this.onSuccessCreate).then(this.toRedirect).catch(this.onError)
    },

    /**
     * Redirect to TtsTutorshipParallel list
     */
    toRedirect() {
      this.goRoute('tts-tutorship-parallel-list')
    },
  },
}