// layout
import RLayoutModule from '@/components/layouts/LayoutModule'

// views
import UsrUserCreateView from '@/views/User/UsrUser/create'
import UsrUserListView from '@/views/User/UsrUser/list'
import UsrUserRoleView from '@/views/User/UsrUser/role'

// routes
export default {
  path: '/usuarios',
  component: RLayoutModule,
  children: [
    {
      name: 'usr-user-list',
      path: '',
      component: UsrUserListView,
    },
    {
      name: 'usr-user-create',
      path: 'crear',
      component: UsrUserCreateView,
      meta: {
        title: 'Crear Usuario'
      },
    },
    {
      name: 'usr-user-role',
      path: ':id/rol',
      component: UsrUserRoleView,
      meta: {
        title: 'Editar Usuario'
      },
    },
  ],
  meta: {
    title: 'Usuarios'
  },
  props: {
    indexText: 'Inicio',
  },
}
