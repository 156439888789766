// required modules
import { Fields } from 'collection-wrapper'

/**
 * This class describes a TtsTutorship filters collection.
 * @class
 */
export default class TtsTutorshipFilters extends Fields {

  /**
   * Returns the TtsTutorship filters options.
   * @returns {Array} filters options.
   */
  fields() {
    return [
      {
        name: 'description',
        type: 'text-field',
        props: {
          label: 'Nombre',
          placeholder: 'Nombre',
        },
      },
      {
        name: 'long_description',
        type: 'text-field',
        props: {
          label: 'Descripción',
          placeholder: 'Descripción',
        },
      },
    ]
  }

  /**
   * Returns the TtsTutorship list filters.
   * @returns {Collection} filters collection.
   */
  static toList() {
    return this.reduce('name', [
      'description',
      'long_description',
    ])
  }
}
