// components
import TtsTutorshipScheduleFields from '@/views/Tutorship/TtsTutorshipSchedule/fields'
import TtsTutorshipScheduleService from '@/services/api/Tutorship/TtsTutorshipSchedule'
// component
export default {
  name: 'tts-tutorship-schedule-update',
  computed: {

    /**
     * 
     */
    fieldsProps() {
      return {
        tutorship_id: this.ttsTutorshipSchedule.tutorship_id,
      }
    },

    /**
     * Gets the TtsTutorshipSchedule update form props.
     * @return {Object} The update form props.
     */
    formProps() {
      return {
        items: TtsTutorshipScheduleFields.toUpdate(this.fieldsProps),
      }
    }
  },
  data() {
    return {
      ttsTutorshipSchedule: {
        value: 'test',
      },
    }
  },
  methods: {

    /**
     * Returns an update TtsTutorshipSchedule success process.
     * @param  {Object}  event - the event success payload.
     */
    onSuccessUpdate(event) {
      this.onSuccess(event)
    },

    /**
     * Returns a reset TtsTutorshipSchedule form.
     * @return {Promise} A form reset promise.
     */
    onReset() {
      return this.toWait(true).then(this.toRefresh).then(this.toWait)
    },

    /**
     * Returns a submit TtsTutorshipSchedule form.
     * @return {Promise} A form submit promise.
     */
    onSubmit() {
      return this.toWait(true).then(this.toUpdate).then(this.toWait)
    },

    /**
     * Returns a refresh TtsTutorshipSchedule form.
     * @return {Promise} A reset promise.
     */
    toRefresh() {
      return TtsTutorshipScheduleService.model().include('careers').find(this.getParam('id')).then(ttsTutorshipSchedule => {
        this.ttsTutorshipSchedule = ttsTutorshipSchedule//.reset()
      })
    },

    /**
     * Returns an update TtsTutorshipSchedule promise.
     *
     * @param  {Object}  model - the tts-tutorship-schedule model
     * @return {Promise} A update promise.
     */
    toUpdate() {
      return TtsTutorshipScheduleService.update(this.ttsTutorshipSchedule).then(this.onSuccessUpdate).catch(this.onError)
    },
  },
  mounted() {
    this.onReset()
  },
  watch: {

    /**
     * 
     */
    'ttsTutorshipSchedule.tutorship_id'() {
      setTimeout(() => {
        this.getRef('form').getRef('fieldset').getRef('tutorship_parallel_id').flat().shift().refresh()
      }, 250)
    }
  },
}