//
import store from '@/store'

//
const ALLOWED_ROUTES = [
  '/auth',
  '/encuesta/responder',
]

/**
 * 
 * @param {object} options 
 */
export default function(options) {

  //
  if (options.to.path) {

    //
    const isBypass          = process.env.VUE_APP_AUTH_BYPASS === "true"
    const isLogged          = store.getters.isLogged
    const hasAllowedRoute   = ALLOWED_ROUTES.some(route => options.to.path.includes(route))
    const hasBlockedRoute   = !isLogged && !hasAllowedRoute
    const nextRoute         = hasBlockedRoute ? '/auth' : undefined
  
    //
    // console.log({ path: options.to.path, hasAllowedRoute, isLogged, hasBlockedRoute, nextRoute }, 'auth')

    // return nextRoute
    // if (nextRoute) {
      options.next(nextRoute)
    // }
  }
}