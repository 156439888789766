// required modules
import { Fields } from 'collection-wrapper'
import { ruleEmail, ruleRequired, ruleRut } from 'vuetify2-wrappers/src/rules'

// required services
import UsrAttentionTypeModel from '@/models/User/UsrAttentionType'
import UsrSpecialityModel from '@/models/User/UsrSpeciality'
import UsrUniversityCampusService from '@/services/api/User/UsrUniversityCampus'

/**
 * This class describes a UsrFacilitator fields collection.
 * 
 * @class UsrFacilitatorFields (name)
 */
export default class UsrFacilitatorFields extends Fields {

  /**
   * Returns the UsrFacilitator fields options.
   *
   * @returns {Array} fields options.
   */
  fields() {
    return [
    {
      name: 'rut',
      type: 'rut-field',
      props: {
        fieldProps: {
          label: 'Rut',
          persistentHint: true,
          placeholder: '1-9',
          rules: [ruleRut],
        },
      },
    },
    {
      name: 'first_names',
      type: 'text-field',
      props: {
        label: 'Primer Nombre',
        placeholder: 'Primer Nombre',
        rules: [ruleRequired],
      },
    },
    {
      name: 'last_name1',
      type: 'text-field',
      props: {
        label: 'Primer Apellido',
        placeholder: 'Primer Apellido',
      },
    },
    {
      name: 'last_name2',
      type: 'text-field',
      props: {
        label: 'Segundo Apellido',
        placeholder: 'Segundo Apellido',
      },
    },
    {
      name: 'email',
      type: 'text-field',
      props: {
        label: 'Email Principal',
        placeholder: 'Email Principal',
        rules: [ruleEmail],
      },
    },
    {
      name: 'alternative_email',
      type: 'text-field',
      props: {
        label: 'Email alternativo',
        placeholder: 'Email alternativo',
        rules: [ruleEmail],
      },
    },
    {
      name: 'phone_number',
      type: 'text-field',
      props: {
        label: 'Celular',
        placeholder: 'Celular',
      },
    },
    {
      name: 'gender',
      type: 'select',
      props: {
        items: [
          {
            text: 'Masculino',
            value: 'M',
          },
          {
            text: 'Femenino',
            value: 'F',
          },
        ],
        label: 'Género',
        placeholder: 'Género',
        rules: [ruleRequired],
        menuProps: {
          contentClass: 'elevation-2 v-list--dense mt-2',
        },
      },
    },
    {
      name: 'university_campus_id',
      type: 'autocomplete-model',
      props: {
        clearable: true,
        fetch: UsrUniversityCampusService.toList(),
        limit: 15,
        autocompleteProps: {
          clearable: true,
          itemText: 'description',
          itemValue: 'id',
          label: 'Sede',
          multiple: false,
          placeholder: 'Sede',
          rules: [ruleRequired],
        },
      
      },
    },
    {
      name: 'specialities',
      type: 'select-model',
      props: {
        fetch: () => UsrSpecialityModel.get(),
        selectProps: {
          itemText: 'description',
          itemValue: 'id',
          label: 'Especialidades',
          placeholder: 'Especialidades',
          menuProps: {
            contentClass: 'elevation-2 v-list--dense mt-2',
          },
          multiple: true,
        },
      },
    },
    {
      name: 'attention_types',
      type: 'select-model',
      props: {
        fetch: () => UsrAttentionTypeModel.get(),
        selectProps: {
          itemText: 'description',
          itemValue: 'id',
          label: 'Tipos de Atención',
          placeholder: 'Tipos de Atención',
          menuProps: {
            contentClass: 'elevation-2 v-list--dense mt-2',
          },
          multiple: true,
        },
      },
    },
    ]
  }

  /**
   * Returns the UsrFacilitator create fields.
   *
   * @returns {Collection} fields collection.
   */
  static toCreate() {
    return this.reduce('name', [
      'rut',
      'first_names',
      'last_name1',
      'last_name2',
      'email',
      'alternative_email',
      'phone_number',
      'gender',
      'university_campus_id',
      'specialities',
      'attention_types',
    ])
  }

  /**
   * Returns the UsrFacilitator update fields.
   *
   * @returns {Collection} fields collection.
   */
  static toUpdate() {
    return this.reduce('name', [
      'rut',
      'first_names',
      'last_name1',
      'last_name2',
      'email',
      'alternative_email',
      'phone_number',
      'gender',
      'university_campus_id',
      'specialities',
      'attention_types',
    ])
  }
}
