// model
import Model from '@/models/Model'

/**
 * This class describes a UsrExternalFacilitator model.
 * @class UsrExternalFacilitator (name)
 */
export default class UsrExternalFacilitatorModel extends Model {

  /**
   * Define the UsrExternalFacilitator resource.
   * @return {String} UsrExternalFacilitator endpoint name.
   */
  resource() {
    return 'external_facilitator'
  }
}