// required modules
import { Fields } from 'collection-wrapper'

/**
 * This class describes a UsrUniversityPeriod filters collection.
 *
 * @class UsrUniversityPeriodFilters (name)
 */
export default class UsrUniversityPeriodFilters extends Fields {

  /**
   * Returns the UsrUniversityPeriod filters options.
   *
   * @returns {Array} filters options.
   */
  fields() {
    return [
      {
        name: 'identifier',
        type: 'text-field',
        info: 'Identificador del Semestre, Ej: 2020-1 o S1-2020.',
        props: {
          label: 'Identificador del semestre',
          placeholder: 'Identificador del semestre',
        },
      },
      {
        name: 'is_active',
        type: 'switch',
        info: 'Periodo activo o inactivo.',
        props: {
          label: 'Activo',
          placeholder: 'Activo',
        },
      },
    ]
  }

  /**
   * Returns the UsrUniversityPeriod list filters.
   *
   * @returns {Collection} filters collection.
   */
  static toList() {
    return this.reduce('name', [
      'identifier',
      'is_active',
    ])
  }
}
