// required modules
import Model from '@/models/Model'

/**
 * This class describes a ASendToTarget model.
 *
 * @class ASendToTarget (name)
 */
export default class ASendToTargetModel extends Model {

  /**
   * Define the ASendToTarget resource.
   *
   * @return {String} ASendToTarget endpoint name.
   */
  resource() {
    return 'send_to_target'
  }
}