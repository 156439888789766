// required modules
import TtsTutorshipScheduleService from '@/services/api/Tutorship/TtsTutorshipSchedule'
import TtsTutorshipScheduleFilters from '@/views/Tutorship/TtsTutorshipSchedule/filters'

// component
export default {
  name: 'tts-tutorship-schedule-schedule',
  computed: {

    /**
     *
     */
    calendarProps() {
      return {
        calendarProps: {
          events: this.events,          
          eventHeight: 100,
          eventMarginBottom: 8,
          eventMore: true,
          eventOverlapMode: this.onCalendarOverlapMode,
          // eventOverlapMode: 'stack',
          eventOverlapThreshold: 30,
          eventStart: 'block_init_datetime_reversed',
          intervalCount: 17,
          intervalHeight: 81,
          firstInterval: 7,
          weekdays: [ 1, 2, 3, 4, 5, 6, 0 ],
          type: 'week',
          // type: 'custom-weekly',
        },
        toolbarProps: {
          color: 'primary',
          dark: true,
          dense: true,
          flat: true,
          style: {
            marginBottom: 2,
          }
        },
      }
    },

    /**
     * 
     */
    formProps() {
      return {
        items: TtsTutorshipScheduleFilters.toList(),
      }
    },
  },
  data() {
    return {
      filter: {

      },
      filtering: false,
      events: [],
      actualWeek: null,
      actualDate: new Date(),
    }
  },
  methods: {
    getActualWeek(){
      //console.log("this.actualDate.getDay()",this.actualDate.getDay())
      let dateBefore = new Date()
      dateBefore.setDate(dateBefore.getDate() - this.actualDate.getDay() + 1)
      //console.log("changeDay BEFORE ", dateBefore.getDate() + "/" + (dateBefore.getMonth() +1) + "/" + dateBefore.getFullYear()  )
      let strDateBefore = dateBefore.getDate() + "/" + (dateBefore.getMonth() +1) + "/" + dateBefore.getFullYear()
      let dateAfter = new Date()
      dateAfter.setDate(dateAfter.getDate() + 7 - this.actualDate.getDay())
      //console.log("changeDay AFTER ", dateAfter.getDate() + "/" + (dateAfter.getMonth() +1) + "/" + dateAfter.getFullYear()  )
      let strDateAfter = dateAfter.getDate() + "/" + (dateAfter.getMonth() +1) + "/" + dateAfter.getFullYear()

      this.actualWeek= "Semana desde: "+ strDateBefore + " al: "+strDateAfter
    
    },
    /**
     * 
     */
    genEvent(event) {
      return (
        `
        <div class="v-event-timed primary white--text" style="width: 100%;">
        <h3>${ event.tutorship_description }</h3>
        <h3>${ event.parallel_description }</h3>
        <h4>${ event.tutor_name_case }</h4>
        <h4>${ event.classroom_description }</h4>
        <h4>${ event.block_init_time } - ${ event.block_end_time }</h4>
        </div>
        `
      )
    },

    /**
     * 
     */
    onCalendarOverlapMode(events) {
      let heights = {}
      events.forEach(event => {
        let hri = event.start.hour - 6
        let wkd = event.start.weekday
        let sel = `.v-calendar-daily__day`
        let itm = `.v-calendar-daily__day-interval:nth-child(${ hri })`
        let cal = document.querySelector('.v-calendar')
        let day = cal.querySelectorAll(sel)
        let hor = day.item(wkd).querySelector(itm)
        let hrs = cal.querySelectorAll(itm)
        let txt = cal.querySelectorAll(`.v-calendar-daily__interval:nth-child(${ hri })`)
        hor.innerHTML += this.genEvent(event.input)
        heights[hri] = heights[hri] ? heights[hri] + 98 : 99
        hrs.forEach((item, index) => {
          item.style.height = `${ heights[hri] }px`
        })
        txt.forEach((item, index) => {
          item.style.height = `${ heights[hri] }px`
        })
        console.log({ txt })
      })
    },

    /**
     * 
     */
    onFilter() {
      let events = document.querySelectorAll('.v-event-timed').forEach(item => {
        item.remove()
      })
      var filter = this.filter
      Object.entries(filter).forEach( o => (o[1] === null ? delete filter[o[0]] : 0))
      TtsTutorshipScheduleService.model().include('campus,period,tutorship,block,classroom,tutor,parallel').params({ filter }).get().then(res => {
        this.events = res.data
      })
    },

    /**
     * 
     */
    onReset() {
      this.toRefresh()
      this.filter = {
        active_period: null,
        university_period_id: null,
        tutorship_id: null,
        weekday: null,
        university_time_block_id: null,
        university_classroom_id: null,
        university_campus_id: null,
        tutor_id: null,
      }
    },

    /**
     * Refresh the TtsTutorshipSchedule list.
     */
    toRefresh() {
      let events = document.querySelectorAll('.v-event-timed').forEach(item => {
        item.remove()
      })
      TtsTutorshipScheduleService.toSchedule().apply(this).then(res => {
        this.events = res.data
      })
    },
  },
  mounted() {
    this.onReset()
    this.getActualWeek()
    },
}