// required modules
import TtsTutorFields from '@/views/Tutorship/TtsTutor/fields'
import TtsTutorService from '@/services/api/Tutorship/TtsTutor'
import TtsTutoringNewFields from '@/views/Tutorship/TtsTutoringNew/fields'
import TtsTutoringNewService from '@/services/api/Tutorship/TtsTutoringNew'
import TtsTutorshipFields from '@/views/Tutorship/TtsTutorship/fields'
import TtsTutorshipService from '@/services/api/Tutorship/TtsTutorship'
import TtsTutorshipScheduleFields from '@/views/Tutorship/TtsTutorshipSchedule/fields'
import TtsTutorshipScheduleService from '@/services/api/Tutorship/TtsTutorshipSchedule'

// component
export default {
  name: 'tts-tutorship-dashboard',
  computed: {

    /**
     * 
     */
    scheduleFormProps() {
      return {
        items: TtsTutorshipScheduleFields.toDetail(),
      }
    },

    /**
     *
     */
    tutorFormProps() {
      return {
        items: TtsTutorFields.toDetail(),
      }
    },

    /**
     *
     */
    tutorProps() {
      return {
        busy: this.busy.tutor,
        items: this.ttsTutorList,
      }
    },

    /**
     * 
     */
    tutoringNewFormProps() {
      return {
        items: TtsTutoringNewFields.toDetail(),
      }
    },
    
    /**
     *
     */
    tutoringNewProps() {
      return {
        busy: this.busy.tutoringNew,
        items: this.ttsTutoringNewList,
      }
    },

    /**
     * 
     */
    tutorshipFormProps() {
      return {
        items: TtsTutorshipFields.toDetail(),
      }
    },

    /**
     *
     */
    tutorshipProps() {
      return {
        busy: this.busy.tutorship,
        items: this.ttsTutorshipList,
      }
    },

    /**
     *
     */
    tutorshipScheduleProps() {
      return {
        busy: this.busy.tutorshipSchedule,
        items: this.ttsTutorshipScheduleList,
      }
    },
  },
  data() {
    return {
      busy: {
        tutor: '',
        tutoringNew: '',
        tutorship: '',
        tutorshipSchedule: '',
      },
      dialogs: {
        news: false,
        schedule: false,
        tutor: false,
        tutorship: false,
      },
      selected: {
        news: {},
        schedule: {},
        tutor: {},
        tutorship: {},
      },
      ttsTutorList: [],
      ttsTutoringNewList: [],
      ttsTutorshipList: [],
      ttsTutorshipScheduleList: [],
    }
  },
  methods: {

    /**
     * 
     */
    async onBusy(type) {
      this.busy[type] = this.busy[type] ? false : 'secondary'
    },

    /**
     * 
     */
    onNews(event) {
      this.dialogs.news = true
      this.selected.news = event.item
    },

    /**
     * 
     */
    onRefreshTutor() {
      this.onBusy("tutor").then(this.toRefreshTutor).then(() => {
        this.onBusy("tutor")
      })
    },

    /**
     * 
     */
    onRefreshTutoringNew() {
      this.onBusy("tutoringNew").then(this.toRefreshTutoringNew).then(() => {
        this.onBusy("tutoringNew")
      })
    },

    /**
     * 
     */
    onRefreshTutorship() {
      this.onBusy("tutorship").then(this.toRefreshTutorship).then(() => {
        this.onBusy("tutorship")
      })
    },

    /**
     * 
     */
    onRefreshTutorshipSchedule() {
      this.onBusy("tutorshipSchedule").then(this.toRefreshTutorshipSchedule).then(() => {
        this.onBusy("tutorshipSchedule")
      })
    },

    /**
     * 
     */
    onSchedule(event) {
      this.dialogs.schedule = true
      this.selected.schedule = event.item
    },

    /**
     * 
     */
    onTutor(event) {
      this.dialogs.tutor = true
      this.selected.tutor = event.item
    },

    /**
     * 
     */
    onTutorship(event) {
      this.dialogs.tutorship = true
      this.selected.tutorship = event.item
    },

    /**
     * 
     */
    onTutorshipList() {
      this.goRoute('tts-tutorship-list')
    },

    /**
     * 
     */
    toRefreshTutor() {
      return TtsTutorService.model().include('career').get().then(res => {
        this.ttsTutorList = res.data.slice(0, 3)
      })
    },

    /**
     * 
     */
    toRefreshTutoringNew() {
      return TtsTutoringNewService.model().include('body').get().then(res => {
        this.ttsTutoringNewList = res.data.slice(0, 3).map(item => {
          return item.reset()
        })
      })
    },


    /**
     * 
     */
    toRefreshTutorship() {
      return TtsTutorshipService.model().get().then(res => {
        this.ttsTutorshipList = res.data.slice(0, 3)
      })
    },

    /**
     * 
     */
    toRefreshTutorshipSchedule() {
      return TtsTutorshipScheduleService.model().include('block,tutorship').get().then(res => {
        this.ttsTutorshipScheduleList = res.data.slice(0, 3)
      })
    },
  },
  mounted() {
    this.onRefreshTutor()
    this.onRefreshTutoringNew()
    this.onRefreshTutorship()
    this.onRefreshTutorshipSchedule()
  },
}