// model
import Model from '@/models/Model'

/**
 * This class describes a AAddStudent model.
 * @class AAddStudent (name)
 */
export default class AAddStudentModel extends Model {

  /**
   * Define the AAddStudent resource.
   * @return {String} AAddStudent endpoint name.
   */
  resource() {
    return 'add_student'
  }
}