// service
import Service from '@/services/api/Service'
import SrvSurveyModel from '@/models/Survey/SrvSurvey'

/**
 * This class describes a SrvSurvey service.
 * @class SrvSurvey (name)
 */
export default class SrvSurveyService extends Service {

  /**
   * 
   */
  static answer(srvSurvey) {
    return srvSurvey.answer().attach(srvSurvey.toAnswer())
  }

  /**
   * 
   */
  static assignTarget(srvSurvey) {
    return srvSurvey.assignTarget().attach(srvSurvey.toAssignTarget())
  }
  /**
   * 
   */
  static complete(srvSurvey) {
    return srvSurvey.complete().attach({})
  }

  /**
   * 
   */
  static excelDownload(srvSurvey) {
    return srvSurvey.excel('excel_answer').then(srvSurvey.file('excel', 'xlsx'))
    // return srvSurvey.excel('excel_answer').then(res => {
    //   console.log()
    //   console.log({ res })
    // })
  }

  /**
   * Define the SrvSurvey model.
   * @return {Function} SrvSurvey resource.
   */
  static model() {
    return SrvSurveyModel
  }

  /**
   * 
   */
  static sendToTarget(srvSurvey) {
    return srvSurvey.sendToTarget().attach({})
  }
  /**
   * 
   */
   static formatDateTime() {
    return new Intl.DateTimeFormat('es-CL', {
    // formatMatcher: 'best fit',
      year: 'numeric',
      day: '2-digit',
      month: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
      // timeStyle: 'short',
    })
  }

  /**
   * 
   * @param {*} opts 
   */
   static getStart() {
    
    //
    let newDate = this.getToday()

    //
    newDate.setTime(this.getToday().getTime())

    //
    return newDate
  }

  /**
   * 
   * @param {*} opts 
   */
  static getInitial() {

    //
    const initialDatetime = this.formatDateTime().format(this.getStart())
    const initialDate = initialDatetime.split(' ').shift().split('-').reverse().join('-')
    const initialTime = initialDatetime.split(' ').pop()

    //
    return `${ initialDate } ${ initialTime }`
  }

  /**
   * 
   * @param {*} opts 
   */
  static getToday() {
    return new Date()
  }

  /**
   * 
   */
  static toCreate() {
    const todayDate = new Date(Date.now())
    let afterDate = new Date(Date.now())
    afterDate.setHours(todayDate.getHours()+1)
    const todayDateString = new Date().toLocaleString('es-CL').split(', ')[0].split('-').reverse().join('-')
    const todayDateStringReverse = todayDate.toLocaleDateString('es-CL')
    const todayHourString = todayDate.toLocaleTimeString('es-CL').split('T').pop().split('.').shift()
    const afterHourString = afterDate.toLocaleTimeString('es-CL').split('T').pop().split('.').shift()
    const eventDatetime = `${ todayDateString } ${ todayHourString }`
    const eventAfterDatetime = `${ todayDateString } ${ afterHourString }`
    //
    return this.shape({
      title: '',
      description: '',
      init_datetime: eventDatetime,//'',
      is_required: false,
      limit_datetime: '',//eventAfterDatetime,
      sections: [],
    })
  }

  /**
   * 
   */
  static toList() {
    return this.fetcher({
      includes: [
        'status',
      ],
    })
  }

  /**
   * 
   */
  static toPreview() {
    return this.model().include([
      'sections.blocks.fields',
      'sections.blocks.options',
      'sections.blocks.range',
    ].join())
  }

  /**
   * 
   */
  static toTarget() {
    return this.model().include([
      'activity',
      'targets.target_identifiers',
    ].join())
  }

  /**
   * 
   */
  static toUpdate() {
    return this.model().include([
      'sections.blocks.fields',
      'sections.blocks.options',
      'sections.blocks.range',
    ].join())
  }
}