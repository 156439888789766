// model
import Model from '@/models/Model'

/**
 * This class describes a AAssignQuota model.
 * @class AAssignQuota (name)
 */
export default class AAssignQuotaModel extends Model {

  /**
   * Define the AAssignQuota resource.
   * @return {String} AAssignQuota endpoint name.
   */
  resource() {
    return 'assign_quota'
  }
}