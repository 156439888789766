// required modules
import Model from '@/models/Model'

/**
 * This class describes a AMove model.
 *
 * @class AMove (name)
 */
export default class AMoveModel extends Model {

  /**
   * Define the AMove resource.
   *
   * @return {String} AMove endpoint name.
   */
  resource() {
    return 'move'
  }
}