// required modules
import { Headers } from 'collection-wrapper'

/**
 * This class describes a UsrUniversityPeriod headers collection.
 *
 * @class UsrUniversityPeriodHeaders (name)
 */
export default class UsrUniversityPeriodHeaders extends Headers {

  /**
   * Returns the UsrUniversityPeriod headers options.
   *
   * @returns {Array} headers options.
   */
  headers() {
    return [
      {
        text: 'Identificador',
        value: 'identifier',
      },
      {
        text: 'Fecha de inicio',
        value: 'init_date',
      },
      {
        text: 'Fecha de termino',
        value: 'end_date',
      },
      {
        text: 'Activo',
        value: 'is_active_str',
      },
    ]
  }

  /**
   * Returns the UsrUniversityPeriod list headers.
   *
   * @returns {Collection} headers collection.
   */
  static toList() {
    return this.reduce('value', [
      'identifier',
      'init_date',
      'end_date',
      'is_active_str',
    ])
  }
}
