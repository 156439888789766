// required components
import TtsTutorshipScheduleFields from '@/views/Tutorship/TtsTutorshipSchedule/fields'
import TtsTutorshipScheduleService from '@/services/api/Tutorship/TtsTutorshipSchedule'
import UsrCareerService from '@/services/api/User/UsrCareer'

// component
export default {
  name: 'tts-tutorship-schedule-create',
  computed: {

    /**
     * 
     */
    fieldsProps() {
      return {
        tutorship_id: this.ttsTutorshipSchedule.tutorship_id,
      }
    },

    /**
     * Gets the TtsTutorshipSchedule create form props.
     *
     * @return {Object} The create form props.
     */
    formProps() {
      return {
        items: TtsTutorshipScheduleFields.toCreate(this.fieldsProps),
      }
    },
  },
  data() {
    return {
      ttsTutorshipSchedule: TtsTutorshipScheduleService.shape({
        careers:  [],
        event_room: '',
        tutor_id: null,
        tutorship_id: null,
        tutorship_parallel_id: null,
        university_classroom_id: null,
        university_time_block_id: null,
        weekday: '',
      }),
    }
  },
  methods: {

    /**
     * Reset the TtsTutorshipSchedule data.
     */
    onReset() {
      this.ttsTutorshipSchedule.careers = []
      this.ttsTutorshipSchedule.event_room = ''
      this.ttsTutorshipSchedule.tutor_id = null
      this.ttsTutorshipSchedule.tutorship_id = null
      this.ttsTutorshipSchedule.tutorship_parallel_id = null
      this.ttsTutorshipSchedule.university_classroom_id = null
      this.ttsTutorshipSchedule.university_time_block_id = null
      this.ttsTutorshipSchedule.weekday = null
    },

    /**
     * Returns a submit TtsTutorshipSchedule create form.
     *
     * @return {Promise} A form submit process.
     */
    onSubmit() {
      return this.toWait(true).then(this.toCreate).then(this.toWait)
    },

    /**
     * Returns an create TtsTutorshipSchedule success process.
     *
     * @param  {Object}  event - the event success payload.
     */
    onSuccessCreate(event) {
      this.onSuccess('Horario de tutoría creado exitosamente.')
    },

    /**
     * Returns an create TtsTutorshipSchedule process.
     *
     * @return {Promise} A create process.
     */
    toCreate() {
      return TtsTutorshipScheduleService.create(this.ttsTutorshipSchedule).then(this.onSuccessCreate).then(this.toRedirect).catch(this.onError)
    },

    /**
     * Redirect to TtsTutorshipSchedule list
     */
    toRedirect() {
      this.goRoute('tts-tutorship-schedule-list')
    },
  },
  watch: {

    /**
     * 
     */
    'ttsTutorshipSchedule.tutorship_id'() {
      setTimeout(() => {
        this.getRef('form').getRef('fieldset').getRef('tutorship_parallel_id').flat().shift().refresh()
      }, 250)
    }
  },
}