// components
import UsrUserFields from '@/views/User/UsrUser/fields'
import UsrUserModel from '@/models/User/UsrUser'
import UsrUserService from '@/services/api/User/UsrUser'
// component
export default {
  name: 'usr-user-role',
  computed: {

    /**
      * Gets the UsrUser role form props.
      * @return {Object} The role form props.
      */
    formProps() {
      return {
        items: UsrUserFields.toRole(),
        // items: UsrUserFields.collection('role'),
      }
    }
  },
  data() {
    return {
      usrUser: {
        value: 'test',
      },
    }
  },
  methods: {

    /**
      * Returns a reset UsrUser form.
      * @return {Promise} A form reset promise.
      */
    reset() {
      return this.toWait(true).then(this.toRefresh).then(this.toWait)
    },

    /**
      * Returns a submit UsrUser form.
      * @return {Promise} A form submit promise.
      */
    submit() {
      return this.toWait(true).then(this.toChangeRole).then(this.toWait)
    },

    /**
      * Returns a refresh UsrUser form.
      * @return {Promise} A reset promise.
      */
    async toRefresh() {
      return UsrUserModel.find(this.getParam('id')).then(usrUser => {
        this.usrUser = usrUser.reset()
      })
    },

    /**
      * Returns an role UsrUser promise.
      * @return {Promise} A role promise.
      */
    toChangeRole() {
      return UsrUserService.changeRole(this.usrUser).then(this.toSuccess).catch(this.toError)
    },

    /**
      * Returns an role UsrUser error process.
      * @param  {Object}  event - the event error payload.
      */
    toError(event) {
      this.getErrorMessage(event).then(this.getErrorToast)
    },

    /**
      * Returns an role UsrUser success process.
      * @param  {Object}  event - the event success payload.
      */
    toSuccess(event) {
      this.getSuccessMessage(event).then(this.getSuccessToast).then(this.refresh)
    },
  },
  mounted() {
    this.reset()
  },
}