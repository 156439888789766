<template lang="pug">
  v-menu( bottom :close-on-content-click='false' content-class='elevation-2 mr-4' v-model='visible' )
    template( slot='activator' slot-scope='props' )
      v-btn( bottom icon large v-bind='buttonProps' v-on='props.on' )
        v-badge( color='accent' :content='items.length.toString()' style='z-index:1;' )
        v-icon( size="24" ) mdi-comment-question
    v-card( v-bind='cardProps' )
      v-list( dense v-bind='listProps' )
        v-subheader
          v-card-subtitle( v-bind='titleProps' ) Encuestas pendientes
          v-spacer
          v-btn( icon small @click='onRefresh' )
            v-icon( small ) mdi-refresh
        template( v-if='items.length' )
          template( v-for='item in items' )
            v-list-item( @click='onClickItem(item)' )
              v-list-item-content
                v-list-item-title {{ item.survey_title }}
                v-list-item-subtitle # {{ item.survey_id }}
        template( v-else )
          v-list-item( disabled )
            v-list-item-content
              v-list-item-title Sin encuestas pendientes
</template>

<script>
  // required modules
  import SrvPendingSurveyService from '@/services/api/Survey/SrvPendingSurvey'
  // component
  export default {
    computed: {

      /**
       * 
       */
      buttonProps() {
        return {
          disabled: this.items.length < 1,
        }
      },
    },
    data() {
      return {
        items: [],
        visible: false,
      }
    },
    methods: {

      /**
       * 
       */
      onClickItem(item) {
        location.href = item.survey_url
      },

      /**
       * 
       */
      onRefresh() {
        SrvPendingSurveyService.model().get().then(res => {
          this.items = res.data
          // this.items = [
          //   {
          //     "survey_id": 41,
          //     "user_id": 11,
          //     "survey_token": "29-b-5636db14-ffc7-4f81-9ed4-fbeb2aa9036e",
          //     "survey_url": "https://apea.web.sys.dev.innlab.cl/#/encuesta/responder/29-b-5636db14-ffc7-4f81-9ed4-fbeb2aa9036e"
          //   }
          // ]
          this.$emit('refresh', {
            data: this.items
          })
        })
      }
    },
    props: {

      /**
       * 
       */
      cardProps: {
        type: Object,
        default: () => ({
          width: 250,
        }),
      },

      /**
       * 
       */
      listProps: {
        type: Object,
        default: () => ({
          class: 'pa-0',
        }),
      },

      /**
       * 
       */
      titleProps: {
        type: Object,
        default: () => ({
          class: 'px-2 py-0',
        }),
      },
    },
    mounted() {
      this.onRefresh()
    }
  }
</script>