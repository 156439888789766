// required modules
import { Headers } from 'collection-wrapper'

/**
 * This class describes a NtMailContact headers collection.
 *
 * @class NtMailContactHeaders (name)
 */
export default class NtMailContactHeaders extends Headers {

  /**
   * Returns the NtMailContact headers options.
   *
   * @returns {Array} headers options.
   */
  headers() {
    return [
      {
        text: 'Orden',
        value: 'order',
      },
      {
        text: 'Descripción',
        value: 'description',
      },
      {
        text: 'Correo electrónico',
        value: 'email',
      },
      {
        text: 'Teléfono',
        value: 'phone_number',
      },
    ]
  }

  /**
   * Returns the NtMailContact list headers.
   *
   * @returns {Collection} headers collection.
   */
  static toList() {
    return this.reduce('value', [
      'order',
      'description',
      'email',
      'phone_number',
    ])
  }
}
