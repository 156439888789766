// model
import Model from '@/models/Model'

/**
 * This class describes a AUploadEmail model.
 * @class AUploadEmail (name)
 */
export default class AUploadEmailModel extends Model {

  /**
   * Define the AUploadEmail resource.
   * @return {String} AUploadEmail endpoint name.
   */
  resource() {
    return 'update_email'
  }
}