// model
import Model from '@/models/Model'

/**
 * This class describes a ACancel model.
 * @class ACancel (name)
 */
export default class ACancelModel extends Model {

  /**
   * Define the ACancel resource.
   * @return {String} ACancel endpoint name.
   */
  resource() {
    return 'cancel'
  }
}