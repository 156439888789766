// components
import ActActivityFields from '@/views/Activity/ActActivity/fields'
import ActActivityFilters from '@/views/Activity/ActActivity/filters'
import ActActivityHeaders from '@/views/Activity/ActActivity/headers'

// services
import ActActivityService from '@/services/api/Activity/ActActivity'

// student
import UsrStudentFilters from '@/views/User/UsrStudent/filters'
import UsrStudentHeaders from '@/views/User/UsrStudent/headers'
import UsrStudentService from '@/services/api/User/UsrStudent'

// component
export default {
  name: 'act-activity-inscriptions',
  computed: {

    /**
     * 
     */
    attendanceModified() {
      return this.actActivity.attendance_view.every(modified => {
        return this.attendance_view_original.some(original => {
          return original.student_user_id === modified.student_user_id
        })
      })
    },

    /**
     * 
     */
    attendanceProps() {
      return {
        items: ActActivityFields.toAttendance(),
      }
    },

    /**
     * 
     */
    formProps() {
      return {
        items: ActActivityFields.toInscription(),
      }
    },

    /**
     * 
     */
    tableProps() {
      return {
        filters: UsrStudentFilters.toList(this.filter),
        headers: UsrStudentHeaders.toPickerList(),
        service: UsrStudentService.toList()//SortByFirstNames(this.filter),
      }
    }
  },
  data() {
    return {
      actActivity: {
        attendance_view: [],
        can_attendance: false,
      },
      actActivityAttendance: {
        student_user_id: null,
        observation: '',        
      },
      actActivityInscription: {
        activity_quota_id: null,
      },
      attendance_view_original: [],
      loading: false,
      selectedIndex: null,
      dialogs: {
        assistance: false,
        create: false,
      },
      filter: {},
    }
  },
  methods: {

    mapFilter(filterData) {
      //console.log(filterData, 'filterData')
      return {
        ...filterData,
        career: [ filterData.career, filterData.year, filterData.career_by_year ].join(','),
        career_by_year: undefined,
        year: undefined,
      }
    },

    /**
     * 
     */
    getActActivityAttendanceViewStudents() {
      return this.actActivity.attendance_view.map(actActivityAttendance => {
        return actActivityAttendance.student
      })
    },

    /**
     * 
     */
    handleExcelClick() {
      ActActivityService.excelAssistance(this.actActivity)
    },

    /**
     * 
     */
    onClose() {
      this.$toaster('¿Desea cerrar las inscripciones?', 'confirmar', () => {
        return ActActivityService.closeInscriptions(this.actActivity).then(this.onSuccess).then(this.refresh).catch(this.onError)
      })
    },

    /**
     * 
     */
    onOpen() {
      this.$toaster('¿Desea abrir las inscripciones?', 'confirmar', () => {
        return ActActivityService.openInscriptions(this.actActivity).then(this.onSuccess).then(this.refresh).catch(this.onError)        
      })
    },

    /**
     * Refresh the ActActivity list
     */
    refresh() {
      return ActActivityService.model().include('attendance_view.student,quotas.career_rules,quotas.program_rules,quotas.user_rules').find(this.getParam('id')).then(actActivity => {

        //
        this.actActivity = actActivity.reset()
        this.attendance_view_original = this.actActivity.attendance_view || []

        //
        this.formProps.items[0].props['items'] = this.actActivity.quotas.map(rule => {

          //
          let text = 'Cuota publica'

          //
          if (rule.career_rules.length) {
            text = 'Cuota de carreras'
          }

          //
          if (rule.program_rules.length) {
            text = 'Cuota de programas'
          }

          //
          if (rule.user_rules.length) {
            text = 'Cuota de usuarios'
          }

          //
          return {
            ...rule,
            text: `${ text } - ${ rule.student_quota } cupos`,
            value: rule.id,
          }
        })

        //        
        this.actActivity.sortAttendance()
      })
    },

    /**
     * 
     */
    reset() {
      this.toWaitCard(true).then(this.refresh).then(this.toWaitCard)
    },

    /**
     * 
     */
    setTableSelected(arr = []) {
      this.getRef('table').selected = arr
    },

    /**
     * Show a delete ActActivity confirm toast
     */
    submit() {
      this.toWaitCard(true).then(this.toEnrollStudent).then(this.toWaitCard)
    },

    /**
     * { function_description }
     *
     * @param      {<type>}  attendance  The attendance
     */
    submitAttendance(attendance) {
      this.actActivityAttendance = {
        activity_attendance_status_id: attendance.activity_attendance_status_id,
        student_user_id: attendance.student_user_id,
        observation: attendance.observation,
      }
    },

    /**
     * 
     */
    submitItem(index) {
      this.selectedIndex = index
    },

    /**
     * 
     */
    submitDelete() {
      this.$toaster('¿Confirma la eliminación de la inscripción seleccionada?', 'confirmar', this.toDelete)
    },

    /**
     * Show a delete ActActivity confirm toast
     */
    submitEnrollStudent() {

      // selected student users
      let users = this.getRef('table').getSelected().map(user => {
        return {
          activity_quota_id: this.actActivityInscription.activity_quota_id,
          is_new: true,
          student: user,
          user,
        }
      })
      .filter(inscription => {
        return this.actActivity.attendance_view.every(attendance => {
          return attendance.student.id !== inscription.student.id
        })
      })

      // set selected students
      this.actActivity.attendance_view = [
        ...this.actActivity.attendance_view,
        ...users,
      ]

      // sort students
      this.actActivity.sortAttendance()
    },

    /**
     * { function_description }
     */
    submitTakeStudent() {

      // set the activity attendance data
      this.actActivity.attendance = [
        this.actActivityAttendance,
      ]

      // execute the take attendance promise
      this.toWaitAssistance(true).then(this.toTakeAssistance).then(this.toWaitAssistance)
    },

    /**
     * 
     */
    toDelete() {
      this.actActivity.attendance_view = [
        ...this.actActivity.attendance_view.slice(0, this.selectedIndex),
        ...this.actActivity.attendance_view.slice(this.selectedIndex + 1),
      ]
      this.$toast.success('Eliminado.')
    },

    /**
     * Return a delete ActActivity promise.
     * @param  {ActActivityModel}  model - the ActActivity model
     * @return {Promise} A delete process.
     */
    toEnrollStudent() {
      return ActActivityService.enrollStudent(this.actActivity).then(this.toSuccessEnrollStudent).catch(this.toError)
    },

    /**
     * The delete ActActivity error process.
     * @param  {Object}  event - the error delete payload
     */
    toError(event) {
      this.getErrorMessage(event).then(this.getErrorToast)      
    },

    /**
     * The delete ActActivity success process.
     * @param  {Object}  event - the success delete payload
     */
    toSuccess(event) {
      this.getSuccessMessage(event).then(this.getSuccessToast).then(this.refresh)    
    },

    /**
     * The delete ActActivity success process.
     * @param  {Object}  event - the success delete payload
     */
    toSuccessEnrollStudent(event) {
      Promise.resolve('Estudiantes agregados exitosamente.').then(this.getSuccessToast).then(this.refresh)    
    },

    /**
     * Returns a take assistance promise request.
     *
     * @return     {Promise}  Take assistance request.
     */
    toTakeAssistance() {
      return ActActivityService.takeAssistance(this.actActivity).then(this.toSuccess).catch(this.toError)
    },

    /**
     * { function_description }
     *
     * @param      {boolean}  [value=false]  The value
     */
    toWaitAssistance(value = false) {
      return this.getRef('assistance').wait(value)
    },

    /**
     * 
     */
    async toWaitCard(value = false) {
      this.loading = value
    },

    /**
     * 
     */
    getSelectedActivityQuota() {
      return this.actActivity.quotas.find(quota => {
        return quota.id == this.actActivityInscription.activity_quota_id
      })
    },

    /**
     * 
     */
    getFilteredList() {
      let actActivityQuotaRule = this.getSelectedActivityQuota()
      if (actActivityQuotaRule.career_rules.length) {
        this.getFilteredListFromCareer(actActivityQuotaRule.career_rules)
      }
      else if (actActivityQuotaRule.program_rules.length) {
        this.getFilteredListFromProgram(actActivityQuotaRule.program_rules)
      }
      else if (actActivityQuotaRule.user_rules.length) {
        this.getFilteredListFromUser(actActivityQuotaRule.user_rules)
      } else {
        this.filter = {}
      }
    },

    /**
     * 
     */
    getFilteredListFromCareer(rules = []) {
      let careers = []
      rules.forEach(rule => {
        let id = rule.university_career_id
        let year = rule.university_career_year
        careers.push(`${id},${year},eq`)
      })
      this.filter = {
        career: careers.join(',')
      }
    },

    /**
     * 
     */
    getFilteredListFromProgram(rules = []) {
      let programs = []
      rules.forEach(rule => {
        let id = rule.university_program_id
        programs.push(id)
      })
      this.filter = {
        programs: programs.join(',')
      }
    },

    /**
     * 
     */
    getFilteredListFromUser(rules = []) {
      let users = []
      rules.forEach(rule => {
        let id = rule.student_user_id
        users.push(id)
      })
      this.filter = {
        search: users.join(',')
      }
    },
  },
  watch: {

    /**
     * 
     */
    'dialogs.create'() {
      setTimeout(() => {
        this.setTableSelected(this.getActActivityAttendanceViewStudents())
      }, 250)
    },

    'actActivityInscription.activity_quota_id'(actActivityQuotaRule) {
      this.getFilteredList()
    },
  },
  mounted() {
    this.reset()
  }
}