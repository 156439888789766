// required modules
import Model from '@/models/Model'

/**
 * This class describes a AEnableSchedule model.
 *
 * @class AEnableSchedule (name)
 */
export default class AEnableScheduleModel extends Model {

  /**
   * Define the AEnableSchedule resource.
   *
   * @return {String} AEnableSchedule endpoint name.
   */
  resource() {
    return 'enable_schedule'
  }
}