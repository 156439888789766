// required modules
import VwLayoutCalendar from 'vuetify2-wrappers/src/components/layouts/calendar'

// component
export default {
  extends: VwLayoutCalendar,
  methods: {

    /**
     * 
     */
     onClickNext() {
      this.$emit('click:next')
      this.$refs.calendar.next()
    },

    /**
     * 
     */
    onClickPrev() {
      this.$emit('click:prev')
      this.$refs.calendar.prev()
    },
  },
  props: {

    /**
     * 
     */
    itemsProps: {
      type: Object,
      default: () => ({
        class: 'ml-auto',
      }),
    },

    /**
     * 
     */
    progressProps: {
      type: Object,
      default: () => ({
        color: 'secondary',
        indeterminate: true,
      }),
    },

    /**
     * 
     */
    sheetProps: {
      type: Object,
      default: () => ({
        elevation: 0,
      }),
    },

    /**
     * 
     */
    toolbarProps: {
      type: Object,
      default: () => ({
        dense: true,
        flat: true,
      }),
    },
  }
}