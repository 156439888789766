// required modules
import { Fields } from 'collection-wrapper'
import StaAttentionTypeService from '@/services/api/StudentAttention/StaAttentionType'
import UsrUniversityPeriodService from '@/services/api/User/UsrUniversityPeriod'
import UsrFacilitatorService from '@/services/api/User/UsrFacilitator'
import UsrUniversityCampusService from '@/services/api/User/UsrUniversityCampus'

/**
 * This class describes a StaAttention filters collection.
 * @class StaAttentionFilters (name)
 */
export default class StaAttentionFilters extends Fields {

  /**
   * Returns the StaAttention filters options.
   * @returns {Array} filters options.
   */
  fields() {
    return [
      {
        name: 'university_period_id',
        type: 'select-model',
        info: 'Tipo de atención que posee el facilitador.',
        props: {
          // autoSelectFirst: true,
          fetch: UsrUniversityPeriodService.toList(),
          selectProps: {
            clearable: true,
            itemText: 'identifier',
            itemValue: 'id',
            label: 'Semestre',
            menuProps: {
              contentClass: 'elevation-2 mt-2 v-list--dense',
            },
            placeholder: 'Semestre',
          },
        },
      },
      {
        name: 'facilitator_user_id',
        type: 'autocomplete-model',
        info: 'Tipo de atención que posee el facilitador.',
        props: {
          fetch: UsrFacilitatorService.toList(),
          autocompleteProps: {
            clearable: true,
            itemText: 'full_name',
            itemValue: 'id',
            label: 'Facilitador',
            menuProps: {
              contentClass: 'elevation-2 mt-2 v-list--dense',
            },
            placeholder: 'Facilitador',
          },
        },
      },
      {
        name: 'attention_type_id',
        type: 'select-model',
        info: 'Tipo de atención que posee el facilitador.',
        props: {
          fetch: StaAttentionTypeService.toList(),
          selectProps: {
            itemText: 'description',
            itemValue: 'id',
            label: 'Tipo de atención',
            menuProps: {
              contentClass: 'elevation-2 mt-2 v-list--dense',
            },
            placeholder: 'Tipo de atención',
            // rules: [ruleRequired],
          },
        },
      },
      {
        name: 'university_campus_id',
        type: 'autocomplete-model',
        props: {
          clearable: true,
          fetch: UsrUniversityCampusService.toList(),
          limit: 15,
          autocompleteProps: {
            clearable: true,
            itemText: 'description',
            itemValue: 'id',
            label: 'Sede',
            multiple: false,
            placeholder: 'Sede',
          },
        },
      },
    ]
  }

  /**
   * Returns the StaAttention list filters.
   * @returns {Collection} filters collection.
   */
  static toList(props = {}) {

    //
    if (props.isAdministrator) {

      //
      return this.reduce('name', [
        'university_period_id',
        'facilitator_user_id',
        'attention_type_id',
        'university_campus_id',
      ])
    }

    //
    return this.reduce('name', [
      'university_period_id',
      'attention_type_id',
    ])
  }
}
