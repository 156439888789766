//
import Vue from 'vue'
import Vuetify, { VSnackbar, VBtn, VIcon } from 'vuetify/lib'
import vuetifyToast from 'vuetify-toast-snackbar'
import es from 'vuetify/es5/locale/es'

//
// import RLayoutForm from '@/components/layouts/LayoutForm'

/**
 * { constant_description }
 *
 * @type       {<type>}
 */
const vuetifyOptions = {
  components: {
    VSnackbar,
    VBtn,
    VIcon,
  },
}

/**
 * { constant_description }
 *
 * @type       {<type>}
 */
const vuetifyParams = {
  theme: {
    themes: {
      light: {//1A237E//00BCD4//4CAF50//2E7D32//23415b//7D9CC0//143a58
        primary: '#143a58',
        //primary: '#3f51b5',
        //primary: '#212121',
        //primary: '#006064',
        //primary: '#2E7D32',
        secondary: '#B86125',
        accent: '#8c9eff',
        error: '#b71c1c',
      },
    },
  },
  lang: {
    locales: { es },
    current: 'es',
  },
}

//
const vueObj = new Vuetify({});

/**
 * { constant_description }
 *
 * @type       {<type>}
 */
const vuetifyToastOptions = {
  $vuetify: vueObj.framework,
  shorts: {
    error: {
      timeout: 30000,
      color: 'error',
      icon: 'mdi-alert-circle',
      multiLine: true,
      closeText: 'cerrar',
      showClose: true,  
      x: 'center',
    },
    info: {
      icon: 'mdi-information',
      color: 'info',
      closeText: 'cerrar',
      showClose: true,
      x: 'center',
    },
    wait: {
      icon: 'mdi-timelapse',
      color: 'grey darken-4',
      closeText: 'cerrar',
      showClose: true,
      x: 'center',
    },
    success: {
      icon: 'mdi-check',
      color: 'success darken-1',
      closeText: 'cerrar',
      showClose: true,
      x: 'center',
    },
  },
}

/**
 * { constant_description }
 *
 * @type       {<type>}
 */
const vuetifyToaster = {
  install: function (Vue, options) {
    Vue.mixin({
      components: {
        // RLayoutForm,
      },
      methods: {
        $toaster(text, confirmText, onclick) {
          return this.$toast(text, {
            classes: 'v-snack--confirm',
            closeText: 'cancelar',
            color: 'warning',
            dismissible: true,
            icon: 'mdi-alert',
            showClose: true,
            slot: [this.$createElement('button', { class: 'v-btn warning lighten-1 ml-4 pa-2', on: { click: onclick } }, [confirmText])],
            // timeout: 15000,
            x: 'center'
          })
        },
      }
    })
  },
}

//
Vue.use(Vuetify, vuetifyOptions)
Vue.use(vuetifyToast, vuetifyToastOptions)
Vue.use(vuetifyToaster)

//
export default new Vuetify(vuetifyParams)
