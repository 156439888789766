// model
import Model from '@/models/Model'

/**
 * This class describes a AEnrollStudent model.
 * @class AEnrollStudent (name)
 */
export default class AEnrollStudentModel extends Model {

  /**
   * Define the AEnrollStudent resource.
   * @return {String} AEnrollStudent endpoint name.
   */
  resource() {
    return 'enroll_student'
  }
}