// model
import Model from '@/models/Model'

/**
 * This class describes a AEnroll model.
 * @class AEnroll (name)
 */
export default class AEnrollModel extends Model {

  /**
   * Define the AEnroll resource.
   * @return {String} AEnroll endpoint name.
   */
  resource() {
    return 'enroll'
  }
}