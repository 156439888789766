// required modules
import Service from '@/services/api/Service'
import UsrClassroomModel from '@/models/User/UsrClassroom'

/**
 * This class describes a UsrClassroom service.
 * @class
 */
export default class UsrClassroomService extends Service {

  /**
   * Define the UsrClassroom model.
   * @return {Function} UsrClassroom resource.
   */
  static model() {
    return UsrClassroomModel
  }

  /**
   * 
   */
  static get store_university_campus_id() {
    return this.store().getters.userUniversityCampusId
  }

  /**
   * 
   */
  static toListAllByCurrentUniversityCampus() {
    return () => this.model().where('university_campus_id', this.store_university_campus_id).params({ get_all_results: 1 }).get().then(res => ({
      data: res,
    }))
  }
}