// model
import Model from '@/models/Model'

/**
 * This class describes a ASendNotificationToFacilitator model.
 * 
 * @class ASendNotificationToFacilitator (name)
 */
export default class ASendNotificationToFacilitatorModel extends Model {

  /**
   * Define the ASendNotificationToFacilitator resource.
   * 
   * @return {String} ASendNotificationToFacilitator endpoint name.
   */
  resource() {
    return 'send_notification_to_facilitator'
  }
}