// required modules
import Service from '@/services/api/Service'
import TtsTutorModel from '@/models/Tutorship/TtsTutor'

/**
 * This class describes a TtsTutor service.
 * @class
 */
export default class TtsTutorService extends Service {

  /**
   * Define the TtsTutor model.
   * @return {Function} TtsTutor resource.
   */
  static model() {
    return TtsTutorModel
  }

  /**
   * 
   */
  static toList() {
    return this.fetcher({
      includes: [
        'career'
      ],
    })
  }

  /**
   * Returns the user upload promise
   * 
   * @param {TtsTutorModel} ttsTutor 
   * @returns {Promise} upload promise
   */
  static upload(ttsTutor) {

    //
    const uploadData = ttsTutor.toUpload()

    //
    return ttsTutor.request({
      method: 'post',
      url: `${ ttsTutor.fullURL() }upload`,
      data: uploadData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }
}