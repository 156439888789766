// required modules
import Model from '@/models/Model'
import SrvSurveySectionModel from '@/models/Survey/SrvSurveySection'
import SrvSurveyModel from '@/models/Survey/SrvSurvey'

/**
 * This class describes a SrvSurveyTemplate model.
 * @class
 */
export default class SrvSurveyTemplateModel extends Model {

  /**
   * 
   */
  answers = {}

  /**
   * 
   */
  get blocks() {
    return this.getProperty('sections', []).reduce(this.reduceSectionToBlock, [])
  }

  /**
   * 
   */
  get can_delete() {
    return this.prop('removable', false)
  }

  /**
   * 
   */
  get description_prevent() {
    return this.prop('description', '').trim().length ? this.description : ''
  }

  /**
   * 
   */
  initAnswers = () => {
    this.answers = this.prop('sections', []).reduce(this.reduceSectionToBlock, []).reduce(this.reduceBlocksToAnswers, {})
  }

  /**
   * 
   */
  get limit_date_prevent() {
    return this.prop('limit_date', null) || 'Sin fecha límite.'
  }

  /**
   * 
   */
  mapBlockResponse = srvSurveyBlock => {
    return srvSurveyBlock.toUpdate()
  }

  /**
   * 
   */
  mapSection(srvSurveySection) {
    return SrvSurveySectionModel.from(srvSurveySection).reset()
  }

  /**
   * 
   */
  mapSectionRequest = srvSurveySection => {
    return {
      title: srvSurveySection.title,
      description: srvSurveySection.description,
      blocks: srvSurveySection.blocks.map(this.mapBlockResponse),
    }
  }

  /**
   * 
   */
  reduceBlocksToAnswers = (answers, item) => {
    
    // required data
    let srvSurveyAnswerName   = `block_response_${ item.id }`
    let srvSurveyAnswerValue  = ''

    // change survey answer value
    switch(item.survey_block_identifier_id) {
      case  4: 
      case  5: srvSurveyAnswerValue = []; break;
      case  8: srvSurveyAnswerValue = 1; break;
      case  9: srvSurveyAnswerValue = new Array(item.fields.length).fill(null, 0); break;
      case 10: srvSurveyAnswerValue = new Array(item.fields.length).fill(new Array(item.options.length).fill(true, 0), 0); break;
    }
    
    // return answers
    return {
      ...answers,
      [srvSurveyAnswerName]: srvSurveyAnswerValue,
    }
  }

  /**
   * 
   */
  reduceSectionToBlock = (blocks, section) => {
    return blocks.concat(section.blocks)
  }

  /**
   * 
   */
  reset() {
    this.sections = this.sections.map(this.mapSection)
    return this
  }

  /**
   * Define the SrvSurveyTemplate resource.
   * @return {String} SrvSurveyTemplate endpoint name.
   */
  resource() {
    return 'survey_template'
  }

  /**
   * 
   */
  toSurvey() {
    return SrvSurveyModel.from(this).reset()
  }

  /**
   * 
   */
  toCreate() {

    //
    var clone         = this.clone()
    var cloneSections = clone.sections.map(this.mapSectionRequest)

    //
    clone.sections    = cloneSections

    //
    return clone.reduce([
      'title',
      'is_required',
      'description',
      'sections',
    ])
  }

  /**
   * 
   */
  toUpdate() {

    //
    var clone               = this.clone()
    var cloneIsRequired     = clone.prop('is_required', false)
    var cloneSections       = clone.sections.map(this.mapSectionRequest)

    //
    clone.sections        = cloneSections
    clone.is_required     = cloneIsRequired

    //
    return clone.reduce([
      'id',
      'title',
      'is_required',
      'description',
      'sections',
    ])
  }
}