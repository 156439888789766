// components
import SrvSurveyBlockFields from '@/views/Survey/SrvSurveyBlock/fields'
import SrvSurveyBlockService from '@/services/api/Survey/SrvSurveyBlock'
import SrvSurveyTemplateFields from '@/views/Survey/SrvSurveyTemplate/fields'
import SrvSurveyTemplateService from '@/services/api/Survey/SrvSurveyTemplate'
import SrvSurveySectionFields from '@/views/Survey/SrvSurveySection/fields'
import SrvSurveySectionService from '@/services/api/Survey/SrvSurveySection'

// component
export default {
  name: 'srv-survey-template-update',
  computed: {

    /**
     * Gets the SrvSurveyBlock create form props.
     * @return {Object} The create form props.
     */
    createBlockFormProps() {
      return {
        items: SrvSurveyBlockFields.toCreate(this.srvSurveyBlock),
      }
    },

    /**
     * Gets the SrvSurveyBlock create form props.
     * @return {Object} The create form props.
     */
    updateBlockFormProps() {
      return {
        items: SrvSurveyBlockFields.toUpdate(this.srvSurveyBlockSelected),
      }
    },

    /**
     * Gets the SrvSurvey update form props.
     * @return {Object} The update form props.
     */
    formProps() {
      return {
        items: SrvSurveyTemplateFields.toUpdate(),
      }
    },   

    /**
     * Gets the SrvSurveySection create form props.
     * @return {Object} The create form props.
     */
    sectionFormProps() {
      return {
        items: SrvSurveySectionFields.toCreate(),
      }
    },
  },
  data() {
    return {
      dialogs: {
        createBlock: false,
        createSection: false,
        createSelectedBlock: false,
        updateSection: false,
        updateBlock: false,
      },
      srvSurveyTemplate: {},
      srvSurveyBlock: SrvSurveyBlockService.toCreate(),
      srvSurveyBlockSelected: {},
      srvSurveySection: SrvSurveySectionService.toCreate(),
      srvSurveySectionSelected: {},
    }
  },
  methods: {

    /**
     * Go to SrvSurvey answers view.
     */
    onClickAnswers() {
      this.goRoute('srv-survey-template-answers', { id: this.getParam('id') })
    },

    /**
     * Go to SrvSurvey preview view.
     */
    onClickPreview() {
      this.goRoute('srv-survey-template-preview', { id: this.getParam('id') })
    },

    /**
     * Go to SrvSurvey target view.
     */
    onClickTarget() {
      this.goRoute('srv-survey-template-target', { id: this.getParam('id') })
    },

    /**
     * Returns a submit SrvSurveyBlock create form.
     * @return {Promise} A form submit process.
     */
    onCreateBlock() {
      return this.toWaitCreateBlock(true).then(this.toCreateBlock).then(this.toWaitCreateBlock)
    },

    /**
     * Returns a submit SrvSurveySection create form.
     * @return {Promise} A form submit process.
     */
    onCreateSection() {
      return this.toWaitCreateSection(true).then(this.toCreateSection).then(this.toWaitCreateSection)
    },

    /**
     * 
     */
    onCreateSelectedBlock() {
      return this.toWaitCreateSelectedBlock(true).then(this.toCreateSelectedBlock).then(this.toWaitCreateSelectedBlock)
    },

    /**
     * 
     */
    onDeleteBlock(event) {
      this.srvSurveySection.blocks.splice(this.srvSurveySection.blocks.findIndex(item => item.id === event.item.id), 1)
    },

    /**
     * 
     */
    onDeleteSection(event) {
      this.srvSurveyTemplate.sections.splice(this.srvSurveyTemplate.sections.findIndex(item => item.id === event.item.id), 1)
    },

    /**
     * 
     */
    onDeleteSelectedBlock(event) {
      this.srvSurveySectionSelected.blocks.splice(this.srvSurveySectionSelected.blocks.findIndex(item => item.id === event.item.id), 1)
    },

    /**
     * Returns an update SrvSurvey success process.
     * @param  {Object}  event - the event success payload.
     */
    onSuccessUpdate(event) {
      this.onSuccess(event)
    },

    /**
     * Returns a reset SrvSurvey form.
     * @return {Promise} A form reset promise.
     */
    onReset() {
      return this.toWait(true).then(this.toRefresh).then(this.toWait)
    },

    /**
     * Reset the SrvSurvey data.
     */
    onResetBlock() {
      this.srvSurveyBlock = SrvSurveyBlockService.toCreate()
    },

    /**
     * Reset the SrvSurvey data.
     */
    onResetSection() {
      this.srvSurveySection = SrvSurveySectionService.toCreate()
    },

    /**
     * Returns a submit SrvSurvey form.
     * @return {Promise} A form submit promise.
     */
    onSubmit() {
      return this.toWait(true).then(this.toUpdate).then(this.toWait)
    },

    /**
     * 
     */
    onUpdateSection(event) {
      this.srvSurveySectionSelected = event.item
      this.dialogs.updateSection = true
    },

    /**
     * 
     */
    onUpdateSelectedBlock(event) {
      this.srvSurveyBlockSelected = event.item
      this.dialogs.updateBlock = true
    },

    /**
     * Returns an create SrvSurvey process.
     * @return {Promise} A create process.
     */
    toCreateBlock() {
      console.log("this.srvSurveyBlock.survey_block_type_id",this.srvSurveyBlock.survey_block_type_id)
      if (this.srvSurveyBlock.survey_block_type_id == 1) {
        this.srvSurveyBlock.survey_block_identifier_id = 1
      }
      this.srvSurveySection.blocks.push(this.srvSurveyBlock.toTimeStampID())
      this.dialogs.createBlock = false
      this.onResetBlock()
    },

    /**
     * Returns an create SrvSurvey process.
     *
     * @return {Promise} A create process.
     */
    toCreateSection() {
      this.srvSurveyTemplate.sections.push(this.srvSurveySection.toTimeStampID())
      this.dialogs.createSection = false
      this.onResetSection()
    },

    toCreateSelectedBlock() {
      console.log("this.srvSurveyBlock.survey_block_type_id",this.srvSurveyBlock.survey_block_type_id)
      if (this.srvSurveyBlock.survey_block_type_id == 1) {
        this.srvSurveyBlock.survey_block_identifier_id = 1
      }
      this.srvSurveySectionSelected.blocks.push(this.srvSurveyBlock.toTimeStampID())
      this.dialogs.createSelectedBlock = false
      this.onResetBlock()
    },

    /**
     * Returns a refresh SrvSurvey form.
     * @return {Promise} A reset promise.
     */
    toRefresh() {
      return SrvSurveyTemplateService.toUpdate().find(this.getParam('id')).then(srvSurveyTemplate => {

        //
        this.srvSurveyTemplate = srvSurveyTemplate.reset()

        //
        this.srvSurveyTemplate.init_datetime = srvSurveyTemplate.init_date
        this.srvSurveyTemplate.limit_datetime = srvSurveyTemplate.limit_date
      })
    },

    /**
     * Returns an update SrvSurvey promise.
     * @return {Promise} A update promise.
     */
    toUpdate() {
      return SrvSurveyTemplateService.update(this.srvSurveyTemplate).then(this.onSuccessUpdate).catch(this.onError)
    },

    /**
     * 
     */
    toWaitCreateBlock(val = false) {
      return this.getRef('createBlock').setWait(val)
    },

    /**
     * 
     */
    toWaitCreateSection(val = false) {
      return this.getRef('createSection').setWait(val)
    },

    /**
     * 
     */
    toWaitCreateSelectedBlock(val = false) {
      return this.getRef('createSelectedBlock').setWait(val)
    },
  },
  mounted() {
    this.onReset()
  },
}