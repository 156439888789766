// required modules
import { Headers } from 'collection-wrapper'

/**
 * This class describes a StaAttentionStudentHistory headers collection.
 *
 * @class StaAttentionStudentHistoryHeaders (name)
 */
export default class StaAttentionStudentHistoryHeaders extends Headers {

  /**
   * Returns the StaAttentionStudentHistory headers options.
   *
   * @returns {Array} headers options.
   */
  headers() {
    return [
      {
        text: 'Tipo de atención',
        value: 'attention_type_description',
      },
      {
        text: 'Fecha',
        value: 'attention_datetime',
      },
      {
        text: 'Facilitador',
        value: 'facilitator_full_name',
      },
      {
        text: 'Estado',
        value: 'status_description',
      },
      {
        text: 'Observación',
        value: 'post_attention_observation',
      },
    ]
  }

  /**
   * Returns the StaAttentionStudentHistory list headers.
   *
   * @returns {Collection} headers collection.
   */
  static toList() {
    return this.reduce('value', [
      'attention_type_description',
      'attention_datetime',
      'facilitator_full_name',
      'status_description',
      'post_attention_observation',
    ])
  }
}
