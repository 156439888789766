// required modules
import Service from '@/services/api/Service'
import BlgWebNewModel from '@/models/Blog/BlgWebNew'

/**
 * This class describes a BlgWebNew service.
 * @class
 */
export default class BlgWebNewService extends Service {

  /**
   * Define the BlgWebNew model.
   * @return {Function} BlgWebNew resource.
   */
  static model() {
    return BlgWebNewModel
  }

  /**
   * 
   */
  static publish(blgWebNew) {
    return blgWebNew.publish().attach({})
  }

  /**
   * 
   */
  static uploadImage(blgWebNew) {
    return blgWebNew.uploadImage().attach(blgWebNew.toUploadImage())
  }
}