// required modules
import UsrUniversityPeriodFilters from '@/views/User/UsrUniversityPeriod/filters'
import UsrUniversityPeriodHeaders from '@/views/User/UsrUniversityPeriod/headers'
import UsrUniversityPeriodService from '@/services/api/User/UsrUniversityPeriod'

// component
export default {
  name: 'usr-university-period-list',
  computed: {

    /**
     *
     */
    tableProps() {
      return {
        filters: UsrUniversityPeriodFilters.toList(),
        headers: UsrUniversityPeriodHeaders.toList(),
        service: UsrUniversityPeriodService.toList(),
      }
    }
  },
  methods: {

    /**
     * Set the selected UsrUniversityPeriod table.
     */
    onClickActivate(event) {
      UsrUniversityPeriodService.activate(event.item).then(this.onSuccessActivate).catch(this.onError)
    },

    /**
     * Set the selected UsrUniversityPeriod table.
     */
    onClickCreate() {
      this.goRoute('usr-university-period-create')
    },

    /**
     * Show a delete UsrUniversityPeriod confirm toast.
     */
    onClickDelete(event) {
      this.$toaster('¿Confirma la eliminación del semestre seleccionado?', 'confirmar', () => this.toDelete())
      this.selected = event.item
    },

    /**
     * Set the selected UsrUniversityPeriod table.
     *
     * @param  {Object}  event - the click item payload.
     */
    onClickUpdate(event) {
      this.goRoute('usr-university-period-update', { id: event.item.id })
    },

    /**
     * The delete UsrUniversityPeriod error process.
     *
     * @param  {Object}  event - the error delete payload.
     */
    onError(event) {
      this.getErrorMessage(event).then(this.getErrorToast)      
    },

    /**
     * Called when UsrUniversityPeriod::Activate success.
     * @param {*} event 
     */
    onSuccessActivate(event) {
      this.onSuccess(event)
      this.toRefresh()
    },

    /**
     * The delete UsrUniversityPeriod success process.
     *
     * @param  {Object}  event - the success delete payload.
     */
    onSuccessDelete(event) {
      this.getSuccessMessage(event).then(this.getSuccessToast).then(this.toRefresh)    
    },

    /**
     * Return a delete UsrUniversityPeriod promise.
     *
     * @param  {UsrUniversityPeriodModel}  model - the UsrUniversityPeriod model.
     * @return {Promise} A delete process.
     */
    toDelete() {
      return UsrUniversityPeriodService.delete(this.selected).then(this.onSuccessDelete).catch(this.onError)
    },

    /**
     * Refresh the UsrUniversityPeriod list.
     */
    toRefresh() {
      this.getRef('table').refresh()
    },
  },
}