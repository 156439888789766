// required modules
import Model from '@/models/Model'

/**
 * This class describes a APublish model.
 * @class
 */
export default class APublishModel extends Model {

  /**
   * Define the APublish resource.
   * @return {String} APublish endpoint name.
   */
  resource() {
    return 'publish'
  }
}