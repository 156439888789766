//
// import RLayoutTableNew from '@/components/layouts/LayoutTable'
//
import UsrStudentFields from '@/views/User/UsrStudent/fields'
import UsrStudentFilters from '@/views/User/UsrStudent/filters'
import UsrStudentHeaders from '@/views/User/UsrStudent/headers'
import UsrStudentService from '@/services/api/User/UsrStudent'

//
export default {
  components: {
    // RLayoutTableNew,
  },
  computed: {

    /**
     * 
     */
    isAdministrator: self => self.$store.getters.isAdministrator,

    /**
     * 
     */
    summaryFormProps() {
      return {        
        items: UsrStudentFields.toSummary(),
      }
    },

    /**
     * 
     */
    uploadFormProps() {
      return {        
        items: UsrStudentFields.toUpload(),
      }
    },

    /**
     * 
     */
    tableProps() {
      return {
        filters: UsrStudentFilters.toList(),
        headers: UsrStudentHeaders.toList(),
        service: UsrStudentService.toList(),//SortByFirstNames(),
      }
    },

    /**
     * 
     */
    updateFormProps() {
      return {        
        items: UsrStudentFields.toUpdate(),
      }
    },

    /**
     * 
     */
    updateEmailFormProps() {
      return {        
        items: UsrStudentFields.toUpdateEmail(),
      }
    },
  },
  data() {
    return {
      dialogs: {
        email: false,
        update: false,
        upload: false,
      },

      usrStudent: UsrStudentService.shape({
        file: [],
      }),      
      usrStudentSelected: {
        email: '',
      },      
      usrStudentSummary: {
        registered: 0,
        enabled: 0,
        disabled: 0,
      },
    }
  },
  methods: {
    
    /**
     * 
     */
    mapFilter(filterData) {
      //console.log(filterData, 'filterData')
      return {
        ...filterData,
        career: [ filterData.career, filterData.year, filterData.career_by_year ].join(','),
        career_by_year: undefined,
        year: undefined,
      }
    },

    /**
     * 
     */
    onClickDetail(event) {
      this.goRoute('usr-student-detail', { id: event.item.id })
    },

    /**
     * 
     */
    onClickEmail(event) {
      this.dialogs.email = true
      this.usrStudentSelected = event.item
    },

    /**
     * 
     */
    onClickUpdate(event) {
      this.dialogs.update = true
      this.usrStudentSelected = event.item
    },

    /**
     * 
     */
    onClickUpload() {
      this.dialogs.upload = true
    },

    /**
     * Show a error toast.
     * 
     * @param  {Object}  error - the error payload
     */
    onError(event) {
      this.getErrorMessage(event).then(this.getErrorToast)
    },

    /**
     * 
     */
    onResetUpdate() {
      //this.usrStudentSelected.refresh()
    },

    /**
     * 
     */
    onResetUpdateEmail() {
      //this.usrStudentSelected.refresh()
    },

    /**
     * 
     */
    onResetUpload() {
      this.usrStudent.file = ''
    },

    /**
     * 
     */
    onSubmitUpdate() {
      this.toWaitUpdate(true).then(this.toUpdate).then(this.toWaitUpdate)
    },

    /**
     * 
     */
    onSubmitUpdateEmail() {
      this.toWaitEmail(true).then(this.toUpdateEmail).then(this.toWaitEmail)
    },

    /**
     * 
     */
    onSubmitUpload() {
      this.toWaitUpload(true).then(this.toUpload).then(this.toWaitUpload)
    },

    /**
     * Show a error toast.
     * 
     * @param  {Object}  error - the error payload
     */
    onSuccess(event) {
      this.getSuccessMessage(event).then(this.getSuccessToast)
    },

    /**
     * Show a error toast.
     * 
     * @param  {Object}  error - the error payload
     */
    onSuccessUpload(event) {
      this.dialogs.summary = true
      this.dialogs.upload = false
      this.usrStudentSummary = event.data.summary
      this.onSuccess(event)
    },

    /**
     * 
     */
    onTemplateButtonClick() {
      window.open('/docs/estudiantes_planilla.xlsx', '_blank')
    },

    /**
      * Refresh the UsrStudent list
      */
    toRefresh() {
      this.getRef('table').refresh()
    },

    /**
     * Returns an update UsrStudent promise.
     * 
     * @return {Promise} A update promise.
     */
    toUpdate() {
      return UsrStudentService.update(this.usrStudentSelected).then(this.onSuccess("Datos actualizados exitosamente!")).then(this.toRefresh).catch(this.onError)
    },

    /**
     * Returns an update email UsrStudent promise.
     * 
     * @return {Promise} A update email promise.
     */
    toUpdateEmail() {
      return UsrStudentService.updateEmail(this.usrStudentSelected).then(this.onSuccess).then(this.toRefresh).catch(this.onError)
    },

    /**
     * Returns an upload UsrStudent promise.
     * 
     * @return {Promise} A upload promise.
     */
    toUpload() {
      return UsrStudentService.upload(this.usrStudent).then(this.onSuccessUpload).then(this.toRefresh).catch(console.log)
    },

    /**
     * 
     */
    toWaitEmail(value = false) {
      return this.getRef('email').setWait(value)
    },

    /**
     * 
     */
    toWaitUpdate(value = false) {
      return this.getRef('update').setWait(value)
    },

    /**
     * 
     */
    toWaitUpload(value = false) {
      return this.getRef('upload').setWait(value)
    },
  },
}