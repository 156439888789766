// model
import Model from '@/models/Model'

/**
 * This class describes a ARemoveStudent model.
 * @class ARemoveStudent (name)
 */
export default class ARemoveStudentModel extends Model {

  /**
   * Define the ARemoveStudent resource.
   * @return {String} ARemoveStudent endpoint name.
   */
  resource() {
    return 'remove_student'
  }
}