// required modules
import TtsTutorshipScheduleFilters from '@/views/Tutorship/TtsTutorshipSchedule/filters'
import TtsTutorshipScheduleHeaders from '@/views/Tutorship/TtsTutorshipSchedule/headers'
import TtsTutorshipScheduleService from '@/services/api/Tutorship/TtsTutorshipSchedule'

// component
export default {
  name: 'tts-tutorship-schedule-list',
  computed: {

    /**
     *
     */
    tableProps() {
      return {
        filters: TtsTutorshipScheduleFilters.toList(),
        headers: TtsTutorshipScheduleHeaders.toList(),
        service: TtsTutorshipScheduleService.toList(),
      }
    }
  },
  methods: {

    /**
     * 
     */
    onCalendar() {
      this.goRoute('tts-tutorship-schedule-schedule')
    },

    /**
     * 
     */
    onCreate() {
      this.goRoute('tts-tutorship-schedule-create')
    },

    /**
     * 
     */
    onDelete(event) {
      this.$toaster('¿Confirma la eliminación del horario de tutoría seleccionado?', 'confirmar', () => {
        return this.toDelete(event.item)
      })
    },

    /**
     * 
     */
    onUpdate(event) {
      this.goRoute('tts-tutorship-schedule-update', { id: event.item.id })
    },

    /**
     * The delete TtsTutorshipSchedule success process.
     * @param  {Object}  event - the success delete payload.
     */
    onSuccessDelete(event) {
      this.getSuccessMessage(event).then(this.getSuccessToast).then(this.toRefresh)    
    },

    /**
     * Return a delete TtsTutorshipSchedule promise.
     * @param  {TtsTutorshipScheduleModel}  model - the TtsTutorshipSchedule model.
     * @return {Promise} A delete process.
     */
    toDelete(ttsTutorshipSchedule) {
      return TtsTutorshipScheduleService.delete(ttsTutorshipSchedule).then(this.onSuccessDelete).catch(this.onError)
    },

    /**
     * Refresh the TtsTutorshipSchedule list.
     */
    toRefresh() {
      this.getRef('table').refresh()
    },
  },
}