// required modules
import axios from 'axios'
import { Model } from 'vue-api-query'
// import { Model } from 'model-service-wrapper'

// set json format
axios.defaults.headers.common['Content-Type'] = 'application/json'

// set model http client
Model.$http = axios
