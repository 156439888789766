// required modules
import Service from '@/services/api/Service'
import RptReportModel from '@/models/Report/RptReport'

/**
 * This class describes a RptReport service.
 * @class
 */
export default class RptReportService extends Service {

  /**
   * 
   */
  static export(rptReport) {

    // required export
    const FILE_CONTENT = 'data:text/csv;charset=utf-8,'
    
    // required file data
    const rptReportHeaders  = [rptReport.report_headers]
    const rptReportRows     = rptReport.report_data
    const rptReportFileName = rptReport.report_file_name

    //
    // const rows = [ rptReportHeaders ].concat(rptReportRows)
    const rows = rptReportRows
    
    let csvContent = `${ FILE_CONTENT }`
    
    rows.forEach(function(rowArray) {
      let row = rowArray.join(",");
      csvContent += row + "\r\n";
    });

    //
    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");

    //
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `${ rptReportFileName }.csv`)

    //
    document.body.appendChild(link)
    link.click()
  }

  /**
   * 
   */
  static fetchByKey(rptReportKey, data = {}) {
    // console.log({ data })AAS001
    switch(rptReportKey) {
      case 1  : return this.shape({ id: 'activity' }).quantity().attach(data);
      case 2  : return this.shape({ id: 'activity' }).attendanceLevel().attach(data);
      case 'ASI001': return this.shape({ id: 'activity' }).satisfactionIndicator().attach(data);
      case 'AAP001': return this.shape({ id: 'attention' }).attendancePercentage().attach(data);
      case 'ASQ001': return this.shape({ id: 'attention' }).sessionQuantity().attach(data);
      case 'ASQ002': return this.shape({ id: 'attention' }).studentQuantity().attach(data);
      case 'AAS001': return this.shape({ id: 'attention' }).averageSessions().attach(data);
      case 'AMQ001': return this.shape({ id: 'attention' }).monthlyQuantity().attach(data);
      case 'ARQ001': return this.shape({ id: 'app' }).registeredQuantity().attach(data);
      case 'AUQ001': return this.shape({ id: 'app' }).usageQuantity().attach(data);
      default : return this.fetchByKeyDefault();
    }
  }

  /**
   * Define the RptReport model.
   * @return {Function} RptReport resource.
   */
  static model() {
    return RptReportModel
  }

  /**
   * 
   */
  static async fetchByKeyDefault() {
    return this.shape({
      "report": {
        "total_quantity": 0,
        "detail": []
      }
    })
  }

  /**
   * 
   */
  static report(rawRptReport, filters = {}) {

    // required data
    const { config, data } = rawRptReport

    // return report
    return this.shape({
      ...config,
      ...data,
      filters,
    })
  }

  /**
   * 
   */
  static snapshot(gChart) {

    //
    var link = document.createElement('a')

    //
    link.setAttribute('href', gChart.getImageURI())
    link.setAttribute('download', 'report.png')

    //
    document.body.appendChild(link)
    link.click()
  }
}