// required modules
import ActActivityFields from '@/views/Activity/ActActivity/fields'
import ActActivityService from '@/services/api/Activity/ActActivity'

// component
export default {
  name: 'act-activity-create',
  computed: {

    /**
     * Gets the ActActivity create form props.
     * @return {Object} The create form props.
     */
    formProps() {
      return {
        items: ActActivityFields.toCreate(),
      }
    }
  },
  data() {
    return {
      actActivity: ActActivityService.toCreate(),
    }
  },
  methods: {

    /**
     * Reset the ActActivity data.
     */
    onReset() {
      this.actActivity = ActActivityService.toCreate()
    },

    /**
     * Returns a submit ActActivity create form.
     * @return {Promise} A form submit process.
     */
    onSubmit() {
      return this.toWait(true).then(this.toCreate).then(this.toWait)
    },

    /**
     * Returns an create ActActivity success process.
     * @param  {Object}  event - the event success payload.
     */
    onSuccessCreate(event) {
      this.onSuccess('Actividad creada exitosamente.')
      this.toRedirect(event)
    },

    /**
     * Returns an create ActActivity process.
     * @return {Promise} A create process.
     */
    toCreate() {
      return ActActivityService.create(this.actActivity).then(this.onSuccessCreate).catch(this.onError)
    },

    /**
     * Redirect to ActActivity list
     * @param  {Object}  event - a event success payload.
     */
    toRedirect(event) {
      this.goRoute('act-activity-update', { id: event.id })
    },
  },
}