// required modules
import Model from '@/models/Model'

/**
 * This class describes a TtsTutorshipParallel model.
 * @class TtsTutorshipParallel
 */
export default class TtsTutorshipParallelModel extends Model {

  /**
   * 
   */
  get created_at_short() {

    // required data
    const time = this.prop('created_at', '').split(' ').pop()
    const date = this.prop('created_at', '').split(' ').shift().split('-').reverse().join('-')

    // formatted data
    return `${ date } ${ time }`
  }

  /**
   * Define the TtsTutorshipParallel resource.
   * @return {String} TtsTutorshipParallel endpoint name.
   */
  resource() {
    return 'tutorship_parallel'
  }

  /**
   * 
   */
  toCreate() {
    return this.reduce([
      'description',
      'tutorship_id',
    ])
  }

  /**
   * 
   */
  toUpdate() {
    return this.reduce([
      'description',
      'id',
      'tutorship_id',
    ])
  }
  
  get tutorship_description() {
    return this.prop('tutorship.description')
  }
  
  get tutorship_campus() {
    return this.prop('tutorship.campus.description', '')
  }
}