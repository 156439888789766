// required modules
import RptReportFilters from '@/views/Report/RptReport/filters'
import RptReportHeaders from '@/views/Report/RptReport/headers'
import RptReportService from '@/services/api/Report/RptReport'

// component
export default {
  name: 'rpt-report-list',
  computed: {

    /**
     *
     */
    tableProps() {
      return {
        filters: RptReportFilters.toList(),
        headers: RptReportHeaders.toList(),
        service: RptReportService.from([
          RptReportService.shape({
            id    : 'attention',
            key   : 'ARQ001',
            title : 'Cantidad de estudiantes registrados vs matriculados',
          }),
          RptReportService.shape({
            id    : 'attention',
            key   : 'AUQ001',
            title : 'Cantidad de usos por estudiante (general y por módulo)',
          }),
        ]),
      }
    }
  },
  methods: {

    /**
     * 
     */
    onReport(event) {
      this.goRoute('rpt-report-applications-chart', { key: event.item.key })
    },
  },
}