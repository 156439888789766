// model
import Model from '@/models/Model'

/**
 * This class describes a AAnswer model.
 * @class AAnswer (name)
 */
export default class AAnswerModel extends Model {

  /**
   * Define the AAnswer resource.
   * @return {String} AAnswer endpoint name.
   */
  resource() {
    return 'answer'
  }
}