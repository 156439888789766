// required modules
import Service from '@/services/api/Service'
import TtsTutorshipModel from '@/models/Tutorship/TtsTutorship'

/**
 * This class describes a TtsTutorship service.
 * @class
 */
export default class TtsTutorshipService extends Service {

  /**
   * Define the TtsTutorship model.
   * @return {Function} TtsTutorship resource.
   */
  static model() {
    return TtsTutorshipModel
  }

  /**
   * 
   * @returns 
   */
  static download() {
    
  }

  /**
   * 
   */
  static toList() {
    return this.fetcher({
      includes: [
        'parallels',
      ],
    })
  }

  /**
   * 
   */
  static toListAll() {
    return () => this
      .model()
      .include('parallels')
      .params({ get_all_results: 1 })
      .get()
  }

  /**
   * Returns the user upload promise
   * 
   * @param {TtsTutorshipModel} ttsTutorship
   * @returns {Promise} upload promise
   */
  static upload(ttsTutorship) {

    //
    const uploadData = ttsTutorship.toUpload()

    //
    return ttsTutorship.request({
      method: 'post',
      url: `${ ttsTutorship.fullURL() }upload`,
      data: uploadData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }
}