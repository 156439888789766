// required modules
import NtMailRrssFields from '@/views/Notification/NtMailRrss/fields'
import NtMailRrssService from '@/services/api/Notification/NtMailRrss'

// component
export default {
  name: 'nt-mail-rrss-update',
  computed: {

    /**
      * Gets the NtMailRrss update form props.
      * @return {Object} The update form props.
      */
    formProps() {
      return {
        items: NtMailRrssFields.toUpdate(),
      }
    }
  },
  data() {
    return {
      ntMailRrss: NtMailRrssService.shape({
        value: 'test',
      }),
    }
  },
  methods: {

    /**
     * Returns an update NtMailRrss error process.
     * @param  {Object}  event - the event error payload.
     */
    onError(event) {
      this.getErrorMessage(event).then(this.getErrorToast)
    },

    /**
     * Returns a reset NtMailRrss form.
     * @return {Promise} A form reset promise.
     */
    onReset() {
      return this.toWait(true).then(this.toRefresh).then(this.toWait)
    },

    /**
     * Returns a submit NtMailRrss form.
     * @return {Promise} A form submit promise.
     */
    onSubmit() {
      return this.toWait(true).then(this.toUpdate).then(this.toWait)
    },

    /**
     * Returns an update NtMailRrss success process.
     * @param  {Object}  event - the event success payload.
     */
    onSuccessUpdate(event) {
      this.getSuccessMessage(event).then(this.getSuccessToast).then(this.toRefresh)
    },

    /**
     * Returns a refresh NtMailRrss form.
     * @return {Promise} A reset promise.
     */
    toRefresh() {
      return NtMailRrssService.model().find(this.getParam('id')).then(ntMailRrss => {
        this.ntMailRrss = ntMailRrss
      })
    },

    /**
     * Returns an update NtMailRrss promise.
     * 
     * @param  {Object}  model - the nt-mail-rrss model
     * @return {Promise} A update promise.
     */
    toUpdate(ntMailRrss) {
      return NtMailRrssService.update(this.ntMailRrss).then(this.onSuccessUpdate).catch(this.onError)
    },
  },
  mounted() {
    this.onReset()
  },
}