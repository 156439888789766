// model
import Model from '@/models/Model'
import AActivateModel from '@/models/Actions/AActivate'

/**
 * This class describes a UsrUniversityPeriod model.
 * 
 * @class UsrUniversityPeriod (name)
 */
export default class UsrUniversityPeriodModel extends Model {

  /**
   * 
   */
  activate() {
    return this.hasMany(AActivateModel)
  }

  /**
   * 
   */
  get is_active_description() {
    return this.getProperty('is_active', false) ? 'Activo': 'Inactivo'
  }

  /**
   * 
   */
  get is_active_str() {
    return this.getProperty('is_active', false) ? 'Si': 'No'
  }

  /**
   * 
   */
  get range_date() {
    return `${ this.prop('init_date', '') } - ${ this.prop('end_date', '') }`
  }

  /**
   * Define the UsrUniversityPeriod resource.
   * 
   * @return {String} UsrUniversityPeriod endpoint name.
   */
  resource() {
    return 'university_period'
  }

  /**
   * Return a create role representation.
   * @return {UsrUserModel} UsrUserModel create.
   */
  toCreate() {
    return this.reduce([
      'identifier',
      'init_date',
      'end_date',
    ])
  }

  /**
   * Return a update representation.
   * @return {UsrUserModel} UsrUserModel update.
   */
  toUpdate() {
    return this.reduce([
      'id',
      'identifier',
      'init_date',
      'end_date',
    ])
  }
}