// components
import TtsTutoringNewFields from '@/views/Tutorship/TtsTutoringNew/fields'
import TtsTutoringNewService from '@/services/api/Tutorship/TtsTutoringNew'

// component
export default {
  name: 'tts-tutoring-new-update',
  computed: {

    /**
     * Gets the TtsTutoringNew update form props.
     * @return {Object} The update form props.
     */
    formProps() {
      return {
        items: TtsTutoringNewFields.toUpdate(),
      }
    }
  },
  data() {
    return {
      ttsTutoringNew: {
        value: 'test',
      },
    }
  },
  methods: {

    /**
     * Returns an update TtsTutoringNew success process.
     * @param  {Object}  event - the event success payload.
     */
    onSuccessUpdate(event) {
      this.onSuccess(event)
    },

    /**
     * Returns a reset TtsTutoringNew form.
     * @return {Promise} A form reset promise.
     */
    onReset() {
      return this.toWait(true).then(this.toRefresh).then(this.toWait)
    },

    /**
     * Returns a submit TtsTutoringNew form.
     * @return {Promise} A form submit promise.
     */
    onSubmit() {
      return this.toWait(true).then(this.toUpdate).then(this.toWait)
    },

    /**
     * Returns a refresh TtsTutoringNew form.
     * @return {Promise} A reset promise.
     */
    toRefresh() {
      return TtsTutoringNewService.model().include('body').find(this.getParam('id')).then(ttsTutoringNew => {
        this.ttsTutoringNew = ttsTutoringNew.reset()
      })
    },

    /**
     * Returns an update TtsTutoringNew promise.
     * @return {Promise} A update promise.
     */
    toUpdate() {
      return TtsTutoringNewService.update(this.ttsTutoringNew).then(this.onSuccessUpdate).catch(this.onError)
    },
  },
  mounted() {
    this.onReset()
  },
}