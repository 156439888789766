// layout
import LayoutCenter from '@/components/layouts/LayoutCenter'

// auth views
import AthLoginCreateView from '@/views/Auth/AthLogin/create'
// import AthServiceUpdateView from '@/views/Auth/AthService/update'

// notification views
import NtNotificationAccountView from '@/views/Notification/NtNotification/account'
import NtNotificationPasswordView from '@/views/Notification/NtNotification/password'

// user views
import UsrUserAccountView from '@/views/User/UsrUser/account'
import UsrUserPasswordView from '@/views/User/UsrUser/password'

// routes
export default {
  path: '/auth',
  component: LayoutCenter,
  children: [
    {
      path: '',
      name: 'auth',
      component: AthLoginCreateView,
    },
    {
      path: 'activar',
      name: 'auth-activation',
      component: NtNotificationAccountView,
    },
    {
      path: 'activar_cuenta/:token',
      name: 'auth-activation-account',
      component: UsrUserAccountView,
    },
    {
      path: 'recuperar/:token',
      name: 'auth-recovery',
      component: UsrUserPasswordView,
    },
    {
      path: 'restablecer',
      name: 'auth-reset',
      component: NtNotificationPasswordView,
    },
    // {
    //   path: 'servicio',
    //   name: 'auth-service',
    //   component: AthServiceUpdateView,
    // },
  ],
}
