// required modules
import TtsTutoringNewFilters from '@/views/Tutorship/TtsTutoringNew/filters'
import TtsTutoringNewHeaders from '@/views/Tutorship/TtsTutoringNew/headers'
import TtsTutoringNewService from '@/services/api/Tutorship/TtsTutoringNew'

// component
export default {
  name: 'tts-tutoring-new-list',
  computed: {

    /**
     *
     */
    tableProps() {
      return {
        filters: TtsTutoringNewFilters.toList(),
        headers: TtsTutoringNewHeaders.toList(),
        service: TtsTutoringNewService.toList(),
      }
    }
  },
  methods: {

    /**
     * 
     */
    onCreate() {
      this.goRoute('tts-tutoring-new-create')
    },

    /**
     * 
     */
    onDelete(event) {
      this.$toaster('¿Confirma la eliminación del aviso de tutoría seleccionado?', 'confirmar', () => {
        return this.toDelete(event.item)
      })
    },

    /**
     * 
     */
    onPublish(event) {
      this.$toaster('¿Confirma la publicación del aviso de tutoría seleccionado?', 'confirmar', () => {
        return this.toPublish(event.item)
      })      
    },

    /**
     * 
     */
    onUpdate(event) {
      this.goRoute('tts-tutoring-new-update', { id: event.item.id })
    },

    /**
     * The delete TtsTutoringNew success process.
     * @param  {Object}  event - the success delete payload.
     */
    onSuccessDelete(event) {
      this.getSuccessMessage(event).then(this.getSuccessToast).then(this.toRefresh)    
    },

    /**
     * Return a delete TtsTutoringNew promise.
     * @param  {TtsTutoringNewModel}  model - the TtsTutoringNew model.
     * @return {Promise} A delete process.
     */
    toDelete(ttsTutoringNew) {
      return TtsTutoringNewService.delete(ttsTutoringNew).then(this.onSuccessDelete).catch(this.onError)
    },

    /**
     * Return a publish TtsTutoringNew promise.
     * @param  {TtsTutoringNewModel}  model - the TtsTutoringNew model.
     * @return {Promise} A publish process.
     */
    toPublish(ttsTutoringNew) {
      return TtsTutoringNewService.publish(ttsTutoringNew).then(this.onSuccess).then(this.toRefresh).catch(this.onError)
    },

    /**
     * Refresh the TtsTutoringNew list.
     */
    toRefresh() {
      this.getRef('table').refresh()
    },
  },
}