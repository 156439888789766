// required modules
import NotFound from '../views/NotFound'

// routes
import AppRoutes from './app'
import AuthRoutes from './auth'
import SurveyRoutes from './survey'

// export all routes
export default [
  AppRoutes,
  AuthRoutes,
  SurveyRoutes,
	{
    path: '*',
    name: 'not-found',
    component: NotFound,
  },
]