// required modules
import Model from '@/models/Model'

/**
 * This class describes a RptReportApplicationRegisteredQuantity model.
 * @class RptReportApplicationRegisteredQuantity
 */
export default class RptReportApplicationRegisteredQuantityModel extends Model {

  /**
   * 
   */
  get data() {
    return [ this.headers ].concat(this.rows)
  }

  /**
   * 
   */
  get headers() {
    return [
      "Mes",
      "Estudiantes"
    ]
  }

  /**
   * 
   */
  mapRow(item) {
    return [
      item.month_str,
      item.registered_students
    ]
  }

  /**
   * 
   */
  get options() {
    return {
      chart: {
        subtitle: "Registrados vs Matriculados"
      },
      height: 400,
      title: "Registrados vs Matriculados",
      vAxis: {
        minValue: 0,
        ticks: [ 0, 5, 10 ],
        title: "Total de actividades"
      },
    }
  }

  /**
   * 
   */
  get params() {
    return {
      type: this.type,
      options: this.options,
      data: this.data,
    }
  }

  /**
   * Define the RptReportApplicationRegisteredQuantity resource.
   * @return {String} RptReportApplicationRegisteredQuantity endpoint name.
   */
  resource() {
    return 'report/app/registered_quantity'
  }

  /**
   * 
   */
  reset() {
    this.loading = false
    return this
  }

  /**
   * 
   */
  get rows() {
    return this.prop('report.detail', []).map(this.mapRow)
  }

  /**
   * 
   */
  get type() {
    return 'BarChart'
  }
}