// required modules
import Model from '@/models/Model'

/**
 * This class describes a AAttendanceLevel model.
 *
 * @class AAttendanceLevel (name)
 */
export default class AAttendanceLevelModel extends Model {

  /**
   * Define the AAttendanceLevel resource.
   *
   * @return {String} AAttendanceLevel endpoint name.
   */
  resource() {
    return 'attendance_level'
  }
}