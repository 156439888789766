import { Headers } from 'collection-wrapper'

//
export default class StaAttentionFacilitatorScheduleHeaders extends Headers {

  /**
   * 
   */
  headers() {
    return [
      {
        text: 'Tipo de atención',
        value: 'attention_type_description',
      },
      {
        text: 'Dia de la semana',
        value: 'weekday_str',
      },
      {
        text: 'Facilitador',
        value: 'facilitator_full_name',
      },
      {
        text: 'Inicio',
        value: 'attention_init_time',
      },
      {
        text: 'Fin',
        value: 'attention_end_time',
      },
    ]
  }

  /**
   * 
   */
  static toList() {
    return this.reduce('value', [
      'attention_type_description', 
      'facilitator_full_name', 
      'weekday_str', 
      'attention_init_time', 
      'attention_end_time', 
    ])
  }
}
