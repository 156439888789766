// required modules
import { Headers } from 'collection-wrapper'

/**
 * This class describes a SrvSurvey headers collection.
 * @class SrvSurveyHeaders
 */
export default class SrvSurveyHeaders extends Headers {

  /**
   * Returns the SrvSurvey headers options.
   * @returns {Array} headers options.
   */
  headers() {
    return [
      {
        text: '#',
        value: 'id',
        sortable: 'id',
      },
      {
        text: 'Titulo',
        value: 'title',
        sortable: 'title',
      },
      {
        text: 'Creado',
        value: 'created_at',
        sortable: 'created_at',
        align: 'right',
      },
      {
        text: 'Modificado',
        value: 'updated_at',
        sortable: 'updated_at',
        align: 'right',
      },
    ]
  }

  /**
   * Returns the SrvSurvey list headers.
   * @returns {Collection} headers collection.
   */
  static toList() {
    return this.reduce('value', [
      'id',
      'title',
      'created_at',
      'updated_at',
    ])
  }
}
