// global
import Vue from 'vue'
import vuetify2wrappers from 'vuetify2-wrappers'

// local
import App from './App.vue'
import local from './plugins/app'
import vuetify from './plugins/vuetify'
import vuexStore from './plugins/store'
import router from './router'
import store from './store'

//
import './plugins/axios'

//
Vue.config.productionTip = false

//
Vue.use(local)
Vue.use(vuetify2wrappers)
Vue.use(vuexStore)

/**
 * { var_description }
 *
 * @type       {<type>}
 */
var vueParams = {
  router,
  store,
  vuetify,
  render: h => h(App)
}

//
new Vue(vueParams).$mount('#app')
