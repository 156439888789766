// required modules
import ActActivityFilters from '@/views/Activity/ActActivity/filters'
import ActActivityHeaders from '@/views/Activity/ActActivity/headers'
import ActActivityService from '@/services/api/Activity/ActActivity'
import UsrUniversityPeriodService from '@/services/api/User/UsrUniversityPeriod'

// component
export default {
  name: 'act-activity-list',
  computed: {

    /**
     * 
     */
    filtersProps() {
      return {
        isAdministrator: this.$store.getters.isAdministrator,
      }
    },

    /**
     * 
     */
    tableProps() {
      return {
        filterData: {
          university_period_id: this.usrActiveUniversityPeriodId,
        },
        filters: ActActivityFilters.toList(this.filtersProps),
        headers: ActActivityHeaders.toList(),
        service: ActActivityService.toList(),
      }
    }
  },
  data() {
    return {
      already: false,
      usrActiveUniversityPeriodId: '',
    }
  },
  methods: {

    /**
     * 
     */
    getActiveUniversityPeriod() {
      UsrUniversityPeriodService.getActive().then(res => {
        this.usrActiveUniversityPeriodId = res.id
        this.already = true
      })
    },

    /**
     * Go to ActActivity create
     */
    onCreate() {
      this.goRoute('act-activity-create')
    },

    /**
     * Show a delete ActActivity confirm toast
     * @param  {Object}  event - the click item payload
     */
    onDelete(event) {
      this.$toaster('¿Confirma la eliminación de la actividad seleccionada?', 'confirmar', () => {
        ActActivityService.delete(event.item).then(this.onSuccess).then(this.toRefresh).catch(this.onError)
      })
    },

    /**
     * Go to ActActivity search
     */
    onSearch() {
      this.goRoute('act-activity-search')
    },

    /**
     * Go to ActActivity update
     * @param  {Object}  event - the click item payload
     */
    onUpdate(event) {
      this.goRoute('act-activity-update', { id: event.item.id })
    },

    /**
     * Refresh the ActActivity list
     */
    toRefresh() {
      this.getRef('table').refresh()
    },
  },
  mounted() {
    this.getActiveUniversityPeriod()
  }
}