// layout
import RLayoutModule from '@/components/layouts/LayoutModule'

// views
import StaAttentionFacilitatorScheduleCreateView from '@/views/StudentAttention/StaAttentionFacilitatorSchedule/create'
import StaAttentionFacilitatorScheduleListView from '@/views/StudentAttention/StaAttentionFacilitatorSchedule/list'
import StaAttentionFacilitatorScheduleScheduleView from '@/views/StudentAttention/StaAttentionFacilitatorSchedule/schedule'
import StaAttentionFacilitatorScheduleUpdateView from '@/views/StudentAttention/StaAttentionFacilitatorSchedule/update'

// routes
export default {
  path: 'horarios',
  component: RLayoutModule,
  children: [
    {
      name: 'sta-attention-facilitator-schedule-list',
      path: '',
      component: StaAttentionFacilitatorScheduleListView,
    },
    {
      name: 'sta-attention-facilitator-schedule-create',
      path: 'crear',
      component: StaAttentionFacilitatorScheduleCreateView,
      meta: {
        title: 'Crear Horario'
      },
    },
    {
      name: 'sta-attention-facilitator-schedule-schedule',
      path: 'calendario',
      component: StaAttentionFacilitatorScheduleScheduleView,
      meta: {
        title: 'Calendario'
      },
    },
    {
      name: 'sta-attention-facilitator-schedule-update',
      path: ':id',
      component: StaAttentionFacilitatorScheduleUpdateView,
      meta: {
        title: 'Editar Horario'
      },
    },
  ],
  meta: {
    title: 'Horarios'
  },
  props: {
    indexText: 'Inicio',
  },
}
