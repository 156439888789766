// required modules
import BlgWebNewFilters from '@/views/Blog/BlgWebNew/filters'
import BlgWebNewHeaders from '@/views/Blog/BlgWebNew/headers'
import BlgWebNewService from '@/services/api/Blog/BlgWebNew'

// component
export default {
  name: 'blg-web-new-list',
  computed: {

    /**
     *
     */
    tableProps() {
      return {
        filters: BlgWebNewFilters.toList(),
        headers: BlgWebNewHeaders.toList(),
        service: BlgWebNewService.toList(),
      }
    }
  },
  methods: {

    /**
     * 
     */
    onCreate() {
      this.goRoute('blg-web-new-create')
    },

    /**
     * Show a delete BlgWebNew confirm toast.
     */
    onDelete(event) {
      this.$toaster('¿Confirma la publicación de la noticia seleccionada?', 'confirmar', () => {
        return BlgWebNewService.publish(event.item).then(this.onSuccess).then(this.toRefresh).catch(this.onError)
      })
    },

    /**
     * Show a delete BlgWebNew confirm toast.
     */
    onPublish(event) {
      this.$toaster('¿Confirma la publicación de la noticia seleccionada?', 'confirmar', () => {
        return BlgWebNewService.publish(event.item).then(this.onSuccess).then(this.toRefresh).catch(this.onError)
      })
    },

    /**
     * 
     */
    onUpdate(event) {
      this.goRoute('blg-web-new-update', { id: event.item.id })
    },

    /**
     * Refresh the BlgWebNew list.
     */
    toRefresh() {
      this.getRef('table').refresh()
    },
  },
}