// required components
import UsrUniversityPeriodFields from '@/views/User/UsrUniversityPeriod/fields'
import UsrUniversityPeriodService from '@/services/api/User/UsrUniversityPeriod'

// component
export default {
  name: 'usr-university-period-create',
  computed: {

    /**
     * Gets the UsrUniversityPeriod create form props.
     *
     * @return {Object} The create form props.
     */
    formProps() {
      return {
        items: UsrUniversityPeriodFields.toCreate(),
      }
    }
  },
  data() {
    return {
      usrUniversityPeriod: UsrUniversityPeriodService.shape({
        value: ''
      }),
    }
  },
  methods: {

    /**
     * Reset the UsrUniversityPeriod data.
     */
    onReset() {
      this.usrUniversityPeriod.value = ''
    },

    /**
     * Returns a submit UsrUniversityPeriod create form.
     *
     * @return {Promise} A form submit process.
     */
    onSubmit() {
      return this.toWait(true).then(this.toCreate).then(this.toWait)
    },

    /**
     * Returns an create UsrUniversityPeriod success process.
     *
     * @param  {Object}  event - the event success payload.
     */
    onSuccessCreate(event) {
      this.onSuccess('Semestre creado exitosamente.')
    },

    /**
     * Returns an create UsrUniversityPeriod process.
     *
     * @return {Promise} A create process.
     */
    toCreate() {
      return UsrUniversityPeriodService.create(this.usrUniversityPeriod).then(this.onSuccessCreate).catch(this.onError)
    },

    /**
     * Redirect to UsrUniversityPeriod list
     */
    toRedirect() {
      this.goRoute('usr-university-period-list')
    },
  },
}