// required modules
import { Fields } from 'collection-wrapper'
import UsrUniversityPeriodService from '@/services/api/User/UsrUniversityPeriod'
import UsrFacilitatorService from '@/services/api/User/UsrFacilitator'
import UsrProgramService from '@/services/api/User/UsrProgram'
import UsrCareerService from '@/services/api/User/UsrCareer'

/**
 * This class describes a RptReport fields collection.
 * @class
 */
export default class RptReportFields extends Fields {

  /**
   * Returns the RptReport fields options.
   * @returns {Array} fields options.
   */
  fields() {
    return [
      {
        name: 'report_title',
        type: 'text-field',
        info: 'Titulo del reporte.',
        flex: {
          cols: 12,
        },
        props: {
          label: 'Titulo',
          placeholder: 'Titulo',
          readonly: true,
        },
      },
      {
        name: 'report_subheader',
        type: 'subheader',
        flex: {
          cols: 12,
        },
        props: {
          class: 'font-weight-bold px-0 mb-1',
          style: 'border-bottom: 1px solid #eee;',
        },
        html: 'Filtros',
      },
      {
        name: 'periods',
        type: 'select-model',
        info: 'Lista de Ids de los periodos universitarios (periodos semestrales). Máximo 4 periodos pueden enviarse. (Por defecto el front debiese enviar el periodo activo)',
        props: {
          fetch: UsrUniversityPeriodService.toSelectList(),
          selectProps: {
            itemText: 'identifier',
            itemValue: 'id',
            label: 'Periodos',
            multiple: true,
            placeholder: 'Periodos',
          },
        },
      },
      {
        name: 'cohort',
        type: 'float-field',
        info: 'Año de Cohorte. Ejemplo: Si es 2014 entonces se obtendrá de todas las carreras definidas en los cupos con nivel igual o superior a 2014',
        props: {
          label: 'Cohorte',
          placeholder: 'Cohorte',
        },
      },
      {
        name: 'facilitators',
        type: 'select-model',
        info: 'Lista de Ids de los facilitadores a consultar. Máximo 200 elementos.',
        props: {
          fetch: UsrFacilitatorService.toSelectList(),
          selectProps: {
            itemText: 'full_name',
            itemValue: 'id',
            label: 'Facilitadores',
            multiple: true,
            placeholder: 'Facilitadores',
          },
        },
      },
      {
        name: 'programs',
        type: 'select-model',
        info: 'Lista de Ids de los programas a consultar. Máximo 200 elementos.',
        props: {
          fetch: UsrProgramService.toList(),
          selectProps: {
            itemText: 'description',
            itemValue: 'id',
            label: 'Programas',
            multiple: true,
            placeholder: 'Programas',
          },
        },
      },
      {
        name: 'careers',
        type: 'select-model',
        info: 'Lista de Ids de las carreras a consultar. Máximo 200 elementos.',
        props: {
          fetch: UsrCareerService.toSelectList(),
          selectProps: {
            itemText: 'description',
            itemValue: 'id',
            label: 'Carreras',
            multiple: true,
            placeholder: 'Carreras',
          },
        },
      },
    ]
  }

  /**
   * Returns the RptReport chart fields.
   * @returns {Collection} fields collection.
   */
  static toChart() {
    return this.reduce('name', [
      'report_subheader',
      'periods',
      'cohort',
      'facilitators',
      'programs',
      'careers',
    ])
  }

  /**
   * Returns the RptReport create fields.
   * @returns {Collection} fields collection.
   */
  static toCreate() {
    return this.reduce('name', [
      'value',
    ])
  }

  /**
   * Returns the RptReport update fields.
   * @returns {Collection} fields collection.
   */
  static toUpdate() {
    return this.reduce('name', [
      'url_identifier',
      'report_title',
    ])
  }
}
