//
const signalR = require('@microsoft/signalr')
import store from '@/store'

/**
 *
 */
export default class PushService {

  /**
   * 
   */
  connection = null

  /**
   * 
   */
  baseUrl() {
    return process.env.VUE_APP_MS_ROUTE
  }

  /**
   * 
   */
  connect(token) {

    //
    // console.log({ token })

    // required data
    const baseUrl   = this.baseUrl()
    const Builder   = signalR.HubConnectionBuilder
    const Factory   = this.genFactory(token)
    const LogLevel  = signalR.LogLevel.Information

    // return connection instance
    this.connection = new Builder()
      .withUrl(baseUrl, Factory)
      .configureLogging(LogLevel)
      .build()

    return this.connection
  }

  /**
   * 
   */
  genFactory(token) {
    return {
      accessTokenFactory: () => {
        return `${ token }`
      }
    }
  }

  /**
   * 
   */
  start() {
    return this.connection.start()
  }

  /**
   * 
   */
  on(type, listener) {
    return this.connection.on(type, listener)
  }
}