// required modules
import Service from '@/services/api/Service'
import NtMailRrssLogoModel from '@/models/Notification/NtMailRrssLogo'

/**
 * This class describes a NtMailRrssLogo service.
 * @class
 */
export default class NtMailRrssLogoService extends Service {

  /**
   * Define the NtMailRrssLogo model.
   * @return {Function} NtMailRrssLogo resource.
   */
  static model() {
    return NtMailRrssLogoModel
  }
}