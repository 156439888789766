// service
import Service from '@/services/api/Service'

// model
import UsrFacilitatorModel from '@/models/User/UsrFacilitator'

/**
 * This class describes a UsrFacilitator service.
 * @class UsrFacilitator (name)
 */
export default class UsrFacilitatorService extends Service {

  /**
   * Define the UsrFacilitator model.
   * @return {String} UsrFacilitator resource.
   */
  static model() {
    return UsrFacilitatorModel
  }

  /**
   * 
   */
  static toSelectList() {
    return () => this.model().params({ get_all_results: 1 }).get()
  }
}