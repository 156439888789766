//
import Vue from 'vue'
import Vuex from 'vuex'

//
import AuthModule from '@/store/modules/AuthModule'
import RoleModule from '@/store/modules/RoleModule'
import UserModule from '@/store/modules/UserModule'

//
Vue.use(Vuex)

//
export default new Vuex.Store({
  modules: {
    AuthModule,
    RoleModule,
    UserModule,
  },
  getters: {
    apiKey: (state) => {
      return state.AuthModule.service.apiKey
    },
    apiUrl: (state) => {
      return state.AuthModule.service.apiUrl
    },
    apiAuthKey: (state) => {
      return state.AuthModule.service.apiAuthKey
    },
    apiAuthUrl: (state) => {
      return state.AuthModule.service.apiAuthUrl
    },
    service: (state) => {
      return state.AuthModule.service
    }
  },
})
