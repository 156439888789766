// model
import Model from '@/models/Model'

/**
 * This class describes a ActActivityType model.
 * @class ActActivityType (name)
 */
export default class ActActivityTypeModel extends Model {

  /**
   * Define the ActActivityType resource.
   * @return {String} ActActivityType endpoint name.
   */
  resource() {
    return 'activity_type'
  }
}