// required modules
import { Fields } from 'collection-wrapper'
import UsrUniversityCampusService from '@/services/api/User/UsrUniversityCampus'

/**
 * This class describes a BlgWebNew fields collection.
 * @class BlgWebNewFields (name)
 */
export default class BlgWebNewFields extends Fields {

  /**
   * Returns the BlgWebNew fields options.
   * @returns {Array} fields options.
   */
  fields() {
    return [
      {
        name: 'title',
        type: 'text-field',
        flex: {
          cols: 12,
        },
        props: {
          label: 'Título',
          placeholder: 'Título',
        },
      },
      {
        name: 'short_description',
        type: 'textarea',
        flex: {
          cols: 12,
        },
        props: {
          label: 'Descripción',
          placeholder: 'Descripción',
          rows: 2,
        },
      },
      {
        name: 'body',
        type: 'editor',
        flex: {
          cols: 12,
        },
        props: {
          label: 'Cuerpo',
          placeholder: 'Cuerpo',
        },
      },
      {
        name: 'link_instagram',
        type: 'text-field',
        props: {
          label: 'Enlace de Instagram',
          placeholder: 'Enlace de Instagram',
        },
      },
      {
        name: 'link_twitter',
        type: 'text-field',
        props: {
          label: 'Enlace UCN',
          placeholder: 'Enlace UCN',
        },
      },
      {
        name: 'link_facebook',
        type: 'text-field',
        props: {
          label: 'Enlace de Facebook',
          placeholder: 'Enlace de Facebook',
        },
      },
      {
        name: 'campus_visibilities',
        type: 'autocomplete-model',
        props: {
          clearable: true,
          fetch: UsrUniversityCampusService.toList(),
          limit: 15,
          autocompleteProps: {
            clearable: true,
            itemText: 'description',
            itemValue: 'id',
            label: 'Visibilidad',
            multiple: true,
            placeholder: 'Visibilidad',
          },
        },
      },
      {
        name: 'images',
        type: 'carousel-field',
        flex: {
          cols: 12,
        },
        props: {
          carouselProps: {
            height: 350,
          },
          imageProps: {
            aspectRatio: 1,
          },
          itemSrc: 'url_image_path',
        },
        slots: {
          item: 'images-item'
        }
      },
      {
        name: 'new_image',
        type: 'file-input',
        flex: {
          cols: 12,
        },
        props: {
          class: 'mt-4',
          label: 'Seleccionar nueva imagen',
          placeholder: 'Seleccionar una nueva imagen para subir y agregarla en la noticia',
        },
      },
    ]
  }

  /**
   * Returns the BlgWebNew create fields.
   * @returns {Collection} fields collection.
   */
  static toCreate() {
    return this.reduce('name', [
      'title',
      'short_description',
      'body',
      'link_instagram',
      'link_twitter',
      'link_facebook',
      'campus_visibilities',
    ])
  }

  /**
   * Returns the BlgWebNew image fields.
   * @returns {Collection} fields collection.
   */
  static toImage() {
    return this.reduce('name', [
      'images',
      'new_image',
    ])
  }

  /**
   * Returns the BlgWebNew update fields.
   * @returns {Collection} fields collection.
   */
  static toUpdate() {
    return this.reduce('name', [
      'title',
      'short_description',
      'body',
      'link_instagram',
      'link_twitter',
      'link_facebook',
      'campus_visibilities',
    ])
  }
}
