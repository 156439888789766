// components
import SrvSurveyFields from '@/views/Survey/SrvSurvey/fields'
import SrvSurveyTargetFields from '@/views/Survey/SrvSurveyTarget/fields'
import SrvSurveyService from '@/services/api/Survey/SrvSurvey'
import SrvSurveyTargetService from '@/services/api/Survey/SrvSurveyTarget'

// activity
import ActActivityFilters from '@/views/Activity/ActActivity/filters'
import ActActivityHeaders from '@/views/Activity/ActActivity/headers'
import ActActivityService from '@/services/api/Activity/ActActivity'

// attention
import StaAttentionFilters from '@/views/StudentAttention/StaAttention/filters'
import StaAttentionHeaders from '@/views/StudentAttention/StaAttention/headers'
import StaAttentionService from '@/services/api/StudentAttention/StaAttention'

// career
import UsrCareerFilters from '@/views/User/UsrCareer/filters'
import UsrCareerHeaders from '@/views/User/UsrCareer/headers'
import UsrCareerService from '@/services/api/User/UsrCareer'

// program
import UsrProgramFilters from '@/views/User/UsrProgram/filters'
import UsrProgramHeaders from '@/views/User/UsrProgram/headers'
import UsrProgramService from '@/services/api/User/UsrProgram'

// user
import UsrUserFilters from '@/views/User/UsrUser/filters'
import UsrUserHeaders from '@/views/User/UsrUser/headers'
import UsrUserService from '@/services/api/User/UsrUser'

// component
export default {
  name: 'srv-survey-target',
  computed: {

    /**
     * 
     */
    activityProps() {
      return {
        filters: ActActivityFilters.toList(),
        headers: ActActivityHeaders.toList(),
        service: ActActivityService.toList(),
      }
    },

    /**
     * 
     */
    attentionProps() {
      return {
        filters: StaAttentionFilters.toList(),
        headers: StaAttentionHeaders.toList(),
        service: StaAttentionService.toList(),
      }
    },

    /**
     * 
     */
    careerProps() {
      return {
        filters: UsrCareerFilters.toList(),
        headers: UsrCareerHeaders.toList(),
        service: UsrCareerService.toList(),
      }
    },

    /**
     * 
     */
    createProps() {
      return {
        items: SrvSurveyTargetFields.toCreate(this.srvSurveyTarget),
      }
    },

    /**
     * Gets the SrvSurvey target form props.
     * @return {Object} The target form props.
     */
    formProps() {
      return {
        items: SrvSurveyFields.toTarget(this.srvSurvey),
        noSubmit: !this.srvSurvey.can_targeted,
      }
    },

    /**
     * 
     */
    programProps() {
      return {
        filters: UsrProgramFilters.toList(),
        headers: UsrProgramHeaders.toList(),
        service: UsrProgramService.toList(),
      }
    },

    /**
     * 
     */
    sendProps() {
      return {
        items: SrvSurveyTargetFields.toCreate(this.srvSurveyTarget),
      }
    },

    /**
     * 
     */
    tableProps() {
      switch (this.srvSurveyTarget.name) {
        case 'Actividad': return this.activityProps
        case 'Atención' : return this.attentionProps
        case 'Carrera'  : return this.careerProps
        case 'Programa' : return this.programProps
        case 'Usuario'  : return this.userProps
        default: return {}
      }
    },

    /**
     * 
     */
    updateProps() {
      return {
        items: SrvSurveyTargetFields.toUpdate(this.srvSurveyTarget),
      }
    },

    /**
     * 
     */
    userProps() {
      //AQUI TENGO QUE MANDARLE OTRO VALOR!!!
      if(this.srvSurveyTarget.target_type == 'Estudiantes'){
        return {
          filters: UsrUserFilters.toList(),
          headers: UsrUserHeaders.toTargetList(),
          service: UsrUserService.toListSortByFirstNamesStudent(),
        }
      }else if(this.srvSurveyTarget.target_type == 'Facilitadores'){
        return {
          filters: UsrUserFilters.toList(),
          headers: UsrUserHeaders.toTargetList(),
          service: UsrUserService.toListSortByFirstNamesFacilitator(),
        }
      }
      return {
        filters: UsrUserFilters.toList(),
        headers: UsrUserHeaders.toTargetList(),
        service: UsrUserService.toListSortByFirstNames(),
      }
    },
  },
  data() {
    return {
      dialogs: {
        create: false,
        update: false,
        identifiers: false,
        send: false,
      },
      srvSurvey: {
        targets: [],
      },
      srvSurveyTarget: {
        name: '',
        target_type: '',
        identifiers: [],
        target_identifiers: [],
      },
    }
  },
  methods: {

    /**
     * 
     */
    onClickCreate() {
      this.srvSurveyTarget = SrvSurveyTargetService.shape({
        name: '',
        target_type: '',
        identifiers: [],
        target_identifiers: [],
      }).reset()
      this.dialogs.create = true
    },

    /**
     * 
     */
    onClickSend() {
      this.$toaster('¿Confirma el envío de la encuesta al público objetivo?', 'Confirmar', () => this.toSendToTarget())
    },

    /**
     * 
     */
    onClickUpdate() {
      this.goRoute('srv-survey-update', { id: this.getParam('id') })
    },

    /**
     * 
     */
    onDelete(event) {
      this.srvSurvey.targets.splice(this.srvSurvey.targets.findIndex(target => target.id === event.item.id), 1)
    },

    /**
     * 
     */
    onDeleteItem(event) {
      this.srvSurveyTarget.deleteIdentifierByIdentifier(event.item.identifier)
      this.srvSurveyTarget.deleteTargetIdentifierByIdentifier(event.item.identifier)
      if (this.dialogs.create) {
        this.dialogs.create = false
        this.dialogs.create = true
      }
      if (this.dialogs.update) {
        this.dialogs.update = false
        this.dialogs.update = true
      }
    },

    /**
     * 
     */
    onEdit(event) {
      this.srvSurveyTarget  = event.item.reset()
      this.srvSurveyTarget.identifiers = event.item.identifiers || event.item.target_identifiers
      this.srvSurveyTarget.target_identifiers = event.item.identifiers || event.item.target_identifiers
      this.srvSurveyTarget.identifiers = this.srvSurveyTarget.identifiers.map(item => {
        if (typeof item === "number") {
          return {
            id: item,
            identifier: item,
            name: '',
          }
        }
        return item
      })
      this.srvSurveyTarget.target_identifiers = this.srvSurveyTarget.target_identifiers.map(item => {
        if (typeof item === "number") {
          return {
            id: item,
            identifier: item,
            name: '',
          }
        }
        return item
      })
      this.srvSurveyTarget.populateIdentifiers()
      this.dialogs.update = true 
    },

    /**
     * Returns an target SrvSurvey success process.
     * @param  {Object}  event - the event success payload.
     */
    onSuccessTarget(event) {
      this.onSuccess(event)
      this.onClickUpdate()
    },

    /**
     * Returns a reset SrvSurvey form.
     * @return {Promise} A form reset promise.
     */
    onReset() {
      return this.toWait(true).then(this.toRefresh).then(this.toWait)
    },

    /**
     * Returns a submit SrvSurvey form.
     * @return {Promise} A form submit promise.
     */
    onSubmit() {
      return this.toWait(true).then(this.toAssignTarget).then(this.toWait)
    },

    /**
     * 
     */
    onSubmitCreate() {
      console.log(this.$refs.tableSelected)
      this.srvSurvey.targets.push(this.srvSurveyTarget.toUpdate())
      this.dialogs.create = false
    },

    /**
     * 
     */
    onSubmitSelected() {

      // require
      let identifiers = this.srvSurveyTarget.identifiers
      let selected = this.$refs.tableSelected.getSelected().map(item => {
        item.identifier = item.id
        switch(this.srvSurveyTarget.name) {
          case 'Actividad':
            item.name = item.title
            this.srvSurveyTarget.model = 'Modules\\ActivityModule\\Entities\\Activity'
            break;
          case 'Atención':
            item.name = item.attention_type.description
            this.srvSurveyTarget.model = 'Modules\\StudentAttentionModule\\Entities\\Attention'
            break;
          case 'Carrera':
            item.name = item.description
            this.srvSurveyTarget.model = 'Modules\\UserModule\\Entities\\Career'
            break;
          case 'Programa':
            item.name = item.description
            this.srvSurveyTarget.model = 'Modules\\UserModule\\Entities\\Program'
            break;
          case 'Usuario':
            item.name = item.name_case
            this.srvSurveyTarget.model = 'Modules\\UserModule\\Entities\\User'
            break;
        }
        return item
      })

      selected = selected.filter(item => {
        return identifiers.every(identifier => {
          return identifier.identifier != item.identifier
        })
      })

      this.srvSurveyTarget.identifiers = [
        ...identifiers,
        ...selected,
      ]

      this.$refs.tableSelected.selected = []
      
      this.dialogs.identifiers = false
    },

    /**
     * 
     */
    onSubmitUpdate() {
      let index = this.srvSurvey.targets.findIndex(e => e.id == this.srvSurveyTarget.id)
      // let 
      if (index > -1) {
        this.srvSurvey.targets[index] = this.srvSurveyTarget
      }
      // this.srvSurvey.targets.push(this.srvSurveyTarget.toUpdate())
      this.dialogs.update = false
    },

    /**
     * Returns a refresh SrvSurvey form.
     * @return {Promise} A reset promise.
     */
    toRefresh() {
      return SrvSurveyService.toTarget().find(this.getParam('id')).then(srvSurvey => {
        this.srvSurvey = srvSurvey.reset()
      })
    },

    /**
     * Returns an assign target SrvSurvey promise.
     * @return {Promise} A assign target promise.
     */
    toAssignTarget() {
      return SrvSurveyService.assignTarget(this.srvSurvey).then(this.onSuccessTarget).catch(this.onError)
    },

    /**
     * Returns an send to target SrvSurvey promise.
     * @return {Promise} A send to target promise.
     */
    toSendToTarget() {

      // if has a activity related
      if (this.srvSurvey.has_activity) {
        return ActActivityService.sendSatisfactionSurvey(ActActivityService.shape(this.srvSurvey.activity)).then(this.onSuccess).catch(this.onError)
      }

      // default response
      return SrvSurveyService.sendToTarget(this.srvSurvey).then(this.onSuccess).catch(this.onError)
    },
  },
  mounted() {
    this.onReset()
  },
}