// required modules
import { Headers } from 'collection-wrapper'

/**
 * This class describes a UsrFacilitator headers collection.
 *
 * @class UsrFacilitatorHeaders (name)
 */
export default class UsrFacilitatorHeaders extends Headers {

  /**
   * Returns the UsrFacilitator headers options.
   *
   * @returns {Array} headers options.
   */
  headers() {
    return [
      {
        text: '#',
        value: 'id',
        sortable: true,
      },
      {
        text: 'Nombre(s)',
        value: 'first_names',
        sortable: 'name',
      },
      {
        text: 'Primer apellido',
        value: 'last_name1',
      },
      {
        text: 'Usuario',
        value: 'username',
      },
      {
        text: 'Creación',
        value: 'created_at_short',
        sortable: 'created_at',
        align: 'right',
      },
    ]
  }

  /**
   * Returns the UsrFacilitator list headers.
   *
   * @returns {Collection} headers collection.
   */
  static toList() {
    return this.reduce('value', [
      'id', 
      'first_names', 
      'last_name1', 
      'username', 
      'created_at_short',
    ])
  }
}
