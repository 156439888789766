// required modules
import Service from '@/services/api/Service'
import BlgWebNewImageModel from '@/models/Blog/BlgWebNewImage'

/**
 * This class describes a BlgWebNewImage service.
 * @class
 */
export default class BlgWebNewImageService extends Service {

  /**
   * Define the BlgWebNewImage model.
   * @return {Function} BlgWebNewImage resource.
   */
  static model() {
    return BlgWebNewImageModel
  }

  /**
   * 
   */
  static moveBack(blgWebNewImage) {
    return blgWebNewImage.move().attach(blgWebNewImage.toMoveBack())
  }

  /**
   * 
   */
  static moveForward(blgWebNewImage) {
    return blgWebNewImage.move().attach(blgWebNewImage.toMoveForward())
  }
}