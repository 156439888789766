// required modules
import { Fields } from 'collection-wrapper'
import { ruleEmail, ruleMin, ruleRequired, ruleRut } from 'vuetify2-wrappers/src/rules'

/**
 * This class describes a UsrUser fields collection.
 * @class UsrUserFields (name)
 */
export default class UsrUserFields extends Fields {

  /**
   * Returns the UsrUser fields options.
   *
   * @returns {Array} fields options.
   */
  fields() {
    return [
      {
        name: 'email',
        type: 'text-field',
        flex: {
          cols: 12
        },
        props: {
          label: 'Correo electrónico',
          persistentHint: true,
          placeholder: 'abc123@ucn.cl',
          rules: [ruleEmail],
        },
      },
      {
        name: 'password',
        type: 'password-field',
        flex: {
          cols: 12
        },
        props: {
          fieldProps: {
            label: 'Contraseña',
            persistentHint: true,
            placeholder: 'abc123',
            rules: [ruleMin(6)],
          },
        },
      },
      {
        name: 'repeat_password',
        type: 'password-field',
        flex: {
          cols: 12
        },
        props: {
          fieldProps: {
            label: 'Repetir Contraseña',
            persistentHint: true,
            placeholder: 'abc123',
            rules: [ruleMin(6)],
          },
        },
      },
      {
        name: 'alternative_email',
        type: 'text-field',
        flex: {
          cols: 12
        },
        props: {
          hint: 'Se recomienda un correo distinto a @ucn.cl o @alumnos.ucn.cl.',
          label: 'Correo electrónico (alternativo)',
          persistentHint: true,
          placeholder: 'abc123@otro.cl',
          rules: [ruleEmail],
        },
      },
      {
        name: 'phone_number',
        type: 'text-field',
        flex: {
          cols: 12
        },
        props: {
          label: 'Teléfono',
          persistentHint: true,
          placeholder: '+56 9 123 123',
          rules: [ruleMin(6)],
        },
      },
      {
        name: 'rut',
        type: 'rut-field',
        flex: {
          cols: 12
        },
        props: {
          fieldProps: {
            label: 'Rut',
            persistentHint: true,
            placeholder: '1-9',
            rules: [ruleRut],
          },
        },
      },
      {
        name: 'file',
        type: 'file-input',
        flex: {
          cols: 12
        },
        props: {
          accept: ".xlsx",
          label: 'Archivo en formato Excel',
          showSize: true,
        },
      },
      
      /**
       * UsrUser Enable Fields
       */
      {
        name: 'enable_account',
        type: 'switch',
        flex: {
          cols: 12
        },
        props: {
          label: 'Habilitar/Deshabilitar Cuenta de Usuario',
        },
      },
  
      /**
       * UsrUser/Student Result Fields
       */
      {
        name: 'registered',
        type: 'text-field',
        flex: {
          cols: 12
        },
        props: {
          disabled: true,
          label: 'Registrados',
        },
      },
      {
        name: 'enabled',
        type: 'text-field',
        flex: {
          cols: 12
        },
        props: {
          disabled: true,
          label: 'Habilitados',
        },
      },
      {
        name: 'disabled',
        type: 'text-field',
        flex: {
          cols: 12
        },
        props: {
          disabled: true,
          label: 'Deshabilitados',
        },
      },
  
      /**
       * ROLE FIELDS
       */
      {
        name: 'role',
        type: 'select',
        props: {
          items: [
            {
              text: 'Administrador',
              value: 'administrator',
            },
            {
              text: 'Facilitador',
              value: 'facilitator',
            },
          ],
          label: 'Rol',
          menuProps: {
            contentClass: 'elevation-2 v-list--dense mt-2',
          },
          placeholder: 'Rol',
        },
      },
      {
        name: 'rut_readonly',
        type: 'text-field',
        props: {
          label: 'RUT',
          placeholder: 'RUT',
        },
      },
      {
        name: 'first_names_readonly',
        type: 'text-field',
        props: {
          label: 'Nombre(s)',
          placeholder: 'Nombre(s)',
        },
      },
      {
        name: 'last_name1_readonly',
        type: 'text-field',
        props: {
          label: 'Primer apellido',
          placeholder: 'Primer apellido',
        },
      },
    ]
  }

  /**
   * Returns the UsrUser create fields.
   *
   * @returns {Collection} fields collection.
   */
  static toAccount() {
    return this.reduce('name', [
      'rut', 
      'alternative_email', 
      'phone_number', 
      'password', 
      'repeat_password',
    ])
  }

  /**
   * Returns the UsrUser create fields.
   *
   * @returns {Collection} fields collection.
   */
  static toCreate() {
    return this.reduce('name', [
      'value',
    ])
  }

  /**
   * Returns the UsrUser create fields.
   *
   * @returns {Collection} fields collection.
   */
  static toEnable() {
    return this.reduce('name', [
      'enable_account',
    ])
  }

  /**
   * Returns the UsrUser update fields.
   *
   * @returns {Collection} fields collection.
   */
  static toPassword() {
    return this.reduce('name', [
      'email',
      'password',
      'repeat_password',
    ])
  }

  /**
   * Returns the UsrUser update fields.
   *
   * @returns {Collection} fields collection.
   */
  static toResult() {
    return this.reduce('name', [
      'registered', 
      'enabled', 
      'disabled',
    ])
  }

  /**
   * Returns the UsrUser update fields.
   *
   * @returns {Collection} fields collection.
   */
  static toRole() {
    return this.reduce('name', [
      'rut_readonly', 
      'first_names_readonly',
      'last_name1_readonly',
      'role',
    ])
  }

  /**
   * Returns the UsrUser update fields.
   *
   * @returns {Collection} fields collection.
   */
  static toUpdate() {
    return this.reduce('name', [
      'value',
    ])
  }

  /**
   * Returns the UsrUser update fields.
   *
   * @returns {Collection} fields collection.
   */
  static toUpload() {
    return this.reduce('name', [
      'file',
    ])
  }
}
