// required modules
import Service from '@/services/api/Service'
import TtsTutorshipScheduleModel from '@/models/Tutorship/TtsTutorshipSchedule'

/**
 * This class describes a TtsTutorshipSchedule service.
 * @class
 */
export default class TtsTutorshipScheduleService extends Service {

  /**
   * Define the TtsTutorshipSchedule model.
   * @return {Function} TtsTutorshipSchedule resource.
   */
  static model() {
    return TtsTutorshipScheduleModel
  }

  /**
   * 
   */
  static toList() {
    return this.fetcher({
      includes: [
        'campus',
        'period',
        'tutorship',
        'block',
        'classroom',
        'parallel',
        'tutor',
      ],
    })
  }

  /**
   * 
   */
  static toSchedule() {
    return () => this.model().include('campus,period,tutorship,block,classroom,tutor,parallel').get()
  }
}