// required modules
import axios from 'axios'
import AthService from '@/services/api/Auth/AthService'

/**
 * Auth Store Module
 * 
 * @module AuthStore
 */
export default {
  state: {
    data: {},
    service: {
      apiKey: process.env.VUE_APP_API_KEY,
      apiUrl: process.env.VUE_APP_API_ROUTE,
      authKey: process.env.VUE_APP_API_AUTH_KEY,
      authUrl: process.env.VUE_APP_API_AUTH_ROUTE,
    },
    pending: false,
  },
  mutations: {

    /**
     * Sets the auth data.
     * 
     * @param      {object}  state   The state
     * @param      {object}  data    The data
     */
    setAuthData: (state, data) => {
      state.data = data
    },

    /**
     * Set or remove the axios token.
     */
    setAxiosToken: (state, data) => {
      AthService.token() ? axios.interceptors.request.use(AthService.interceptor) : axios.interceptors.request.eject(AthService.interceptor)
    },

    /**
     * Sets the service.
     * @param      {<type>}  state   The state
     * @param      {<type>}  data    The data
     */
    setAuthService: (state, data) => {
      state.service = {
        apiKey: String(data.apiKey),
        apiUrl: String(data.apiUrl),
        apiAuthKey: String(data.apiAuthKey),
        apiAuthUrl: String(data.apiAuthUrl),
      }
    },

    /**
     * 
     */
    setPending: (state, data = {}) => {
      state.pending = data.pending
    },

    /**
     * Store the service.
     * 
     * @param      {<type>}  state   The state
     * @param      {<type>}  data    The data
     */
    storeAuthService: (state) => {
      localStorage.setItem('api-key', state.service.apiKey)
      localStorage.setItem('api-url', state.service.apiUrl)
      localStorage.setItem('api-auth-key', state.service.apiAuthKey)
      localStorage.setItem('api-auth-url', state.service.apiAuthUrl)
    },
  },
  actions: {

    /**
     * { function_description }
     * @param      {<type>}  context  The context
     * @param      {<type>}  options  The options
     */
    inzAuthData: (context, options) => {
      context.dispatch('setAuthData', {
        //data: {},
      })
    },

    /**
     * 
     */
    inzAuthService: (context, options) => {

      //
      const storeUserData = localStorage.getItem('user-data')
      const userData = JSON.parse(storeUserData)

      //
      if (storeUserData !== '{}') {
        context.dispatch('setAuthData', {
          data: userData,
        })
        options.onLocal()
      }

      //
      context.commit('setAuthService', {
        apiKey: localStorage.getItem('api-key') || process.env.VUE_APP_API_KEY,
        apiUrl: localStorage.getItem('api-url') || process.env.VUE_APP_API_ROUTE,
        apiAuthKey: localStorage.getItem('api-auth-key') || process.env.VUE_APP_API_AUTH_KEY,
        apiAuthUrl: localStorage.getItem('api-auth-url') || process.env.VUE_APP_API_AUTH_ROUTE,
      })

      //
      // console.log({ storeUserData, userData })
    },

    /**
     * 
     */
    setAuthData: (context, options) => {

      //
      context.commit('setAuthData', options.data)
      context.commit('setAxiosToken', options.data)

      //
      if (options.remember) {
        context.dispatch('setLocalAuthData', {
          data: options.data,
        })
      }
    },

    /**
     * 
     */
    setAuthService: (context, options) => {
      context.commit('setAuthService', options.data)
    },

    /**
     * 
     */
    setLocalAuthData: (context, options) => {
      localStorage.setItem('user-data', JSON.stringify(options.data))
    },

    /**
     * 
     */
    setPending: (context, options) => {
      context.commit('setPending', options.data)
    },

    /**
     * 
     */
    storeAuthService: (context) => {
      context.commit('storeAuthService')
    },
  },
  getters: {

    /**
     * 
     */
    authToken: (state) => {

      // required data
      const type = state.data.notification_token_type
      const body = state.data.access_token

      // returns the full token
      return type && body && `${ state.data.notification_token_type } ${ state.data.access_token }`
    },

    /**
     * 
     */
    hasAuthData: state => !! state.data.user,

    /**
     * 
     */
    hasPendingSurveys: state => state.pending,

    /**
     * 
     */
    isAdministrator: state => state.data.roles.some(role => {
      return role.name === 'administrator' || role.name === 'system'
    }),

    /**
     * 
     */
    isLogged: state => !! state.data.user,

    /**
     * 
     */
    userFullName: state => state.data ? `${state.data.user.first_names} ${state.data.user.last_name1}` : '',
    userId: state => state.data.user.id,
    userName: state => `${state.data.username}`,
    userRol: state => {
      //
      const role = state.data.roles.slice().shift() || {}
      const name = role.name || ''
      //
      return {
        student: 'Estudiante', 
        facilitator: 'Facilitador', 
        administrator: 'Administrador', 
        system: 'Sistema' }[name] 
      },//map(rol => rol.name).join(', ')}`,

    /**
     * 
     */
    userRolName: state => {
      //
      const role = state.data.roles.slice().shift() || {}
      const name = role.name || ''
      //
      return name
    },

    /**
     * 
     */
    userUniversityCampusId: state => state.data.user.university_campus_id
  }
}