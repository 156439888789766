// required modules
import AthLoginModel from '@/models/Auth/AthLogin'
import AthLoginService from '@/services/api/Auth/AthLogin'
import AthServiceService from '@/services/api/Auth/AthService'
import AthLoginFields from '@/views/Auth/AthLogin/fields'

// component
export default {
  computed: {

    /**
     * Gets the AthLogin form props.
     * 
     * @return {Object} The form props.
     */
    formProps() {
      return {
        items: AthLoginFields.toCreate(),
      }
    },
  },
  data() {
    return {
      athLogin: new AthLoginModel({
        username: '',
        password: '',
      }),
      status: null,
    }
  },
  methods: {

    /**
     * Redirect to Auth index.
     */
    redirect() {
      this.goRoute('home')
    },

    /**
     * Reset the AthLogin data.
     */
    onReset() {
      this.athLogin.username = ''
      this.athLogin.password = ''
    },

    /**
     * Returns a submit AthLogin form.
     * 
     * @return {Promise} A form submit process.
     */
    onSubmit() {
      return this.toWait(true).then(this.toCreate).then(this.toWait)
    },

    /**
     * Returns an create AthLogin process.
     * 
     * @return {Promise} A create process.
     */
    toCreate() {
      return AthLoginService.create(this.athLogin).then(this.onSuccessCreate).catch(this.onError)
    },

    /**
     * Returns an create AthLogin error process.
     * 
     * @param  {Object}  event - the event error payload
     */
    onError(event) {
      this.getErrorMessage(event).then(this.getErrorToast)
    },

    /**
     * Returns an create AthLogin success process.
     */
    onSuccessCreate(event) {
      this.getSuccessMessage('Bienvenido.').then(this.getSuccessToast).then(this.redirect)
    },
  },
}
