// components
import ActActivityFields from '@/views/Activity/ActActivity/fields'
import ActActivityModel from '@/models/Activity/ActActivity'
import ActActivityService from '@/services/api/Activity/ActActivity'
// component
export default {
  name: 'act-activity-detail',
  computed: {

    /**
      * Gets the ActActivity detail form props.
      * @return {Object} The detail form props.
      */
    formProps() {
      return {
        items: ActActivityFields.toDetail(),
      }
    }
  },
  data() {
    return {
      actActivity: {
        value: 'test',
      },
    }
  },
  methods: {

    /**
      * Returns a reset ActActivity form.
      * @return {Promise} A form reset promise.
      */
    reset() {
      return this.toWait(true).then(this.toRefresh).then(this.toWait)
    },

    /**
      * Returns a submit ActActivity form.
      * @return {Promise} A form submit promise.
      */
    submit() {
      return this.toWait(true).then(this.toDetail).then(this.toWait)
    },

    /**
      * Returns a refresh ActActivity form.
      * @return {Promise} A reset promise.
      */
    toRefresh() {
      return ActActivityModel.find(this.getIdParam).then(actActivity => {
        this.actActivity = actActivity.reset()
      })
    },

    /**
      * Returns an detail ActActivity promise.
      * @param  {Object}  model - the act-activity model
      * @return {Promise} A detail promise.
      */
    toDetail(actActivity) {
      return ActActivityService.detail(actActivity).then(this.toSuccess).catch(this.toError)
    },

    /**
      * Returns an detail ActActivity error process.
      * @param  {Object}  event - the event error payload.
      */
    toError(event) {
      this.getErrorMessage(event).then(this.getErrorToast)
    },

    /**
      * Returns an detail ActActivity success process.
      * @param  {Object}  event - the event success payload.
      */
    toSuccess(event) {
      this.getSuccessMessage(event).then(this.getSuccessToast).then(this.refresh)
    },
  },
  mounted() {
    //this.reset()
  },
}