// required modules
import { Fields } from 'collection-wrapper'
//import UsrCareerModel from '@/models/User/UsrCareer'
import UsrCareerService from '@/services/api/User/UsrCareer'
import UsrUniversityCampusService from '@/services/api/User/UsrUniversityCampus'

/**
 * This class describes a UsrStudent filters collection.
 *
 * @class UsrStudentFilters (name)
 */
export default class UsrStudentFilters extends Fields {

  /**
   * Returns the UsrStudent filters options.
   * @returns {Array} filters options.
   */
  fields() {
    return [
      {
        name: 'university_campus_id',
        type: 'autocomplete-model',
        props: {
          clearable: true,
          fetch: UsrUniversityCampusService.toList(),
          limit: 15,
          autocompleteProps: {
            clearable: true,
            itemText: 'description',
            itemValue: 'id',
            label: 'Sede',
            multiple: false,
            placeholder: 'Sede',
          },
        },
      },
      {
        name: 'rut',
        type: 'text-field',
        props: {
          clearable: true,
          label: 'RUT',
        },
      },
      {
        name: 'email',
        type: 'text-field',
        props: {
          clearable: true,
          label: 'Correo electrónico',
        },
      },
      {
        name: 'career',
        type: 'autocomplete-model',
        props: {
          clearable: true,
          fetch: UsrCareerService.toSelectList(),
          limit: 15,
          autocompleteProps: {
            clearable: true,
            itemText: 'description',
            itemValue: 'id',
            label: 'Carrera(s)',
            multiple: false,
            placeholder: 'Carrera(s)',
          },
        },
      },
      {
        name: 'career_by_year',
        type: 'select',
        props: {
          clearable: true,
          items: [
            {
              text: 'Igual que',
              value: 'eq',
            }, 
            {
              text: 'Menor que',
              value: 'lt',
            }, 
            {
              text: 'Menor o igual que',
              value: 'lte',
            }, 
            {
              text: 'Mayor que',
              value: 'gt',
            }, 
            {
              text: 'Mayor o igual que',
              value: 'gte',
            }, 
          ],
          label: 'Opciones de cohorte por carrera',
          menuProps: {
            contentClass: 'elevation-2 v-list--dense mt-2',
          },
        },
      },
      {//1978 a actual
        name: 'year',
        type: 'select',
        props: {
          clearable: true,
          items: this.getYears(),
          label: 'Cohorte',
          menuProps: {
            contentClass: 'elevation-2 v-list--dense mt-2',
          },
        },
      },
      {
        name: 'activated_account',
        type: 'select',
        props: {
          clearable: true,
          items: [
            {
              text: 'Si',
              value: 'true',
            },
            {
              text: 'No',
              value: 'false',
            }
          ],
          label: 'Cuenta activada',
          placeholder: 'Cuenta activada',
        },
      },
    ]
  }

  /**
   * Returns the UsrStudent list filters.
   * @returns {Collection} filters collection.
   */
  static toList() {
    return this.reduce('name', [
      'list', 
      'university_campus_id',
      'rut',
      'email',
      'career',
      'career_by_year',
      'year',
      'activated_account',
    ])
  }

  getYears() {
    let d =  new Date ()
    let year = d.getFullYear()
    var years = []
    for (var i = year; i > 1978; i--){years.push(i)}
    return years
  } 
  
}


