// required modules
import VwLayoutBoard from 'vuetify2-wrappers/src/components/layouts/board'

// component
export default {
  extends: VwLayoutBoard,
  props: {

    /**
     * 
     */
    additionalProps: {
      type: Object,
      default: () => ({
        md: 6,
        cols: 12,
      })
    },

    /**
     * 
     */
    leftProps: {
      type: Object,
      default: () => ({
        md: 8,
        cols: 12,
      })
    },

    /**
     * 
     */
    principalProps: {
      type: Object,
      default: () => ({
        cols: 12,
      })
    },

    /**
     * 
     */
    rightProps: {
      type: Object,
      default: () => ({
        sm: 4,
        cols: 12,
      })
    },

    /**
     * 
     */
    subContainerProps: {
      type: Object,
      default: () => ({
        class: 'pa-0',
        style: {
          margin: '-12px 0'
        },
      })
    },
  },
}