// required modules
import Model from '@/models/Model'

/**
 * This class describes a StaAttentionSchedule model.
 * @class StaAttentionSchedule
 */
export default class StaAttentionScheduleModel extends Model {

  /**
   * 
   */
  get attention_end_datetime() {

    //
    const datetime  = new Date(Date.now()).toLocaleString()
    const date      = datetime.split(' ').shift().split('/').reverse().map(s => s.length < 2 ? `0${s}` : s).join('-')
    const time      = datetime.split(' ').pop()

    //
    return `${ date } ${ time }`
  }

  /**
   * 
   */
  get attention_type_campus_data_place() {
    return this.prop('attention_type.campus_data.place', '')
  }

  /**
   * 
   */
  get facilitator_name_case() {
    return `${ this.prop('facilitator.first_names', '') } ${ this.prop('facilitator.last_name1', '') }`
  }

  /**
   * Define the StaAttentionSchedule resource.
   * @return {String} StaAttentionSchedule endpoint name.
   */
  resource() {
    return 'attention/schedule'
  }

  /**
   * 
   */
  get status_color() {
    return this.status_colors[this.attention_status_id]
  }

  /**
   * 
   */
  get status_colors() {
    return [
      StaAttentionScheduleStatusColors.DRAFTED,
      StaAttentionScheduleStatusColors.STARTED,
      StaAttentionScheduleStatusColors.READY,
      StaAttentionScheduleStatusColors.CANCELLED,
      StaAttentionScheduleStatusColors.COMPLETE,
    ]
  }

  /**
   * 
   */
  get status_id() {
    return this.prop('status.id', 1)
  }
}

export const StaAttentionScheduleStatusColors = {
  DRAFTED: 'accent',
  STARTED: 'secondary',
  READY: 'primary',
  CANCELLED: 'grey darken-4',
  COMPLETE: 'success',
}