// required modules
import NtMailContactFields from '@/views/Notification/NtMailContact/fields'
import NtMailContactService from '@/services/api/Notification/NtMailContact'

// component
export default {
  name: 'nt-mail-contact-create',
  computed: {

    /**
      * Gets the NtMailContact create form props.
      * @return {Object} The create form props.
      */
    formProps() {
      return {
        items: NtMailContactFields.toCreate(),
      }
    }
  },
  data() {
    return {
      ntMailContact: NtMailContactService.shape({
        value: ''
      }),
    }
  },
  methods: {

    /**
      * Redirect to NtMailContact list
      */
    redirect() {
      this.pushRoute('nt-mail-contact-list')
    },

    /**
      * Reset the NtMailContact data.
      */
    reset() {
      this.ntMailContact.value = ''
    },

    /**
      * Returns a submit NtMailContact create form.
      * @return {Promise} A form submit process.
      */
    submit() {
      return this.toWait(true).then(this.toCreate).then(this.toWait)
    },

    /**
      * Returns an create NtMailContact process.
      * @return {Promise} A create process.
      */
    toCreate() {
      return NtMailContactService.create(this.ntMailContact).then(this.toSuccess).catch(this.toError)
    },

    /**
      * Returns an create NtMailContact error process.
      * @param  {Object}  event - the event error payload.
      */
    toError(event) {
      this.getErrorMessage(event).then(this.getErrorToast)
    },

    /**
      * Returns an create NtMailContact success process.
      * @param  {Object}  event - the event success payload.
      */
    toSuccess(event) {
      this.getSuccessMessage('Contacto agregado exitosamente.').then(this.getSuccessToast).then(this.redirect)
    },
  },
}