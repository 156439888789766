// required modules
import Model from '@/models/Model'
import ACompleteModel from '@/models/Actions/AComplete'
import ACancelModel from '@/models/Actions/ACancel'
import ACancelReservationModel from '@/models/Actions/ACancelReservation'
import AConfirmModel from '@/models/Actions/AConfirm'
import AReserveModel from '@/models/Actions/AReserve'
import AScheduleModel from '@/models/Actions/ASchedule'
import ASendNotificationModel from '@/models/Actions/ASendNotification'
import ASendNotificationToFacilitatorModel from '@/models/Actions/ASendNotificationToFacilitator'
import StaAttentionStudentModel from '@/models/StudentAttention/StaAttentionStudent'

/**
 * This class describes a StaAttention model.
 * 
 * @class StaAttention (name)
 */
export default class StaAttentionModel extends Model {

  /**
   * attention_datetime
   */
  get attention_datetime_short() {

    const date = this.prop('attention_datetime', '').split(' ').shift().split('-').reverse().join('-')
    const time = this.prop('attention_datetime', '').split(' ').pop().split(':').slice(0, -1).join(':')
    
    return `${date} ${time}`
  }

  /**
   * 
   */
  get attention_students_readonly() {
    return this.prop('attention_students', []).map(e => new StaAttentionStudentModel(e))
  }

  /**
   * 
   */
  get attention_time_in_minutes_str() {
    return `${ this.prop('attention_time_in_minutes', '0') } min.`
  }

  /**
   * 
   */
  get attention_type_description() {
    return this.prop('attention_type.description', '')
  }

  /**
   * 
   */
  complete() {
    return this.hasMany(ACompleteModel)
  }

  /**
   * 
   */
  cancel() {
    return this.hasMany(ACancelModel)
  }

  /**
   * 
   */
  cancelReservation() {
    return this.hasMany(ACancelReservationModel)
  }

  /**
   * 
   */
  confirm() {
    return this.hasMany(AConfirmModel)
  }

  /**
   * 
   */
  get facilitator_name_case() {
    return `${ this.prop('facilitator.first_names', '') } ${ this.prop('facilitator.last_name1', '') }`
  }

  /**
   * 
   */
  filterByAvailableCompleteStatus(staAttentionStudent) {
    
    //
    const statusId = staAttentionStudent.attention_student_status_id

    //
    return statusId === 2 || statusId === 5
  }

  /**
   * 
   */
  reserve() {
    return this.hasOne(AReserveModel)
  }

  /**
   * Define the StaAttention resource.
   * 
   * @return {String} StaAttention endpoint name.
   */
  resource() {
    return 'attention'
  }

  /**
   * 
   */
  sendNotification() {
    return this.hasMany(ASendNotificationModel)
  }

  /**
   * 
   */
  sendNotificationToFacilitator() {
    return this.hasMany(ASendNotificationToFacilitatorModel)
  }

  /**
   * 
   */
  schedule() {
    return this.hasOne(AScheduleModel)
  }

  /**
   * 
   */
  sortStudents() {
    if (this.students) {
      this.students.sort((current, next) => {
        return current.first_names.localeCompare(next.first_names)
      })
    }
    if (this.attention_students) {
      this.attention_students.sort((current, next) => {
        return current.student.first_names.localeCompare(next.student.first_names)
      })
    }
  }

  /**
   * 
   */
  get status_description() {
    return this.getProperty('status.description', '')
  }

  /**
   * 
   */
  toCancelReservation() {
    return this.reduce([
      'cancel_comment',
    ])
  }

  /**
   * 
   */
  toComplete() {

    //
    this.post_observation = this.attention_students.filter(this.filterByAvailableCompleteStatus).map(item => {
      return {
        student_user_id: item.student_user_id,
        post_attention_observation: item.post_attention_observation,
        attention_student_status_id: item.attention_student_status_id,
        attention_type_orientation_list_id: item.attention_type_orientation_list_id,
      }
    })

    //
    return this.reduce([
      'id',
      'post_observation',
    ])
  }

  /**
   * 
   */
  toConfirm() {
    return this.reduce([
      'id',
    ])
  }

  /**
   * 
   */
  toCancel() {
    return this.reduce([
      'id',
      'cancel_comment',
    ])
  }

  /**
   * 
   */
  toReserve() {

    //

    //
    return this.reduce([
      'attention_facilitator_schedule_id',
      'attention_date',
    ])
  }

  /**
   * 
   */
  toSchedule() {

    //
    var clone = this.clone()
    
    //
    clone.students = clone.students ? clone.students.map(item => item.id) : clone.attention_students.map(item => item.student.id)

    //
    return clone.reduce([
      'attention_group_id',
      'attention_id',
      'attention_type_id',
      'attention_datetime',
      'attention_time_in_minutes',
      'cancel_time_in_minutes',
      'students',
    ])
  }

  /**
   * 
   */
  toSendNotification() {
    return this.reduce([
      'id',
      'send_email',
      'target',
      'message_subject',
      'message_body',
    ])
  }

  /**
   * 
   */
  toSendNotificationToFacilitator() {
    return this.reduce([
      'id',
      'send_email',
      'message_subject',
      'message_body',
    ])
  }

  get weekday() {
    let datetime = this.prop('attention_datetime')
    let date = new Date(datetime)
    let weekday = date.getDay()
    // console.log({ weekday })
    return weekday
  }
}