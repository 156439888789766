// required modules
import VOdemeter from 'vue-odometer'
import { GChart } from 'vue-google-charts'
import 'odometer/themes/odometer-theme-default.css';

// components
import RptReportFields from '@/views/Report/RptReport/fields'
import RptReportService from '@/services/api/Report/RptReport'

// component
export default {
  name: 'rpt-report-update',
  components: {
    GChart,
    VOdemeter,
  },
  computed: {

    /**
     * 
     */
    chartProps() {
      return {
        type: this.rptReport.report_type,
        data: this.rptReport.report_data,
        options: {
          ...this.rptReport.report_options,
          width: this.width,
        },
      }
    },

    /**
     * 
     */
    chartAppendProps() {
      return {
        type    : this.rptReportAppend.report_type,
        data    : this.rptReportAppend.report_data,
        options : this.rptReportAppend.report_options,
      }
    },

    /**
     * Gets the RptReport chart form props.
     * @return {Object} The chart form props.
     */
    formProps() {
      return {
        infoTitle: this.rptReport.report_title,
        items: RptReportFields.toChart(),
        title: this.rptReport.report_title,
      }
    }
  },
  data() {
    return {
      odemeter: {
        fun: null,
        num: 0,
      },
      ready: false,
      rptReport: RptReportService.shape({
        filters: {
          periods: [1],
        },
        report: {
          details: [],
          total_quantity: 0,
        },
      }),
      rptReportAppend: null,
      gChart: null,
      width: '100%',
    }
  },
  methods: {

    /**
     * 
     */
    increment() {
      if (this.rptReport.report_unique_value > this.odemeter.num) {
        this.odemeter.num += this.chartProps.options.increment
      } else {
        clearInterval(this.odemeter.fun)
      }
    },
    
    /**
     * 
     */
    onExport() {
      this.$toaster('¿Confirma la descarga del reporte actual?', 'confirmar', () => {
        RptReportService.export(this.rptReport)
      })
    },

    /**
     */
    onOdemeter() {
      if (this.rptReport.report_type === 'IndicatorChart') {
        this.odemeter.fun = setInterval(() => this.increment(), 10)
        this.odemeter.num = 0
      }
    },

    /**
     * 
     */
    onReady(event) {
      this.gChart = event
    },

    /**
     * 
     */
    onRefresh() {
      return this.toReady(false).then(this.toRefresh).then(this.onOdemeter).then(this.toReady)
    },

    /**
     * Returns a reset RptReport form.
     * @return {Promise} A form reset promise.
     */
    onReset() {
      return this.toWait(true).then(this.onRefresh).then(this.toWait)
    },

    /**
     * 
     */
    onResize() {
      this.width = this.$refs.form.$el.offsetWidth - 100
    },

    /**
     * 
     */
    onSnapshot() {
      this.$toaster('¿Confirma la descarga del gráfico del reporte actual?', 'confirmar', () => {
        RptReportService.snapshot(this.gChart)
      })      
    },

    /**
     * Returns an update RptReport success process.
     * @param  {Object}  event - the event success payload.
     */
    onSuccessUpdate(event) {
      this.onSuccess(event)
    },

    /**
     * Returns a submit RptReport form.
     * @return {Promise} A form submit promise.
     */
    onSubmit() {
      this.toRefresh()
    },

    /**
     * 
     */
    async toReady(ready = true) {
      this.ready = ready
      console.log(this.rptReport.toAppendChart(), 'append report')
      this.rptReportAppend = this.rptReport.toAppendChart()
    },

    /**
     * 
     */
    toFetch() {
      return RptReportService.fetchByKey(this.getParam('key'), this.rptReport.filters).then(resRptReport => {
        this.rptReport = RptReportService.report(resRptReport, this.rptReport.filters).reset()
      })
    },

    /**
     * Returns a refresh RptReport form.
     * @return {Promise} A reset promise.
     */
    toRefresh() {
      return RptReportService.fetchByKey(this.getParam('key'), this.rptReport.filters).then(resRptReport => {
        this.rptReport = RptReportService.report(resRptReport, this.rptReport.filters).reset()
      })
    },
  },
  created() {
    window.onresize = this.onResize
  },
  mounted() {
    this.onReset()
  },
}