// layout
import RLayoutModule from '@/components/layouts/LayoutModule'

// views
import StaAttentionCreateView from '@/views/StudentAttention/StaAttention/create'
import StaAttentionListView from '@/views/StudentAttention/StaAttention/list'
import StaAttentionScheduleView from '@/views/StudentAttention/StaAttention/schedule'
import StaAttentionUpdateView from '@/views/StudentAttention/StaAttention/update'

// routes
export default {
  path: 'atenciones',
  component: RLayoutModule,
  children: [
    {
      name: 'sta-attention-list',
      path: '',
      component: StaAttentionListView,
    },
    {
      name: 'sta-attention-create',
      path: 'crear',
      component: StaAttentionCreateView,
      meta: {
        title: 'Crear Atención'
      },
    },
    {
      name: 'sta-attention-schedule',
      path: 'calendario',
      component: StaAttentionScheduleView,
      meta: {
        title: 'Calendario de atenciones'
      },
    },
    {
      name: 'sta-attention-update',
      path: ':id',
      component: StaAttentionUpdateView,
      meta: {
        title: 'Editar Atención'
      },
    },
  ],
  meta: {
    title: 'Atenciones'
  },
  props: {
    indexText: 'Inicio',
  },
}
