// model
import Model from '@/models/Model'

/**
 * This class describes a AEnableAccount model.
 * @class AEnableAccount (name)
 */
export default class AEnableAccountModel extends Model {

  /**
   * Define the AEnableAccount resource.
   * @return {String} AEnableAccount endpoint name.
   */
  resource() {
    return 'enable_account'
  }
}