// required modules
import SrvSurveyFilters from '@/views/Survey/SrvSurvey/filters'
import SrvSurveyHeaders from '@/views/Survey/SrvSurvey/headers'
import SrvSurveyService from '@/services/api/Survey/SrvSurvey'

// component
export default {
  name: 'srv-survey-list',
  computed: {

    /**
     *
     */
    tableProps() {
      return {
        filters: SrvSurveyFilters.toList(),
        headers: SrvSurveyHeaders.toList(),
        service: SrvSurveyService.toList(),
      }
    }
  },
  methods: {

    /**
     * 
     */
    onClickCreate() {
      this.goRoute('srv-survey-create')
    },

    /**
     * Show a delete SrvSurvey confirm toast.
     */
    onClickDelete(event) {
      this.selected = event.item
      this.$toaster('¿Confirma la eliminación de la encuesta seleccionada?', 'confirmar', this.toDelete)
    },

    /**
     * 
     */
    onClickUpdate(event) {
      this.goRoute('srv-survey-update', { id: event.item.id })
    },

    /**
     * The delete SrvSurvey error process.
     *
     * @param  {Object}  event - the error delete payload.
     */
    onError(event) {
      this.getErrorMessage(event).then(this.getErrorToast)      
    },

    /**
     * The delete SrvSurvey success process.
     *
     * @param  {Object}  event - the success delete payload.
     */
    onSuccessDelete(event) {
      this.onSuccess('Encuesta eliminada exitosamente')
      this.toRefresh()  
    },

    /**
     * Return a delete SrvSurvey promise.
     *
     * @param  {SrvSurveyModel}  model - the SrvSurvey model.
     * @return {Promise} A delete process.
     */
    toDelete() {
      return SrvSurveyService.delete(this.selected).then(this.onSuccessDelete).catch(this.onError)
    },

    /**
     * Refresh the SrvSurvey list.
     */
    toRefresh() {
      this.getRef('table').refresh()
    },
  },
}