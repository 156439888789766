// components
import RBtn from '@/components/buttons/ButtonTooltip'
// import RLayoutTableNew from '@/components/layouts/LayoutTable'
// components
import UsrProgramFields from '@/views/User/UsrProgram/fields'
import UsrProgramModel from '@/models/User/UsrProgram'
import UsrProgramService from '@/services/api/User/UsrProgram'
// student
import UsrStudentFilters from '@/views/User/UsrStudent/filters'
import UsrStudentHeaders from '@/views/User/UsrStudent/headers'
import UsrStudentService from '@/services/api/User/UsrStudent'
// user
import UsrUserService from '@/services/api/User/UsrUser'
// component
export default {
  name: 'usr-program-update',
  components: {
    RBtn,
    // RLayoutTableNew,
  },
  computed: {

    /**
      * Gets the UsrProgram update form props.
      * 
      * @return {Object} The update form props.
      */
    formProps() {
      return {
        items: UsrProgramFields.toUpdate(),
      }
    },

    /**
     * 
     */
    tableStudentProps() {
      return {
        filters: UsrStudentFilters.toList(),
        headers: UsrStudentHeaders.toList(),
        service: UsrStudentService.toList(),
      }
    },

    /**
     * 
     */
    tableUserProps() {
      return {
        headers: UsrStudentHeaders.toDetailListWithCareer(),
        service: UsrStudentService.from(this.usrProgram.users),
      }
    },
  },
  data() {
    return {
      dialogs: {
        students: false,
      },
      usrProgram: {
        users: [],
      },
      selected: null,
    }
  },
  methods: {

    /**
      * Returns a reset UsrProgram form.
      * @return {Promise} A form reset promise.
      */
    reset() {
      return this.toWait(true).then(this.toRefresh).then(this.toWait)
    },

    /**
      * Returns a submit UsrProgram form.
      * @return {Promise} A form submit promise.
      */
    submit() {
      this.toWait(true).then(this.toUpdate).then(this.toWait)
    },

    /**
     * 
     */
    submitAddStudent() {
      this.toWait(true).then(this.toAddStudent).then(this.toWait)
    },

    /**
     * 
     */
    submitItem(event) {
      this.selected = event.item
    },

    /**
     * 
     */
    submitRemoveStudent() {
      this.toWait(true).then(this.toRemoveUsers).then(this.toWait)
    },

    /**
     * 
     */
    toAddStudent() {
      return UsrProgramService
        .addStudent(this.usrProgram, this.getRef('table').getSelected())
        .then(this.toSuccessAddStudent)
        .then(this.toRefresh)
        .catch(this.toError)
    },

    /**
      * Returns a refresh UsrProgram form.
      * @return {Promise} A reset promise.
      */
    toRefresh() {
      return UsrProgramService.toUpdate().find(this.getParam('id')).then(usrProgram => {
        this.usrProgram = usrProgram.reset()
      })
    },

    /**
      * Returns a removeStudent service-promise.
      * @return {Promise} A removeStudent promise.
     */
    toRemoveUsers() {
      return UsrProgramService
        .removeStudent(this.usrProgram, [ this.selected ])
        .then(this.toSuccessRemoveStudent)
        .then(this.toRefresh)
        .catch(this.toError)
    },

    /**
     * Returns an update UsrProgram promise.
     * 
     * @return {Promise} A update promise.
     */
    toUpdate() {
      return UsrProgramService
        .update(this.usrProgram)
        .then(this.toSuccess)
        .catch(this.toError)
    },

    /**
      * Returns an update UsrProgram error process.
      * 
      * @param  {Object}  event - the event error payload.
      */
    toError(event) {
      this.getErrorMessage(event).then(this.getErrorToast)
    },

    /**
      * Returns an update UsrProgram success process.
      * 
      * @param  {Object}  event - the event success payload.
      */
    toSuccess(event) {
      this.getSuccessMessage(event).then(this.getSuccessToast).then(this.refresh)
    },

    /**
     * 
     */
    toSuccessAddStudent() {
      this.getSuccessToast('Estudiantes actualizados.')
    },

    /**
     * 
     */
    toSuccessRemoveStudent() {
      this.getSuccessToast('Estudiante removido exitosamente.')
    },
  },
  mounted() {
    this.reset()
  },
}