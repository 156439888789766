// required modules
import Model from '@/models/Model'
import AAccountActivationModel from '@/models/Actions/AAccountActivation'
import AMarkModel from '@/models/Actions/AMark'
import ARestorePasswordModel from '@/models/Actions/ARestorePassword'

/**
 * This class describes a NtNotification model.
 * @class NtNotification (name)
 */
export default class NtNotificationModel extends Model {

  /**
   * 
   */
  get created_at_short() {

    // required data
    const time = this.prop('created_at', '').split(' ').pop()//.split(':').slice(0, 2).join(':')
    const date = this.prop('created_at', '').split(' ').shift().split('-').reverse().join('-')

    // formatted data
    return `${ date } ${ time }`
  }

  /**
   * 
   */
  get updated_at_short() {

    // required data
    const time = this.prop('updated_at', '').split(' ').pop()//.split(':').slice(0, 2).join(':')
    const date = this.prop('updated_at', '').split(' ').shift().split('-').reverse().join('-')

    // formatted data
    return `${ date } ${ time }`
  }

  /**
   * Set a account activation endpoint.
   * @return {NtNotification} NtNotification account activation model.
   */
  accountActivation() {
    return this.hasOne(AAccountActivationModel)
  }

  /**
   * 
   */
  mark() {
    return this.hasMany(AMarkModel)
  }

  /**
   * Define the NtNotification resource.
   * @return {String} NtNotification endpoint name.
   */
  resource() {
    return 'notification'
  }

  /**
   * Set a restore password endpoint.
   * @return {NtNotification} NtNotification restore password model.
   */
  restorePassword() {
    return this.hasOne(ARestorePasswordModel)
  }

  /**
   * 
   */
  toMark() {
    return {
      read: true,
    }
  }

  /**
   * Return a password representation.
   * 
   * @return {NtNotification} NtNotification password.
   */
  toPassword() {
    return this.reduce([
      'identifier',
    ])
  }

  /**
   * Return a account activation representation.
   * 
   * @return {NtNotification} NtNotification account activation.
   */
  toAccountActivation() {
    return this.reduce([
      'rut',
    ])
  }
}