// model
import Model from '@/models/Model'
// import ARestorePasswordCases from '@/services/cases/Actions/ARestorePassword'

/**
 * This class describes a ARestorePassword model.
 * @class ARestorePassword (name)
 */
export default class ARestorePasswordModel extends Model {

  /**
   * Define the ARestorePassword cases.
   * @return {String} ARestorePassword cases.
   */
  // cases() {
  //   return ARestorePasswordCases
  // }

  /**
   * Define the ARestorePassword resource.
   * @return {String} ARestorePassword endpoint name.
   */
  resource() {
    return 'restore_password'
  }
}