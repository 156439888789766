//
import UsrUserModel from '@/models/User/UsrUser'
import UsrUserService from '@/services/api/User/UsrUser'
import UsrUserFields from '@/views/User/UsrUser/fields'
//
export default {
  computed: {

    /**
      * Gets the usr-user form props.
      * @return {Object} The form props.
      */
    formProps() {
      return {
        items: UsrUserFields.toPassword()//.rule('password', this.rulePassword).rule('repeat_password', this.rulePassword),
      }
    }
  },
  data() {
    return {
      usrUser: new UsrUserModel({
        email: '',
        password: '',
        repeat_password: '',
        token: '',
      }),
    }
  },
  methods: {

    /**
      * Returns a reset usr-user form.
      * @return {Promise} A form reset process.
      */
    reset(event) {
      return this
        .toWait(true)
        .then(this.toRefresh)
        .then(this.toWait)
    },

    /**
     * 
     */
    rulePassword() {
      return this.usrUser.password == this.usrUser.repeat_password || 'Las contraseñas deben ser las mismas.'
    },

    /**
      * Returns a submit usr-user form.
      * @return {Promise} A form submit process.
      */
    submit() {
      return this
        .toWait(true)
        .then(this.toRestorePassword)
        .then(this.toWait)
    },

    /**
      * Redirect to Auth index.
      */
    toRedirect() {
      this.goRoute('auth')
    },

    /**
      * Returns a reset usr-user form process.
      * @return {Object} A reset process.
      */
    toRefresh() {

      // set user data
      this.usrUser.email = this.getQuery('email')
      this.usrUser.password = ''
      this.usrUser.repeat_password = ''
      this.usrUser.token = this.getParam('token')

      // set readonly email
      this.formProps.items[0].props.readonly = this.usrUser.email != undefined && this.usrUser.email != ''
    },

    /**
      * Returns an restore password usr-user process.
      * @param  {Object}  model - the usr-user model
      * @return {Promise} A update process.
      */
    toRestorePassword() {
      return UsrUserService
        .restorePassword(this.usrUser)
        .then(this.onSuccess)
        .then(this.toRedirect)
        .catch(this.onError)
    },
  },
  mounted() {
    this.toRefresh()
  },
}