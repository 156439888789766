// model
import Model from '@/models/Model'

/**
 * This class describes a StaAttentionStudentHistory model.
 * @class StaAttentionStudentHistory (name)
 */
export default class StaAttentionStudentHistoryModel extends Model {

  /**
   * 
   */
  get attention_type_description() {
    return this.getProperty('attention_type.description', '')
  }
  /**
   * 
   */
  get facilitator_full_name() {
    return `${ this.getProperty('facilitator.first_names', '') } ${ this.getProperty('facilitator.last_name1', '') }`
  }

  /**
   * Define the StaAttentionStudentHistory resource.
   * @return {String} StaAttentionStudentHistory endpoint name.
   */
  resource() {
    return 'attention/student_history'
  }

  /**
   * 
   */
  get status_description() {
    return this.getProperty('status.description', '')
  }
}