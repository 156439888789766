// required modules
import Model from '@/models/Model'

/**
 * This class describes a AthLogin model.
 * @class AthLogin (name)
 */
export default class AthLoginModel extends Model {

  /**
   * Define the AthLogin resource.
   * @return {String} AthLogin endpoint name.
   */
  resource() {
    return 'login'
  }

  /**
   * Return a create representation.
   * @return {Object} AthLogin create model.
   */
  toCreate() {

    // required data
    this.username = this.username.toLowerCase()

    // create payload
    return this.reduce([
      'username', 
      'password',
    ])
  }
}