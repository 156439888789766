// layout
import RLayoutModule from '@/components/layouts/LayoutModule'

// views
import UsrProgramCreateView from '@/views/User/UsrProgram/create'
import UsrProgramListView from '@/views/User/UsrProgram/list'
import UsrProgramUpdateView from '@/views/User/UsrProgram/update'

// routes
export default {
  path: 'programas',
  component: RLayoutModule,
  children: [
    {
      name: 'usr-program-list',
      path: '',
      component: UsrProgramListView,
    },
    {
      name: 'usr-program-create',
      path: 'crear',
      component: UsrProgramCreateView,
      meta: {
        title: 'Crear programa'
      },
    },
    {
      name: 'usr-program-update',
      path: ':id/editar',
      component: UsrProgramUpdateView,
      meta: {
        title: 'Editar programa'
      },
    },
  ],
  meta: {
    title: 'Programas'
  },
  props: {
    indexText: 'Inicio',
  },
}
