// required modules
import Model from '@/models/Model'

/**
 * This class describes a NtMailRrssLogo model.
 * @class NtMailRrssLogo
 */
export default class NtMailRrssLogoModel extends Model {

  /**
   * Define the NtMailRrssLogo resource.
   * @return {String} NtMailRrssLogo endpoint name.
   */
  resource() {
    return 'mail_rrss_logo'
  }
}