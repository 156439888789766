// model
import Model from '@/models/Model'

/**
 * This class describes a ASchedule model.
 * @class ASchedule (name)
 */
export default class AScheduleModel extends Model {

  /**
   * Define the ASchedule resource.
   * @return {String} ASchedule endpoint name.
   */
  resource() {
    return 'schedule'
  }
}