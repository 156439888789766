// required modules
import { Fields } from 'collection-wrapper'
import StaAttentionGroupService from '@/services/api/StudentAttention/StaAttentionGroup'
import StaAttentionTypeService from '@/services/api/StudentAttention/StaAttentionType'
import { ruleRequired } from 'vuetify2-wrappers/src/rules'

/**
 * This class describes a StaAttention fields collection.
 * @class StaAttentionFields
 */
export default class StaAttentionFields extends Fields {

  /**
   * Returns the StaAttention fields options.
   * @returns {Array} fields options.
   */
  fields() {
    return [

      /**
       * CREATE
       */
      {
        name: 'attention_type_id',
        type: 'select-model',
        info: 'Tipo de atención que posee el facilitador.',
        props: {
          fetch: () => StaAttentionTypeService.toListByLoggedUser().get(),
          // fetch: StaAttentionTypeService.toList(),
          selectProps: {
            itemText: 'descriptionFormatted',
            itemValue: 'id',
            label: 'Tipo de atención',
            menuProps: {
              contentClass: 'elevation-2 mt-2 v-list--dense',
            },
            placeholder: 'Tipo de atención',
            rules: [ruleRequired],
          },
        },
      },
      {
        name: 'attention_datetime',
        type: 'date-time-field',
        info: 'Fecha/Hora inicio que comienza la atención.',
        props: {
          datePickerProps: {
          },
          fieldProps: {
            label: 'Fecha',
            placeholder: 'Fecha',
            rules: [
              ruleRequired,
              this.ruleAboveToday,
            ],
            dateFormat: 'dd-MM-yyyy',
          },
          timePickerProps: {
            format: "24hr",
          },
          reverse: true,
        },
      },
      {
        name: 'attention_time_in_minutes',
        type: 'float-field',
        info: 'Tiempo en minutos que dura la atención.',
        props: {
          hint: 'Minutos.',
          label: 'Tiempo de duración',
          persistentHint: true,
          placeholder: '60',
          reverse: true,
          rules: [ruleRequired],
          prefix: 'min.',
        },
      },
      {
        name: 'cancel_time_in_minutes',
        type: 'float-field',
        info: 'Cuánto tiempo en minutos antes alcanza un estudiante a cancelar la atención.',
        props: {
          hint: 'Minutos.',
          label: 'Tiempo de cancelación',
          persistentHint: true,
          placeholder: '60',
          reverse: true,
          rules: [
            ruleRequired,
          ],
          prefix: 'min.',
        },
      },

      /**
       * CANCEL
       */
      {
        name: 'cancel_comment',
        type: 'textarea',
        flex: {
          cols: 12,
        },
        props: {
          label: 'Comentario de Cancelación',
          placeholder: 'Comentario de Cancelación',
          rules: [
            ruleRequired,
          ],
        },
      },

      /**
       * SEND
       */
      {
        name: 'message_subject',
        type: 'text-field',
        flex: {
          cols: 12,
        },
        props: {
          label: 'Asunto',
          placeholder: 'Asunto', 
        },
      },
      {
        name: 'message_body',
        type: 'editor',
        flex: {
          cols: 12,
        },
        props: {
          label: 'Mensaje',
          placeholder: 'Mensaje', 
        },
      },
      {
        name: 'send_email',
        type: 'switch',
        flex: {
          cols: 12,
          sm: 6,
        },
        props: {
          label: 'Enviar correo electrónico',
        },
      },
      {
        name: 'target',
        type: 'select',
        info: 'Todos los estudiante: Estudiantes pendientes por atender, cancelados por facilitador y atendidos. o Estudiante(s) pendientes: Estudiantes pendientes por atender.',
        flex: {
          cols: 12,
          sm: 6,
        },
        props: {
          items: [
            {
              text: 'A todos los estudiantes',
              value: 1,
            },
            {
              text: 'Solo estudiante(s) pendientes',
              value: 2,
            },
          ],
          label: 'Publico objetivo',
          menuProps: {
            contentClass: 'elevation-2 v-list--dense mt-2',
          },
          placeholder: 'Publico objetivo', 
        },
      },

      /**
       * SCHEDULE DETAIL
       */
      {
        name: 'attention_type.description',
        type: 'text-field',
        props: {
          label: 'Tipo',
          placeholder: 'Tipo', 
        },
      },
      {
        name: 'status.description',
        type: 'text-field',
        props: {
          label: 'Estado',
          placeholder: 'Estado', 
        },
      },
      {
        name: 'facilitator_name_case',
        type: 'text-field',
        props: {
          label: 'Facilitador',
          placeholder: 'Facilitador',
          readonly: true, 
        },
      },      
      {
        name: 'attention_end_datetime',
        type: 'date-time-field',
        info: 'Fecha/Hora que termina la atención.',
        props: {
          fieldProps: {
            label: 'Termino',
            placeholder: 'Termino',
            readonly: true,
          },
          datePickerProps: {
            readonly: true,
          },
          timePickerProps: {
            readonly: true,
          },
        },
      },
      {
        name: 'attention_type_campus_data_place',
        type: 'text-field',
        flex: {
          cols: 12,
          sm: 6,
        },
        props: {
          label: 'Lugar',
          placeholder: 'Lugar',
          readonly: true, 
        },
      }, 
    ]
  }

  /**
   * 
   */
  ruleAboveToday(value) {

    //
    const dateValue = value.split(' ').shift().split('-').reverse().join('-')
    const timeValue = value.split(' ').pop()
    const realValue = `${dateValue} ${timeValue}`
    const todayDate = new Date(Date.now())
    const inputDate = new Date(realValue)

    //
    return todayDate < inputDate || 'La fecha debe ser mayor a la fecha actual.'
  }

  /**
   * Returns the StaAttention create fields.
   * @returns {Collection} fields collection.
   */
  static toCancel() {
    return this.reduce('name', [
      'cancel_comment',
    ])
  }

  /**
   * Returns the StaAttention create fields.
   * @returns {Collection} fields collection.
   */
  static toCreate(props = {}) {
    return this.reduce('name', [
      'attention_type_id',
      'attention_datetime',
      'attention_time_in_minutes',
      'cancel_time_in_minutes',
    ])
    .map(item => {
      //
      if (item.name === 'attention_datetime') {
        item.props.datePickerProps['allowedDates']    = props.allowedDates
        item.props.timePickerProps['allowedHours']    = props.allowedHours
        item.props.timePickerProps['allowedMinutes']  = props.allowedMinutes
      }
      //
      return item
    })
  }

  /**
   * Returns the StaAttention create fields.
   * @returns {Collection} fields collection.
   */
  static toScheduleDetail(props = {}) {
    return this.init().whereIn('name', [
      'attention_datetime',
      'status.description',
      'attention_type.description',
      'facilitator_name_case',
      'attention_end_datetime',
      'attention_type_campus_data_place',
    ])
    .each(item => {
      if (item.name == 'attention_datetime') {
        item.props.fieldProps.readonly = true
        item.props.fieldProps.rules = []
        item.props.datePickerProps = {readonly: true}
        item.props.timePickerProps = {readonly: true}
      }
      item.props.readonly = true
    })
  }

  /**
   * Returns the StaAttention create fields.
   * @returns {Collection} fields collection.
   */
  static toSend(props) {
    let many = props.attention_students_readonly.length > 1
    return this.init().whereIn('name', [
      'send_email',
      'target',
      'message_subject',
      'message_body',
    ])
    .each(item => {
      // if (item.name == 'target') {
      //   item.props.items[0].text = many ? 'Los estudiantes' : 'El estudiante'
      // }
    })
  }

  static toSendStudents() {
    return this.init().whereIn('name', [
      'send_email',
      'message_subject',
      'message_body',
    ])
  }

  /**
   * Returns the StaAttention create fields.
   * @returns {Collection} fields collection.
   */
  static toSendFacilitator() {
    return this.reduce('name', [
      'send_email',
      'message_subject',
      'message_body',
    ])
  }

  /**
   * Returns the StaAttention update fields.
   * @returns {Collection} fields collection.
   */
  static toUpdate() {
    return this.reduce('name', [
      'attention_type_id',
      'attention_datetime',
      'attention_time_in_minutes',
      'cancel_time_in_minutes',
    ])
  }
}
