// required modules
import Model from '@/models/Model'

/**
 * This class describes a AAverageSessions model.
 *
 * @class AAverageSessions (name)
 */
export default class AAverageSessionsModel extends Model {

  /**
   * Define the AAverageSessions resource.
   *
   * @return {String} AAverageSessions endpoint name.
   */
  resource() {
    return 'average_sessions'
  }
}