// required modules
import RptReportFilters from '@/views/Report/RptReport/filters'
import RptReportHeaders from '@/views/Report/RptReport/headers'
import RptReportService from '@/services/api/Report/RptReport'

// component
export default {
  name: 'rpt-report-list',
  computed: {

    /**
     *
     */
    tableProps() {
      return {
        filters: RptReportFilters.toList(),
        headers: RptReportHeaders.toList(),
        service: RptReportService.from([
          RptReportService.shape({
            id    : 'activity',
            key   : 1,
            title : 'Cantidad de Actividades realizadas (general y por tipo de atención)',
          }),
          RptReportService.shape({
            id    : 'activity',
            key   : 2,
            title : 'Nivel de Asistencia (general y por tipo de atención)',
          }),
          RptReportService.shape({
            id    : 'activity',
            key   : 'ASI001',
            title : 'Indicador de Satisfacción (general y por tipo de atención)',
          }),
        ]),
      }
    }
  },
  methods: {

    /**
     * 
     */
    onReport(event) {
      this.goRoute('rpt-report-activities-chart', { key: event.item.key })
    },
  },
}