//
import store from '@/store'

// survey
const ALLOWED_ROUTES = [
  '/',
  '/auth',
  '/encuesta/responder',
]

// auth
const AUTH_ALLOWED_ROUTES = [
  '/auth',
  '/encuesta/responder',
]

/**
 * 
 * @param {object} options 
 */
export default function(options) {

  //
  if (options.to.path) {

    //
    const hasPendingSurveys   = store.getters.hasPendingSurveys
    const isLogged            = store.getters.isLogged
    const hasAllowedRoute     = ALLOWED_ROUTES.some(route => options.to.path.includes(route))
    const hasAuthAllowedRoute = AUTH_ALLOWED_ROUTES.some(route => options.to.path.includes(route))
    const hasBlockedRoute     = hasPendingSurveys && !hasAllowedRoute
    const nextRoute           = hasBlockedRoute ? '/' : isLogged || hasAuthAllowedRoute ? undefined : '/auth'

    //
    // console.log({ nextRoute }, 'survey')

    // return nextRoute
    // if (nextRoute) {
      options.next(nextRoute)
    // }
  }
}