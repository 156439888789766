// service
import Service from '@/services/api/Service'

// model
import UsrStudentModel from '@/models/User/UsrStudent'

/**
 * This class describes a UsrStudent service.
 * @class UsrStudent (name)
 */
export default class UsrStudentService extends Service {

  /**
   * Define the UsrStudent model.
   * @return {String} UsrStudent resource.
   */
  static model() {
    return UsrStudentModel
  }

  /**
   * 
   */
  static toList() {
    return this.fetcher({
      includes:[
        'careers'
      ],
      sortBy:[
        'first_names'
      ]
    })
  }
  
  /**
   * 
   */
  static toListSortByFirstNames(payload = {}) {
    let filter = payload
    // console.log("filter",{ filter })
    let sortBy = ['first_names']
    return (params) => {
      // console.log("params",{ params })
      return this.model().include('careers').params({ sortBy,...params, headers: undefined, filter }).get()
    }
  }

  /**
   * Returns the user update email promise
   * @param {UsrStudentModel} usrStudent 
   * @returns {Promise} update email promise
   */
  static updateEmail(usrStudent) {

    // update email data
    const updateEmailData  = usrStudent.toEmail()
    const updateEmailModel = usrStudent.updateEmail()
    // const updateEmailSuccessMessage = usrStudent.getMessage('update_email_success')

    // update email promise
    return updateEmailModel.sync(updateEmailData)//.then(updateEmailSuccessMessage)
  }

  /**
   * Returns the user upload promise
   * @param {UsrStudentModel} usrStudent 
   * @returns {Promise} upload promise
   */
  static upload(usrStudent) {

    //
    const uploadData = usrStudent.toUpload()

    //
    return usrStudent.request({
      method: 'post',
      url: `${ usrStudent.fullURL() }upload`,
      data: uploadData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(this.uploadVerify)
  }

  static toUpload() {
    return this.shape({
      id: 'upload'
    })
  }

  static timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  /**
   * 
   */
  static uploadVerify = async (processResponse) => {

    //
    console.log('verify')
    
    //
    let usrStudentUploadProcessId = processResponse.data.summary.process_id
    let usrStudentUpload = this.toUpload()

    //
    await Promise.all([
      this.timeout(2000)
    ])

    //
    return usrStudentUpload.request({
      method: 'post',
      url: `${ usrStudentUpload.fullURL() }/${ usrStudentUploadProcessId }/status`,
    })
    .then(statusResponse => {
      if (statusResponse.data.status.upload_completed) {
        console.log('complete')
        return Promise.resolve(statusResponse)
      } else {
        console.log('continue')
        return this.uploadVerify(processResponse)
      }
    })
  }

  /**
   * 
   */
  static resumeVerify(res) {
    //console.log(res)
    return res
  }
}