// required modules
import VwList from 'vuetify2-wrappers/src/components/list'

// component
export default {
  extends: VwList,
  props: {

    /**
     * 
     */
    cardProps: {
      type: Object,
      default: () => ({
        elevation: 0,
        style: {
          border: '1px solid white',
        }
      }),
    },

    /**
     * 
     */
    textProps: {
      type: Object,
      default: () => ({
        class: 'px-0',
      }),
    },

    /**
     * 
     */
    titleProps: {
      type: Object,
      default: () => ({
        class: 'primary white--text',
      }),
    },
  },
}