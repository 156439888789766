// required modules
import { Headers } from 'collection-wrapper'

/**
 * This class describes a UsrUser headers collection.
 *
 * @class UsrUserHeaders (name)
 */
export default class UsrUserHeaders extends Headers {

  /**
   * Returns the UsrUser headers options.
   *
   * @returns {Array} headers options.
   */
  headers() {
    return [
      {
        text: '#',
        value: 'id',
        sortable: true,
      },
      {
        text: 'Rut',
        value: 'rut',
      },
      {
        text: 'Nombre(s)',
        value: 'first_names',
        sortable: 'name',
      },
      {
        text: 'Primer apellido',
        value: 'last_name1',
      },
      {
        text: 'Segundo apellido',
        value: 'last_name2',
      },
      {
        text: 'Usuario',
        value: 'username',
      },
      {
        text: 'Correo electrónico',
        value: 'email',
      },
      {
        text: 'Carrera(s)',
        value: 'careers_description',
      },
      {
        text: 'Cohorte',
        value: 'careers_year',
      },
      {
        text: 'Estado',
        value: 'is_disabled',
        align: 'center',
        format: val => val ? 'Deshabilitada' : 'Habilitada'
      },
      {
        text: 'Creación',
        value: 'created_at_short',
        sortable: 'created_at',
        align: 'right',
      },
      {
        text: 'Carrera(s)',
        value: 'careers[0].description',
      },
    ]
  }

  /**
   * Returns the UsrUser facilitator list headers.
   *
   * @returns {Collection} headers collection.
   */
  static toFacilitatorList() {
    return this.reduce('value', [
      'id', 
      'first_names', 
      'last_name1', 
      'username', 
      'created_at',
    ])
  }

  /**
   * Returns the UsrUser list headers.
   *
   * @returns {Collection} headers collection.
   */
  static toList() {
    return this.reduce('value', [ 
      'id', 
      'rut', 
      'first_names', 
      'last_name1', 
      'username', 
      'is_disabled', 
      'created_at_short',
    ])
  }

  /**
   * Returns the UsrUser quota list headers.
   *
   * @returns {Collection} headers collection.
   */
  static toQuotaList() {
    return this.reduce('value', [
      'rut', 
      'first_names', 
      'last_name1',
    ])
  }

  /**
   * Returns the UsrUser student list headers.
   *
   * @returns {Collection} headers collection.
   */
  static toStudentList() {
    return this.reduce('value', [
      'rut', 
      'first_names', 
      'last_name1', 
      'username', 
      'email', 
      'careers_description', 
      'careers_year',
    ])
  }

  /**
   * @description
   * Returns the UsrUser list headers.
   *
   * @returns {Collection} headers collection.
   */
  static toTargetList() {
    return this.reduce('value', [ 
      'id', 
      'rut', 
      'first_names', 
      'last_name1',
      'last_name2', 
      'careers[0].description', 
    ])
  }
}
