// required modules
import Model from '@/models/Model'

/**
 * This class describes a ASessionQuantity model.
 *
 * @class ASessionQuantity (name)
 */
export default class ASessionQuantityModel extends Model {

  /**
   * Define the ASessionQuantity resource.
   *
   * @return {String} ASessionQuantity endpoint name.
   */
  resource() {
    return 'session_quantity'
  }
}