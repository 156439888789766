// model
import Model from '@/models/Model'

/**
 * This class describes a ALeave model.
 * @class ALeave (name)
 */
export default class ALeaveModel extends Model {

  /**
   * Define the ALeave resource.
   * @return {String} ALeave endpoint name.
   */
  resource() {
    return 'leave'
  }
}