// required modules
import NtMailContactFilters from '@/views/Notification/NtMailContact/filters'
import NtMailContactHeaders from '@/views/Notification/NtMailContact/headers'
import NtMailContactService from '@/services/api/Notification/NtMailContact'

// component
export default {
  name: 'nt-mail-contact-list',
  computed: {

    /**
     * 
     */
    tableProps() {
      return {
        filters: NtMailContactFilters.toList(),
        headers: NtMailContactHeaders.toList(),
        service: NtMailContactService.toList(),
      }
    }
  },
  methods: {

    /**
     * 
     */
    onClickCreate() {
      this.goRoute('nt-mail-contact-create')
    },

    /**
     * Show a delete NtMailContact confirm toast
     */
    onClickDelete() {
      this.$toaster('¿Confirma la eliminación del contacto seleccionado?', 'confirmar', this.toDelete)
    },

    /**
     * Set the selected NtMailContact table
     * 
     * @param  {Object}  event - the click item payload
     */
    onClickItem(event) {
      this.selected = event.item
    },

    /**
     * 
     */
    onClickUpdate(event) {
      this.goRoute('nt-mail-contact-update', { id: event.item.id })
    },

    /**
     * The delete NtMailContact error process.
     * 
     * @param  {Object}  event - the error delete payload
     */
    onError(event) {
      this.getErrorMessage(event).then(this.getErrorToast)      
    },

    /**
     * The delete NtMailContact success process.
     * 
     * @param  {Object}  event - the success delete payload
     */
    onSuccessDelete(event) {
      this.getSuccessMessage(event).then(this.getSuccessToast).then(this.toRefresh)    
    },

    /**
     * Return a delete NtMailContact promise.
     * 
     * @param  {NtMailContactModel}  model - the NtMailContact model
     * @return {Promise} A delete process.
     */
    toDelete() {
      return NtMailContactService.delete(this.selected).then(this.onSuccessDelete).catch(this.onError)
    },

    /**
     * Refresh the NtMailContact list
     */
    toRefresh() {
      this.getRef('table').refresh()
    },
  },
}