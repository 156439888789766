// required modules
import { Headers } from 'collection-wrapper'

// services
import StaAttentionTypeOrientationListService from '@/services/api/StudentAttention/StaAttentionTypeOrientationList'

/**
 * This class describes a StaAttentionStudent headers collection.
 * @class StaAttentionStudent
 */
export default class StaAttentionStudentHeaders extends Headers {

  /**
   * Returns the StaAttentionStudent headers options.
   * @returns {Array} headers options.
   */
  headers() {
    return [
      {
        text: 'Rut',
        value: 'student_rut',
      },
      {
        text: 'Estudiante',
        value: 'student_first_names',
      },
      {
        text: 'Email',
        value: 'student_email',
      },
      {
        text: 'Nombre',
        value: 'student_name',
      },
      {
        text: 'Teléfono',
        value: 'student_phone',
      },
      {
        text: 'Estado',
        value: 'attention_student_status_id',
        component: 'v-select',
        props: {
          items: [
            {
              text: 'Por Atender',
              value: 1,
            },
            {
              text: 'Atendido',
              value: 2,
            },
            {
              text: 'No Asistió',
              value: 5,
            },
          ],
          hideDetails: true,
          singleLine: true,
          dense: true,
          solo: true,
          flat: true,
        },
      },
      {
        text: 'Comentario',
        value: 'post_attention_observation',
        component: 'v-textarea',
        props: {
          hideDetails: true,
          singleLine: true,
          dense: true,
          solo: true,
          flat: true,
          rows: 1,
        },
      },
      {
        text: 'Observación',
        value: 'attention_type_orientation_list_id',
        component: 'vw-select-model',
        props: {
          fetch: () => StaAttentionTypeOrientationListService.toListAll(),
          selectProps: {
            itemText: 'description',
            itemValue: 'id',
          },
        },
      },
      {
        text: 'Comentario',
        value: 'post_attention_observation_read',
      },
    ]
  }

  /**
   * Returns the StaAttentionStudent list headers.
   * @returns {Collection} headers collection.
   */
  static toComplete(props = {}) {

    // orientation type
    if (props.attention_type_id === 4) {
      return this.reduce('value', [
        'student_name',
        'attention_student_status_id',
        'attention_type_orientation_list_id',
      ])
    }

    // default
    return this.reduce('value', [
      'student_name',
      'attention_student_status_id',
      'post_attention_observation',
    ])
  }

  /**
   * Returns the StaAttentionStudent list headers.
   * @returns {Collection} headers collection.
   */
  static toList() {
    return this.reduce('value', [
      'student_rut',
      'student_first_names',
      'student_email',
      'post_attention_observation_read',
    ])
  }

  /**
   * Returns the StaAttentionStudent list headers.
   * @returns {Collection} headers collection.
   */
  static toDetailList() {
    return this.reduce('value', [
      'student_rut',
      'student_first_names',
      'student_email',
      'student_phone',
    ])
  }
}
