// model
import Model from '@/models/Model'

/**
 * This class describes a ActActivityGroup model.
 * @class ActActivityGroup (name)
 */
export default class ActActivityGroupModel extends Model {

  /**
   * Define the ActActivityGroup resource.
   * @return {String} ActActivityGroup endpoint name.
   */
  resource() {
    return 'activity_group'
  }
}