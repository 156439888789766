// required modules
import Service from '@/services/api/Service'
import SrvPendingSurveyModel from '@/models/Survey/SrvPendingSurvey'

/**
 * This class describes a SrvPendingSurvey service.
 * @class
 */
export default class SrvPendingSurveyService extends Service {

  /**
   * Define the SrvPendingSurvey model.
   * @return {Function} PendingSurvey resource.
   */
  static model() {
    return SrvPendingSurveyModel
  }
}