// required modules
import { Fields } from 'collection-wrapper'
import { ruleAlphaNumber, ruleMax, ruleMin, ruleRequired } from 'vuetify2-wrappers/src/rules'

/**
 * This class describes a TtsTutorship fields collection.
 * @class
 */
export default class TtsTutorshipFields extends Fields {

  /**
   * Returns the TtsTutorship fields options.
   * @returns {Array} fields options.
   */
  fields() {
    return [
      {
        name: 'description',
        type: 'text-field',
        props: {
          label: 'Nombre',
          placeholder: 'Nombre',
          rules: [ruleRequired],
        },
      },
      {
        name: 'long_description',
        type: 'textarea',
        flex: {
          cols: 12,
        },
        props: {
          label: 'Descripción',
          placeholder: 'Descripción',
        },
      },
      {
        name: 'created_at',
        type: 'text-field',
        props: {
          label: 'Fecha de creación',
          placeholder: 'Fecha de creación',
        },
      },

      /**
       * UPLOAD
       */
      {
        name: 'file',
        type: 'file-input',
        flex: {
          cols: 12
        },
        props: {
          accept: ".xlsx",
          label: 'Archivo en formato Excel',
          showSize: true,
        },
      },
    ]
  }

  /**
   * Returns the TtsTutorship create fields.
   * @returns {Collection} fields collection.
   */
  static toCreate() {
    return this.reduce('name', [
      'description',
      'long_description',
    ])
  }

  /**
   * Returns the TtsTutorship create fields.
   * @returns {Collection} fields collection.
   */
  static toDetail() {
    return this.init().whereIn('name', [
      'description',
      'long_description',
      'created_at',
    ]).each(item => {
      item.flex = {
        cols: 12,
      }
      item.props.readonly = true
    })
  }

  /**
   * Returns the TtsTutorship update fields.
   * @returns {Collection} fields collection.
   */
  static toUpdate() {
    return this.reduce('name', [
      'description',
      'long_description',
    ])
  }

  /**
   * Returns the TtsTutor upload fields.
   * @returns {Collection} fields collection.
   */
  static toUpload() {
    return this.reduce('name', [
      'file',
    ])
  }
}
