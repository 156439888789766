// required modules
import { Fields } from 'collection-wrapper'
import UsrCareerModel from '@/models/User/UsrCareer'
import UsrUniversityCampusService from '@/services/api/User/UsrUniversityCampus'
/**
 * This class describes a UsrUser filters collection.
 *
 * @class UsrUserFilters (name)
 */
export default class UsrUserFilters extends Fields {

  /**
   * Returns the UsrUser filters options.
   *
   * @returns {Array} filters options.
   */
  fields() {
    return [
      {
        name: 'university_campus_id',
        type: 'autocomplete-model',
        props: {
          clearable: true,
          fetch: UsrUniversityCampusService.toList(),
          limit: 15,
          autocompleteProps: {
            clearable: true,
            itemText: 'description',
            itemValue: 'id',
            label: 'Sede',
            multiple: false,
            placeholder: 'Sede',
          },
        },
      },
    {
      name: 'rut',
      type: 'text-field',
      props: {
        label: 'RUT',
      },
    },
    {
      name: 'email',
      type: 'text-field',
      props: {
        label: 'Correo electrónico',
      },
    },
    {
      name: 'role',
      type: 'select',
      props: {
        clearable: true,
        items: [
          {
            text: 'Super Usuario',
            value: 'system',
          },
          {
            text: 'Administrador',
            value: 'administrator',
          },
          {
            text: 'Facilitador',
            value: 'facilitator',
          },
          {
            text: 'Estudiante',
            value: 'student',
          },
        ],
        label: 'Rol',
        menuProps: {
          contentClass: 'elevation-2 v-list--dense mt-2',
        },
      },
    },
    {
      name: 'career',
      type: 'select-model',
      props: {
        selectProps: {
          itemText: 'description',
          itemValue: 'id',
          label: 'Carreras',
          menuProps: {
            contentClass: 'elevation-2 v-list--dense mt-2',
          },
        },
        model: UsrCareerModel,
        limit: 20,
      },
    },
    {//1978 a actual
      name: 'year',
      type: 'select',
      props: {
        clearable: true,
        items: this.getYears(),
        label: 'Cohorte',
        menuProps: {
          contentClass: 'elevation-2 v-list--dense mt-2',
        },
      },
    },    
    {
      name: 'career_by_year',
      type: 'select',
      props: {
        items: [
          {
            text: 'Igual que',
            value: 'eq',
          }, 
          {
            text: 'Menor que',
            value: 'lt',
          }, 
          {
            text: 'Menor o igual que',
            value: 'lte',
          }, 
          {
            text: 'Mayor que',
            value: 'gt',
          }, 
          {
            text: 'Mayor o igual que',
            value: 'gte',
          }, 
        ],
        label: 'Opciones de cohorte por carrera',
        menuProps: {
          contentClass: 'elevation-2 v-list--dense mt-2',
        },
      },
    },
    ]
  }

  /**
   * Returns the UsrUser list filters.
   *
   * @returns {Collection} filters collection.
   */
  static toFacilitatorList() {
    return this.reduce('name', [
      'university_campus_id',
      'email',
    ])
  }

  /**
   * Returns the UsrUser list filters.
   *
   * @returns {Collection} filters collection.
   */
  static toList() {
    return this.reduce('name', [
      'university_campus_id',
      'rut',
      'email',
      'role',
      'career',
    ])
  }

  /**
   * Returns the UsrUser list filters.
   *
   * @returns {Collection} filters collection.
   */
  static toStudentList() {
    return this.reduce('name', [
      'university_campus_id',
      'rut',
      'email',
      'career',
      'year',
      'career_by_year',
    ])
  }
  getYears() {
    let d =  new Date ()
    let year = d.getFullYear()
    var years = []
    for (var i = year; i > 1978; i--){years.push(i)}
    return years
  } 
}
