// model
import Model from '@/models/Model'

/**
 * This class describes a AthService model.
 * @class AthService (name)
 */
export default class AthServiceModel extends Model {

  /**
   * Reset the AthService from environment.
   * @return {AthService} AthService instance.
   */
  resetFromEnvironment() {

    // reset attributes
    this.api_auth_key = this.VUE_APP_API_AUTH_KEY
    this.api_auth_url = this.VUE_APP_API_AUTH_ROUTE
    this.api_key = this.VUE_APP_API_KEY
    this.api_url = this.VUE_APP_API_ROUTE

    // return instance
    return this
  }

  /**
   * Reset the AthService from store.
   * @return {AthService} AthService instance.
   */
  resetFromStore() {

    // reset attributes
    this.api_auth_key = this.apiAuthKey
    this.api_auth_url = this.apiAuthUrl
    this.api_key = this.apiKey
    this.api_url = this.apiUrl

    // return instance
    return this
  }

  /**
   * Define the AthService resource.
   * @return {String} AthService endpoint name.
   */
  resource() {
    return 'service'
  }

  /**
   * Return a update representation.
   * @return {Object} AthService model.
   */
  toUpdate() {
    return this.reduce([
      'api_auth_key', 
      'api_auth_url', 
      'api_key', 
      'api_url', 
    ])
  }
}