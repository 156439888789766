// required components
import TtsTutorFields from '@/views/Tutorship/TtsTutor/fields'
import TtsTutorService from '@/services/api/Tutorship/TtsTutor'

// component
export default {
  name: 'tts-tutor-create',
  computed: {

    /**
     * Gets the TtsTutor create form props.
     *
     * @return {Object} The create form props.
     */
    formProps() {
      return {
        items: TtsTutorFields.toCreate(),
      }
    }
  },
  data() {
    return {
      ttsTutor: TtsTutorService.shape({
        value: ''
      }),
    }
  },
  methods: {

    /**
     * Reset the TtsTutor data.
     */
    onReset() {
      this.ttsTutor.value = ''
    },

    /**
     * Returns a submit TtsTutor create form.
     *
     * @return {Promise} A form submit process.
     */
    onSubmit() {
      return this.toWait(true).then(this.toCreate).then(this.toWait)
    },

    /**
     * Returns an create TtsTutor success process.
     *
     * @param  {Object}  event - the event success payload.
     */
    onSuccessCreate(event) {
      this.onSuccess('Tutor creado exitosamente.')
    },

    /**
     * Returns an create TtsTutor process.
     *
     * @return {Promise} A create process.
     */
    toCreate() {
      return TtsTutorService.create(this.ttsTutor).then(this.onSuccessCreate).then(this.toRedirect).catch(this.onError)
    },

    /**
     * Redirect to TtsTutor list
     */
    toRedirect() {
      this.goRoute('tts-tutor-list')
    },
  },
}