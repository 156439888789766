// required modules
import { Headers } from 'collection-wrapper'

/**
 * This class describes a TtsTutorship headers collection.
 * @class TtsTutorshipHeaders
 */
export default class TtsTutorshipHeaders extends Headers {

  /**
   * Returns the TtsTutorship headers options.
   * @returns {Array} headers options.
   */
  headers() {
    return [
      {
        text: '#',
        value: 'id',
        sortable: 'id',
      },
      {
        text: 'Nombre',
        value: 'description',
        sortable: 'description',
      },
      {
        text: 'Descripción',
        value: 'long_description',
      },
      {
        text: 'Paralelos',
        align: 'right',
        value: 'parallels_length',
      },
      {
        text: 'Creado',
        value: 'created_at_short',
        sortable: 'created_at',
        align: 'right',
      },
      {
        text: 'Modificado',
        value: 'updated_at_short',
        sortable: 'updated_at',
        align: 'right',
      },
    ]
  }

  /**
   * Returns the TtsTutorship list headers.
   * @returns {Collection} headers collection.
   */
  static toList() {
    return this.reduce('value', [
      'id',
      'description',
      'long_description',
      'parallels_length',
      'created_at_short',
      'updated_at_short',
    ])
  }
}
