// components
import BlgWebNewFields from '@/views/Blog/BlgWebNew/fields'
import BlgWebNewService from '@/services/api/Blog/BlgWebNew'
import BlgWebNewImageService from '@/services/api/Blog/BlgWebNewImage'

// component
export default {
  name: 'blg-web-new-update',
  computed: {

    /**
     * Gets the BlgWebNew update form props.
     * @return {Object} The update form props.
     */
    formProps() {
      return {
        items: BlgWebNewFields.toUpdate(),
      }
    },

    /**
     * 
     */
    imagesProps() {
      return {
        items: BlgWebNewFields.toImage(),
      }
    },
  },
  data() {
    return {
      blgWebNew: {
        value: 'test',
      },
      dialogs: {
        images: false,
      },
    }
  },
  methods: {

    /**
     * 
     */
    onDelete(event) {
      this.$toaster('¿Confirma eliminar la imagen seleccionada?', 'confirmar', () => {
        this.toWaitUploadImage(true).then(() => this.toDelete(event.item)).then(this.toWaitUploadImage)
      })
    },

    /**
     * 
     */
    onMoveBack(event) {
      this.$toaster('¿Confirma mover la imagen una posición atrás?', 'confirmar', () => {
        this.toWaitUploadImage(true).then(() => this.toMoveBack(event.item)).then(this.toWaitUploadImage)
      })
    },

    /**
     * 
     */
    onMoveForward(event) {
      this.$toaster('¿Confirma mover la imagen una posición adelante?', 'confirmar', () => {
        this.toWaitUploadImage(true).then(() => this.toMoveForward(event.item)).then(this.toWaitUploadImage)
      })
    },

    /**
     * Returns an update BlgWebNew success process.
     * @param  {Object}  event - the event success payload.
     */
    onSuccessUpdate(event) {
      this.onSuccess(event)
    },

    /**
     * Returns a reset BlgWebNew form.
     * @return {Promise} A form reset promise.
     */
    onReset() {
      return this.toWait(true).then(this.toRefresh).then(this.toWait)
    },

    /**
     * Returns a submit BlgWebNew form.
     * @return {Promise} A form submit promise.
     */
    onSubmit() {
      return this.toWait(true).then(this.toUpdate).then(this.toWait)
    },

    /**
     * 
     */
    onUploadImage() {
      this.toWaitUploadImage(true).then(this.toUploadImage).then(this.toWaitUploadImage)
    },

    /**
     * 
     */
    toDelete(data) {
      return BlgWebNewImageService.delete(BlgWebNewImageService.shape(data)).then(this.onSuccess).then(this.onReset).catch(this.onError)
    },

    /**
     * 
     */
    toMoveBack(data) {
      return BlgWebNewImageService.moveBack(BlgWebNewImageService.shape(data)).then(this.onSuccess).then(this.onReset).catch(this.onError)
    },

    /**
     * 
     */
    toMoveForward(data) {
      return BlgWebNewImageService.moveForward(BlgWebNewImageService.shape(data)).then(this.onSuccess).then(this.onReset).catch(this.onError)
    },

    /**
     * Returns a refresh BlgWebNew form.
     * @return {Promise} A reset promise.
     */
    toRefresh() {
      return BlgWebNewService.model().include('body,images,campus_visibilities').find(this.getParam('id')).then(blgWebNew => {
        this.blgWebNew = blgWebNew//.reset()
        this.blgWebNew.body = blgWebNew.body.body
      })
    },

    /**
     * Returns an update BlgWebNew promise.
     * @param  {Object}  model - the blg-web-new model
     * @return {Promise} A update promise.
     */
    toUpdate() {
      return BlgWebNewService.update(this.blgWebNew).then(this.onSuccess).then(this.onReset).catch(this.onError)
    },

    /**
     * 
     */
    toUploadImage() {
      return BlgWebNewService.uploadImage(this.blgWebNew).then(this.onSuccess).then(this.onReset).catch(this.onError)
    },

    /**
     * 
     */
    toWaitUploadImage(val = false) {
      return this.getRef('upload-image').setWait(val)
    }
  },
  mounted() {
    this.onReset()
  },
}