// required modules
import UsrFacilitatorService from '@/services/api/User/UsrFacilitator'
import UsrFacilitatorFields from '@/views/User/UsrFacilitator/fields'

//
export default {
  computed: {

    /**
      * Gets the UsrFacilitator form props.
      * 
      * @return {Object} The form props.
      */
    formProps() {
      return {
        items: UsrFacilitatorFields.toCreate(),
      }
    }
  },
  data() {
    return {
      usrFacilitator: UsrFacilitatorService.shape({
        rut: '',
        first_names: '', 
        last_name1: '', 
        last_name2: '', 
        email: '', 
        alternative_email : '', 
        phone_number: '', 
        gender: '',
        university_campus_id: '',
        specialities: [],
        attention_types: [],
      }),
    }
  },
  methods: {

    /**
     * Redirect to UsrFacilitator list
     */
    redirect() {
      this.goRoute('usr-facilitator-list')
    },

    /**
     * Reset the UsrFacilitator data.
     */
    reset() {
      this.usrFacilitator.value = ''
    },

    /**
     * Returns a submit UsrFacilitator form.
     * 
     * @return {Promise} A form submit process.
     */
    submit() {
      return this
        .toWait(true)
        .then(this.toCreate)
        .then(this.toWait)
    },

    /**
     * Returns an create UsrFacilitator process.
     * 
     * @return {Promise} A create process.
     */
    toCreate() {
      return UsrFacilitatorService
        .create(this.usrFacilitator)
        .then(this.toSuccess)
        .catch(this.toError)
    },

    /**
     * Returns an create UsrFacilitator error process.
     * 
     * @param  {Object}  event - error payload
     */
    toError(event) {
      this.getErrorMessage(event).then(this.getErrorToast)
    },

    /**
     * Returns an create UsrFacilitator success process.
     * 
     * @param  {Object}  event - success payload
     */
    toSuccess(event) {
      this.getSuccessMessage(event).then(this.getSuccessToast).then(this.redirect)
    },
  },
}
