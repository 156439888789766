<template lang='pug'>
	<vw-layout-not-found/>
</template>

<script>
	export default {
		mounted() {
			this.setBackground('primary')
		},
		destroyed() {
			this.removeBackground('primary')
		},
	}
</script>