//
import Vue from 'vue'
import VueRouter from 'vue-router'

//
import guard from './guard'
import routes from './routes'

//
Vue.use(VueRouter)

/**
 * { constant_description }
 *
 * @type       {VueRouter}
 */
const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
})

//
router.beforeEach(guard)

//
export default router
