// required modules
import UsrUserFields from '@/views/User/UsrUser/fields'
import UsrUserFilters from '@/views/User/UsrUser/filters'
import UsrUserHeaders from '@/views/User/UsrUser/headers'
import UsrUserService from '@/services/api/User/UsrUser'

// components
export default {
  name: 'usr-user-list',
  computed: {

    /**
     * 
     */
    enableFormProps() {
      return {
        items: UsrUserFields.toEnable(),
      }
    },

    /**
     * 
     */
    tableProps() {
      return {
        filters: UsrUserFilters.toList(),
        headers: UsrUserHeaders.toList(),
        service: UsrUserService.toList(),
      }
    }
  },
  data() {
    return {
      enable: false,
      selected: null,
    }
  },
  methods: {

    /**
     * Click the disable button.
     */
    onClickDisable(event) {
      this.selected = event.item
      this.selected.enable_account = false
    },

    /**
     * Click the enable button.
     */
    onClickEnable(event) {
      this.selected = event.item
      this.selected.enable_account = true
    },

    /**
     * Go to UsrUserRoleView
     * 
     * @param {Object} event - the event click payload 
     */
    onClickRole(event) {
      this.goRoute('usr-user-role', { id: event.item.id })
    },

    /**
     * Refresh the UsrUser list
     */
    refresh() {
      this.getRef('table').refresh()
    },

    /**
     * Submit the enable form.
     */
    submitEnable() {
      return this
        .toTableWait(true)
        .then(this.toEnable)
        .then(this.toTableWait)
        .then(this.refresh)
    },

    /**
     * Returns an enable UsrStudent promise.
     * @return {Promise} A enable promise.
     */
    toEnable() {
      return UsrUserService
        .enableAccount(this.selected)
        .then(this.onSuccess)
        .then(this.refresh)
        .catch(this.onError)
    },

    /**
     * Returns an enable form wait promise.
     * @return {Promise} A enable promise.
     */
    async toTableWait(value = false) {
      return this.getRef('table').setBusy(value)
    },
  },
}