// required modules
import { Headers } from 'collection-wrapper'

/**
 * This class describes a TtsTutor headers collection.
 * @class TtsTutorHeaders
 */
export default class TtsTutorHeaders extends Headers {

  /**
   * Returns the TtsTutor headers options.
   * @returns {Array} headers options.
   */
  headers() {
    return [
      {
        text: '#',
        value: 'id',
        sortable: 'id',
      },
      {
        text: 'RUT',
        value: 'rut',
      },
      {
        text: 'Nombres',
        value: 'first_names',
      },
      {
        text: 'Primer apellido',
        value: 'last_name1',
      },
      {
        text: 'Correo',
        value: 'email',
      },
      {
        text: 'Carrera',
        value: 'career_description',
      },
    ]
  }

  /**
   * Returns the TtsTutor list headers.
   * @returns {Collection} headers collection.
   */
  static toList() {
    return this.reduce('value', [
      // 'id',
      'rut',
      'first_names',
      'last_name1',
      'email',
      'career_description',
    ])
  }
}
