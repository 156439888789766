<template lang="pug">
  v-card.v-card-user( v-bind='_cardProps' )
    v-list( dense subheader )
      v-subheader.px-3 Notificaciones
        v-spacer
        v-btn( icon @click='onClickRefresh' )
          v-icon( small ) mdi-refresh
      #overflow( style='overflow-y: scroll; height:400px;' )
        template( v-for='item in items' )
          v-list-item
            v-list-item-avatar
              v-btn( :color='item[itemDisabled] ? "" : "primary"' icon @click='submit(item)' )
                v-icon( class="text-uppercase" small ) mdi-bell
            v-list-item-content
              v-list-item-title {{ item.message }}
              v-list-item-subtitle {{ item.created_at_short }}
</template>

<script>
  export default {
    computed: {
      
      /**
       * 
       */
      _cardProps() {
        return {
          loading: this.loading,
        }
      },

      /**
       * 
       */
      avatarChar() {
        return this.title.slice(0, 1)
      },
    },
    methods: {

      /**
       * This method is called when the refresh button on the notification 
       * card is clicked.
       * 
       * @fires event:refresh
       * @listens event:onclick
       */
      onClickRefresh() {
        this.$emit('refresh')
      },

      /**
       * 
       */
      submit(item) {
        this.$emit('click-item', { item })
      },
    },
    props: {

      /**
       * 
       */
      actionIcon: {
        type: String,
        default: 'mdi-pencil',
      },

      /**
       * 
       */
      actionText: {
        type: String,
        default: 'salir',
      },

      /**
       * 
       */
      cardProps: {
        type: Object,
        default: () => ({}),
      },

      /**
       * 
       */
      itemDisabled: {
        type: String,
        default: 'read',
      },

      /**
       * 
       */
      items: {
        type: Array,
        default: () => [
          'Habilitar notificaciones',
          'Recordar mensajes',
        ],
      },

      /**
       * 
       */
      loading: {
        type: Boolean,
        default: false,
      },

      /**
       * 
       */
      subtitle: {
        type: String,
        default: 'Rol',
      },

      /**
       * 
       */
      title: {
        type: String,
        default: 'Nombre Usuario',
      },
    }
  }
</script>

<style>
  .v-card-user {
    min-width: 300px;
  }
</style>