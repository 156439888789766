// required modules
import TtsTutorshipParallelFilters from '@/views/Tutorship/TtsTutorshipParallel/filters'
import TtsTutorshipParallelHeaders from '@/views/Tutorship/TtsTutorshipParallel/headers'
import TtsTutorshipParallelService from '@/services/api/Tutorship/TtsTutorshipParallel'

// component
export default {
  name: 'tts-tutorship-parallel-list',
  computed: {

    /**
     *
     */
    tableProps() {
      return {
        filters: TtsTutorshipParallelFilters.toList(),
        headers: TtsTutorshipParallelHeaders.toList(),
        service: TtsTutorshipParallelService.toList(),
      }
    }
  },
  methods: {

    /**
     * 
     */
    onCreate() {
      this.goRoute('tts-tutorship-parallel-create')
    },

    /**
     * 
     */
    onDelete(event) {
      this.$toaster('¿Confirma la eliminación del paralelo de tutoría seleccionado?', 'confirmar', () => {
        return this.toDelete(event.item)
      })
    },

    /**
     * 
     */
    onUpdate(event) {
      this.goRoute('tts-tutorship-parallel-update', { id: event.item.id })
    },

    /**
     * Return a delete TtsTutorshipParallel promise.
     *
     * @param  {TtsTutorshipParallelModel}  model - the TtsTutorshipParallel model.
     * @return {Promise} A delete process.
     */
    toDelete(ttsTutorshipParallel) {
      return TtsTutorshipParallelService.delete(ttsTutorshipParallel).then(this.onSuccess).then(this.toRefresh).catch(this.onError)
    },

    /**
     * Refresh the TtsTutorshipParallel list.
     */
    toRefresh() {
      this.getRef('table').refresh()
    },
  },
}