/**
 * @description
 * User Store Module
 */
export default {
  state: {
    data: {
      email: '',
      names: '',
      last_name1: '',
      last_name2: '',
      phone: '',
      rut: '',
    },
  },
  mutations: {

    /**
     * Sets the user data.
     *
     * @param      {<type>}  state   The state
     * @param      {<type>}  data    The data
     */
    setUserData: (state, data) => {
      state.data = data
    }
  },
  actions: {

    /**
     * Sets the user data.
     *
     * @param      {<type>}  context  The context
     * @param      {<type>}  options  The options
     */
    setUserData: (context, options) => {
      context.commit('setUserData', options.data)
    }
  },
  getters: {

    /**
     * Determines if user data.
     *
     * @param      {<type>}   state   The state
     * @return     {boolean}  True if user data, False otherwise.
     */
    hasUserData: state => !! state.data
  }
}