// required modules
import StaAttentionFilters from '@/views/StudentAttention/StaAttention/filters'
import StaAttentionHeaders from '@/views/StudentAttention/StaAttention/headers'
import StaAttentionService from '@/services/api/StudentAttention/StaAttention'
import UsrUniversityPeriodService from '@/services/api/User/UsrUniversityPeriod'

// component
export default {
  name: 'sta-attention-list',
  computed: {

    /**
     * 
     */
    filtersProps() {
      return {
        isAdministrator: this.$store.getters.isAdministrator
      }
    },

    /**
     * 
     */
    tableProps() {
      return {
        filterData: {
          university_period_id: this.usrActiveUniversityPeriodId,
        },
        filters: StaAttentionFilters.toList(this.filtersProps),
        headers: StaAttentionHeaders.toList(),
        // mapFilter: this.mapFilter,
        service: StaAttentionService.toList(),
      }
    }
  },
  data() {
    return {
      already: false,
      usrLastUniversityPeriodId: 1,
    }
  },
  methods: {

    /**
     * 
     */
    getActiveUniversityPeriod() {
      UsrUniversityPeriodService.getActive().then(res => {
        this.usrActiveUniversityPeriodId = res.id
        this.already = true
      })
    },

    /**
     * Called when click in create button.
     */
    onClickCreate() {
      this.goRoute('sta-attention-create')
    },

    /**
     * Called when click in schedule button.
     */
    onClickSchedule() {
      this.goRoute('sta-attention-schedule')
    },

    /**
     * Called when click in update button.
     */
    onClickUpdate(event) {
      this.goRoute('sta-attention-update', { id: event.item.id })
    },

    /**
     * Refresh the StaAttention list
     */
    refresh() {
      this.getRef('table').refresh()
    },

    /**
     * Show a delete StaAttention confirm toast
     */
    submitDelete() {
      this.$toaster('¿Confirma la eliminación de la atención seleccionada?', 'confirmar', this.toDelete)
    },

    /**
     * Set the selected StaAttention table
     * @param  {Object}  event - the click item payload
     */
    submitItem(event) {
      this.selected = event.item
    },

    /**
     * Return a delete StaAttention promise.
     * @param  {StaAttentionModel}  model - the StaAttention model
     * @return {Promise} A delete process.
     */
    toDelete() {
      return StaAttentionService.delete(this.selected).then(this.toSuccess).catch(this.toError)
    },

    /**
     * The delete StaAttention error process.
     * @param  {Object}  event - the error delete payload
     */
    toError(event) {
      this.getErrorMessage(event).then(this.getErrorToast)      
    },

    /**
     * The delete StaAttention success process.
     * @param  {Object}  event - the success delete payload
     */
    toSuccess(event) {
      this.getSuccessMessage(event).then(this.getSuccessToast).then(this.refresh)    
    },
  },
  mounted() {
    this.getActiveUniversityPeriod()
  }
}