// required modules
import RptReportFilters from '@/views/Report/RptReport/filters'
import RptReportHeaders from '@/views/Report/RptReport/headers'
import RptReportService from '@/services/api/Report/RptReport'

// component
export default {
  name: 'rpt-report-list',
  computed: {

    /**
     *
     */
    tableProps() {
      return {
        filters: RptReportFilters.toList(),
        headers: RptReportHeaders.toList(),
        service: RptReportService.from([
          RptReportService.shape({
            id    : 'attention',
            key   : 'ASQ001',
            title : 'Cantidad de Sesiones realizadas (general y por tipo de atención)',
          }),
          RptReportService.shape({
            id    : 'attention',
            key   : 'ASQ002',
            title : 'Cantidad de Estudiantes Atendidos (general y por tipo de atención)',
          }),
          RptReportService.shape({
            id    : 'attention',
            key   : 'AAP001',
            title : 'Porcentaje de Asistencia (general y por tipo de atención)',
          }),
          RptReportService.shape({
            id    : 'attention',
            key   : 'AAS001',
            title : 'Promedio de Sesiones realizadas por Estudiante por Tipo de Atención',
          }),
          RptReportService.shape({
            id    : 'attention',
            key   : 'AMQ001',
            title : 'Cantidad de Sesiones realizadas por Mes por Tipo de Atención',
          }),
        ]),
      }
    }
  },
  methods: {

    /**
     * 
     */
    onReport(event) {
      this.goRoute('rpt-report-attentions-chart', { key: event.item.key })
    },
  },
}