// model
import Model from '@/models/Model'

/**
 * This class describes a NtMailContact model.
 * @class NtMailContact (name)
 */
export default class NtMailContactModel extends Model {

  /**
   * Define the NtMailContact resource.
   * @return {String} NtMailContact endpoint name.
   */
  resource() {
    return 'mail_contact'
  }

  /**
   * 
   */
  toCreate() {
    return this.reduce([
      'description',
      'email',
      'order',
      'phone_number',
    ])
  }

  /**
   * 
   */
  toUpdate() {
    return this.reduce([
      'description',
      'email',
      'id',
      'order',
      'phone_number',
    ])
  }
}