// required modules
import Model from '@/models/Model'

/**
 * This class describes a SrvSurveyBlock model.
 * @class
 */
export default class SrvSurveyBlockModel extends Model {

  /**
   * 
   */
  get description_prevent() {
    return this.prop('description', '').trim().length ? this.description : ''
  }

  /**
   * 
   */
  get user_answers_length() {
    return this.prop('user_answers.length', 0)
  }

  /**
   * 
   */
  mapField = field => {
    return field.description
  }

  /**
   * 
   */
  mapOption = option => {
    return option.description
  }

  /**
   * 
   */
  reset() {

    //
    this.rules = JSON.parse(this.rules || '[]')
    this.validators = JSON.parse(this.validators || '[]')

    //
    this._fields = this.getProperty('fields', []).slice()
    this._options = this.getProperty('options', []).slice()
    this.fields = this.getProperty('fields', []).map(this.mapField)
    this.options = this.getProperty('options', []).map(this.mapOption)

    //
    if (this.range) {
      this.max_range = this.range.max_range
      this.max_range_label = this.range.max_range_label
      this.min_range = this.range.min_range
      this.min_range_label = this.range.min_range_label
    }

    //
    return this
  }

  /**
   * Define the SrvSurveyBlock resource.
   * @return {String} SrvSurveyBlock endpoint name.
   */
  resource() {
    return 'survey_block'
  }

  /**
   * 
   */
  toTimeStampID() {
    this.id = Date.now(); return this
  }

  /**
   * 
   */
  toUpdate() {
    switch(this.survey_block_identifier_id) {
      case 1  : return this.toUpdateBasic();
      case 2  : return this.toUpdateBasic();
      case 3  : return this.toUpdateBasic();
      case 4  : return this.toUpdateMultiple();
      case 5  : return this.toUpdateMultiple();
      case 6  : return this.toUpdateMultiple();
      case 7  : return this.toUpdateRange();
      case 8  : return this.toUpdateRange();
      case 9  : return this.toUpdateMultipleFields();
      case 10 : return this.toUpdateMultipleFields();
      case 11 : return this.toUpdateBasic();
      case 12 : return this.toUpdateBasic();
      default : return this;
    }
  }

  /**
   * 
   */
  toUpdateBasic() {
    return this.reduce([
      'title',
      'description',
      'survey_block_type_id',
      'survey_block_identifier_id',
      'placeholder',
      'is_required',
      'rules',
      'validators',
    ])
  }

  /**
   * 
   */
  toUpdateMultiple() {
    return this.reduce([
      'title',
      'description',
      'survey_block_type_id',
      'survey_block_identifier_id',
      'placeholder',
      'is_required',
      'options',
      'rules',
      'validators',
    ])
  }

  /**
   * 
   */
  toUpdateMultipleFields() {
    return this.reduce([
      'title',
      'description',
      'survey_block_type_id',
      'survey_block_identifier_id',
      'placeholder',
      'is_required',
      'options',
      'fields',
      'rules',
      'validators',
    ])
  }

  /**
   * 
   */
  toUpdateRange() {

    // check empty max_range_label
    if (this.max_range_label.trim() === '') {
      this.max_range_label = 'Máximo.'
    }

    // check empty min_range_label
    if (this.min_range_label.trim() === '') {
      this.min_range_label = 'Mínimo.'
    }

    // range update body
    return this.reduce([
      'title',
      'description',
      'survey_block_type_id',
      'survey_block_identifier_id',
      'placeholder',
      'max_range',
      'max_range_label',
      'min_range',
      'min_range_label',
      'is_required',
      'rules',
      'validators',
    ])
  }
}