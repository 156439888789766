// service
import Service from '@/services/api/Service'
import SrvSurveySectionModel from '@/models/Survey/SrvSurveySection'

/**
 * This class describes a SrvSurveySection service.
 * @class SrvSurveySection (name)
 */
export default class SrvSurveySectionService extends Service {

  /**
   * Define the SrvSurveySection model.
   * @return {Function} SrvSurveySection resource.
   */
  static model() {
    return SrvSurveySectionModel
  }

  /**
   * 
   */
  static toCreate() {
    return this.shape({
      title: '',
      description: '',
      blocks: [],
    })
  }
}