//
export default {

  /**
   * { function_description }
   *
   * @param      {<type>}  Vue      The vue
   * @param      {<type>}  options  The options
   */
  install: function (Vue, options) {

    //
    Vue.mixin({
      computed: {

        /**
         * { function_description }
         *
         * @return     {<type>}  { description_of_the_return_value }
         */
        authStore() {
          return this.$store.state.AuthModule.data
        },

        /**
         * { function_description }
         *
         * @return     {<type>}  { description_of_the_return_value }
         */
        roleStore() {
          return this.$store.state.RoleModule.data
        },

        /**
         * { function_description }
         *
         * @return     {<type>}  { description_of_the_return_value }
         */
        userStore() {
          return this.$store.state.UserModule.data
        },
      },
      methods: {

        /**
         * { function_description }
         *
         * @param      {<type>}  res     The resource
         * @return     {<type>}  { description_of_the_return_value }
         */
        mapResponseData(res) {
          return res && res.data ? res.data : null
        },

        /**
         * Sets the auth data.
         *
         * @param      {<type>}  data    The data
         */
        setAuthData(data) {
          this.$store.dispatch('setAuthData', {
            data,
          })
        }, 

        /**
         * Sets the role data.
         *
         * @param      {<type>}  data    The data
         */
        setRoleData(data) {
          this.$store.dispatch('setRoleData', {
            data,
          })
        }, 

        /**
         * Sets the user data.
         *
         * @param      {<type>}  data    The data
         */
        setUserData(data) {
          this.$store.dispatch('setUserData', {
            data,
          })
        },

        /**
         * { function_description }
         *
         * @param      {boolean}  [value=false]  The value
         * @return     {<type>}   { description_of_the_return_value }
         */
        toWait(value = false) {
          if (this.$refs.form !== undefined) {
            return this
              .$refs
              .form
              .wait(value)
          }
        },
      },
    })
  }
}