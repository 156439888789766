// required modules
import { Fields } from 'collection-wrapper'
import UsrUniversityCampusService from '@/services/api/User/UsrUniversityCampus'

/**
 * This class describes a UsrProgram fields collection.
 * @class UsrProgramFields (name)
 */
export default class UsrProgramFields extends Fields {

  /**
   * Returns the UsrProgram fields options.
   *
   * @returns {Array} fields options.
   */
  fields() {
    return [
      {
        name: 'university_campus_id',
        type: 'autocomplete-model',
        props: {
          clearable: true,
          fetch: UsrUniversityCampusService.toList(),
          limit: 15,
          autocompleteProps: {
            clearable: true,
            itemText: 'description',
            itemValue: 'id',
            label: 'Sede',
            multiple: false,
            placeholder: 'Sede',
          },
        },
      },
      {
        name: 'description',
        type: 'text-field',
        flex: {
          cols: 12,
          sm: 6,
          md: 9,
        },
        props: {
          label: 'Descripción',
          placeholder: 'Descripción',
        },
      },
    ]
  }

  /**
   * Returns the UsrProgram create fields.
   *
   * @returns {Collection} fields collection.
   */
  static toCreate() {
    return this.reduce('name', [
      'university_campus_id',
      'description',
    ])
  }

  /**
   * Returns the UsrProgram update fields.
   *
   * @returns {Collection} fields collection.
   */
  static toUpdate() {
    return this.reduce('name', [
      'university_campus_id',
      'description',
    ])
  }
}
