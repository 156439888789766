<template lang='pug'>
  v-app#apea
    router-view
</template>

<script>
  export default {
    methods: {

      /**
       * 
       */
      onLocal() {
        this.goRoute('home')
      },
    },
    created() {
      this.$store.dispatch('inzAuthService', { onLocal: this.onLocal })
    }
  }
</script>

<style>
  html {
    overflow-y: auto;
  }
</style>