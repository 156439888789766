// required modules
import { Fields } from 'collection-wrapper'
import UsrFacilitator from '@/services/api/User/UsrFacilitator'
import SrvSurveyStatus from '@/services/api/Survey/SrvSurveyStatus'

/**
 * This class describes a SrvSurvey filters collection.
 *
 * @class SrvSurveyFilters (name)
 */
export default class SrvSurveyFilters extends Fields {

  /**
   * Returns the SrvSurvey filters options.
   *
   * @returns {Array} filters options.
   */
  fields() {
    return [
      {
        name: 'title',
        type: 'text-field',
        props: {
          label: 'Titulo',
          placeholder: 'Titulo',
        },
      },
      {
        name: 'creator_user_id',
        type: 'select-model',
        props: {
          fetch: UsrFacilitator.toList(),
          selectProps: {
            itemText: 'name_case',
            itemValue: 'id',
            label: 'Usuario',
            placeholder: 'Usuario',
          }
        },
      },
      {
        name: 'survey_status_id',
        type: 'select',
        props: {
          // fetch: SrvSurveyStatus.toList(),
          // selectProps: {
          items: [
            {
              text: 'Borrador',
              value: 1,
            },
            {
              text: 'Esperando Respuestas',
              value: 2,
            },
            {
              text: 'Completado',
              value: 3,
            },
          ],
          label: 'Estado',
          placeholder: 'Estado',
          // }
        },
      },
      {
        name: 'is_required',
        type: 'switch',
        props: {
          label: 'Requerida',
          placeholder: 'Requerida',
        },
      },
    ]
  }

  /**
   * Returns the SrvSurvey list filters.
   *
   * @returns {Collection} filters collection.
   */
  static toList() {
    return this.reduce('name', [
      'title',
      'creator_user_id',
      // 'survey_status_id',
      // 'is_required',
    ])
  }
}
