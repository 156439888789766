// model
import Model from '@/models/Model'

/**
 * This class describes a AActivate model.
 * @class AActivate (name)
 */
export default class AActivateModel extends Model {

  /**
   * Define the AActivate resource.
   * @return {String} AActivate endpoint name.
   */
  resource() {
    return 'activate'
  }
}