<template lang='pug'>
  v-menu( bottom :close-on-content-click="false" content-class='elevation-2' v-model='menu' )
    template( slot='activator' slot-scope='props' )
      v-btn( icon large v-on='props.on' ) 
        v-avatar( color='success darken-2' size='36' ) {{ avatarChar }}
    v-card-user(
      v-bind='cardProps'
      @click-card-action='$emit("click-card-action")'
      @click-item-action='$emit("click-item-action")'
      @click-switch='submitSwitch'
      )
</template>

<script>
  //
  import { VBtnTooltip } from '../buttons'
  import { VCardUser } from '../cards'
  //
  export default {
    components: {
      VBtnTooltip,
      VCardUser,
    },
    computed: {

      /**
       * 
       */
      avatarChar() {
        return this.cardProps.title.slice(0, 1)
      }
    },
    data() {
      return {
        menu: false,
      }
    },
    methods: {

      /**
       * 
       */
      submitSwitch(event) {
        this.$emit('click-switch', event)
      },
    },
    props: {

      /**
       * 
       */
      cardProps: {
        type: Object,
        default: () => ({})
      },
    },
  }
</script>