// required modules
import Model from '@/models/Model'
import SrvSurveyBlockModel from '@/models/Survey/SrvSurveyBlock'

/**
 * This class describes a SrvSurveyAnonymous model.
 * @class 
 */
export default class SrvSurveyAnonymousModel extends Model {

  /**
   * 
   */
  get blocks_length() {
    return this.prop('blocks.length', '0')
  }

  /**
   * 
   */
  mapBlock = block => {
    return SrvSurveyBlockModel.from(block)
  }

  /**
   * 
   */
  reset() {

    //
    this.blocks = this.prop('blocks', []).map(this.mapBlock)

    //
    return this
  }

  /**
   * Define the SrvSurveyAnonymous resource.
   * @return {String} SrvSurveyAnonymous endpoint name.
   */
  resource() {
    return 'survey_anonymous'
  }
}