// required modules
import { Headers } from 'collection-wrapper'

/**
 * This class describes a SrvSurveyAnonymous headers collection.
 *
 * @class SrvSurveyAnonymousHeaders (name)
 */
export default class SrvSurveyAnonymousHeaders extends Headers {

  /**
   * Returns the SrvSurveyAnonymous headers options.
   *
   * @returns {Array} headers options.
   */
  headers() {
    return [
      {
        text: 'Orden',
        value: 'order',
      },
      {
        text: 'Titulo',
        value: 'title',
      },
      {
        text: 'N° de bloques',
        value: 'blocks_length',
      },
    ]
  }

  /**
   * Returns the SrvSurveyAnonymous list headers.
   *
   * @returns {Collection} headers collection.
   */
  static toList() {
    return this.reduce('value', [
      'order',
      'title',
      'blocks_length',
    ])
  }
}
