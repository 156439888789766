// model
import Model from '@/models/Model'
import SrvSurveyModel from '@/models/Survey/SrvSurvey'

/**
 * This class describes a SrvSurveyUser model.
 * @class SrvSurveyUser (name)
 */
export default class SrvSurveyUserModel extends Model {

  /**
   * 
   */
  get is_available() {
    return this.is_to_response && this.is_in_init_date && this.is_in_limit_date
  }

  /**
   * 
   */
  get is_in_init_date() {
    return new Date(this.survey_init_date) <= this.today
  }

  /**
   * 
   */
  get is_in_limit_date() {
    return this.survey_limit_date === null || this.today <= new Date(this.survey_limit_date)
  }

  /**
   * 
   */
  get is_to_response() {
    return this.survey_user_status_id === 1 && this.survey_survey_status_id === 2
  }

  /**
   * 
   */
  reset() {
    this.survey = SrvSurveyModel.from(this.survey).reset()
    return this
  }

  /**
   * Define the SrvSurveyUser resource.
   * @return {String} SrvSurveyUser endpoint name.
   */
  resource() {
    return 'survey_user'
  }

  /**
   * 
   */
  get survey_init_date() {
    return this.prop('survey.init_date', null)
  }

  /**
   * 
   */
  get survey_limit_date() {
    return this.prop('survey.limit_date', null)
  }

  /**
   * 
   */
  get survey_survey_status_id() {
    return this.prop('survey.survey_status_id', 0)
  }

  /**
   * 
   */
  get today() {
    return new Date(Date.now())
  }

  /**
   * 
   */
  get today_str() {
    return this.today.toLocaleString('en-GB').split('/').join('-').split(', ').map(item => item.split('-').reverse().join('-')).join(' ')
  }

  /**
   * 
   */
  toSurvey() {
    return new SrvSurveyModel(this.survey)
  }
}