// required modules
import Model from '@/models/Model'

/**
 * This class describes a AAnonymous model.
 *
 * @class AAnonymous (name)
 */
export default class AAnonymousModel extends Model {

  /**
   * Define the AAnonymous resource.
   *
   * @return {String} AAnonymous endpoint name.
   */
  resource() {
    return 'anonymous'
  }
}