// required modules
import store from '@/store'
import { Service } from 'model-service-wrapper'

/**
 * This class describes the api service.
 * @class Service 
 */
export default class extends Service {

  /**
   * 
   */
  static from(data = []) {
    return () => ({
      data: data,
      total: data.length,
    })
  }

  /**
   * Define the App store.
   * 
   * @return {String} App store instance.
   */
  static store() {
    return store
  }


  /**
   * Parametrize the params argument to model param get
   * 
   * @param      {Object}  params   The params
   * @return     {Object}  model get params
   */
  static parametrize(params = {}) {
    // console.log({ params })
    return {
      //appends : this.appends,
      //include : this.includes.join(),
      limit   : params.limit, 
      page    : params.offset,
      sort    : this.parametrizeSort(params),
      filter  : this.parametrizeFilter(params),
    }
  }

  /**
   * 
   * @param {*} params 
   */
  static parametrizeFilter(params) {
    return {
      ...params.filter,
      [this.paramSearch]: params.search !== '' ? params.search : undefined,
    }
  }

  /**
   * 
   * @param {*} mdl 
   */
  static parametrizeSort(params = {}) {

    //
    let paramSortBy = params.sortBy ? params.sortBy.length ? params.sortBy.slice().pop() : '' : ''
    let paramSortDesc = params.sortDesc ? params.sortDesc.length && params.sortDesc.slice().pop() ? '-' : '' : ''

    //
    if (paramSortBy != '') {

      //
      let headerSortBy = params.headers.find(header => {
        return header.value === paramSortBy
      })

      //
      paramSortBy = headerSortBy && typeof headerSortBy.sortable === 'string' ? headerSortBy.sortable : paramSortBy
    }

    //
    return `${ paramSortDesc }${ paramSortBy }`
  }

  /**
   * 
   */
  static get paramSearch() {
    return 'search'
  }
}