<template lang='pug'>
  v-tooltip( content-class='caption font-weight-medium px-2 py-1' v-bind='tooltipProps' )
    template( slot='activator' slot-scope='props' )
      v-btn( 
        v-bind='buttonProps'
        v-model='value'
        v-on='props.on' 
        @click='$emit("click"); $emit("input", !value)'
      )
        slot
    span( style='white-space: pre-line;' ) {{ tip }}
</template>

<script>
  export default {
    computed: {
      buttonProps() {
        return this.mapButtonProps(this.$attrs)
      },
      tooltipProps() {
        return this.mapTooltipProps(this.$attrs)
      },
    },
    methods: {
      mapButtonProps: ({ 
        classes, 
        color, 
        dark, 
        disabled, 
        icon, 
        large, 
        small, 
        text 
      }) => ({ 
        class: classes, 
        color, 
        dark, 
        disabled,
        icon, 
        large, 
        small, 
        text 
      }),
      mapTooltipProps: ({ 
        bottom, 
        left
      }) => ({ 
        bottom, 
        left 
      }),
    },
    props: {
      tip: {
        type: String,
        default: '',
      },
      value: {
        type: Boolean,
        default: false,
      },
    }
  }
</script>