// required modules
import Model from '@/models/Model'

/**
 * This class describes a StaAttentionTypeOrientationList model.
 * @class StaAttentionTypeOrientationList
 */
export default class StaAttentionTypeOrientationListModel extends Model {

  /**
   * Define the StaAttentionTypeOrientationList resource.
   * @return {String} StaAttentionTypeOrientationList endpoint name.
   */
  resource() {
    return 'attention_type_orientation_list'
  }
}