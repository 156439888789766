//
import VwLayoutDashboard from 'vuetify2-wrappers/src/components/layouts/dashboard'

//
export default {
  extends: VwLayoutDashboard,
  props: {

    /**
     * 
     */
    drawerProps: {
      type: Object,
      default: () => ({
        app: true,
        width: 300,
      }),
    },

    /**
     * 
     */
    toolbarProps: {
      type: Object,
      default: () => ({
        toolbarProps: {
          app: true,
          class: 'px-2',
          color: 'primary',
          dark: true,
          flat: true,
        },
        title: 'EAPP',
        titleProps: {
          class: 'px-4'
        },
        tooltip: 'Menú',
        tooltipProps: {
          bottom: true,
        },
      }),
    },
  }
}