// required modules
import { Fields } from 'collection-wrapper'

/**
 * This class describes a TtsTutor filters collection.
 *
 * @class TtsTutorFilters (name)
 */
export default class TtsTutorFilters extends Fields {

  /**
   * Returns the TtsTutor filters options.
   *
   * @returns {Array} filters options.
   */
  fields() {
    return [
      {
        name: 'rut',
        type: 'text-field',
        props: {
          label: 'Rut',
          placeholder: 'Rut',
        },
      },
      {
        name: 'first_names',
        type: 'text-field',
        props: {
          label: 'Nombre',
          placeholder: 'Nombre',
        },
      },
      {
        name: 'email',
        type: 'text-field',
        props: {
          label: 'Correo electrónico',
          placeholder: 'Correo electrónico',
        },
      },
    ]
  }

  /**
   * Returns the TtsTutor list filters.
   *
   * @returns {Collection} filters collection.
   */
  static toList() {
    return this.reduce('name', [
      'rut',
      'first_names',
      'email',
    ])
  }
}
