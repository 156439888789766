// model
import Model from '@/models/Model'

/**
 * This class describes a StaAttentionStudent model.
 * @class StaAttentionStudent (name)
 */
export default class StaAttentionStudentModel extends Model {
  
  get post_attention_observation_read() {
    // console.log(this)
    return this.prop('post_attention_observation', 'ola')
  }

  /**
   * Define the StaAttentionStudent resource.
   * @return {String} StaAttentionStudent endpoint name.
   */
  resource() {
    return 'attention_student'
  }

  /**
   * 
   */
  get student_email() {
    return this.getProperty('student.email')
  }

  /**
   * 
   */
  get student_first_names() {
    return this.getProperty('student.first_names')
  }

  /**
   * 
   */
  get student_rut() {
    return this.getProperty('student.rut')
  }
}