// service
import Service from '@/services/api/Service'

// model
import UsrUniversityPeriodModel from '@/models/User/UsrUniversityPeriod'

/**
 * This class describes a UsrUniversityPeriod service.
 * @class UsrUniversityPeriod (name)
 */
export default class UsrUniversityPeriodService extends Service {

  /**
   * 
   */
  static activate(usrUniversityPeriod) {
    return usrUniversityPeriod.activate().attach({})
  }

  /**
   * 
   */
  static getLast() {
    return this
      .model()
      .orderBy('-created_at')
      .limit(1)
      .get()
  }

  /**
   * 
   * @returns 
   */
  static getActive() {
    return this
      .model()
      .find('active')
      // .where('is_active', 1)
      // .get()
  }

  /**
   * Define the UsrUniversityPeriod model.
   * @return {Function} UsrUniversityPeriod resource.
   */
  static model() {
    return UsrUniversityPeriodModel
  }

  /**
   * 
   */
  static toSelectList() {
    return () => this.model().params({ get_all_results: 1 }).get()
  }
}