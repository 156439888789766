// model
import Model from '@/models/Model'

// nested
import AActivateAccountModel from '@/models/Actions/AActivateAccount'
import AChangeRoleModel from '@/models/Actions/AChangeRole'
import AEnableAccountModel from '@/models/Actions/AEnableAccount'
import ARestorePasswordModel from '@/models/Actions/ARestorePassword'
import UsrFacilitatorModel from '@/models/User/UsrFacilitator'
import UsrStudentModel from '@/models/User/UsrStudent'

/**
 * This class describes a UsrUser model.
 * @class UsrUser (name)
 */
export default class UsrUserModel extends Model {

  /**
   * 
   */
  get created_at_short() {

    // required data
    const time = this.prop('created_at', '').split(' ').pop()//.split(':').slice(0, 2).join(':')
    const date = this.prop('created_at', '').split(' ').shift().split('-').reverse().join('-')

    // formatted data
    return `${ date } ${ time }`
  }

  /**
   * 
   */
  get updated_at_short() {

    // required data
    const time = this.prop('updated_at', '').split(' ').pop()//.split(':').slice(0, 2).join(':')
    const date = this.prop('updated_at', '').split(' ').shift().split('-').reverse().join('-')

    // formatted data
    return `${ date } ${ time }`
  }

  /**
   * Set a activate account endpoint.
   * @return {UsrUserModel} UsrUserModel activate account model.
   */
  activateAccount() {
    return this.hasOne(AActivateAccountModel)
  }

  /**
   * 
   */
  get can_activate_account() {
    return this.getProperty('activated_account_at', null) === null
  }

  /**
   * 
   */
  get can_disable_account() {
    return this.getProperty('disabled_account_at', null) === null
  }

  /**
   * 
   */
  get can_enable_account() {
    return this.getProperty('disabled_account_at', null) !== null
  }

  /**
   * 
   */
  get careers_description() {
    return this.getProperty('careers', []).map(({ description }) => description)
  }

  /**
   * 
   */
  get careers_year() {
    return this.getProperty('careers', []).map(({ pivot }) => `${ pivot.career_year }`)
  }

  /**
   * 
   */
  changeRole() {
    return this.hasMany(AChangeRoleModel)
  }

  /**
   * 
   */
  get is_disabled() {
    return this.getProperty('disabled_account_at', null) !== null
  }

  /**
   * 
   */
  get email_readonly() {
    return this.getProperty('email', '')//.map(({ pivot }) => `${ pivot.career_year }`)
  }

  /**
   * Set a enable/disable account endpoint.
   * @return {UsrUserModel} UsrUserModel enable account model.
   */
  enableAccount() {
    return this.hasMany(AEnableAccountModel)
  }

  /**
   * 
   */
  get first_names_readonly() {
    return this.getProperty('first_names', 'Sin nombre(s).')
  }

  /**
   * 
   */
  get last_name1_readonly() {
    return this.getProperty('last_name1', 'Sin primero apellido.')
  }

  /**
   * 
   */
  get name_case() {
    return `${ this.getProperty('first_names', '') } ${ this.getProperty('last_name1', '') } ${ this.getProperty('last_name2', '') }`
  }

  /**
   * 
   */
  reset() {
    
    //
    this.enable_account = !!this.activate_account_at && !this.disabled_account_at
    this.file = ''

    //
    return this
  }

  /**
   * 
   */
  resetUpload() {
    this.file = ''
  }

  /**
   * Define the UsrUser resource.
   * @return {String} UsrUser endpoint name.
   */
  resource() {
    return 'user'
  }

  /**
   * Set a restore password endpoint.
   * @return {UsrUserModel} UsrUserModel restore password model.
   */
  restorePassword() {
    return this.hasOne(ARestorePasswordModel)
  }

  /**
   * 
   */
  get rut_readonly() {
    return this.getProperty('rut', 'Sin rut.')
  }

  /**
   * Return a account activation representation.
   * @return {UsrUserModel} UsrUserModel account activation.
   */
  toActivateAccount() {
    return this.reduce([
      'alternative_email',
      'password',
      'phone_number',
      'rut',
      'token',
    ])
  }

  /**
   * Return a change role representation.
   * @return {UsrUserModel} UsrUserModel change role.
   */
  toChangeRole() {
    return this.reduce([
      'role',
    ])
  }

  /**
   * Return a enable representation.
   * @return {UsrUserModel} UsrUserModel enable.
   */
  toEnableAccount() {
    return this.reduce([
      'enable_account',
      'id',
    ])
  }

  /**
   * Return a facilitator representation.
   * @return {UsrFacilitatorModel} UsrFacilitatorModel model.
   */
  toFacilitator() {
    return new UsrFacilitatorModel(this)
  }

  /**
   * Return a restore password representation.
   * @return {UsrUserModel} UsrUserModel restore password model.
   */
  toRestorePassword() {
    return this.reduce([
      'email',
      'password',
      'token',
    ])
  }

  /**
   * Return a student representation.
   * @return {UsrStudentModel} UsrStudentModel model.
   */
  toStudent() {
    return new UsrStudentModel(this)
  }
}