// components
import SrvSurveyFields from '@/views/Survey/SrvSurvey/fields'
import SrvSurveyService from '@/services/api/Survey/SrvSurvey'
import SrvSurveyBlockService from '@/services/api/Survey/SrvSurveyBlock'

// component
export default {
  name: 'srv-survey-preview',
  computed: {

    /**
     * 
     */
    formItems() {
      return this.srvSurveyItems.filter(this.filterByIndex).reduce(this.reduceItems, []).filter(this.filterInput)
    },

    /**
     * Gets the SrvSurvey preview form props.
     * @return {Object} The preview form props.
     */
    formProps() {
      return {
        items: this.formItems,
        noActions: !this.srvSurvey.id,
        // noSubmit: this.inLast,
        resetText: this.inFirst ? 'reiniciar' : 'anterior',
        submitText: this.inLast ? 'finalizar' : 'siguiente',
        title: this.srvSurvey.id ? this.srvSurvey.title : 'Error al obtener encuesta',
      }
    },

    /**
     * 
     */
    inFirst() {
      return this.itemIndex === 0
    },

    /**
     * 
     */
    inLast() {
      return this.itemIndex === this.srvSurveyItems.length - 1
    },

    /**
     * 
     */
    leftProps() {
      return {
        disabled: this.itemIndex < 1
      }
    },

    /**
     * 
     */
    rightProps() {
      return {
        disabled: this.inLast
      }
    },
  },
  data() {
    return {
      itemIndex: 0,
      srvSurvey: {},
      srvSurveyItems: [],
      srvSurveySectionIndex: 0,
    }
  },
  methods: {

    /**
     * 
     */
    filterInput(item) {
      return !! item
    },

    /**
     * 
     */
    filterByIndex(item, index) {
      return index === this.itemIndex
    },

    /**
     * 
     */
    onNext() {
      this.itemIndex++;
    },

    /**
     * 
     */
    onPrevious() {
      this.itemIndex--;
    },

    /**
     * Called when clicked on edit button.
     */
    onClickEdit() {
      this.goRoute('srv-survey-update', { id: this.getParam('id') })
    },

    /**
     * Returns an preview SrvSurvey success process.
     * @param  {Object}  event - the event success payload.
     */
    onSuccessPreview(event) {
      this.onSuccess(event)
    },

    /**
     * Returns a reset SrvSurvey form.
     * @return {Promise} A form reset promise.
     */
    onReset() {

      //
      if (this.inFirst) {
        return this.toWait(true).then(this.toRefresh).then(this.toWait)
      }

      //
      this.onPrevious()
    },

    /**
     * Returns a submit SrvSurvey form.
     * @return {Promise} A form submit promise.
     */
    onSubmit() {
      if (!this.inLast) {
        this.onNext()
      } else {
        this.$router.go(-1)
      }
    },

    /**
     * 
     */
    reduceItems(items, item) {
      return [ ...items, ...item ]
    },

    /**
     * Set a new block in survey items.
     */
    setBlock(srvSurveyBlock) {
      this.srvSurveyItems[this.srvSurveySectionIndex+1].push(SrvSurveyBlockService.genDivider())
      this.srvSurveyItems[this.srvSurveySectionIndex+1].push(SrvSurveyBlockService.genBlockTitle(srvSurveyBlock))
      this.srvSurveyItems[this.srvSurveySectionIndex+1].push(SrvSurveyBlockService.genInput(srvSurveyBlock))
      if(srvSurveyBlock.survey_block_identifier_id == 7){
        this.srvSurveyItems[this.srvSurveySectionIndex+1].push(SrvSurveyBlockService.genBlockLegend(srvSurveyBlock))
      }
      if(srvSurveyBlock.survey_block_identifier_id == 8){
        this.srvSurveyItems[this.srvSurveySectionIndex+1].push(SrvSurveyBlockService.genBlockLegend(srvSurveyBlock))
      }
      // this.srvSurveyItems.push(SrvSurveyBlockService.genSubheader(srvSurveyBlock))
      // this.srvSurveyItems.push(SrvSurveyBlockService.genInput(srvSurveyBlock))
    },

    /**
     * 
     */
    setIndex(srvSurvey) {

      // required data
      let title     = SrvSurveyBlockService.genSurveyTitle(srvSurvey)
      let subtitle  = SrvSurveyBlockService.genSurveySubtitle(srvSurvey)

      // set items
      this.srvSurveyItems.push([ title, subtitle ])
    },

    /**
     * 
     */
    setResume(srvSurvey) {

      // required data
      let title = SrvSurveyBlockService.genTitle('Resumen', 'Antes de enviar la encuesta revise sus respuestas. Una vez que esté seguro haga click en "responder".')

      // set items
      this.srvSurveyItems.push([ title ])
    },

    /**
     * Set a new section in survey items.
     */
    setSection(srvSurveySection, index) {

      // required data
      let title = SrvSurveyBlockService.genSectionTitle(srvSurveySection)

      // set initial section data
      this.srvSurveyItems.push([title])
      this.srvSurveySectionIndex = index

      // set section items
      srvSurveySection.prop('blocks', []).map(block => { block.section_index = index + 1; return block }).forEach(this.setBlock)
      // this.srvSurveyItems.push(SrvSurveyBlockService.genTitle(srvSurveySection, index))
      // srvSurveySection.prop('blocks', []).forEach(this.setBlock)
    },

    /**
     * Returns a refresh SrvSurvey form.
     * @return {Promise} A reset promise.
     */
    async toRefresh() {
      return SrvSurveyService.toPreview().find(this.getParam('id')).then(srvSurvey => {

        // reset items
        this.srvSurveyItems = []
        this.srvSurvey = srvSurvey.reset()

        // initialize answers
        this.srvSurvey.initAnswers()
          
        // set initial page
        this.setIndex(this.srvSurvey)

        // set sections
        this.srvSurvey.sections.forEach(this.setSection)

        // set last page
        this.setResume(this.srvSurvey)
      })
    },

    /**
     * Returns an preview SrvSurvey promise.
     * @param  {Object}  model - the srv-survey model
     * @return {Promise} A preview promise.
     */
    toPreview(srvSurvey) {
      return SrvSurveyService.preview(srvSurvey).then(this.toSuccessPreview).catch(this.toError)
    },
  },
  mounted() {
    this.onReset()
  },
}