import Model from '@/models/Model'

/**
 * This class describes a StaAttentionType model.
 * @class StaAttentionType (name)
 */
export default class StaAttentionTypeModel extends Model {

  get descriptionFormatted() {
    return this.description.split('vocacional').join('Vocacional')
  }

  /**
   * Define the StaAttentionType resource.
   * @return {String} StaAttentionType endpoint name.
   */
  resource() {
    return 'attention_type'
  }
}