// required modules
import { Headers } from 'collection-wrapper'

/**
 * This class describes a UsrProgram headers collection.
 *
 * @class UsrProgramHeaders (name)
 */
export default class UsrProgramHeaders extends Headers {

  /**
   * Returns the UsrProgram headers options.
   *
   * @returns {Array} headers options.
   */
  headers() {
    return [
      {
        text: '#',
        value: 'id',
      },
      {
        text: 'Descripción',
        value: 'description',
        sortable: true,
      },
      {
        text: 'Creación',
        value: 'created_at_short',
        sortable: 'created_at_short',
        align: 'right'
      },
      {
        text: 'Modificación',
        value: 'updated_at_short',
        sortable: 'updated_at',
        align: 'right'
      },
    ]
  }

  /**
   * Returns the UsrProgram list headers.
   *
   * @returns {Collection} headers collection.
   */
  static toList() {
    return this.reduce('value', [
      'id',
      'description',
      'created_at_short',
      'updated_at_short',
    ])
  }
}
