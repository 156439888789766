// service
import Service from '@/services/api/Service'

// model
import UsrUserModel from '@/models/User/UsrUser'

/**
 * This class describes a UsrUser service.
 * @class UsrUser (name)
 */
export default class UsrUserService extends Service {

  /**
   * Activate the user account.
   * @param {UsrUserModel} usrUser - a UsrUserModel instance.
   * @return {Promise} UsrUserModel/activateAccount promise.
   */
  static activateAccount(usrUser) {
    return usrUser.activateAccount().attach(usrUser.toActivateAccount())
  }

  /**
   * Change role the user account.
   * @param {UsrUserModel} usrUser - a UsrUserModel instance.
   * @return {Promise} UsrUserModel/changeRole promise.
   */
  static changeRole(usrUser) {
    return usrUser.changeRole().attach(usrUser.toChangeRole())
  }

  /**
   * Enable the user account.
   * @param {UsrUserModel} usrUser - a UsrUserModel instance.
   * @return {Promise} UsrUserModel/enableAccount promise.
   */
  static enableAccount(usrUser) {

    // enable account data
    const enableAccountData  = usrUser.toEnableAccount()
    const enableAccountModel = usrUser.enableAccount()
    // const enableAccountSuccessMessage = usrUser.getMessage('enable_account_success')

    // enable account promise
    return enableAccountModel.attach(enableAccountData)//.then(enableAccountSuccessMessage)
  }

  /**
   * Define the UsrUser model.
   * @return {UsrUserModel} UsrUserModel model.
   */
  static model() {
    return UsrUserModel
  }

  /**
   * Restore the password of a user.
   * @param {UsrUserModel} usrUser - a UsrUserModel instance.
   * @return {Promise} UsrUserModel/restorePassword promise.
   */
  static restorePassword(usrUser) {
    return usrUser.restorePassword().attach(usrUser.toRestorePassword())
  }

  /**
   * Return a facilitator list representation.
   * @return {UsrUserService} facilitator list representation.
   */
  static toFacilitatorList() {
    return this.reset().filter({ 'role': 'facilitator' })
  }

  /**
   * Return a student list representation.
   * @return {UsrUserService} student list representation.
   */
  static toStudentList() {
    return this.reset().include(['careers']).filter({ 'role': 'student' })
  }

  static toListSortByFirstNamesFacilitator() {
    return (params) => this.model().params(this.parametrize({ ...params,filter:{role:'facilitator'},sortBy: ['first_names'] })).get()
  }

  static toListSortByFirstNamesStudent() {
    return (params) => this.model().params(this.parametrize({ ...params,filter:{role:'student'},sortBy: ['first_names'] })).get()
  }

  /**
   * Return a list representation.
   * @return {UsrUserService} list representation.
   */
  // static toList() {
  //   return (params) => this.model().params
  // }

  /**
   * 
   */
  static toListSortByFirstNames() {
    return (params) => this.model().include(['careers']).params(this.parametrize({ ...params, sortBy: ['first_names'] })).get()
  }
}