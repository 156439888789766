// required modules
import { Fields } from 'collection-wrapper'
import { ruleRequired } from 'vuetify2-wrappers/src/rules'
import SrvSurveyBlockHeaders from '@/views/Survey/SrvSurveyBlock/headers'

/**
 * This class describes a SrvSurveySection fields collection.
 * @class SrvSurveySectionFields (name)
 */
export default class SrvSurveySectionFields extends Fields {

  /**
   * Returns the SrvSurveySection fields options.
   *
   * @returns {Array} fields options.
   */
  fields() {
    return [
      {
        name: 'title',
        type: 'text-field',
        info: 'Título de la sección.',
        flex: {
          cols: 12,
        },
        props: {
          label: 'Titulo',
          placeholder: 'Titulo',
          rules: [ruleRequired],
        },
      },
      {
        name: 'description',
        type: 'textarea',
        info: 'Descripción de la sección.',
        flex: {
          cols: 12,
        },
        props: {
          label: 'Descripción',
          placeholder: 'Descripción',
          rows: 2,
        },
      },
      {
        name: 'blocks',
        type: 'table-field',
        info: 'Bloques pertenecientes a la sección.',
        flex: {
          cols: 12,
        },
        props: {
          label: 'Bloques',
          tableProps: {
            dense: true,
            headers: SrvSurveyBlockHeaders.toList(),
            style: 'border: 1px solid #ccc; overflow: hidden;',
          },
          titleProps: {
            class: 'subtitle-2 mr-2 text-uppercase',
          },
          toolbarProps: {
            color: 'primary',
            dark: true,
            dense: true,
            flat: true,
            small: true,
          },
        },
        slots: {
          actions: 'blocks.actions',
          items: 'blocks.items',
        },
      },
    ]
  }

  /**
   * Returns the SrvSurveySection create fields.
   *
   * @returns {Collection} fields collection.
   */
  static toCreate() {
    return this.reduce('name', [
      'title',
      'description',
      'blocks',
    ])
  }

  /**
   * Returns the SrvSurveySection update fields.
   *
   * @returns {Collection} fields collection.
   */
  static toUpdate() {
    return this.reduce('name', [
      'value',
    ])
  }
}
