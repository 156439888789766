// App
import Home from '@/views/Home'
import Welcome from '@/views/Welcome'

// Activity
import ActActivityRoutes from './Activity/ActActivity'

// Blog
import BlgWebNewRoutes from './Blog/BlgWebNew'

// Notification
import NtMailContactRoutes from './Notification/NtMailContact'
import NtMailRrssRoutes from './Notification/NtMailRrss'

// Report
import RptReportRoutes from './Report/RptReport'

// Student Attention
import StaAttentionRoutes from './StudentAttention/StaAttention'
import StaAttentionFacilitatorScheduleRoutes from './StudentAttention/StaAttentionFacilitatorSchedule'

// Survey
import SrvSurveyRoutes from './Survey/SrvSurvey'
import SrvSurveyTemplateRoutes from './Survey/SrvSurveyTemplate'

// Tutorship
import TtsTutorRoutes from './Tutorship/TtsTutor'
import TtsTutoringNewRoutes from './Tutorship/TtsTutoringNew'
import TtsTutorshipRoutes from './Tutorship/TtsTutorship'
import TtsTutorshipParallelRoutes from './Tutorship/TtsTutorshipParallel'
import TtsTutorshipScheduleRoutes from './Tutorship/TtsTutorshipSchedule'

// User
import UsrFacilitatorRoutes from './User/UsrFacilitator'
import UsrProgramRoutes from './User/UsrProgram'
import UsrStudentRoutes from './User/UsrStudent'
import UsrUniversityPeriodRoutes from './User/UsrUniversityPeriod'
import UsrUserRoutes from './User/UsrUser'

//
export default {
    path: '/',
    component: Home,
    children: [
      {
        path: '',
        name: 'home',
        component: Welcome,
      },
      ActActivityRoutes,
      BlgWebNewRoutes,
      NtMailContactRoutes,
      NtMailRrssRoutes,
      RptReportRoutes,
      SrvSurveyRoutes,
      SrvSurveyTemplateRoutes,
      StaAttentionRoutes,
      StaAttentionFacilitatorScheduleRoutes,
      TtsTutorRoutes,
      TtsTutorshipRoutes,
      TtsTutoringNewRoutes,
      TtsTutorshipParallelRoutes,
      TtsTutorshipScheduleRoutes,
      UsrFacilitatorRoutes,
      UsrProgramRoutes,
      UsrStudentRoutes,
      UsrUniversityPeriodRoutes,
      UsrUserRoutes,
    ],
}