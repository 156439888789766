// required modules
import { Fields } from 'collection-wrapper'

/**
 * This class describes a TtsTutoringNew fields collection.
 * @class
 */
export default class TtsTutoringNewFields extends Fields {

  /**
   * Returns the TtsTutoringNew fields options.
   * @returns {Array} fields options.
   */
  fields() {
    return [
      {
        name: 'title',
        type: 'text-field',
        flex: {
          cols: 12,
        },
        props: {
          label: 'Titulo',
          placeholder: 'Titulo',
        },
      },
      {
        name: 'body',
        type: 'editor',
        flex: {
          cols: 12,
        },
        props: {
          label: 'Mensaje',
          placeholder: 'Mensaje',
        },
      },
      {
        name: 'created_at',
        type: 'text-field',
        props: {
          label: 'Fecha de creación',
          placeholder: 'Fecha de creación',
        },
      },
    ]
  }

  /**
   * Returns the TtsTutoringNew create fields.
   * @returns {Collection} fields collection.
   */
  static toCreate() {
    return this.reduce('name', [
      'title',
      'body',
    ])
  }

  /**
   * Returns the TtsTutoringNew detail fields.
   * @returns {Collection} fields collection.
   */
  static toDetail() {
    return this.init().whereIn('name', [
      'title',
      'body',
      'created_at',
    ]).each(item => {
      item.flex = {
        cols: 12,
      }
      item.props.readonly = true
    })
  }

  /**
   * Returns the TtsTutoringNew update fields.
   * @returns {Collection} fields collection.
   */
  static toUpdate() {
    return this.reduce('name', [
      'title',
      'body',
    ])
  }
}
