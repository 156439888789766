// service
import Service from '@/services/api/Service'
import AthServiceModel from '@/models/Auth/AthService'

/**
 * This class describes a AthService service.
 * @class AthService (name)
 */
export default class AthServiceService extends Service {

  /**
   * 
   */
  static token() {
    return this.store().getters.authToken
  }

  /**
   * Intercept the request of axios and set the current authorization token.
   * 
   * @param {*} opts 
   */
  static interceptor = (config) => { 

    // required token
    const token = this.token()
        
    // set request headers
    if (token) {
      config.headers = {
        ...config.headers,
        Authorization: token,
      }
    }

    // the axios config
    return config
  }

  /**
   * Define the AthService model.
   * @return {String} AthService resource.
   */
  static model() {
    return AthServiceModel
  }

  /**
   * Return the environment service params.
   * @return {Object} Environment Service data.
   */
  static async getEnvironmentParams() {
    return process.env
  }

  /**
   * Return the status service.
   * @return {Object} status service text.
   */
  static async getStatus() {
    //
    const athService = this.shape()
    const url = athService.baseURL().split('/').slice(0, 3).join('/')
    //
    return athService.request({ method: 'get', url }).then(() => 'online').catch(() => 'offline')
  }

  /**
   * Return the store service params.
   * @return {Object} Store Service data.
   */
  static async getStoreParams() {
    return this.store().getters.service
  }

  /**
   * Return a AthServiceModel with environment service params.
   * @return {AthServiceModel} Store AthServiceModel.
   */
  static async getFromEnvironment() {
    return this.getEnvironmentParams().then(res => {
      return this.shape(res)
    })
  }

  /**
   * Return a AthServiceModel with store service params.
   * @return {AthServiceModel} Store AthServiceModel.
   */
  static async getFromStore() {
    return this.getStoreParams().then(res => {
      return this.shape(res)
    })
  }

  /**
   * Set the app service params.
   * @param {AthServiceModel} athService - a AthServiceModel instance.
   */
  static update(athService) {
    return this.store().dispatch('setAuthService', {
      data: athService.toUpdate().toCase('camel'),
    })
  }

  /**
   * Set the app service params in local store.
   */
  static updateStore() {
    return this.store().dispatch('storeAuthService')
  }
}