// required modules
import { Fields } from 'collection-wrapper'

/**
 * This class describes a SrvSurveyAnonymous fields collection.
 * @class SrvSurveyAnonymousFields (name)
 */
export default class SrvSurveyAnonymousFields extends Fields {

  /**
   * Returns the SrvSurveyAnonymous fields options.
   *
   * @returns {Array} fields options.
   */
  fields() {
    return [
      {
        name: 'title',
        type: 'text-field',
        flex: {
          cols: 12,
          sm: 6,
        },
        props: {
          label: 'Titulo',
          placeholder: 'Titulo',
        },
      },
      {
        name: 'description',
        type: 'textarea',
        flex: {
          cols: 12,
        },
        props: {
          label: 'Descripción',
          placeholder: 'Descripción',
          rows: 2,
        },
      },
      {
        name: 'blocks',
        type: 'table-field',
        flex: {
          cols: 12,
        },
        props: {
          label: 'Bloques',
          noActions: true,
          tableProps: {
            dense: true,
            headers: [
              {
                text: 'Titulo',
                value: 'title',
              },
              {
                text: 'Tipo',
                value: 'type',
              },
              {
                text: 'Identificador',
                value: 'identifier',
              },
              {
                text: 'N° de respuestas',
                value: 'user_answers_length',
              },
            ],
            style: 'border: 1px solid #ccc; overflow: hidden;',
          },
          titleProps: {
            class: 'subtitle-2'
          },
          toolbarProps: {
            class: 'primary',
            dark: true,
            dense: true,
            flat: true,
          },
        },
      },
    ]
  }

  /**
   * Returns the SrvSurveyAnonymous create fields.
   *
   * @returns {Collection} fields collection.
   */
  static toCreate() {
    return this.reduce('name', [
      'value',
    ])
  }

  /**
   * Returns the SrvSurveyAnonymous create fields.
   * @returns {Collection} fields collection.
   */
  static toDetail() {
    return this.reduce('name', [
      'title',
      'description',
      'blocks',
    ])
  }

  /**
   * Returns the SrvSurveyAnonymous update fields.
   *
   * @returns {Collection} fields collection.
   */
  static toUpdate() {
    return this.reduce('name', [
      'value',
    ])
  }
}
