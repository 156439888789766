// components
import StaAttentionFields from '@/views/StudentAttention/StaAttention/fields'
import StaAttentionFilters from '@/views/StudentAttention/StaAttention/filters'
import StaAttentionHeaders from '@/views/StudentAttention/StaAttention/headers'
import StaAttentionService from '@/services/api/StudentAttention/StaAttention'
import StaAttentionStudentHistoryHeaders from '@/views/StudentAttention/StaAttentionStudentHistory/headers'
import StaAttentionStudentHistoryService from '@/services/api/StudentAttention/StaAttentionStudentHistory'
import StaAttentionStudentModel from '@/models/StudentAttention/StaAttentionStudent'
import StaAttentionStudentHeaders from '@/views/StudentAttention/StaAttentionStudent/headers'

// service
import StaAttentionScheduleModel from '@/models/StudentAttention/StaAttentionSchedule'

// component
export default {
  name: 'sta-attention-schedule',
  computed: {

    cancelProps() {
      return {
        items: StaAttentionFields.toCancel(),
      }
    },

    /**
     * 
     */
    imStudent() {
      return this.$store.getters.userRolName === 'student'
    },

    sendProps() {
      return {
        items: StaAttentionFields.toSendStudents(),
      }
    },

    /**
     * 
     */
    tableProps() {
      return {
        noActions: this.imStudent,
        headers: StaAttentionStudentHeaders.toDetailList(),
        service: StaAttentionService.from(this.event.attention_students.map(e => new StaAttentionStudentModel(e)))
      }
    },

    /**
     * 
     */
    formProps() {
      return {
        items: StaAttentionFields.toScheduleDetail({ imStudent: this.imStudent }),
        noActions: this.imStudent,
      }
    },

    /**
     * 
     */
    calendarProps() {
      //console.log("eventos van por aquí",this.events)
      return {
        events: this.events,
      }
    },

    historyProps() {
      return {
        headers: StaAttentionStudentHistoryHeaders.toList(),
        service: StaAttentionStudentHistoryService.toList(),
        mapFilter: filter => ({
          ...filter,
          student_user_id: this.student_user_id ? this.student_user_id : undefined,
        })
      }
    },
  },
  data() {
    return {
      busy: false,
      dialogs: {
        cancel: false,
        detail: false,
        send: false,
        history: false,
      },
      weekday: [1, 2, 3, 4, 5, 6, 0],
      event: {
        attention_students: [],
      },
      events: [
        {
          name: 'ola',
          start: '2020-06-03 12:00',
          end: '2020-06-03 13:00',
        },
      ],
      value: '',
      student_user_id: null,
      months: ['Enero','Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
      'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
      actualMonth: new Date(),
      numberActualMonth: 0,
    }
  },
  methods: {

    /**
     * 
     */
    onClickInterval(event) {
      //months[this.$refs.calendar.lastStart.month - 1]
      this.event = event.event
      //console.log({ event })
      this.dialogs.detail = true
    },
    
    /**
     * 
     */
    onClickNext() {
      
      this.getRef('calendar').next() 
      this.numberActualMonth = this.numberActualMonth == 11? 0: this.numberActualMonth + 1
      this.actualMonth = this.months[  this.numberActualMonth]   
      //console.log("EL NUMERO DE MES NEXT ES ",this.numberActualMonth)
    },
    
    /**
     * 
     */
    onClickPrevious() {
      this.getRef('calendar').prev()   
      //si el número del mes es 0 y presiona el previo se va diciembre, sino se resta uno al mes
      this.numberActualMonth = this.numberActualMonth == 0? 11: this.numberActualMonth - 1
      //se busca el indice del mes actual en los meses
      this.actualMonth = this.months[  this.numberActualMonth]   
      //console.log("EL NUMERO DE MES prev ES ",this.numberActualMonth)
    },
    
    /**
     * 
     */
    onClickRefresh() {
      this.toRefresh()
    },

    /**
     * 
     */
    onSubmitReservation() {
      this.toReservation()
    },

    /**
     * 
     */
    onSubmitCancel() {
      this.toDelete(this.event)
    },

    /**
     * 
     */
    onSubmitSend() {
      this.toSendFacilitator()
    },
    
    onError(event) {
      this.getErrorMessage(event).then(this.getErrorToast)      
    },
    
    onSuccess(event) {
      this.getSuccessMessage(event).then(this.getSuccessToast)
    },

    onSuccessDelete(res) {
      this.dialogs.cancel = false
      this.onSuccess(res)
    },

    onSuccessSendNotification(res) {
      this.dialogs.send = false
      this.onSuccess(res)
    },

    /**
     * 
     */
    toDelete(staAttentionStudent) {
      return StaAttentionService.cancelReservation(StaAttentionService.shape(this.event)).then(this.onSuccessDelete).catch(this.onError)
    },

    /**
     * 
     */
    toRefresh(events = []) {
      this.busy=true
      StaAttentionService.model().include('attention_type.campus_data,facilitator').find('schedule').then(res => {
        this.busy=false

        Object.values(res).forEach(item => {
          if (item.id) {

            //
            let staAttentionStudent = StaAttentionScheduleModel.from({
              ...item,
              name: item.attention_type.description,
              start: item.attention_datetime,
              end: '2020-06-03 13:00',
              attention_facilitator_schedule_id: item.id,
              attention_date: item.attention_datetime.split(' ').shift(),
            })

            //
            events.push(staAttentionStudent)

            // events.push({
            //   ...item,
            //   name: item.attention_type.description,
            //   start: item.attention_datetime,
            //   end: '2020-06-03 13:00',
            //   attention_facilitator_schedule_id: item.id,
            //   attention_date: item.attention_datetime.split(' ').shift(),
            // })
          }
        })
        
        this.events = events
      })
    },

    /**
     * 
     */
    toReservation() {
      StaAttentionService.reserve(StaAttentionService.shape(this.event)).then(this.onSuccess).catch(this.onError)
    },

    /**
     * 
     */
    toSendFacilitator() {
      let staAttention = StaAttentionService.shape(this.event)
      staAttention.target = 1
      staAttention.send_email = !! staAttention.send_email
      StaAttentionService.sendNotification(staAttention).then(this.onSuccessSendNotification).catch(this.onError)
    },
  },
  mounted() {
    this.numberActualMonth = this.actualMonth.getMonth()
    //console.log("mes actual: ",this.numberActualMonth)
    this.actualMonth = this.months[this.actualMonth.getMonth()]

    this.onClickRefresh()
    

  },
}