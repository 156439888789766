// required modules
import { Fields } from 'collection-wrapper'

/**
 * This class describes a UsrUniversityPeriod fields collection.
 * @class UsrUniversityPeriodFields (name)
 */
export default class UsrUniversityPeriodFields extends Fields {

  /**
   * Returns the UsrUniversityPeriod fields options.
   *
   * @returns {Array} fields options.
   */
  fields() {
    return [
      {
        name: 'identifier',
        type: 'text-field',
        info: 'Identificador del Semestre, Ej: 2020-1 o S1-2020.',
        props: {
          label: 'Identificador del semestre',
          placeholder: 'Identificador del semestre',
        },
      },
      {
        name: 'init_date',
        type: 'date-field',
        info: 'Fecha de inicio del periodo.',
        props: {
          fieldProps: {
            label: 'Fecha de inicio del periodo',
            placeholder: 'Fecha de inicio del periodo',
          },
        },
      },
      {
        name: 'end_date',
        type: 'date-field',
        info: 'Fecha de término del periodo.',
        props: {
          fieldProps: {
            label: 'Fecha de término del periodo',
            placeholder: 'Fecha de término del periodo',
          },
        },
      },
    ]
  }

  /**
   * Returns the UsrUniversityPeriod create fields.
   *
   * @returns {Collection} fields collection.
   */
  static toCreate() {
    return this.reduce('name', [
      'identifier',
      'init_date',
      'end_date',
    ])
  }

  /**
   * Returns the UsrUniversityPeriod update fields.
   *
   * @returns {Collection} fields collection.
   */
  static toUpdate() {
    return this.reduce('name', [
      'identifier',
      'init_date',
      'end_date',
    ])
  }
}
