// required modules
import Model from '@/models/Model'

/**
 * This class describes a AMonthlyQuantity model.
 *
 * @class AMonthlyQuantity (name)
 */
export default class AMonthlyQuantityModel extends Model {

  /**
   * Define the AMonthlyQuantity resource.
   *
   * @return {String} AMonthlyQuantity endpoint name.
   */
  resource() {
    return 'monthly_quantity'
  }
}