// model
import Model from '@/models/Model'

/**
 * This class describes a AUpdateBody model.
 * @class AUpdateBody (name)
 */
export default class AUpdateBodyModel extends Model {

  /**
   * Define the AUpdateBody resource.
   * @return {String} AUpdateBody endpoint name.
   */
  resource() {
    return 'update_body'
  }
}