// model
import Model from '@/models/Model'

/**
 * This class describes a AReserve model.
 * @class AReserve (name)
 */
export default class AReserveModel extends Model {

  /**
   * Define the AReserve resource.
   * @return {String} AReserve endpoint name.
   */
  resource() {
    return 'reserve'
  }
}