// model
import Model from '@/models/Model'
//import AAccountNotificationCases from '@/services/cases/Actions/AAccountNotification'

/**
 * This class describes a AAccountNotification model.
 * @class AAccountNotification (name)
 */
export default class AAccountNotificationModel extends Model {

  /**
   * Define the AAccountNotification cases.
   * @return {String} AAccountNotification cases.
   */
  //cases() {
  //  return AAccountNotificationCases
  //}

  /**
   * Define the AAccountNotification resource.
   * @return {String} AAccountNotification endpoint name.
   */
  resource() {
    return 'account_activation'
  }
}