// service
import Service from '@/services/api/Service'
import AthLoginModel from '@/models/Auth/AthLogin'

/**
 * This class describes a AthLogin service.
 * @class AthLogin (name)
 */
export default class AthLoginService extends Service {

  /**
   * Define the AthLogin model.
   * @return {String} AthLogin resource.
   */
  static model() {
    return AthLoginModel
  }
  
  /**
   * Login with AthAuth in api service and set the required data
   * inside of vuex store of the application.
   * 
   * @param {AthLoginModel} athLogin - a AthLoginModel instance
   * @returns {Promise} response of initialize user in application
   */
  static create(athLogin) {
    return athLogin.toCreate().save().then(res => {
      this.store().dispatch('setAuthData', {
        data: res.toData(),
        remember: athLogin.remember_me,
      })
    })
  }
}