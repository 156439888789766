// required modules
import { GChart } from 'vue-google-charts'

// services
import RptReportService from '@/services/api/Report/RptReport'
import RptReportActivityQuantityService from '@/services/api/Report/RptReportActivityQuantity'
import RptReportApplicationRegisteredQuantityService from '@/services/api/Report/RptReportApplicationRegisteredQuantity'
import RptReportAttentionSessionQuantityService from '@/services/api/Report/RptReportAttentionSessionQuantity'

// component
export default {
  name: 'rpt-report-dashboard',
  components: {
    GChart,
  },
  computed: {

    /**
     * 
     */
    activityProps() {
      return {
        loading: this.rptReportActivity.loading,
      }
    },
  },
  data() {
    return {
      rptReportActivity: {
        loading: false,
        params: {
          type: 'BarChart',
          options: {

          },
          data: [],
        }
      },
      rptReportApplication: {
        loading: false,
        params: {
          type: 'BarChart',
          options: {

          },
          data: [],
        }
      },
      rptReportAttention: {
        loading: false,
        params: {
          type: 'BarChart',
          options: {

          },
          data: [],
        }
      },
      rprReports: {
        items: [
          RptReportService.shape({
            id    : 'activity',
            key   : 2,
            title : 'Nivel de Asistencia (general y por tipo de atención)',
            type  : 'Gráfico de barras',
            route : 'rpt-report-activities-chart',
          }),
          RptReportService.shape({
            id    : 'activity',
            key   : 'ASI001',
            title : 'Indicador de Satisfacción (general y por tipo de atención)',
            type  : 'Indicador',
            route : 'rpt-report-activities-chart',
          }),
          RptReportService.shape({
            id    : 'application',
            key   : 'AUQ001',
            title : 'Cantidad de usos por estudiante (general y por módulo)',
            type  : 'Gráfico de columnas',
            route : 'rpt-report-applications-chart',
          }),
          RptReportService.shape({
            id    : 'attention',
            key   : 'ASQ001',
            title : 'Cantidad de Sesiones realizadas (general y por tipo de atención)',
            type  : 'Gráfico de barras',
            route : 'rpt-report-attentions-chart',
          }),
          RptReportService.shape({
            id    : 'attention',
            key   : 'AAP001',
            title : 'Porcentaje de Asistencia (general y por tipo de atención)',
            type  : 'Indicador',
            route : 'rpt-report-attentions-chart',
          }),
          RptReportService.shape({
            id    : 'attention',
            key   : 'AAS001',
            title : 'Promedio de Sesiones realizadas por Estudiante por Tipo de Atención',
            type  : 'Gráfico de barras',
            route : 'rpt-report-attentions-chart',
          }),
          RptReportService.shape({
            id    : 'attention',
            key   : 'AMQ001',
            title : 'Cantidad de Sesiones realizadas por Mes por Tipo de Atención',
            type  : 'Gráfico de burbujas',
            route : 'rpt-report-attentions-chart',
          }),
        ]
      }
    }
  },
  methods: {

    /**
     * 
     */
    onActivities() {
      this.goRoute('rpt-report-activities')
    },

    /**
     * 
     */
    onApplications() {
      this.goRoute('rpt-report-applications')
    },

    /**
     * 
     */
    onAttentions() {
      this.goRoute('rpt-report-attentions')
    },

    /**
     * 
     */
    onRefreshActivity() {
      return this.toWaitActivity(true).then(this.toRefreshActivity).then(this.toWaitActivity)
    },

    /**
     * 
     */
    onRefreshApplication() {
      return this.toWaitApplication(true).then(this.toRefreshApplication).then(this.toWaitApplication)
    },

    /**
     * 
     */
    onRefreshAttention() {
      return this.toWaitAttention(true).then(this.toRefreshAttention).then(this.toWaitAttention)
    },

    /**
     * 
     */
    onReport(event) {
      this.goRoute(event.item.route, { key: event.item.key })
    },

    /**
     * 
     */
    toRefreshActivity() {
      return RptReportActivityQuantityService.shape({ periods: [ 1 ] }).save().then(res => {
        this.rptReportActivity = res.reset()
      })
    },

    /**
     * 
     */
    toRefreshApplication() {
      return RptReportApplicationRegisteredQuantityService.shape({ periods: [ 1 ] }).save().then(res => {
        this.rptReportApplication = res.reset()
      })
    },

    /**
     * 
     */
    toRefreshAttention() {
      return RptReportAttentionSessionQuantityService.shape({ periods: [ 1 ] }).save().then(res => {
        this.rptReportAttention = res.reset()
      })
    },

    /**
     * 
     */
    async toWaitActivity(val = false) {
      this.rptReportActivity.loading = val
    },

    /**
     * 
     */
    async toWaitApplication(val = false) {
      this.rptReportApplication.loading = val
    },

    /**
     * 
     */
    async toWaitAttention(val = false) {
      this.rptReportAttention.loading = val
    },
  },
  mounted() {
    this.onRefreshActivity()
    this.onRefreshApplication()
    this.onRefreshAttention()
  },
}