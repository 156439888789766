// service
import Service from '@/services/api/Service'

// model
import NtNotificationModel from '@/models/Notification/NtNotification'

/**
 * This class describes a NtNotification service.
 * @class NtNotification (name)
 */
export default class NtNotificationService extends Service {

  /**
   * Request a email for user account activation.
   * 
   * @param {NtNotificationModel} ntNotification - a NtNotificationModel instance.
   * @returns {Promise} response of notify user account activation.
   */
  static accountNotification(ntNotification) {
    return ntNotification.accountActivation().attach(ntNotification.toAccountActivation())
  }

  /**
   * Request a mark for notification.
   * 
   * @param {NtNotificationModel} ntNotification - a NtNotificationModel instance.
   * @returns {Promise} response of mark notification.
   */
  static mark(ntNotification) {
    return ntNotification.mark().attach(ntNotification.toMark())
  }

  /**
   * Define the NtNotification model.
   * @return {String} NtNotification resource.
   */
  static model() {
    return NtNotificationModel
  }

  /**
   * Request a email for reset the user password.
   * 
   * @param {NtNotificationModel} ntNotification - a NtNotificationModel instance.
   * @returns {Promise} response of notify reset password user.
   */
  static password(ntNotification) {
    return ntNotification.restorePassword().attach(ntNotification.toPassword())
  }
}