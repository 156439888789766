// required modules
import Model from '@/models/Model'

/**
 * This class describes a TtsTutor model.
 * @class
 */
export default class TtsTutorModel extends Model {

  /**
   * 
   */
  get career_description() {
    return this.prop('career.description', '-')
  }

  /**
   * 
   */
  get name_case() {
    return `${ this.prop('first_names') } ${ this.prop('last_name1') }`
  }

  /**
   * Define the TtsTutor resource.
   * @return {String} TtsTutor endpoint name.
   */
  resource() {
    return 'tutor'
  }

  /**
   * 
   */
  toCreate() {
    return this.reduce([
      'rut',
      'first_names',
      'last_name1',
      'last_name2',
      'email',
      'university_career_id',
    ])
  }

  /**
   * 
   */
  toUpdate() {
    return this.reduce([
      'id',
      'rut',
      'first_names',
      'last_name1',
      'last_name2',
      'email',
      'university_career_id',
    ])
  }

  /**
   * Return a upload representation.
   * 
   * @return {FormData} usr-user upload representation.
   */
  toUpload() {
    return this.toFormData(['file'])
  }
}