// required modules
import { Fields } from 'collection-wrapper'
import { ruleEmail, ruleMin } from 'vuetify2-wrappers/src/rules'

/**
 * This class describes a UsrStudent fields collection.
 * @class UsrStudentFields (name)
 */
export default class UsrStudentFields extends Fields {

  /**
   * Returns the UsrStudent fields options.
   *
   * @returns {Array} fields options.
   */
  fields() {
    return [

      /*-------------------------------------------*
       * DETAIL FIELDS
       *-------------------------------------------*/
      {
        name: 'rut',
        type: 'rut-field',
        props: {
          fieldProps: {
            label: 'RUT',
            placeholder: 'RUT',
            readonly: true,
          }
        },
      },
      {
        name: 'first_names',
        type: 'text-field',
        props: {
          label: 'Nombres',
          placeholder: 'Nombres',
          readonly: true,
        },
      },
      {
        name: 'last_name1',
        type: 'text-field',
        props: {
          label: 'Primer apellido',
          placeholder: 'Primer apellido',
          readonly: true,
        },
      },
      {
        name: 'last_name2',
        type: 'text-field',
        props: {
          label: 'Segundo apellido',
          placeholder: 'Segundo apellido',
          readonly: true,
        },
      },
      {
        name: 'username',
        type: 'text-field',
        props: {
          label: 'Nombre de usuario',
          placeholder: 'Nombre de usuario',
          readonly: true,
        },
      },
      {
        name: 'email_readonly',
        type: 'text-field',
        props: {
          label: 'Correo electrónico',
          placeholder: 'Correo electrónico',
          readonly: true,
        },
      },
      {
        name: 'alternative_email_readonly',
        type: 'text-field',
        props: {
          label: 'Correo electrónico alternativo',
          placeholder: 'Correo electrónico alternativo',
          readonly: true,
        },
      },
      {
        name: 'phone_number_readonly',
        type: 'text-field',
        props: {
          label: 'Numero telefónico',
          placeholder: 'Numero telefónico',
          readonly: true,
        },
      },
      {
        name: 'gender_full',
        type: 'text-field',
        props: {
          label: 'Genero',
          placeholder: 'Genero',
          readonly: true,
        },
      },
      {
        name: 'university_subheader',
        type: 'subheader',
        flex: {
          cols: 12,
        },
        props: {
          class: 'font-weight-bold px-0 mb-1',
          style: 'border-bottom: 1px solid #eee;',
        },
        html: 'Universidad',
      },
      {
        name: 'campus_name',
        type: 'text-field',
        props: {
          label: 'Sede',
          placeholder: 'Sede',
          readonly: true,
        },
      },
      {
        name: 'careers_name',
        type: 'text-field',
        props: {
          label: 'Carreras',
          placeholder: 'Carreras',
          readonly: true,
        },
      },
      {
        name: 'programs_name',
        type: 'text-field',
        props: {
          label: 'Programas',
          placeholder: 'Programas',
          readonly: true,
        },
      },
      {
        name: 'user_subheader',
        type: 'subheader',
        flex: {
          cols: 12,
        },
        props: {
          class: 'font-weight-bold px-0 mb-1',
          style: 'border-bottom: 1px solid #eee;',
        },
        html: 'Usuario',
      },
      {
        name: 'email_verified_at',
        type: 'text-field',
        props: {
          label: 'Fecha de verificación de correo electrónico',
          placeholder: 'Email verificado',
          readonly: true,
        },
      },
      {
        name: 'activated_account_at_short',
        type: 'text-field',
        props: {
          label: 'Fecha de activación de cuenta',
          placeholder: 'Fecha de activación de cuenta',
          readonly: true,
        },
      },
      {
        name: 'disabled_account_at',
        type: 'text-field',
        props: {
          label: 'Fecha de des-habilitación de cuenta',
          readonly: true,
        },
      },
      {
        name: 'created_at',
        type: 'text-field',
        props: {
          label: 'Fecha de creación',
          readonly: true,
        },
      },
      {
        name: 'updated_at',
        type: 'text-field',
        props: {
          label: 'Fecha de modificación',
          readonly: true,
        },
      },
  
      /**
       * UsrStudent Email Fields.
       */
      {
        name: 'email',
        type: 'text-field',
        flex: {
          cols: 12
        },
        props: {
          label: 'Correo electrónico',
          persistentHint: true,
          placeholder: 'abc123@otro.cl',
          rules: [ruleEmail],
        },
      },

      /**
       * SUMMARY
       */
      {
        name: 'registered',
        type: 'text-field',
        flex: {
          cols: 12
        },
        props: {
          disabled: true,
          label: 'Registrados',
        },
      },
      {
        name: 'enabled',
        type: 'text-field',
        flex: {
          cols: 12
        },
        props: {
          disabled: true,
          label: 'Habilitados',
        },
      },
      {
        name: 'disabled',
        type: 'text-field',
        flex: {
          cols: 12
        },
        props: {
          disabled: true,
          label: 'Deshabilitados',
        },
      },
  
      /**
       * UsrStudent Update Fields.
       */
      {
        name: 'alternative_email',
        type: 'text-field',
        flex: {
          cols: 12
        },
        props: {
          hint: 'Se recomienda un correo distinto a @ucn.cl o @alumnos.ucn.cl.',
          label: 'Correo electrónico (alternativo)',
          persistentHint: true,
          placeholder: 'abc123@otro.cl',
          rules: [ruleEmail],
        },
      },
      {
        name: 'phone_number',
        type: 'text-field',
        flex: {
          cols: 12
        },
        props: {
          label: 'Teléfono',
          persistentHint: true,
          placeholder: '+56 9 123 123',
          rules: [ruleMin(6)],
        },
      },

      /**
       * UPLOAD
       */
      {
        name: 'file',
        type: 'file-input',
        flex: {
          cols: 12
        },
        props: {
          accept: ".xlsx",
          label: 'Archivo en formato Excel',
          showSize: true,
        },
      },
    ]
  }

  /**
   * Returns the UsrStudent create fields.
   *
   * @returns {Collection} fields collection.
   */
  // static toCreate() {
  //   return this.reduce('name', [
  //     'value',
  //   ])
  // }

  /**
   * Returns the UsrStudent detail fields.
   *
   * @returns {Collection} fields collection.
   */
  static toDetail() {
    return this.reduce('name', [
      'detail', 
      'rut', 
      'first_names', 
      'last_name1', 
      'last_name2', 
      'gender_full',  
      'email_readonly', 
      'phone_number_readonly', 
      'university_subheader',
      'campus_name',
      'careers_name',
      'programs_name',
      'user_subheader',
      'username',
      'alternative_email_readonly', 
      'activated_account_at_short',
    ])
  }

  /**
   * Returns the UsrStudent summary fields.
   *
   * @returns {Collection} fields collection.
   */
  static toSummary() {
    return this.reduce('name', [
      'registered',
      'enabled',
      'disabled',
    ])
  }

  /**
   * Returns the UsrStudent update fields.
   *
   * @returns {Collection} fields collection.
   */
  static toUpdate() {
    return this.reduce('name', [
      'alternative_email', 
      'phone_number',
    ])
  }

  /**
   * Returns the UsrStudent update email fields.
   *
   * @returns {Collection} fields collection.
   */
  static toUpdateEmail() {
    return this.reduce('name', [
      'email',
    ])
  }

  /**
   * Returns the UsrStudent update fields.
   *
   * @returns {Collection} fields collection.
   */
  static toUpload() {
    return this.reduce('name', [
      'file',
    ])
  }
}
