// required modules
import { Headers } from 'collection-wrapper'

/**
 * This class describes a SrvSurvey headers collection.
 * @class SrvSurveyHeaders
 */
export default class SrvSurveyHeaders extends Headers {

  /**
   * Returns the SrvSurvey headers options.
   * @returns {Array} headers options.
   */
  headers() {
    return [
      {
        text: '#',
        value: 'id',
        sortable: 'id',
      },
      {
        text: 'Titulo',
        value: 'title',
        sortable: 'title',
      },
      {
        text: 'Estado',
        value: 'status_description',
        sortable: 'survey_status_id',
        align: 'center',
      },
      {
        text: 'Enviadas',
        value: 'total_sent_surveys',
        align: 'right',
      },
      {
        text: 'Respondidas',
        value: 'total_answered_surveys',
        align: 'right',
      },
      {
        text: 'Fecha inicial',
        value: 'only_init_date',
        sortable: 'init_date',
        align: 'right',
      },
    ]
  }

  /**
   * Returns the SrvSurvey list headers.
   * @returns {Collection} headers collection.
   */
  static toList() {
    return this.reduce('value', [
      'id',
      'title',
      'status_description',
      'total_sent_surveys',
      'total_answered_surveys',
      'only_init_date',
    ])
  }
}
