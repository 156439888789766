// layout
import RLayoutModule from '@/components/layouts/LayoutModule'
import AppLayoutRouter from '@/components/layouts/router'

// views
import RptReportActivitiesView from '@/views/Report/RptReport/activities'
import RptReportApplicationsView from '@/views/Report/RptReport/applications'
import RptReportAttentionsView from '@/views/Report/RptReport/attentions'
import RptReportChartView from '@/views/Report/RptReport/chart'
import RptReportDashboardView from '@/views/Report/RptReport/dashboard'

// routes
export default {
  path: '/reportes',
  component: RLayoutModule,
  children: [
    {
      path: '',
      name: 'rpt-report-dashboard',
      component: RptReportDashboardView,
    },
    {
      path: 'actividades',
      component: AppLayoutRouter,
      children: [
        {
          name: 'rpt-report-activities',
          path: '',
          component: RptReportActivitiesView,
        },
        {
          name: 'rpt-report-activities-chart',
          path: ':key',
          component: RptReportChartView,
          meta: {
            title: 'Ver Reporte'
          },
        }
      ],
      meta: {
        title: 'Actividades'
      },
    },
    {
      path: 'aplicaciones',
      component: AppLayoutRouter,
      children: [
        {
          name: 'rpt-report-applications',
          path: '',
          component: RptReportApplicationsView,
        },
        {
          name: 'rpt-report-applications-chart',
          path: ':key',
          component: RptReportChartView,
          meta: {
            title: 'Ver Reporte'
          },
        }
      ],
      meta: {
        title: 'Aplicaciones'
      },
    },
    {
      path: 'atenciones',
      component: AppLayoutRouter,
      children: [
        {
          name: 'rpt-report-attentions',
          path: '',
          component: RptReportAttentionsView,
        },
        {
          name: 'rpt-report-attentions-chart',
          path: ':key',
          component: RptReportChartView,
          meta: {
            title: 'Ver Reporte'
          },
        }
      ],
      meta: {
        title: 'Atenciones'
      },
    },
    {
      name: 'rpt-report-chart',
      path: ':key',
      component: RptReportChartView,
      meta: {
        title: 'Ver Reporte'
      },
    },
  ],
  meta: {
    title: 'Reportes'
  },
  props: {
    indexText: 'Inicio',
  },
}
