// model
import Model from '@/models/Model'

/**
 * This class describes a AUpload model.
 * @class AUpload (name)
 */
export default class AUploadModel extends Model {

  /**
   * Define the AUpload resource.
   * @return {String} AUpload endpoint name.
   */
  resource() {
    return 'upload'
  }
}