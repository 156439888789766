<template lang='pug'>
  v-container.fill-height.pa-0( :class="color" fluid )
    img#ucn-lg( v-bind='imgProps' @click='submit' )
    v-row( align='center' justify='center' style='max-width: initial' )
      v-col( cols='12' sm='9' md='7' lg='5' xl='4' )
        router-view
</template>

<script>
  export default {
    computed: {
      imgProps() {
        return {    
          src: this.logoSrc,
          // width: this.$vuetify.breakpoint.xs ? 100 : this.logoSize,
        }
      },
    },
    methods: {
      submit() {
        this.$router.push({ name: 'home' })
      },
    },
    props: {
      color: {
        type: String,
        default: 'primary',
      },
      logoSrc: {
        type: String,
        default: '/isologo-ucn-18.png',
      },
      logoSize: {
        type: Number,
        default: 256,
      },
    }
  }
</script>

<style type="text/css" scoped>
  #ucn-lg {
    cursor: pointer;
    left: 24px; 
    position: absolute; 
    top: 24px;
    width: 125px;
  }

  @media (min-width: 720px) { 
    #ucn-lg {
      width: 256px;
    }
  }
</style>