// required modules
import Model from '@/models/Model'

/**
 * This class describes a BlgWebNewDashboard model.
 * @class
 */
export default class BlgWebNewDashboardModel extends Model {

  /**
   * 
   */
  get body_body() {
    return this.prop('body.body', '')
  }

  /**
   * 
   */
  get publisher_label() {
    return `Publicado por: ${ this.prop('publisher', 'Desconocido') }, el ${ this.published_at_short }`
  }

  /**
   * 
   */
  get published_at_short() {

    // required data
    const time = this.prop('published_at', '').split(' ').pop()
    const date = this.prop('published_at', '').split(' ').shift().split('-').reverse().join('-')

    // formatted data
    return `${ date } ${ time }`
  }

  /**
   * Define the BlgWebNewDashboard resource.
   * @return {String} BlgWebNewDashboard endpoint name.
   */
  resource() {
    return 'web_new_dashboard'
  }
}