<template lang='pug'>
  app-layout-board

    // principal
    template( slot='principal' )
      app-layout-list(
        item-avatar='mdi-bulletin-board'
        item-subtitle='type_description'
        item-title='title'
        subheader='Últimas actividades agregadas'
        title='Actividades'
        v-bind='activityProps'
        @click-item='onActivity'
        )

    // secondary
    template( slot='secondary' )
      app-layout-list(
        item-avatar='mdi-account-clock'
        item-subtitle='status_description'
        item-title='attention_type_description'
        subheader='Últimas atenciones agregadas'
        title='Atenciones'
        v-bind='attentionProps'
        @click-item='onAttention'
        )

    // additional
    template( slot='additional' )
      app-layout-list(
        item-avatar='mdi-teach'
        item-subtitle='long_description'
        item-title='description'
        subheader='Últimas tutorías agregadas'
        title='Tutorías'
        v-bind='tutorshipProps'
        )

    // side
    template( slot='side' )
      app-layout-list(
        item-avatar='mdi-post'
        item-subtitle='short_description'
        item-title='title'
        subheader='Últimas noticias agregadas'
        title='Noticias'
        v-bind='newProps'
        )
</template>

<script>
  // required modules
  import ActActivityService from '@/services/api/Activity/ActActivity'
  import BlgWebNewService from '@/services/api/Blog/BlgWebNew'
  import StaAttentionService from '@/services/api/StudentAttention/StaAttention'
  import TtsTutorshipService from '@/services/api/Tutorship/TtsTutorship'

  // component
  export default {
    computed: {
      activityProps() {
        return {
          items: this.actActivityList,
        }
      },
      attentionProps() {
        return {
          items: this.staAttentionList,
        }
      },
      newProps() {
        return {
          items: this.blgWebNewList,
        }
      },
      tutorshipProps() {
        return {
          items: this.ttsTutorshipList,
        }
      },
    },
    data() {
      return {
        actActivityList: [],
        blgWebNewList: [],
        staAttentionList: [],
        ttsTutorshipList: [],
      }
    },
    methods: {

      /**
       * 
       */
      onActivity(event) {
        this.goRoute('act-activity-update', { id: event.item.id })
      },

      /**
       * 
       */
      onAttention(event) {
        // this.goRoute('sta-attention-update', { id: event.item.id })
      },

      /**
       * 
       */
      onTutorship(event) {
        // this.goRoute('sta-attention-update', { id: event.item.id })
      },

      /**
       * 
       */
      onWebNew(event) {
        // this.goRoute('sta-attention-update', { id: event.item.id })
      },

      /**
       * 
       */
      onRefreshActivity() {
        ActActivityService.model().include('type').limit(3).get().then(res => {
          this.actActivityList = res.data
        })
      },

      /**
       * 
       */
      onRefreshWebNew() {
        BlgWebNewService.model().get().then(res => {
          this.blgWebNewList = res.data 
        })
      },

      /**
       * 
       */
      onRefreshAttention() {
        StaAttentionService.model().include('status,attentionType').limit(3).get().then(res => {
          this.staAttentionList = res.data
        })
      },

      /**
       * 
       */
      onRefreshTutorship() {
        TtsTutorshipService.model().limit(3).get().then(res => {
          this.ttsTutorshipList = res.data
        })
      },
    },
    mounted() {
      this.onRefreshActivity()
      this.onRefreshAttention()
      this.onRefreshTutorship()
      this.onRefreshWebNew()
    }
  }
</script>
