// required modules
import { Fields } from 'collection-wrapper'

/**
 * This class describes a NtMailRrss filters collection.
 * @class NtMailRrssFilters
 */
export default class NtMailRrssFilters extends Fields {

  /**
   * Returns the NtMailRrss filters options.
   * @returns {Array} filters options.
   */
  fields() {
    return [
      {
        name: 'description',
        type: 'text-field',
        props: {
          label: 'Descripción',
          placeholder: 'Descripción',
        },
      },
      {
        name: 'email',
        type: 'text-field',
        props: {
          label: 'Correo',
          placeholder: 'Correo',
        },
      },
      {
        name: 'phone_number',
        type: 'text-field',
        props: {
          label: 'Teléfono',
          placeholder: 'Teléfono',
        },
      },
    ]
  }

  /**
   * Returns the NtMailRrss list filters.
   * @returns {Collection} filters collection.
   */
  static toList() {
    return this.reduce('name', [
      'description',
      'email',
      'phone_number',
    ])
  }
}
