// required modules
import { Headers } from 'collection-wrapper'

/**
 * This class describes a UsrCareer headers collection.
 *
 * @class UsrCareerHeaders (name)
 */
export default class UsrCareerHeaders extends Headers {

  /**
   * Returns the UsrCareer headers options.
   * @returns {Array} headers options.
   */
  headers() {
    return [
      {
        text: 'Nombre',
        value: 'description',
      },
      {
        text: 'Año',
        value: 'year',
        component: 'v-select',
        props: {
          dense: true,
          flat: true,
          hideDetails: true,
          items: genYears(),
          multiple: true,
          solo: true,
          menuProps: {
            contentClass: 'elevation-2 v-list--dense mt-2',
          },
        },
      },
    ]
  }

  /**
   * Returns the UsrCareer list headers.
   * @returns {Collection} headers collection.
   */
  static toList() {
    return this.reduce('value', [
      'description',
      'year',
    ])
  }
}

function genYears() {
  let date = new Date(Date.now())
  let year = date.getFullYear()
  let resp = []
  for(var i = year; i > 2009; i--) {
    resp.push(i)
  }
  return resp
}
