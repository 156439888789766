// required modules
import Service from '@/services/api/Service'
import NtMailRrssModel from '@/models/Notification/NtMailRrss'

/**
 * This class describes a NtMailRrss service.
 * 
 * @class NtMailRrss (name)
 */
export default class NtMailRrssService extends Service {

  /**
   * Define the NtMailRrss model.
   * 
   * @return {Function} NtMailRrss resource.
   */
  static model() {
    return NtMailRrssModel
  }
}