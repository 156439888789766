// required modules
import { Fields } from 'collection-wrapper'
import TtsTutorshipService from '@/services/api/Tutorship/TtsTutorship'

/**
 * This class describes a TtsTutorshipParallel fields collection.
 * @class TtsTutorshipParallelFields (name)
 */
export default class TtsTutorshipParallelFields extends Fields {

  /**
   * Returns the TtsTutorshipParallel fields options.
   * @returns {Array} fields options.
   */
  fields() {
    return [
      {
        name: 'tutorship_id',
        type: 'select-model',
        props: {
          selectProps: {
            itemText: 'description',
            itemValue: 'id',
            label: 'Tutoría',
            placeholder: 'Seleccionar tutoría',
          },
          fetch: TtsTutorshipService.toListAll(),
        },
      },
      {
        name: 'description',
        type: 'text-field',
        props: {
          label: 'Descripción',
          placeholder: 'P-1',
        },
      },
    ]
  }

  /**
   * Returns the TtsTutorshipParallel create fields.
   *
   * @returns {Collection} fields collection.
   */
  static toCreate() {
    return this.reduce('name', [
      'tutorship_id',
      'description',
    ])
  }

  /**
   * Returns the TtsTutorshipParallel update fields.
   *
   * @returns {Collection} fields collection.
   */
  static toUpdate() {
    return this.reduce('name', [
      'tutorship_id',
      'description',
    ])
  }
}
