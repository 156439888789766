
// required modules
import Service from '@/services/api/Service'
import StaAttentionTypeModel from '@/models/StudentAttention/StaAttentionType'

/**
 * This class describes a StaAttentionType service.
 * @class StaAttentionType
 */
export default class StaAttentionTypeService extends Service {

  /**
   * Define the StaAttentionType model.
   * @return {Function} StaAttentionType resource.
   */
  static model() {
    return StaAttentionTypeModel
  }

  /**
   * 
   */
  static toListByLoggedUser() {
    return this.model().where('by_logged_user', 1)
  }
}
