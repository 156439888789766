// layout
import RLayoutModule from '@/components/layouts/LayoutModule'

// views
import UsrStudentListView from '@/views/User/UsrStudent/list'
import UsrStudentDetailView from '@/views/User/UsrStudent/detail'

// routes
export default {
  path: '/estudiantes',
  component: RLayoutModule,
  children: [
    {
      name: 'usr-student-list',
      path: '',
      component: UsrStudentListView,
    },
    {
      name: 'usr-student-detail',
      path: ':id',
      component: UsrStudentDetailView,
      meta: {
        title: 'Detalle de estudiante'
      },
    },
  ],
  meta: {
    title: 'Estudiantes'
  },
  props: {
    indexText: 'Inicio',
  },
}
