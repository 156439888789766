/**
 * @description
 * User Store Module
 */
export default {
  state: {
    data: {
      code: '',
      name: '',
      display_text: '',
      icon: '',
      items: {},
    },
  },
  mutations: {

    /**
     * Sets the role data.
     *
     * @param      {<type>}  state   The state
     * @param      {<type>}  data    The data
     */
    setRoleData: (state, data) => {
      state.data = {
        ...data || {},
        items: [
          ...((data || {}).items || []),
        ],
      }
    }
  },
  actions: {

    /**
     * Sets the role data.
     *
     * @param      {<type>}  context  The context
     * @param      {<type>}  options  The options
     */
    setRoleData: (context, options) => {
      context.commit('setRoleData', options.data)
    }
  },
  getters: {

    /**
     * Determines if role data.
     *
     * @param      {<type>}   state   The state
     * @return     {boolean}  True if role data, False otherwise.
     */
    hasRoleData: state => !! state.data
  }
}