// required modules
import { Headers } from 'collection-wrapper'

/**
 * This class describes a StaAttention headers collection.
 * @class StaAttentionHeaders
 */
export default class StaAttentionHeaders extends Headers {

  /**
   * Returns the StaAttention headers options.
   * @returns {Array} headers options.
   */
  headers() {
    return [
      {
        text: 'Tipo de atención',
        value: 'attention_type_description',
        sortable: 'attention_type_id',
      },
      {
        text: 'Facilitador',
        value: 'facilitator_name_case',
        sortable: 'facilitator_user_id',

      },
      {
        text: 'Inicio',
        value: 'attention_datetime_short',
        align: 'right',
        sortable: 'attention_datetime',
      },
      {
        text: 'Duración',
        value: 'attention_time_in_minutes_str',
        align: 'right',
        sortable: 'attention_time_in_minutes',
      },
      {
        text: 'Estado',
        value: 'status_description',
        align: 'center'
      },
    ]
  }

  /**
   * Returns the StaAttention list headers.
   * @returns {Collection} headers collection.
   */
  static toList() {
    return this.reduce('value', [
      'attention_type_description',
      'facilitator_name_case',
      'attention_datetime_short',
      'attention_time_in_minutes_str',
      'status_description',
    ])
  }
}
