// layout
import RLayoutModule from '@/components/layouts/LayoutModule'

// views
import ActActivityCreateView from '@/views/Activity/ActActivity/create'
import ActActivityDetailView from '@/views/Activity/ActActivity/detail'
import ActActivityGroupView from '@/views/Activity/ActActivity/group'
import ActActivityInscriptionsView from '@/views/Activity/ActActivity/inscriptions'
import ActActivityListView from '@/views/Activity/ActActivity/list'
import ActActivityQuotasView from '@/views/Activity/ActActivity/quotas'
import ActActivitySearchView from '@/views/Activity/ActActivity/search'
import ActActivitySurveysView from '@/views/Activity/ActActivity/surveys'
import ActActivityUpdateView from '@/views/Activity/ActActivity/update'

// routes
export default {
  path: '/actividades',
  component: RLayoutModule,
  children: [
    {
      name: 'act-activity-list',
      path: '',
      component: ActActivityListView,
    },
    {
      name: 'act-activity-create',
      path: 'crear',
      component: ActActivityCreateView,
      meta: {
        title: 'Crear actividad'
      },
    },
    {
      name: 'act-activity-search',
      path: 'buscar',
      component: ActActivitySearchView,
      meta: {
        title: 'Buscar actividad'
      },
    },
    {
      path: ':id',
      component: ActActivityDetailView,
      children: [
        {
          name: 'act-activity-update',
          path: '',
          component: ActActivityUpdateView,
          meta: {
            title: 'Detalle de actividad'
          },
        },
        {
          name: 'act-activity-group',
          path: 'grupo',
          component: ActActivityGroupView,
          meta: {
            title: 'Grupo de actividad'
          },
        },
        {
          name: 'act-activity-inscriptions',
          path: 'inscripciones',
          component: ActActivityInscriptionsView,
          meta: {
            title: 'Inscripciones'
          },
        },
        {
          name: 'act-activity-quotas',
          path: 'cupos',
          component: ActActivityQuotasView,
          meta: {
            title: 'Cuotas'
          },
        },
        {
          name: 'act-activity-surveys',
          path: 'encuestas',
          component: ActActivitySurveysView,
          meta: {
            title: 'Encuestas'
          },
        },
      ],
    },
  ],
  meta: {
    title: 'Actividades'
  },
  props: {
    indexText: 'Inicio',
  },
}
