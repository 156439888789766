//
import UsrStudentService from '@/services/api/User/UsrStudent'
import UsrStudentFields from '@/views/User/UsrStudent/fields'
//
export default {
  computed: {

    /**
      * Gets the usr-student form props.
      * @return {Object} The form props.
      */
    formProps() {
      return {
        items: UsrStudentFields.toDetail(),
      }
    }
  },
  data() {
    return {
      usrStudent: {},
    }
  },
  methods: {

    /**
      * Returns a reset usr-student form.
      * 
      * @return {Promise} A form reset process.
      */
    reset() {
      return this
        .toWait(true)
        .then(this.refresh)
        .then(this.toWait)
    },

    /**
      * Returns a reset usr-student form process.
      * 
      * @return {Object} A reset process.
      */
    refresh() {
      return UsrStudentService.model().include('campus,careers,programs').find(this.getParam('id')).then(usrStudent => {
        this.usrStudent = usrStudent
      })
    },
  },
  mounted() {
    this.reset()
  }
}