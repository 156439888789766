// layout
import RLayoutModule from '@/components/layouts/LayoutModule'

// views
import SrvSurveyAnswersView from '@/views/Survey/SrvSurvey/answers'
import SrvSurveyCreateView from '@/views/Survey/SrvSurvey/create'
import SrvSurveyListView from '@/views/Survey/SrvSurvey/list'
import SrvSurveyPreviewView from '@/views/Survey/SrvSurvey/preview'
import SrvSurveyTargetView from '@/views/Survey/SrvSurvey/target'
import SrvSurveyUpdateView from '@/views/Survey/SrvSurvey/update'

// routes
export default {
  path: '/encuestas',
  component: RLayoutModule,
  children: [
    {
      name: 'srv-survey-list',
      path: '',
      component: SrvSurveyListView,
    },
    {
      name: 'srv-survey-create',
      path: 'crear',
      component: SrvSurveyCreateView,
      meta: {
        title: 'Crear encuesta'
      },
    },
    {
      name: 'srv-survey-update',
      path: ':id',
      component: SrvSurveyUpdateView,
      meta: {
        title: 'Editar encuesta'
      },
    },
    {
      name: 'srv-survey-preview',
      path: ':id/vista_previa',
      component: SrvSurveyPreviewView,
      meta: {
        title: 'Vista previa de encuesta'
      },
    },
    {
      name: 'srv-survey-target',
      path: ':id/publico_objetivo',
      component: SrvSurveyTargetView,
      meta: {
        title: 'Público objetivo de encuesta'
      },
    },
    {
      name: 'srv-survey-answers',
      path: ':id/respuestas',
      component: SrvSurveyAnswersView,
      meta: {
        title: 'Respuestas anónimas'
      },
    },
  ],
  meta: {
    title: 'Encuestas'
  },
  props: {
    indexText: 'Inicio',
  },
}
