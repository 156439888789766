// required modules
import Model from '@/models/Model'
import APublishModel from '@/models/Actions/APublish'

/**
 * This class describes a TtsTutoringNew model.
 * @class
 */
export default class TtsTutoringNewModel extends Model {

  /**
   * 
   */
  get created_at_short() {

    // required data
    const time = this.prop('created_at', '').split(' ').pop()//.split(':').slice(0, 2).join(':')
    const date = this.prop('created_at', '').split(' ').shift().split('-').reverse().join('-')

    // formatted data
    return `${ date } ${ time }`
  }

  /**
   * 
   */
  get updated_at_short() {

    // required data
    const time = this.prop('updated_at', '').split(' ').pop()//.split(':').slice(0, 2).join(':')
    const date = this.prop('updated_at', '').split(' ').shift().split('-').reverse().join('-')

    // formatted data
    return `${ date } ${ time }`
  }

  /**
   * 
   */
  get new_datetime_short() {

    // required data
    const time = this.prop('new_datetime', '').split(' ').pop()//.split(':').slice(0, 2).join(':')
    const date = this.prop('new_datetime', '').split(' ').shift().split('-').reverse().join('-')

    // formatted data
    return `${ date } ${ time }`
  }

  /**
   * 
   */
  publish() {
    return this.hasMany(APublishModel)
  }

  /**
   * 
   */
  reset() {
    this.body = this.prop('body.body')
    return this
  }

  /**
   * Define the TtsTutoringNew resource.
   * @return {String} TtsTutoringNew endpoint name.
   */
  resource() {
    return 'tutoring_new'
  }

  /**
   * 
   */
  get status_description() {
    return this.prop('status.description', '')
  }

  /**
   * 
   */
  get status_published() {
    return this.prop('status.id', 0) === 2
  }

  /**
   * 
   */
  toCreate() {
    return this.reduce([
      'title',
      'body',
    ])
  }

  /**
   * 
   */
  toUpdate() {
    return this.reduce([
      'id',
      'title',
      'body',
    ])
  }
}