// service
import Service from '@/services/api/Service'

// model
import UsrUniversityCampusModel from '@/models/User/UsrUniversityCampus'

/**
 * This class describes a UsrUniversityCampus service.
 * @class UsrUniversityCampus (name)
 */
export default class UsrUniversityCampusService extends Service {

  /**
   * Define the UsrUniversityCampus model.
   * @return {String} UsrUniversityCampus resource.
   */
  static model() {
    return UsrUniversityCampusModel
  }
  /**
   * 
   */
   static toList() {
    return this.fetcher({
      sortBy:[
        'id'
      ]
    })
  }

}