// model
import Model from '@/models/Model'
import AAnswerModel from '@/models/Actions/AAnswer'
import AAnonymousModel from '@/models/Actions/AAnonymous'
import AAssignTargetModel from '@/models/Actions/AAssignTarget'
import ACompleteModel from '@/models/Actions/AComplete'
import ASendToTargetModel from '@/models/Actions/ASendToTarget'
import SrvSurveySectionModel from '@/models/Survey/SrvSurveySection'
import SrvSurveyTargetModel from '@/models/Survey/SrvSurveyTarget'

/**
 * This class describes a SrvSurvey model.
 * @class SrvSurvey (name)
 */
export default class SrvSurveyModel extends Model {

  /**
   * 
   */
  answers = {}

  /**
   * 
   */
  anonymous() {
    return this.hasMany(AAnonymousModel)
  }

  /**
   * 
   */
  answer() {
    return this.hasMany(AAnswerModel)
  }

  /**
   * 
   */
  assignTarget() {
    return this.hasMany(AAssignTargetModel)
  }

  /**
   * 
   */
  get blocks() {
    return this.getProperty('sections', []).reduce(this.reduceSectionToBlock, [])
  }

  /**
   * 
   */
  get can_add_target() {
    return this.can_targeted && !this.has_activity
  }

  /**
   * 
   */
  get can_edit() {
    return this.is_draft
  }

  /**
   * 
   */
  get can_targeted() {
    return this.is_draft
  }

  /**
   * 
   */
  complete() {
    return this.hasMany(ACompleteModel)
  }

  /**
   * 
   */
  get description_prevent() {
    return this.prop('description', '').trim().length ? this.description : ''
  }

  /**
   * 
   */
  get has_activity() {
    return this.prop('activity', null) !== null
  }

  /**
   * 
   */
  get init_date_prevent() {
    return this.prop('init_date', null) || 'Sin fecha de inicio.'
  }

  /**
   * 
   */
  initAnswers = () => {
    this.answers = this.prop('sections', []).reduce(this.reduceSectionsToBlocks, []).reduce(this.reduceBlocksToAnswers, {})
  }

  /**
   * 
   */
  get is_draft() {
    return this.prop('survey_status_id', 0) === 1
  }

  /**
   * 
   */
  get limit_date_prevent() {
    return this.prop('limit_date', null) || 'Sin fecha límite.'
  }

  /**
   * 
   */
  get only_init_date() {
    return this.getProperty('init_date', '').split(' ').shift().split('-').reverse().join('-')
  }

  /**
   * 
   */
  mapBlockResponse = srvSurveyBlock => {
    return srvSurveyBlock.toUpdate()
  }

  /**
   * 
   */
  mapBlockToResponse = (item) => {
    
    //
    const srvSurveyBlockName          = `block_response_${ item.id }`
    const surveyBlockId               = item.id
    const surveyBlockTypeIdentifierId = item.survey_block_identifier_id 
    let surveyBlockResponse           = ''
    let surveyBlockResponseOther      = ''
    let surveyBlockOptions            = []
    let surveyBlockFields             = []

    //
    switch(surveyBlockTypeIdentifierId) {

      //basic
      case 2:
      case 3:
      case 11:
      case 12:
        surveyBlockResponse = this.answers[srvSurveyBlockName]
        break;
      
      // multiple
      case 4:
      case 5:
        surveyBlockOptions  = this.answers[srvSurveyBlockName]
        break;

      // list
      case 6:
        surveyBlockOptions  = [this.answers[srvSurveyBlockName]]
        break;
      
      // range
      case 7:
      case 8:
        surveyBlockResponse = this.answers[srvSurveyBlockName]
        break;
      
      // multiple x multiple
      case 9:
      case 10:
        surveyBlockFields   = (this.answers[srvSurveyBlockName] || []).map(this.mapOption)
        break;
    }
    
    //
    return {
      survey_block_id : surveyBlockId,
      response        : surveyBlockResponse,
      response_other  : surveyBlockResponseOther,
      options         : surveyBlockOptions,
      fields          : surveyBlockFields,
    }
  }

  /**
   * 
   */
  mapDateTime(datetime) {
    return datetime.split(':').length > 2 ? datetime : datetime.concat(':00')
  }

  /**
   * 
   */
  mapOption(item) {
    return {
      options: Array.isArray(item) ? item.filter(item => item !== null && typeof item !== "boolean") : [item].filter(item => item !== null)
    }
  }

  /**
   * 
   */
  mapSection(srvSurveySection) {
    return SrvSurveySectionModel.from(srvSurveySection).reset()
  }

  /**
   * 
   */
  mapSectionRequest = srvSurveySection => {
    return {
      title: srvSurveySection.title,
      description: srvSurveySection.description,
      blocks: srvSurveySection.blocks.map(this.mapBlockResponse),
    }
  }

  /**
   * 
   */
  mapTarget = target => {
    return SrvSurveyTargetModel.from(target).reset()
  }

  /**
   * 
   */
  mapTargetRequest = srvSurveyTarget => {
    let identifiers         = srvSurveyTarget.identifiers 
    let target_identifiers  = srvSurveyTarget.target_identifiers
    let career_year         = srvSurveyTarget.career_year
    let extra_param         = ''

    if (identifiers) {
      identifiers = identifiers.map(e => typeof e === "number" ? e : e.identifier)
    }

    if (target_identifiers && target_identifiers.length) {
      extra_param         = target_identifiers[0].extra_param
      target_identifiers  = target_identifiers.map(e => {
        console.log({ e })
        return typeof e === "number" ? e : e.identifier
      })
      if (extra_param) {
        career_year       = JSON.parse(extra_param).career_year
      }
    }

    console.log({ identifiers, target_identifiers })

    return {
      identifiers : identifiers || target_identifiers || [],
      name        : srvSurveyTarget.name,
      target_type : srvSurveyTarget.target_type,
      career_year : srvSurveyTarget.name === "Carrera" ? career_year : undefined,
    }
  }

  /**
   * 
   */
  reduceBlocksToAnswers = (answers, item) => {
    
    // required data
    let srvSurveyAnswerName   = `block_response_${ item.id }`
    let srvSurveyAnswerValue  = ''

    // change survey answer value
    switch(item.survey_block_identifier_id) {
      case  4: 
      case  5: srvSurveyAnswerValue = []; break;
      case  8: srvSurveyAnswerValue = 1; break;
      case  9: srvSurveyAnswerValue = new Array(item.fields.length).fill(null, 0); break;
      case 10: srvSurveyAnswerValue = new Array(item.fields.length).fill(new Array(item.options.length).fill(true, 0), 0); break;
    }
    
    // return answers
    return {
      ...answers,
      [srvSurveyAnswerName]: srvSurveyAnswerValue,
    }
  }

  /**
   * 
   */
  reduceSectionToBlock = (blocks, section) => {
    return blocks.concat(section.blocks.map(this.mapBlockToResponse))
  }

  /**
   * 
   */
  reduceSectionsToBlocks = (blocks, section) => {
    return [ ...blocks, ...section.blocks ]
  }

  /**
   * 
   */
  reset() {

    //
    // this.map('sections', [], this.mapSection)
    // this.sections = this.prop('sections', [], e => e.map(this.mapSection))
    // this.answers = this.answers || {}
    this.sections = this.prop('sections', []).map(this.mapSection)
    this.targets = this.prop('targets', []).map(this.mapTarget)

    //
    return this
  }

  /**
   * Define the SrvSurvey resource.
   * @return {String} SrvSurvey endpoint name.
   */
  resource() {
    return 'survey'
  }

  /**
   * 
   */
  sendToTarget() {
    return this.hasMany(ASendToTargetModel)
  }

  /**
   * 
   */
  get status_description() {
    return this.prop('status.description', '-')
  }
  
  /**
   * 
   */
  toAnswer() {

    //
    return {
      blocks: this.blocks,
    }
  }
  
  /**
   * 
   */
  toAssignTarget() {

    //
    var clone = this.clone()
    var cloneTargets = this.clone().prop('targets', []).map(this.mapTargetRequest)

    //
    clone.targets = cloneTargets

    //
    return clone.reduce([
      'id',
      'targets',
    ])
  }

  /**
   * 
   */
  toCreate() {

    //
    var clone               = this.clone()
    var cloneInitDateTime   = this.mapDateTime(clone.init_datetime)
    var cloneLimitDateTime  = clone.limit_datetime !== '' ? this.mapDateTime(clone.limit_datetime) : null
    var cloneSections       = clone.sections.map(this.mapSectionRequest)

    //
    clone.init_datetime   = cloneInitDateTime
    clone.limit_datetime  = cloneLimitDateTime
    clone.sections        = cloneSections

    //
    return clone.reduce([
      'title',
      'init_datetime',
      'is_required',
      'limit_datetime',
      'description',
      'sections',
    ])
  }

  /**
   * 
   */
  toUpdate() {

    //
    var clone               = this.clone()
    var cloneInitDateTime   = this.mapDateTime(clone.init_datetime)
    var cloneIsRequired     = clone.prop('is_required', false)
    var cloneLimitDateTime  = clone.limit_datetime !== '' ? this.mapDateTime(clone.limit_datetime) : null
    var cloneSections       = clone.sections.map(this.mapSectionRequest)

    //
    clone.init_datetime   = cloneInitDateTime
    clone.limit_datetime  = cloneLimitDateTime
    clone.sections        = cloneSections
    clone.is_required     = cloneIsRequired

    //
    return clone.reduce([
      'id',
      'title',
      'init_datetime',
      'is_required',
      'limit_datetime',
      'description',
      'sections',
    ])
  }
}