// required modules
import store from '@/store'
import { Fields } from 'collection-wrapper'
import { ruleEmail, ruleMax, ruleMinNumber, ruleRequired, ruleRut } from 'vuetify2-wrappers/src/rules'

// models
import ActActivityTypeModel from '@/models/Activity/ActActivityType'
import ActActivityAttendanceStatusModel from '@/models/Activity/ActActivityAttendanceStatus'
import UsrCareerModel from '@/models/User/UsrCareer'
import UsrClassroomModel from '@/models/User/UsrClassroom'
import UsrClassroomService from '@/services/api/User/UsrClassroom'
import UsrExternalFacilitatorModel from '@/models/User/UsrExternalFacilitator'
import UsrFacilitatorModel from '@/models/User/UsrFacilitator'

//
export default class ActActivityFields extends Fields {

  /**
   * Return the ActActivityFields fields items
   * @returns {Array} the fields items
   */
  fields() {
    return [
      {
        name: 'title',
        type: 'text-field',
        info: 'Título de la Actividad.',
        props: {
          label: 'Titulo',
          placeholder: 'Titulo',
          rules: [ruleRequired],
        },
      },
      {
        name: 'activity_type_id',
        type: 'select-model',
        info: 'Tipo de Actividad.',
        props: {
          fetch: () => ActActivityTypeModel.get(),
          selectProps: {
            itemText: 'description',
            itemValue: 'id',
            label: 'Tipo de actividad',
            menuProps: {
              contentClass: 'elevation-2 v-list--dense mt-2',
            },
            placeholder: 'Tipo de actividad',
            rules: [ruleRequired],
          },
        },
      },
      {
        name: 'event_datetime',
        type: 'date-time-field',
        info: 'Fecha/Hora de la Actividad.',
        props: {
          fieldProps: {
            label: 'Fecha de inicio',
            placeholder: 'Fecha de inicio',
            dateFormat: 'dd-MM-yyyy',
            rules: [ruleRequired],
          },
          reverse: true,
        },
      },
      {
        name: 'event_finish_datetime',
        type: 'date-time-field',
        info: 'Fecha/Hora de la Actividad.',
        props: {
          fieldProps: {
            label: 'Fecha de termino',
            placeholder: 'Fecha de termino',
            dateFormat: 'dd-MM-yyyy',
            rules: [ruleRequired],
          },
          reverse: true,
        },
      },
      {
        name: 'event_place',
        type: 'combobox-model',
        info: 'Lugar donde se realizará la Actividad.',
        props: {
          allowCreate: false,
          comboboxProps: {
            itemText: 'description',
            itemValue: 'description',
            label: 'Sala',
            menuProps: {
              contentClass: 'elevation-2 v-list--dense mt-2',
            },
            placeholder: 'Sala',
            returnObject: false,
            rules: [ruleRequired],
          },
          filter: {
            university_campus_id: store.getters.userUniversityCampusId,
          },
          itemCreate: 'description',
          limit: -1,
          map: res => res,
          model: UsrClassroomModel,
          label: 'Sala',
        },
      },
      {
        name: 'body',
        type: 'editor',
        info: 'Cuerpo o Contenido en HTML de la actividad.',
        flex: {
          cols: 12,
        },
        props: {
          label: 'Texto',
          placeholder: 'Texto',
        },
      },
      {
        name: 'support_facilitators_ids',
        type: 'select-model',
        info: 'Facilitadores de apoyo',
        props: {
          selectProps: {
            itemText: 'name_case',
            itemValue: 'id',
            label: 'Facilitadores de apoyo',
            menuProps: {
              contentClass: 'elevation-2 v-list--dense mt-2',
            },
            multiple: true,
            placeholder: 'Facilitadores de apoyo',
            smallChips: true,
          },
          fetch: () => UsrFacilitatorModel.get(),
        },
      },
      {
        name: 'external_facilitator',
        type: 'combobox-model',
        info: 'Facilitador de externo',
        props: {
          allowCreate: true,
          itemCreate: 'name',
          comboboxProps: {
            clearable: true,
            items: [],
            hint:'Aprieta la tecla ENTER para guardar el valor ingresado.',
            itemText: 'name',
            itemValue: 'id',
            label: 'Facilitador externo',
            menuProps: {
              maxHeight: 0,
              contentClass: 'elevation-2 v-list--dense',
            },
            persistentHint: true,
            placeholder: 'Facilitador externo',
            smallChips: true,
          },
          model: UsrExternalFacilitatorModel,
          limit: 10,
          label: 'Facilitador externo',
        },
      },
      {
        name: 'closing_subscription_minutes',
        type: 'text-field',
        info: 'Tiempo para alcanzar a inscribirse.',
        props: {
          label: 'Limite inscripción (Minutos)',
          placeholder: 'Limite inscripción (Minutos)',
        },
      },
      {
        name: 'closing_unsubscription_minutes',
        type: 'text-field',
        info: 'Tiempo para alcanzar a des-inscribirse.',
        props: {
          label: 'Limite des-inscripción (Minutos)',
          placeholder: 'Limite des-inscripción (Minutos)',
        },
      },
      {
        name: 'type_quota',
        type: 'select',
        info: 'Tipo de cuota.',
        flex: {
          cols: 12,
          sm: 6,
        },
        props: {
          items: [
            'Pública',
            'Carreras',
            'Programas',
            'Usuarios',
          ],
          label: 'Tipo',
          menuProps: {
            contentClass: 'elevation-2 v-list--dense mt-2',
          },
          placeholder: 'Tipo', 
        },
      },
      {
        name: 'student_quota',
        type: 'text-field',
        info: 'Cupo máximo de estudiantes.',
        flex: {
          cols: 12,
          sm: 6,
        },
        props: {
          label: 'Cupo máximo de estudiantes',
          placeholder: 'Cupo máximo de estudiantes', 
          rules: [ruleMinNumber(1)],
        },
      },
      {
        name: 'image',
        type: 'file-input',
        info: 'Imagen portada de la actividad.',
        flex: {
          cols: 12,
        },
        props: {
          label: 'Imagen',
          placeholder: 'Seleccionar imagen',
        },
      },
      {
        name: 'message_subject',
        type: 'text-field',
        info: 'Asunto.',
        flex: {
          cols: 12,
        },
        props: {
          label: 'Asunto',
          placeholder: 'Asunto', 
        },
      },
      {
        name: 'message_body',
        type: 'editor',
        info: 'Cuerpo de la notificación, puede ser HTML.',
        flex: {
          cols: 12,
        },
        props: {
          label: 'Mensaje',
          placeholder: 'Mensaje', 
        },
      },
      {
        name: 'send_email',
        type: 'switch',
        flex: {
          cols: 12,
          sm: 6,
        },
        props: {
          label: 'Enviar correo electrónico',
        },
      },
      {
        name: 'target',
        type: 'select',
        flex: {
          cols: 12,
          sm: 6,
        },
        props: {
          items: [
            {
              text: 'Todos quienes pueden ver la actividad',
              value: 1,
            },
            {
              text: 'Solo los inscritos en la actividad',
              value: 2,
            },
          ],
          label: 'Publico objetivo',
          menuProps: {
            contentClass: 'elevation-2 v-list--dense mt-2',
          },
          placeholder: 'Publico objetivo', 
        },
      },
      {
        name: 'activity_quota_id',
        type: 'select',
        info: 'Cuota de la actividad',
        flex: {
          cols: 12,
        },
        props: {
          label: 'Cuota de la actividad',
          menuProps: {
            contentClass: 'elevation-2 v-list--dense mt-2',
          },
          placeholder: 'Cuota de la actividad',
          rules: [ruleRequired]
        },
      },
      {
        name: 'activity_attendance_status_id',
        type: 'select-model',
        flex: {
          cols: 12,
        },
        props: {
          fetch: () => ActActivityAttendanceStatusModel.get(),
          selectProps: {
            itemText: 'description',
            itemValue: 'id',
            label: 'Estado de la asistencia',
            menuProps: {
              contentClass: 'elevation-2 v-list--dense mt-2',
            },
            placeholder: 'Estado de la asistencia', 
          },
        },
      },
      {
        name: 'observation',
        type: 'textarea',
        flex: {
          cols: 12,
        },
        props: {
          label: 'Observación de la asistencia',
          placeholder: 'Observación de la asistencia', 
        },
      },
      {
        name: 'group_length',
        type: 'text-field',
        info: 'Cantidad de actividades',
        flex: {
          cols: 12,
        },
        props: {
          label: 'Cantidad de actividades',
          placeholder: 'Cantidad de actividades', 
        },
        trigger: null,
      },
      {
        name: 'notify_students',
        type: 'switch',
        info: 'Indica si se deben enviar notificaciones a los estudiantes.',
        flex: {
          flex: 12,
        },
        prop: {
          inset: true,
          label: '¿Desea notificar a los estudiantes?',
          inputValue: true,
        },
      }
    ]
  }

  /**
   * 
   */
  static toAttendance() {
    return this.reduce('name', [
      'activity_attendance_status_id',
      'observation',
    ])
  }

  /**
   * 
   */
  static toBody() {
    return this.reduce('name', [
      'body',
    ])
  }

  /**
   * 
   */
  static toConfirm() {
    return this.reduce('name', [
      'notify_students'
    ])
  }

  /**
   * @returns {Array} the create activity fields.
   */
  static toCreate() {
    return this.reduce('name', [
      'title',
      'activity_type_id',
      'event_datetime',
      'event_finish_datetime',
      'event_place',
      'body',
      'support_facilitators_ids',
      'external_facilitator',
      'closing_subscription_minutes',
      'closing_unsubscription_minutes',
    ])
  }

  /**
   * 
   */
  static toDate() {
    return this.init().whereIn('name', [
      'event_datetime',
      'event_finish_datetime',
      'event_place',
    ])
    .each(item => {
      item.flex = {
        sm: 6,
        cols: 12,
      }
    })
  }

  /**
   * 
   */
  static toFacilitators() {
    return this.init().whereIn('name', [
      'support_facilitators_ids',
      'external_facilitator',
    ])
    .each(item => {
      item.flex = {
        sm: 6,
        cols: 12,
      }
    })
  }

  /**
   * 
   */
  static toGeneral() {
    return new this().collect().whereIn('name', [
      'title',
      'activity_type_id',
    ])
    .each(item => {
      item.flex = {
        sm: 6,
        cols: 12,
      }
    })
  }

  /**
   * 
   */
  static toGroup() {
    return this.reduce('name', [
      'group_length',
    ])
  }

  /**
   * 
   */
  static toImage() {
    return this.reduce('name', [
      'image',
    ])
  }

  /**
   * 
   */
  static toInscription() {
    return this.reduce('name', [
      'activity_quota_id',
    ])
  }

  /**
   * 
   */
  static toNotification() {
    return this.init().whereIn('name', [
      'message_subject',
      'message_body',
      'target',
      'send_email',
    ]).all()
  }

  /**
   * 
   */
  static toQuota(props, other) {
    // console.log(props, other)
    return this.init().whereIn('name', [
      'student_quota',
      'type_quota',
    ]).each(item => {
      // console.log(props, other)
      if (item.name == 'student_quota') {
        if (other.type_quota == 'Usuarios') {
          item.props.readonly = true
          item.props.label = "Seleccione estudiantes de la lista"
        } else {
          item.props.readonly = false
          item.props.label = "Cupo máximo de estudiantes."
        }
      }
    }).all()
  }
}