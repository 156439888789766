// layout
import RLayoutModule from '@/components/layouts/LayoutModule'

// views
import TtsTutorshipParallelCreateView from '@/views/Tutorship/TtsTutorshipParallel/create'
import TtsTutorshipParallelListView from '@/views/Tutorship/TtsTutorshipParallel/list'
import TtsTutorshipParallelUpdateView from '@/views/Tutorship/TtsTutorshipParallel/update'

// routes
export default {
  path: '/paralelos',
  component: RLayoutModule,
  children: [
    {
      name: 'tts-tutorship-parallel-list',
      path: '',
      component: TtsTutorshipParallelListView,
    },
    {
      name: 'tts-tutorship-parallel-create',
      path: 'crear',
      component: TtsTutorshipParallelCreateView,
      meta: {
        title: 'Crear Paralelo'
      },
    },
    {
      name: 'tts-tutorship-parallel-update',
      path: 'editar',
      component: TtsTutorshipParallelUpdateView,
      meta: {
        title: 'Editar Paralelo'
      },
    },
  ],
  meta: {
    title: 'Paralelos'
  },
  props: {
    indexText: 'Inicio',
  },
}
