// service
import Service from '@/services/api/Service'

// model
import StaAttentionModel from '@/models/StudentAttention/StaAttention'

/**
 * This class describes a StaAttention service.
 * @class StaAttention
 */
export default class StaAttentionService extends Service {

  /**
   * 
   */
  static complete(staAttention) {

    //
    const staAttentionComplete = staAttention.toComplete()

    //
    if (staAttentionComplete.post_observation.length) {
      return staAttention.complete().attach(staAttention.toComplete())
    }

    //
    return Promise.reject('Solo se puede completar con los estados "Atendido" y/o "No Asistió".')
  }

  /**
   * 
   */
  static cancel(staAttention) {
    return staAttention.cancel().attach(staAttention.toCancel())
  }

  /**
   * 
   */
  static cancelReservation(staAttention) {
    return staAttention.cancelReservation().attach(staAttention.toCancelReservation())
  }

  /**
   * 
   */
  static confirm(staAttention) {
    return staAttention.confirm().attach(staAttention.toConfirm())
  }

  /**
   * 
   */
  static formatDateTime() {
    return new Intl.DateTimeFormat('es-CL', {
    // formatMatcher: 'best fit',
      year: 'numeric',
      day: '2-digit',
      month: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
      // timeStyle: 'short',
    })
  }

  /**
   * 
   * @param {*} opts 
   */
  static getStart() {
    
    //
    let newDate = this.getToday()

    //
    newDate.setTime(this.getToday().getTime() + 3600000)

    //
    return newDate
  }

  /**
   * 
   * @param {*} opts 
   */
  static getInitial() {

    //
    const initialDatetime = this.formatDateTime().format(this.getStart())
    const initialDate = initialDatetime.split(' ').shift().split('-').reverse().join('-')
    const initialTime = initialDatetime.split(' ').pop()

    //
    return `${ initialDate } ${ initialTime }`
  }

  /**
   * 
   * @param {*} opts 
   */
  static getToday() {
    return new Date()
  }

  /**
   * Return a initial data with the options provided.
   * 
   * @param   {Object} opts - The options of a new StaAttention model instance.
   * @returns {Object}        The initial data.
   */
  static initialize(opts) {
    const todayDate = new Date(Date.now())
    let afterDate = new Date(Date.now())
    afterDate.setHours(todayDate.getHours()+1)
    const todayDateString = new Date().toLocaleString('es-CL').split(', ')[0].split('-').reverse().join('-')
    const todayDateStringReverse = todayDate.toLocaleDateString('es-CL')
    const todayHourString = todayDate.toLocaleTimeString('es-CL').split('T').pop().split('.').shift()
    const afterHourString = afterDate.toLocaleTimeString('es-CL').split('T').pop().split('.').shift()
    const eventDatetime = `${ todayDateString } ${ todayHourString }`
    const eventAfterDatetime = `${ todayDateString } ${ afterHourString }`
    return {
      // attention_datetime: '2020-06-04 12:00',
      attention_type_id: '',
      attention_datetime: eventDatetime,
      attention_time_in_minutes: 60,
      cancel_time_in_minutes: 60,
      students: [],
    }
  }

  /**
   * Define the StaAttention model.
   * 
   * @return {Function} StaAttention resource.
   */
  static model() {
    return StaAttentionModel
  }

  /**
   * 
   */
  static toList() {
    return this.fetcher({
      includes: [
        'attention_type',
        'facilitator',
        'status',
      ]
    })
  }

  /**
   * 
   */
  static reserve(staAttention) {
    return staAttention.reserve().attach(staAttention.toReserve())
  }

  /**
   * 
   */
  static schedule(staAttention) {
    return staAttention.schedule().attach(staAttention.toSchedule())
  }

  /**
   * 
   */
  static sendNotification(staAttention) {
    return staAttention.sendNotification().attach(staAttention.toSendNotification())
  }

  /**
   * 
   */
  static sendNotificationToFacilitator(staAttention) {
    return staAttention.sendNotificationToFacilitator().attach(staAttention.toSendNotificationToFacilitator())
  }
}