// required modules
import Model from '@/models/Model'

/**
 * This class describes a RptReportAttentionSessionQuantity model.
 * @class RptReportAttentionSessionQuantity
 */
export default class RptReportAttentionSessionQuantityModel extends Model {

  /**
   * 
   */
  get data() {
    return [ this.headers ].concat(this.rows)
  }

  /**
   * 
   */
  get headers() {
    return [
      "Tipo", 
      "Sesiones",
    ]
  }

  /**
   * 
   */
  mapRow(item) {
    return [
      item.type, 
      item.quantity,
    ]
  }

  /**
   * 
   */
  get options() {
    return {
      chart: {
        subtitle: "Cantidad de Sesiones realizadas (general y por tipo de atención)"
      },
      height: 400,
      title: "Cantidad de Sesiones realizadas (general y por tipo de atención)",
      vAxis: {
        minValue: 0,
        ticks: [ 0, 5, 10 ],
        title: "Total de actividades"
      },
    }
  }

  /**
   * 
   */
  get params() {
    return {
      type: this.type,
      options: this.options,
      data: this.data,
    }
  }

  /**
   * Define the RptReportAttentionSessionQuantity resource.
   * @return {String} RptReportAttentionSessionQuantity endpoint name.
   */
  resource() {
    return 'report/attention/session_quantity'
  }

  /**
   * 
   */
  reset() {
    this.loading = false
    return this
  }

  /**
   * 
   */
  get rows() {
    return this.prop('report.detail', []).map(this.mapRow)
  }

  /**
   * 
   */
  get type() {
    return 'BarChart'
  }
}