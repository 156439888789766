// model
import Model from '@/models/Model'

/**
 * This class describes a UsrCareer model.
 * @class UsrCareer (name)
 */
export default class UsrCareerModel extends Model {

  //
  year = 0

  /**
   * Define the UsrCareer resource.
   * @return {String} UsrCareer endpoint name.
   */
  resource() {
    return 'career'
  }
}