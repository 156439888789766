// required modules
import Service from '@/services/api/Service'
import RptReportApplicationRegisteredQuantityModel from '@/models/Report/RptReportApplicationRegisteredQuantity'

/**
 * This class describes a RptReportApplicationRegisteredQuantity service.
 * @class
 */
export default class RptReportApplicationRegisteredQuantityService extends Service {

  /**
   * Define the RptReportApplicationRegisteredQuantity model.
   * @return {Function} RptReportApplicationRegisteredQuantity resource.
   */
  static model() {
    return RptReportApplicationRegisteredQuantityModel
  }
}