// required modules
import { Fields } from 'collection-wrapper'
import { ruleRequired } from 'vuetify2-wrappers/src/rules'
import SrvSurveySectionHeaders from '@/views/Survey/SrvSurveySection/headers'
import SrvSurveyTargetHeaders from '@/views/Survey/SrvSurveyTarget/headers'

/**
 * This class describes a SrvSurvey fields collection.
 * @class SrvSurveyFields (name)
 */
export default class SrvSurveyFields extends Fields {

  /**
   * Returns the SrvSurvey fields options.
   *
   * @returns {Array} fields options.
   */
  fields() {
    return [
      {
        name: 'title',
        type: 'text-field',
        info: 'Título de la Encuesta.',
        flex: {
          cols: 12,
        },
        props: {
          label: 'Titulo',
          placeholder: 'Titulo',
          rules: [ruleRequired],
        },
      },
      {
        name: 'init_datetime',
        type: 'date-time-field',
        info: 'Fecha/Hora de inicio para responder la encuesta.',
        props: {
          fieldProps: {
            label: 'Inicio',
            placeholder: 'Inicio',
          },
        },
      },
      {
        name: 'limit_datetime',
        type: 'date-time-field',
        info: 'Fecha/Hora de término para responder la encuesta. Si no se envía, no hay tiempo límite (a menos que el facilitador cierre manualmente la encuesta).',
        props: {
          fieldProps: {
            label: 'Termino',
            placeholder: 'Termino',
          },
        },
      },
      {
        name: 'description',
        type: 'textarea',
        info: 'Descripción de la Encuesta.',
        flex: {
          cols: 12,
        },
        props: {
          label: 'Descripción',
          placeholder: 'Descripción',
          rows: 3,
        },
      },
      {
        name: 'sections',
        type: 'table-field',
        info: 'Establece si es requerida la encuesta para poder seguir usando la aplicación.',
        flex: {
          cols: 12,
        },
        prop: {
          label: 'Secciones',
          tableProps: {
            dense: true,
            headers: SrvSurveySectionHeaders.toList(),
            style: 'border: 1px solid #ccc; overflow: hidden;',
          },
          titleProps: {
            class: 'subtitle-2 mr-2 text-uppercase',
          },
          toolbarProps: {
            color: 'primary',
            dark: true,
            dense: true,
            flat: true,
            small: true,
          },
          //headers: SrvSurveyHeaders.toSections(),
        },
        slots: {
          actions: 'sections.actions',
          items: 'sections.items',
        },
      },
      {
        name: 'is_required',
        type: 'switch',
        info: 'Establece si es requerida la encuesta para poder seguir usando la aplicación.',
        props: {
          label: 'Requerida ',
        },
      },

      /**
       * TARGET
       */
      {
        name: 'targets',
        type: 'table-field',
        info: 'Establece si es requerida la encuesta para poder seguir usando la aplicación.',
        flex: {
          cols: 12,
        },
        prop: {
          label: 'Públicos objetivos',
          tableProps: {
            dense: true,
            headers: SrvSurveyTargetHeaders.toList(),
            style: 'border: 1px solid #ccc; overflow: hidden;',
          },
          titleProps: {
            class: 'subtitle-2 mr-2 text-uppercase',
          },
          toolbarProps: {
            color: 'primary',
            dark: true,
            dense: true,
            flat: true,
            small: true,
          },
          //headers: SrvSurveyHeaders.toSections(),
        },
        slots: {
          actions: 'targets.actions',
          items: 'targets.items',
        },
      },
    ]
  }

  /**
   * Returns the SrvSurvey create fields.
   * @returns {Collection} fields collection.
   */
  static toCreate() {
    return this.reduce('name', [
      'title',
      'description',
      // 'init_datetime',
      // 'limit_datetime',
      'sections',
      // 'is_required',
    ])
  }

  /**
   * Returns the SrvSurvey target fields.
   * @returns {Collection} fields collection.
   */
  static toTarget() {
    return this.init().whereIn('name', [
      'title',
      // 'init_datetime',
      // 'limit_datetime',
      'targets',
    ])
    .each(item => {
      switch (item.name) {
        case 'title': item.props.readonly = true; break;
        case 'init_datetime':
        case 'limit_datetime': item.props.submitProps = { disabled: true };item.props.datePickerProps = { disabled: true };item.props.timePickerProps = { disabled: true }; break;
      }
      return item
    })
    .all()
  }

  /**
   * Returns the SrvSurvey update fields.
   * @returns {Collection} fields collection.
   */
  static toUpdate() {
    return this.reduce('name', [
      'title',
      'description',
      'sections',
      // 'is_required',
    ])
  }
}
