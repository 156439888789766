// inputs
import RSearchField from 'vuetify2-wrappers/src/components/fields/search'
// components
import ActActivityFilters from '@/views/Activity/ActActivity/filters'
import ActActivityHeaders from '@/views/Activity/ActActivity/headers'
import ActActivityService from '@/services/api/Activity/ActActivity'
// component
export default {
  name: 'act-activity-search',
  components: {
    RSearchField,
  },
  computed: {

    /**
     * 
     */
    cardProps() {
      return {
        avatarProps: {
          color: 'primary',
          size: 100,
          tile: true,
        },
        itemProps: {
          class: 'py-4',
        },
        itemTitleProps: {
          class: 'title'
        },
        items: this.items,
        titleProps: {
          class: 'primary white--text subtitle-2',
        }
      }
    },

    /**
     * 
     */
    formProps() {
      return {
        items: ActActivityFilters.toSearch()
      }
    },

    /**
     * 
     */
    isStudent() {
      return this.$store.getters.userRolName === 'student'
    },

    /**
     * 
     */
    tableProps() {
      return {
        filters: ActActivityFilters.collection('search'),
        // headers: ActActivityHeaders.toSearch(),
        service: ActActivityService//.toList(),
      }
    }
  },
  data() {
    return {
      dialogs: {
        detail: false,
      },
      deals: [],
      filtering: false,
      filter: {
        // activity_status_id: [3, 4],
      },
      items: [],
      length: 1,
      loading: false,
      loadingCard: false,
      page: 1,
      search: '',
      selected: null,
    }
  },
  methods: {

    /**
     * 
     */
    onReset() {
      this.filter = {}
      this.refresh()
    },

    /**
     * Refresh the ActActivity list
     */
    refresh() {

      //
      this.loading = true

      //
      ActActivityService.model().include('body,status,type,support_facilitators.facilitator,enrollment').where('title', this.search)
      .params({ filter: this.filter })
      .page(this.page).limit(3).get().then(res => {
        this.items = res.data
        this.length = res.last_page
        this.loading = false
        this.dialogs.detail = false
      })
    },

    /**
     * Refresh the deals
     */
    refreshDeals() {
      ActActivityService.model().include('body,status,type,support_facilitators.facilitator,enrollment').limit(5).get().then(res => {
        this.deals = res.data
      })
    },

    /**
     * Show a delete ActActivity confirm toast
     */
    submitDelete() {
      this.$toaster('¿Confirma la eliminación del ActActivity seleccionado?', 'confirmar', this.toDelete)
    },

    /**
     * 
     */
    submitEnroll() {
      this.toWaitCard(true).then(this.toEnroll).then(this.toWaitCard)
    },

    /**
     * Set the selected ActActivity table
     * @param  {Object}  event - the click item payload
     */
    submitItem(event) {
      this.selected = event.item
    },

    /**
     * 
     */
    submitLeave() {
      this.toWaitCard(true).then(this.toLeave).then(this.toWaitCard)
    },

    /**
     * Return a delete ActActivity promise.
     * @param  {ActActivityModel}  model - the ActActivity model
     * @return {Promise} A delete process.
     */
    toDelete() {
      return ActActivityService.delete(this.selected).then(this.toSuccess).catch(this.toError)
    },

    /**
     * 
     */
    toEnroll() {
      return ActActivityService.enroll(this.selected).then(this.toSuccess).catch(this.toError)
    },

    /**
     * The delete ActActivity error process.
     * @param  {Object}  event - the error delete payload
     */
    toError(event) {
      this.getErrorMessage(event).then(this.getErrorToast)      
    },

    /**
     * 
     */
    toLeave() {
      return ActActivityService.leave(this.selected).then(this.toSuccess).catch(this.toError)
    },

    /**
     * The delete ActActivity success process.
     * @param  {Object}  event - the success delete payload
     */
    toSuccess(event) {
      this.getSuccessMessage(event).then(this.getSuccessToast).then(this.refresh)    
    },

    /**
     * 
     */
    async toWaitCard(value = false) {
      this.loadingCard = value
    },

    /**
     * 
     */
    verify() {

      // required data
      const actActivityId = this.$route.query.id;
      const hasActActivityId = !!actActivityId
      const hasSelected = !!this.selected
      const equalActActivityId = hasSelected && this.selected.id == actActivityId

      //
      // console.log({ actActivityId })

      // show modal if has activity id
      if (hasActActivityId && !equalActActivityId) {
        ActActivityService.model().include('body,status,type,support_facilitators.facilitator').find(actActivityId).then(res => {
          this.selected = res.reset()
          if(this.selected.id) {
            this.dialogs.detail = true
          } else {
            this.getErrorToast('Actividad no encontrada.')
          }
        })
      }
    }
  },
  watch: {
    $route() {
      // console.log('$route')
    },
    '$route.query'() {
      // console.log('route')
      this.verify()
    }
  },
  beforeRouteUpdate(to, from, next) {
    //console.log(to, from)
    // console.log('route updated')
    next()
  },
  mounted() {
    // console.log('mounted')
    this.refresh()
    this.refreshDeals()
    this.verify()
  },
  updated() {
    // console.log('updated')
  }
}