// required modules
import { Headers } from 'collection-wrapper'

/**
 * This class describes a UsrStudent headers collection.
 * @class UsrStudentHeaders
 */
export default class UsrStudentHeaders extends Headers {

  /**
   * Returns the UsrStudent headers options.
   * @returns {Array} headers options.
   */
  headers() {
    return [
      {
        text: 'RUT',
        value: 'rut',
      },
      {
        text: 'Nombre(s)',
        value: 'first_names',
        sortable: 'name',
      },
      {
        text: 'Primer apellido',
        value: 'last_name1'
      },
      {
        text: 'Segundo apellido',
        value: 'last_name2',
      },
      {
        text: 'Teléfono',
        value: 'phone_number',
      },
      {
        text: 'Creado',
        value: 'created_at_short',
        align: 'right',
        sortable: 'created_at',
      },
      {
        text: 'Modificado',
        value: 'updated_at_short',
        align: 'right',
        sortable: 'created_at',
      },
      {//hacer map para value carreras, temporal primera carrera del arreglo
        text: 'Carrera(s)',
        value: 'careers_name',
      },
    ]
  }

  /**
   * Returns the UsrStudent list headers.
   * @returns {Collection} headers collection.
   */
  static toList() {
    return this.reduce('value', [
      'rut',
      'first_names',
      'last_name1',
      'last_name2',
      'careers_name',
    ])
  }

  /**
   * Returns the UsrStudent list headers.
   * @returns {Collection} headers collection.
   */
  static toPickerList() {
    return this.reduce('value', [
      'rut',
      'first_names',
      'last_name1',
      'last_name2',
      'careers_name',
    ])
  }

  /**
   * Returns the UsrStudent detail list headers.
   * 
   * @returns {Collection} headers collection.
   */
  static toDetailList() {
    return this.reduce('value', [
      'rut',
      'first_names',
      'last_name1',
      'phone',
    ])
  }

  /**
   * Returns the UsrStudent detail list headers.
   * 
   * @returns {Collection} headers collection.
   */
  static toDetailListWithCareer() {
    return this.reduce('value', [
      'rut',
      'first_names',
      'last_name1',
      'careers_name',
    ])
  }
}
