// required modules
import store from '@/store'
import guards from '@/router/guards'

/**
 * { function_description }
 * @param      {<type>}    to      { parameter_description }
 * @param      {<type>}    from    The from
 * @param      {Function}  next    The next
 */
export default function(to, from, next) {

  // 
  // var route = ''
  // guards.auth({ to, from, next })
  // guards.bypass({ to, from, next })

  //
  // const isLogged  = store.getters.isLogged || process.env.VUE_APP_AUTH_BYPASS === "true"
  // const inAuth    = to.path.includes('/auth') || to.path.includes('/test') || to.path.includes('/encuesta/responder')
  // const authRoute = '/auth'
  // const nextRoute = !isLogged && !inAuth ? authRoute : undefined

  //
  // console.log({ isLogged })
  // console.log({ inAuth })
  // console.log({ nextRoute })
  
  //
  guards.auth({ to, from, next })
  guards.survey({ to, from, next })

  //
  // next(route)
}