// service
import Service from '@/services/api/Service'
import UsrProgramModel from '@/models/User/UsrProgram'

/**
 * This class describes a UsrProgram service.
 * @class UsrProgram (name)
 */
export default class UsrProgramService extends Service {

  /**
   * Request to add users in a program
   * @param {Array} usrStudents - the addStudent usrProgram payload
   * @returns {Promise} the request promise
   */
  static addStudent(usrProgram, usrStudents) {

    // required data
    const usrProgramAddStudent = usrProgram.addStudent()
    const usrStudentsIds = usrStudents.map(({ id }) => id)

    // request promise
    return usrProgramAddStudent.attach({
      users: usrStudentsIds,
    })
  }

  /**
   * Define the UsrProgram model.
   * @return {Function} UsrProgram resource.
   */
  static model() {
    return UsrProgramModel
  }

  /**
   * Request to remove users in a program
   * 
   * @param {UsrProgramModel} usrProgram - the usrProgram where remove the students
   * @param {Array} usrStudents - the usrStudents to remove from usrProgram
   * @returns {Promise} the request promise
   */
  static removeStudent(usrProgram, usrStudents) {

    // required data
    const usrProgramRemoveStudent = usrProgram.removeStudent()
    const usrStudentsIds = usrStudents.map(({ id }) => id)

    // request promise
    return usrProgramRemoveStudent.attach({
      users: usrStudentsIds,
    })
  }

  /**
   * 
   */
  static toSelectList() {
    return () => this.model().params({ get_all_results: 1 }).get()
  }

  /**
   * 
   */
  static toUpdate() {
    return this.model().include('users.careers')
  }
}