// required modules
import TtsTutorshipFields from '@/views/Tutorship/TtsTutorship/fields'
import TtsTutorshipFilters from '@/views/Tutorship/TtsTutorship/filters'
import TtsTutorshipHeaders from '@/views/Tutorship/TtsTutorship/headers'
import TtsTutorshipService from '@/services/api/Tutorship/TtsTutorship'

// component
export default {
  name: 'tts-tutorship-list',
  computed: {

    /**
     *
     */
    tableProps() {
      return {
        filters: TtsTutorshipFilters.toList(),
        headers: TtsTutorshipHeaders.toList(),
        service: TtsTutorshipService.toList(),
      }
    },

    /**
     * 
     */
    uploadProps() {
      return {
        items: TtsTutorshipFields.toUpload(),
      }
    },
  },
  data() {
    return {
      dialogs: {
        upload: false,
      },
      ttsTutorship: TtsTutorshipService.shape(),
    }
  },
  methods: {

    /**
     * 
     */
    onCreate() {
      this.goRoute('tts-tutorship-create')
    },

    /**
     * 
     */
    onDashboard() {
      this.goRoute('tts-tutorship-dashboard')
    },

    /**
     * 
     */
    onDelete(event) {
      this.$toaster('¿Confirma la eliminación de la tutoría seleccionada?', 'confirmar', () => {
        return this.toDelete(event.item)
      })
    },

    /**
     * 
     */
     onDownload() {
       window.open('/docs/tutorias_planilla.xlsx')
      // TtsTutorshipService.download()
     },

    /**
     * 
     */
    onReset() {
      this.ttsTutorship = TtsTutorshipService.shape()
    },

    /**
     * 
     */
    onUpdate(event) {
      this.goRoute('tts-tutorship-update', { id: event.item.id })
    },

    /**
     * 
     * @param {*} event 
     */
    onUpload() {
      this.$whereas(true, this.toWaitUpload, this.toUpload)
    },

    /**
     * The delete TtsTutorship success process.
     *
     * @param  {Object}  event - the success delete payload.
     */
    onSuccessDelete(event) {
      this.getSuccessMessage(event).then(this.getSuccessToast).then(this.toRefresh)    
    },

    /**
     * 
     * @param {*} ttsTutorship 
     */
    onSuccessUpload(res) {
      this.onSuccess(res)
      this.toRefresh()
      this.dialogs.upload = false
    },

    /**
     * Return a delete TtsTutorship promise.
     *
     * @param  {TtsTutorshipModel}  model - the TtsTutorship model.
     * @return {Promise} A delete process.
     */
    toDelete(ttsTutorship) {
      return TtsTutorshipService.delete(ttsTutorship).then(this.onSuccessDelete).catch(this.onError)
    },

    /**
     * Refresh the TtsTutorship list.
     */
    toRefresh() {
      this.getRef('table').refresh()
    },

    /**
     * 
     */
    toUpload() {
      return TtsTutorshipService.upload(this.ttsTutorship).then(this.onSuccessUpload).catch(this.onError)
    },

    /**
     * 
     */
    toWaitUpload(value = false) {
      return this.getRef('upload').setWait(value)
    },
  },
}