// required modules
import TtsTutorFields from '@/views/Tutorship/TtsTutor/fields'
import TtsTutorFilters from '@/views/Tutorship/TtsTutor/filters'
import TtsTutorHeaders from '@/views/Tutorship/TtsTutor/headers'
import TtsTutorService from '@/services/api/Tutorship/TtsTutor'

// component
export default {
  name: 'tts-tutor-list',
  computed: {

    /**
     *
     */
    tableProps() {
      return {
        filters: TtsTutorFilters.toList(),
        headers: TtsTutorHeaders.toList(),
        service: TtsTutorService.toList(),
      }
    },

    /**
     * 
     */
    uploadProps() {
      return {
        items: TtsTutorFields.toUpload(),
      }
    },
  },
  data() {
    return {
      dialogs: {
        upload: false,
      },
      ttsTutor: TtsTutorService.shape(),
    }
  },
  methods: {

    /**
     * 
     */
    onCreate() {
      this.goRoute('tts-tutor-create')
    },

    /**
     * 
     */
    onDelete(event) {
      this.$toaster('¿Confirma la eliminación del tutor seleccionado?', 'confirmar', () => {
        return this.toDelete(event.item)
      })
    },

    /**
     * 
     */
     onDownload() {
      window.open('/docs/tutores_planilla.xlsx')
     },

    /**
     * 
     */
    onReset() {
      this.ttsTutor = TtsTutorService.shape()
    },

    /**
     * 
     */
    onUpdate(event) {
      this.goRoute('tts-tutor-update', { id: event.item.id })
    },

    /**
     * 
     * @param {*} event 
     */
    onUpload() {
      this.$whereas(true, this.toWaitUpload, this.toUpload)
    },

    /**
     * The delete TtsTutor success process.
     *
     * @param  {Object}  event - the success delete payload.
     */
    onSuccessDelete(event) {
      this.getSuccessMessage(event).then(this.getSuccessToast).then(this.toRefresh)    
    },

    /**
     * 
     * @param {*} ttsTutorship 
     */
    onSuccessUpload(res) {
      this.onSuccess(res)
      this.toRefresh()
      this.dialogs.upload = false
    },

    /**
     * 
     */
    onUploadError(error) {
      this.onError('Error al subir listado de tutores.')
    },

    /**
     * Return a delete TtsTutor promise.
     *
     * @param  {TtsTutorModel}  model - the TtsTutor model.
     * @return {Promise} A delete process.
     */
    toDelete(ttsTutor) {
      return TtsTutorService.delete(ttsTutor).then(this.onSuccessDelete).catch(this.onError)
    },

    /**
     * Refresh the TtsTutor list.
     */
    toRefresh() {
      this.getRef('table').refresh()
    },

    /**
     * 
     */
    toUpload() {
      return TtsTutorService.upload(this.ttsTutor).then(this.onSuccessUpload).catch(this.onUploadError)
    },

    /**
     * 
     */
    toWaitUpload(value = false) {
      return this.getRef('upload').setWait(value)
    },
  },
}