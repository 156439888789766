// required modules
import Model from '@/models/Model'

/**
 * This class describes a AAssignTarget model.
 *
 * @class AAssignTarget (name)
 */
export default class AAssignTargetModel extends Model {

  /**
   * Define the AAssignTarget resource.
   *
   * @return {String} AAssignTarget endpoint name.
   */
  resource() {
    return 'assign_target'
  }
}