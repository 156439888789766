// required modules
import { Fields } from 'collection-wrapper'
import { ruleAlphaNumber, ruleMax, ruleMin, ruleRequired } from 'vuetify2-wrappers/src/rules'

/**
 * This class describes a AthLogin fields collection.
 * @class AthLoginFields (name)
 */
export default class AthLoginFields extends Fields {

  /**
   * Returns the AthLogin fields options.
   *
   * @returns {Array} fields options.
   */
  fields() {
    return [
      {
        name: 'username',
        type: 'text-field',
        flex: {
          cols: 12
        },
        props: {
          label: 'Nombre de usuario',
          persistentHint: true,
          placeholder: 'juan123@ucn.cl',
          max: 10,
          rules: [ruleMax(120)],
        },
      },
      {
        name: 'password',
        type: 'password-field',
        flex: {
          cols: 12
        },
        props: {
          fieldProps: {
            label: 'Contraseña',
            persistentHint: true,
            placeholder: '******',
            rules: [ruleMin(6)],
          },
        },
      },
      {
        name: 'remember_me',
        type: 'checkbox',
        flex: {
          cols: 12
        },
        props: {
          class: 'pa-0 ma-0',
          dense: true,
          label: 'Recordarme',
        },
      },
    ]
  }

  /**
   * Returns the AthLogin create fields.
   *
   * @returns {Collection} fields collection.
   */
  static toCreate() {
    return this.reduce('name', [
      'username',
      'password',
      'remember_me',
    ])
  }
}
