// model
import Model from '@/models/Model'

/**
 * This class describes a ATakeAssistance model.
 * @class ATakeAssistance (name)
 */
export default class ATakeAssistanceModel extends Model {

  /**
   * Define the ATakeAssistance resource.
   * @return {String} ATakeAssistance endpoint name.
   */
  resource() {
    return 'take_assistance'
  }
}