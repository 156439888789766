// required modules
import { Fields } from 'collection-wrapper'
import { ruleMax, ruleRequired, ruleRut } from 'vuetify2-wrappers/src/rules'

/**
 * This class describes a NtNotification fields collection.
 * @class NtNotificationFields (name)
 */
export default class NtNotificationFields extends Fields {

  /**
   * Returns the NtNotification fields options.
   *
   * @returns {Array} fields options.
   */
  fields() {
    return [
      {
        name: 'rut',
        type: 'rut-field',
        flex: {
          cols: 12
        },
        props: {
          fieldProps: {          
            label: 'Rut del estudiante',
            persistentHint: true,
            placeholder: '1-9',
            rules: [ruleRut, ruleRequired],
          }
        },
      },
      {
        name: 'identifier',
        type: 'text-field',
        flex: {
          cols: 12
        },
        props: {
          label: 'Nombre de usuario o correo electrónico',
          persistentHint: true,
          placeholder: 'Juan123 o juan123@ucn.cl',
          rules: [ruleMax(120)],
        },
      },
    ]
  }

  /**
   * Returns the NtNotification account fields.
   *
   * @returns {Collection} fields collection.
   */
  static toAccount() {
    return this.reduce('name', [
      'rut',
    ])
  }

  /**
   * Returns the NtNotification password fields.
   *
   * @returns {Collection} fields collection.
   */
  static toPassword() {
    return this.reduce('name', [
      'identifier',
    ])
  }
}