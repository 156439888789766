// model
import Model from '@/models/Model'

/**
 * This class describes a ActActivityStatus model.
 * @class ActActivityStatus (name)
 */
export default class ActActivityStatusModel extends Model {

  /**
   * Define the ActActivityStatus resource.
   * @return {String} ActActivityStatus endpoint name.
   */
  resource() {
    return 'activity_status'
  }
}