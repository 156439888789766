// layout
import RLayoutModule from '@/components/layouts/LayoutModule'

// views
import SrvSurveyTemplateCreateView from '@/views/Survey/SrvSurveyTemplate/create'
import SrvSurveyTemplateListView from '@/views/Survey/SrvSurveyTemplate/list'
import SrvSurveyTemplatePreviewView from '@/views/Survey/SrvSurveyTemplate/preview'
import SrvSurveyTemplateUpdateView from '@/views/Survey/SrvSurveyTemplate/update'

// routes
export default {
  path: '/encuestas-predeterminadas',
  component: RLayoutModule,
  children: [
    {
      name: 'srv-survey-template-list',
      path: '',
      component: SrvSurveyTemplateListView,
    },
    {
      name: 'srv-survey-template-create',
      path: 'crear',
      component: SrvSurveyTemplateCreateView,
      meta: {
        title: 'Crear encuesta'
      },
    },
    {
      name: 'srv-survey-template-update',
      path: ':id',
      component: SrvSurveyTemplateUpdateView,
      meta: {
        title: 'Editar encuesta'
      },
    },
    {
      name: 'srv-survey-template-preview',
      path: ':id/vista_previa',
      component: SrvSurveyTemplatePreviewView,
      meta: {
        title: 'Vista previa de encuesta'
      },
    },
  ],
  meta: {
    title: 'Encuestas predeterminadas'
  },
  props: {
    indexText: 'Inicio',
  },
}
