// required modules
import { Headers } from 'collection-wrapper'

/**
 * This class describes a SrvSurveyTarget headers collection.
 * @class 
 */
export default class SrvSurveyTargetHeaders extends Headers {

  /**
   * Returns the SrvSurveyTarget headers options.
   * @returns {Array} headers options.
   */
  headers() {
    return [
      {
        text: 'Identificador',
        value: 'identifier',
      },
      {
        text: 'Nombre',
        value: 'name',
      },
      {
        text: 'Tipo',
        value: 'target_type',
      },
      {
        text: 'N° de identificadores',
        value: 'identifiers_length',
      },
    ]
  }

  /**
   * Returns the SrvSurveyTarget identifiers headers.
   * @returns {Collection} headers collection.
   */
  static toIdentifiers() {
    return this.reduce('value', [
      'identifier',
      'name',
    ])
  }

  /**
   * Returns the SrvSurveyTarget list headers.
   * @returns {Collection} headers collection.
   */
  static toList() {
    return this.reduce('value', [
      'name',
      'target_type',
      'identifiers_length',
    ])
  }
}
