// required modules
import NtMailContactFields from '@/views/Notification/NtMailContact/fields'
import NtMailContactService from '@/services/api/Notification/NtMailContact'

// component
export default {
  name: 'nt-mail-contact-update',
  computed: {

    /**
      * Gets the NtMailContact update form props.
      * @return {Object} The update form props.
      */
    formProps() {
      return {
        items: NtMailContactFields.toUpdate(),
      }
    }
  },
  data() {
    return {
      ntMailContact: {
        value: 'test',
      },
    }
  },
  methods: {

    /**
      * Returns a reset NtMailContact form.
      * @return {Promise} A form reset promise.
      */
    reset() {
      return this.toWait(true).then(this.toRefresh).then(this.toWait)
    },

    /**
      * Returns a submit NtMailContact form.
      * @return {Promise} A form submit promise.
      */
    submit() {
      return this.toWait(true).then(this.toUpdate).then(this.toWait)
    },

    /**
      * Returns a refresh NtMailContact form.
      * @return {Promise} A reset promise.
      */
    toRefresh() {
      return NtMailContactService.model().find(this.getParam('id')).then(ntMailContact => {
        this.ntMailContact = ntMailContact
      })
    },

    /**
      * Returns an update NtMailContact promise.
      * @return {Promise} A update promise.
      */
    toUpdate() {
      return NtMailContactService.update(this.ntMailContact).then(this.toSuccess).catch(this.toError)
    },

    /**
      * Returns an update NtMailContact error process.
      * @param  {Object}  event - the event error payload.
      */
    toError(event) {
      this.getErrorMessage(event).then(this.getErrorToast)
    },

    /**
      * Returns an update NtMailContact success process.
      * @param  {Object}  event - the event success payload.
      */
    toSuccess(event) {
      this.getSuccessMessage(event).then(this.getSuccessToast).then(this.refresh)
    },
  },
  mounted() {
    this.reset()
  },
}