// required modules
import StaAttentionFields from '@/views/StudentAttention/StaAttention/fields'
import StaAttentionService from '@/services/api/StudentAttention/StaAttention'
import StaAttentionStudentHeaders from '@/views/StudentAttention/StaAttentionStudent/headers'
import UsrStudentHeaders from '@/views/User/UsrStudent/headers'
import UsrStudentService from '@/services/api/User/UsrStudent'

// component
export default {
  name: 'sta-attention-update',
  computed: {

    /**
     * 
     */
    cancelProps() {
      return {
        items: StaAttentionFields.toCancel(),
      }
    },

    /**
     * 
     */
    sendProps() {
      return {
        items: StaAttentionFields.toSend(this.staAttention),
      }
    },

    /**
      * Gets the StaAttention update form props.
      * @return {Object} The update form props.
      */
    formProps() {
      return {
        items: StaAttentionFields.toUpdate(),
      }
    },

    /**
     * 
     */
    tableProps() {
      return {
        headers: StaAttentionStudentHeaders.toList(),
        service: StaAttentionService.from(this.staAttention.attention_students_readonly)
      }
    },

    /**
     * 
     */
    tableCompleteProps() {
      return {
        headers: StaAttentionStudentHeaders.toComplete(this.staAttention),
        service: StaAttentionService.from(this.staAttention.attention_students)
      }
    },

    /**
     * 
     */
    tableDialogProps() {
      return {
        headers: UsrStudentHeaders.toPickerList(),
        service: UsrStudentService.toListSortByFirstNames(),
      }
    },
  },
  data() {
    return {
      dialogs: {
        cancel: false,
        complete: false,
        send: false,
        students: false,
      },
      staAttention: {
        attention_students_readonly: [],
      },
    }
  },
  methods: {

    /**
     * 
     */
    onClickCompleteAccept() {
      this.toComplete()
    },

    /**
     * 
     */
    onClickConfirm() {
      this.$toaster('¿Confirma la confirmación de la atención?', 'confirmar', this.toConfirm)
      //this.toWait(true).then(this.toConfirm).then(this.toWait)
    },

    /**
     * 
     */
    onClickDialogAccept() {

      //
      const oldStudents = this.staAttention.attention_students
      const newStudents = this.getRef('tableDialog').getSelected()
      const addStudents = newStudents.filter(student => oldStudents.every(item => student.id != item.student.id)).map(item => ({ student: item }))

      //
      this.staAttention.attention_students = [
        ...oldStudents,
        ...addStudents,
      ]

      //
      this.staAttention.sortStudents()

      //
      this.dialogs.students = false
    },

    /**
     * 
     */
    onClickTableDelete(event) {
      
      //
      const students  = this.staAttention.attention_students
      const itemIndex = students.findIndex(item => item.id === event.item.id)

      //
      this.staAttention.attention_students = [
        ...students.slice(0, itemIndex),
        ...students.slice(itemIndex + 1),
      ]  
    },

    /**
     * Returns an update StaAttention error process.
     * 
     * @param  {Object}  event - the event error payload.
     */
    onError(event) {
      this.getErrorMessage(event).then(this.getErrorToast)
    },

    /**
     * 
     */
    onSubmitCancel() {
      this.toWaitCancel(true).then(this.toCancel).then(this.WaitCancel)
    },

    /**
     * 
     */
    onSubmitSend() {
      this.toWaitSend(true).then(this.toSendNotification).then(this.toWaitSend)
    },

    /**
     * Returns an update StaAttention success process.
     * 
     * @param  {Object}  event - the event success payload.
     */
    onSuccess(event) {
      this.getSuccessMessage(event).then(this.getSuccessToast)
      this.dialogs.complete = false
      this.$router.push('/atenciones')
    },

    /**
      * Returns a reset StaAttention form.
      * 
      * @return {Promise} A form reset promise.
      */
    reset() {
      return this.toWait(true).then(this.toRefresh).then(this.toWait)
    },

    /**
      * Returns a submit StaAttention form.
      * @return {Promise} A form submit promise.
      */
    submit() {
      return this.toWait(true).then(this.toUpdate).then(this.toWait)
    },

    /**
     * 
     */
    toComplete() {
      return StaAttentionService.complete(this.staAttention).then(this.onSuccess).catch(this.onError)
    },

    /**
     * 
     */
    toCancel() {
      return StaAttentionService.cancel(this.staAttention).then(this.onSuccess).catch(this.onError)
    },

    /**
     * 
     */
    toConfirm() {
      return StaAttentionService.confirm(this.staAttention).then(this.onSuccess).catch(this.onError)
    },

    /**
     * Returns a refresh StaAttention form.
     * @return {Promise} A reset promise.
     */
    toRefresh() {
      StaAttentionService.model().include('attention_students.student,attention_students.status').find(this.getParam('id')).then(staAttention => {
        this.staAttention = staAttention
        this.staAttention.attention_id = staAttention.id
        this.staAttention.attention_students = this.staAttention.attention_students.map(item => {
          //
          item.student_name = `${ item.student.first_names } ${ item.student.last_name1 }`
          item.post_attention_observation = item.post_attention_observation || ''
          // item.attention_student_status_id = 2
          //
          return item
        })
        this.staAttention.sortStudents()
      })
    },

    /**
     * 
     */
    toSendNotification() {
      return StaAttentionService.sendNotification(this.staAttention).then(this.onSuccess).catch(this.onError)
    },

    /**
     * Returns an update StaAttention promise.
     * 
     * @param  {Object}  model - the sta-attention model
     * @return {Promise} A update promise.
     */
    toUpdate(staAttention) {
      var isCareerChange  = this.staAttention.attention_type_id == 2
      var isManyStudent   = this.staAttention.attention_students.length > 1
      if (isCareerChange && isManyStudent) {
        this.onError('Por cambio de política en la universidad, por favor solo 1 estudiante para el horario de "Cambio de carrera".')
        return 
      }
      return StaAttentionService.schedule(this.staAttention).then(this.onSuccess).catch(this.onError)
    },

    /**
     * 
     */
    toWaitCancel(val = false) {
      return this.getRef('cancel').setWait(val)
    },

    /**
     * 
     */
    toWaitSend(val = false) {
      return this.getRef('send').setWait(val)
    },
  },
  mounted() {
    this.reset()
  },
}