// required modules
import { Headers } from 'collection-wrapper'

/**
 * This class describes a TtsTutorshipSchedule headers collection.
 * @class
 */
export default class TtsTutorshipScheduleHeaders extends Headers {

  /**
   * Returns the TtsTutorshipSchedule headers options.
   * @returns {Array} headers options.
   */
  headers() {
    return [
      {
        text: '#',
        value: 'id',
        sortable: 'id',
      },
      {
        text: 'Tutoría',
        value: 'tutorship_description',
        sortable: 'tutorship_id',
      },
      {
        text: 'Paralelo',
        value: 'parallel_description',
        sortable: 'tutorship_id',
      },
      {
        text: 'Tutor',
        value: 'tutor_name_case',
        sortable: 'tutor_id',
      },
      {
        text: 'Dia',
        value: 'weekday_name',
        sortable: 'weekday',
        // align: 'right',
      },
      {
        text: 'Bloque',
        value: 'block_description',
        sortable: 'university_time_block_id',
        // align: 'right',
      },
      {
        text: 'Sala',
        value: 'classroom_description',
        sortable: 'university_classroom_id',
        align: 'right',
      },
      {
        text: 'Periodo',
        value: 'period_identifier',
        sortable: 'university_period_id',
        align: 'right',
      },
      {
        text: 'Campus',
        value: 'campus_description',
        sortable: 'university_campus_id',
      },
    ]
  }

  /**
   * Returns the TtsTutorshipSchedule list headers.
   * @returns {Collection} headers collection.
   */
  static toList() {
    return this.reduce('value', [
      'id',
      'campus_description',
      'period_identifier',
      'tutorship_description',
      'parallel_description',
      'block_description',
      'classroom_description',
      'tutor_name_case',
      'weekday_name',
    ])
  }
}
