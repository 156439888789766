// required modules
import Model from '@/models/Model'

/**
 * This class describes a AUsageQuantity model.
 *
 * @class AUsageQuantity (name)
 */
export default class AUsageQuantityModel extends Model {

  /**
   * Define the AUsageQuantity resource.
   *
   * @return {String} AUsageQuantity endpoint name.
   */
  resource() {
    return 'usage_quantity'
  }
}