// required modules
import { Headers } from 'collection-wrapper'

/**
 * This class describes a TtsTutorshipParallel headers collection.
 *
 * @class TtsTutorshipParallelHeaders (name)
 */
export default class TtsTutorshipParallelHeaders extends Headers {

  /**
   * Returns the TtsTutorshipParallel headers options.
   *
   * @returns {Array} headers options.
   */
  headers() {
    return [
      {
        text: '#',
        value: 'id',
      },
      {
        text: 'Descripción',
        value: 'description',
      },
      {
        text: 'Tutoría',
        value: 'tutorship_description',
      },
      {
        text: 'Sede',
        value: 'tutorship_campus',
      },
      {
        text: 'Creado el',
        align: 'right',
        format: 'date',
        value: 'created_at_short',
      },
    ]
  }

  /**
   * Returns the TtsTutorshipParallel list headers.
   *
   * @returns {Collection} headers collection.
   */
  static toList() {
    return this.reduce('value', [
      'id',
      'description',
      'tutorship_description',
      'tutorship_campus',
      'created_at_short',
    ])
  }
}
