// required modules
import Service from '@/services/api/Service'
import NtMailContactModel from '@/models/Notification/NtMailContact'

/**
 * This class describes a NtMailContact service.
 * 
 * @class NtMailContact (name)
 */
export default class NtMailContactService extends Service {

  /**
   * Define the NtMailContact model.
   * 
   * @return {Function} NtMailContact resource.
   */
  static model() {
    return NtMailContactModel
  }
}