// required modules
import { Fields } from 'collection-wrapper'
import UsrUniversityCampusService from '@/services/api/User/UsrUniversityCampus'

/**
 * This class describes a UsrFacilitator filters collection.
 *
 * @class UsrFacilitatorFilters (name)
 */
export default class UsrFacilitatorFilters extends Fields {

  /**
   * Returns the UsrFacilitator filters options.
   *
   * @returns {Array} filters options.
   */
  fields() {
    return [
      {
        name: 'university_campus_id',
        type: 'autocomplete-model',
        props: {
          clearable: true,
          fetch: UsrUniversityCampusService.toList(),
          limit: 15,
          autocompleteProps: {
            clearable: true,
            itemText: 'description',
            itemValue: 'id',
            label: 'Sede',
            multiple: false,
            placeholder: 'Sede',
          },
          menuProps: {
            contentClass: 'elevation-2 v-list--dense mt-2',
          },
        },
      },
      {
        name: 'email',
        type: 'text-field',
        props: {
          label: 'Correo electrónico',
        },
      },
    ]
  }

  /**
   * Returns the UsrFacilitator list filters.
   *
   * @returns {Collection} filters collection.
   */
  static toList() {
    return this.reduce('name', [
      'university_campus_id',
      'email',
    ])
  }
}
