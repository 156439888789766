// layout
import RLayoutModule from '@/components/layouts/LayoutModule'

// views
import NtMailContactCreateView from '@/views/Notification/NtMailContact/create'
import NtMailContactListView from '@/views/Notification/NtMailContact/list'
import NtMailContactUpdateView from '@/views/Notification/NtMailContact/update'

// routes
export default {
  path: '/contactos',
  component: RLayoutModule,
  children: [
    {
      name: 'nt-mail-contact-list',
      path: '',
      component: NtMailContactListView,
    },
    {
      name: 'nt-mail-contact-create',
      path: 'crear',
      component: NtMailContactCreateView,
      meta: {
        title: 'Crear contacto'
      },
    },
    {
      name: 'nt-mail-contact-update',
      path: ':id',
      component: NtMailContactUpdateView,
      meta: {
        title: 'Editar contacto'
      },
    },
  ],
  meta: {
    title: 'Contactos'
  },
  props: {
    indexText: 'Inicio',
  },
}
