// required modules
import { Fields } from 'collection-wrapper'
import { ruleRequired } from 'vuetify2-wrappers/src/rules'

/**
 * This class describes a NtMailRrss fields collection.
 * 
 * @class NtMailRrssFields (name)
 */
export default class NtMailRrssFields extends Fields {

  /**
   * Returns the NtMailRrss fields options.
   *
   * @returns {Array} fields options.
   */
  fields() {
    return [
      {
        name: 'order',
        type: 'text-field',
        props: {
          label: 'Orden',
          placeholder: 'Orden',
        },
      },
      {
        name: 'description',
        type: 'text-field',
        props: {
          label: 'Descripción',
          placeholder: 'Descripción',
        },
      },
      {
        name: 'email',
        type: 'text-field',
        props: {
          label: 'Correo electrónico',
          placeholder: 'Correo electrónico',
        },
      },
      {
        name: 'phone_number',
        type: 'text-field',
        props: {
          label: 'Teléfono',
          placeholder: 'Teléfono',
        },
      },
    ]
  }

  /**
   * Returns the NtMailRrss create fields.
   *
   * @returns {Collection} fields collection.
   */
  static toCreate() {
    return new this().collect().whereIn('name', [
      'order',
      'description',
      'email',
      'phone_number',
    ])
  }

  /**
   * Returns the NtMailRrss update fields.
   *
   * @returns {Collection} fields collection.
   */
  static toUpdate() {
    return new this().collect().whereIn('name', [
      'order',
      'description',
      'email',
      'phone_number',
    ])
  }
}
