// components
import SrvSurveyBlockFields from '@/views/Survey/SrvSurveyBlock/fields'
import SrvSurveyBlockService from '@/services/api/Survey/SrvSurveyBlock'
import SrvSurveyFields from '@/views/Survey/SrvSurvey/fields'
import SrvSurveyService from '@/services/api/Survey/SrvSurvey'
import SrvSurveySectionFields from '@/views/Survey/SrvSurveySection/fields'
import SrvSurveySectionService from '@/services/api/Survey/SrvSurveySection'

// component
export default {
  name: 'srv-survey-update',
  computed: {

    /**
     * Gets the SrvSurveyBlock create form props.
     * @return {Object} The create form props.
     */
    createBlockFormProps() {
      return {
        items: SrvSurveyBlockFields.toCreate(this.srvSurveyBlock),
        noSubmit: !this.srvSurvey.can_edit,
      }
    },

    /**
     * Gets the SrvSurveyBlock update form props.
     * @return {Object} The update form props.
     */
    updateBlockFormProps() {
      return {
        items: SrvSurveyBlockFields.toUpdate(this.srvSurveyBlockSelected),
        noSubmit: !this.srvSurvey.can_edit,
      }
    },

    /**
     * Gets the SrvSurvey update form props.
     * @return {Object} The update form props.
     */
    formProps() {
      return {
        editable: this.srvSurvey.can_edit,
        items: SrvSurveyFields.toUpdate(this.srvSurvey),
        //noSubmit: !this.srvSurvey.can_edit,
      }
    },   

    /**
     * Gets the SrvSurveySection create form props.
     * @return {Object} The create form props.
     */
    sectionFormProps() {
      return {
        items: SrvSurveySectionFields.toCreate(),
        noSubmit: !this.srvSurvey.can_edit,
      }
    },
  },
  data() {
    return {
      dialogs: {
        createBlock: false,
        createSection: false,
        createSelectedBlock: false,
        updateSection: false,
        updateBlock: false,
      },
      srvSurvey: {},
      srvSurveyBlock: SrvSurveyBlockService.toCreate(),
      srvSurveyBlockSelected: {},
      srvSurveySection: SrvSurveySectionService.toCreate(),
      srvSurveySectionSelected: {},
    }
  },
  methods: {

    /**
     * Go to SrvSurvey answers view.
     */
    onClickAnswers() {
      this.goRoute('srv-survey-answers', { id: this.getParam('id') })
    },

    /**
     * 
     */
    onClickComplete() {
      this.$toaster('¿Confirma el termino de la encuesta?', 'Confirmar', this.toComplete)
    },

    /**
     * Go to SrvSurvey preview view.
     */
    onClickPreview() {
      this.goRoute('srv-survey-preview', { id: this.getParam('id') })
    },

    /**
     * Go to SrvSurvey target view.
     */
    onClickTarget() {
      this.goRoute('srv-survey-target', { id: this.getParam('id') })
    },

    /**
     * Returns a submit SrvSurveyBlock create form.
     * @return {Promise} A form submit process.
     */
    onCreateBlock() {
      return this.toWaitCreateBlock(true).then(this.toCreateBlock).then(this.toWaitCreateBlock)
    },

    /**
     * Returns a submit SrvSurveySection create form.
     * @return {Promise} A form submit process.
     */
    onCreateSection() {
      return this.toWaitCreateSection(true).then(this.toCreateSection).then(this.toWaitCreateSection)
    },

    /**
     * 
     */
    onCreateSelectedBlock() {
      return this.toWaitCreateSelectedBlock(true).then(this.toCreateSelectedBlock).then(this.toWaitCreateSelectedBlock)
    },

    /**
     * 
     */
    onDeleteBlock(event) {
      this.srvSurveySection.blocks.splice(this.srvSurveySection.blocks.findIndex(item => item.id === event.item.id), 1)
    },

    /**
     * 
     */
    onDeleteSection(event) {
      this.srvSurvey.sections.splice(this.srvSurvey.sections.findIndex(item => item.id === event.item.id), 1)
    },

    /**
     * 
     */
    onDeleteSelectedBlock(event) {
      this.srvSurveySectionSelected.blocks.splice(this.srvSurveySectionSelected.blocks.findIndex(item => item.id === event.item.id), 1)
    },

    /**
     * Returns an update SrvSurvey success process.
     * @param  {Object}  event - the event success payload.
     */
    onSuccessUpdate(event) {
      this.onSuccess(event)
      this.$router.push({ name: 'srv-survey-list' })
    },

    /**
     * Returns a reset SrvSurvey form.
     * @return {Promise} A form reset promise.
     */
    onReset() {
      return this.toWait(true).then(this.toRefresh).then(this.toWait)
    },

    /**
     * Reset the SrvSurvey data.
     */
    onResetBlock() {
      this.srvSurveyBlock = SrvSurveyBlockService.toCreate()
    },

    /**
     * Reset the SrvSurvey data.
     */
    onResetSection() {
      this.srvSurveySection = SrvSurveySectionService.toCreate()
    },

    /**
     * Returns a submit SrvSurvey form.
     * @return {Promise} A form submit promise.
     */
    onSubmit() {
      return this.toWait(true).then(this.toUpdate).then(this.toWait)
    },

    /**
     * 
     */
    onUpdateSection(event) {
      this.srvSurveySectionSelected = event.item
      this.dialogs.updateSection = true
    },

    /**
     * 
     */
    onUpdateSelectedBlock(event) {
      this.srvSurveyBlockSelected = event.item
      this.dialogs.updateBlock = true
    },

    /**
     * 
     */
    toComplete() {
      SrvSurveyService.complete(this.srvSurvey).then(this.onSuccess).then(this.toRefresh).catch(this.onError)
    },

    /**
     * Returns an create SrvSurvey process.
     * @return {Promise} A create process.
     */
    toCreateBlock() {
      if (this.srvSurveyBlock.survey_block_type_id == 1) {
        this.srvSurveyBlock.survey_block_identifier_id = 1
      }
      this.srvSurveySection.blocks.push(this.srvSurveyBlock.toTimeStampID())
      this.dialogs.createBlock = false
      this.onResetBlock()
    },

    /**
     * Returns an create SrvSurvey process.
     *
     * @return {Promise} A create process.
     */
    toCreateSection() {
      this.srvSurvey.sections.push(this.srvSurveySection.toTimeStampID())
      this.dialogs.createSection = false
      this.onResetSection()
    },

    toCreateSelectedBlock() {
      if (this.srvSurveyBlock.survey_block_type_id == 1) {
        this.srvSurveyBlock.survey_block_identifier_id = 1
      }
      this.srvSurveySectionSelected.blocks.push(this.srvSurveyBlock.toTimeStampID())
      this.dialogs.createSelectedBlock = false
      this.onResetBlock()
    },

    /**
     * Returns a refresh SrvSurvey form.
     * @return {Promise} A reset promise.
     */
    toRefresh() {
      return SrvSurveyService.toUpdate().find(this.getParam('id')).then(srvSurvey => {

        //
        this.srvSurvey = srvSurvey.reset()
                
        //
        this.srvSurvey.init_datetime = srvSurvey.init_date
        this.srvSurvey.limit_datetime = srvSurvey.limit_date === null ? '' : srvSurvey.limit_date

        //
        // this.srvSurvey.sections = sections1
      })
    },

    /**
     * Returns an update SrvSurvey promise.
     * @return {Promise} A update promise.
     */
    toUpdate() {
      return SrvSurveyService.update(this.srvSurvey).then(this.onSuccessUpdate).catch(this.onError)
    },

    /**
     * 
     */
    toWaitCreateBlock(val = false) {
      return this.getRef('createBlock').setWait(val)
    },

    /**
     * 
     */
    toWaitCreateSection(val = false) {
      return this.getRef('createSection').setWait(val)
    },

    /**
     * 
     */
    toWaitCreateSelectedBlock(val = false) {
      return this.getRef('createSelectedBlock').setWait(val)
    },
  },
  mounted() {
    this.onReset()
  },
}