// required modules
import Service from '@/services/api/Service'
import UsrCareerModel from '@/models/User/UsrCareer'

/**
 * This class describes a UsrCareer service.
 * @class
 */
export default class UsrCareerService extends Service {

  /**
   * Define the UsrCareer model.
   * @return {Function} UsrCareer resource.
   */
  static model() {
    return UsrCareerModel
  }

  /**
   * 
   */
  static get paramSearch() {
    return 'description'
  }

  /**
   * 
   */
  static toSelectList() {
    return () => this.model().params({ get_all_results: 1}).get().then(res => ({
      data: res,
    }))
  }

  static toSelectListbyCampus(){
    return () => this.model().params({ get_all_results: 1}).where('university_campus_id', this.store().getters.userUniversityCampusId).get().then(res => ({
      data: res,
    }))
  }
}
