// required modules
import { Fields } from 'collection-wrapper'
import UsrUniversityCampusService from '@/services/api/User/UsrUniversityCampus'

/**
 * This class describes a UsrProgram filters collection.
 *
 * @class UsrProgramFilters (name)
 */
export default class UsrProgramFilters extends Fields {

  /**
   * Returns the UsrProgram filters options.
   *
   * @returns {Array} filters options.
   */
  fields() {
    return [
      {
        name: 'university_campus_id',
        type: 'autocomplete-model',
        props: {
          clearable: true,
          fetch: UsrUniversityCampusService.toList(),
          limit: 15,
          autocompleteProps: {
            clearable: true,
            itemText: 'description',
            itemValue: 'id',
            label: 'Sede',
            multiple: false,
            placeholder: 'Sede',
          },
        },
      },
      {
        name: 'description',
        type: 'text-field',
        flex: {
          cols: 12,
          sm: 6,
          md: 9,
        },
        props: {
          label: 'Descripción',
          placeholder: 'Descripción',
        },
      },
    ]
  }

  /**
   * Returns the UsrProgram list filters.
   *
   * @returns {Collection} filters collection.
   */
  static toList() {
    return this.reduce('name', [
      'university_campus_id',
      'description',
    ])
  }
}
