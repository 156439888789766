// required modules
import Model from '@/models/Model'
import AEnableScheduleModel from '@/models/Actions/AEnableSchedule'
import ADisableScheduleModel from '@/models/Actions/ADisableSchedule'

/**
 * This class describes a StaAttentionFacilitatorSchedule model.
 * @class
 */
export default class StaAttentionFacilitatorScheduleModel extends Model {

  increment = 0

  /**
   * 
   */
  get attention_init_time_short() {
    return this.prop('attention_init_time').split(':').slice(0, 2).join(':')
  }

  get attention_init_date_temporally() {
    return `${ this.genToday() }`
  }

  /**
   * 
   */
  get attention_init_datetime_temporally() {
    return `${ this.genToday() } ${ this.prop('attention_init_time') }`
  }

  /**
   * 
   */
  get attention_end_time_short() {
    return this.prop('attention_end_time').split(':').slice(0, 2).join(':')
  }

  get attention_end_date_temporally() {
    return `${ this.genToday() }`
  }

  /**
   * 
   */
  get attention_end_datetime_temporally() {
    return `${ this.genToday() } ${ this.prop('attention_end_time') }`
  }

  /**
   * 
   */
  get attention_type_description() {
    return this.getProperty('attention_type.description', '')
  }

  /**
   * 
   */
  get bar_color() {
    switch (this.attention_type_id) {
      case 1  : return 'ap'
      case 2  : return 'cc'
      case 3  : return 'ev'
      case 4  : return 'os'
      default : 'accent'
    }
  }

  /**
   * 
   */
  get calendar_color() {
    return this.prop('enabled_schedule', false) ? this.im_owner ? 'secondary' : 'primary' : 'grey darken-4'
  }

  /**
   * 
   */
  get can_edit() {
    let userIsAdmin = this.store().getters.isAdministrator
    let userId = this.store().getters.userId
    let facilitatorId = this.prop('facilitator_user_id') 
    return userIsAdmin || userId == facilitatorId
  }

  /**
   * 
   */
  get enabled_schedule() {

    let disablement = this.prop('disablements', [])
    let datetime    = new Date(this.attention_init_datetime_temporally)

    return !disablement.some(disablement => {

      let start   = new Date(disablement.start_at)
      let finish  = new Date(disablement.finish_at)

      return start < datetime && datetime < finish
    })
  }

  /**
   * 
   */
  get im_owner() {
    return this.prop('facilitator_user_id', 0) === this.store().getters.userId
  }

  /**
   * 
   */
  get facilitator_full_name() {
    return `${ this.prop('facilitator.first_names') } ${ this.prop('facilitator.last_name1') }`
  }

  /**
   * 
   */
  enableSchedule() {

    // required data
    let clone       = this.clone()
    let disablement = this.prop('disablements', []).slice().shift()

    // console.log(clone)

    // set disable id
    if (disablement) {
      clone.id = disablement.id
    }

    // return enable model
    return clone.hasMany(AEnableScheduleModel)
  }

  /**
   * 
   */
  disableSchedule() {
    return this.hasMany(ADisableScheduleModel)
  }

  /**
   * 
   */
  genToday() {

    // new today format options
    const options = {
      year: 'numeric', 
      month: 'numeric', 
      day: 'numeric',
    }

    // new created date
    const created = new Date(this.created_at)
    const past    = created.getDate()
    
    // new today date
    const stamp = Date.now() + this.increment
    const today = new Date(stamp)
    const day   = today.getDay()
    const days  = today.getDate()

    // set week day
    today.setDate(days + (this.weekday - day))

    // set week day for future events
    if (today < created) {
      today.setDate(days + (this.weekday + day - 6))
    }
    
    // new today date formatted
    return today.toLocaleDateString('es-CL', options).split('-').reverse().join('-')
  }

  /**
   * 
   */
  get disabled_schedule() {
    return !this.prop('enabled_schedule', false)
  }

  getCloneWithIncrement(increment = 0) {
    let clone = this.clone()
    clone.increment = increment
    return clone
  }

  getCloneWithIncrementByDays(days = 0) {
    let increment = days * 24 * 60 * 60 * 1000
    return this.getCloneWithIncrement(increment)
  }

  /**
   * Define the StaAttentionFacilitatorSchedule resource.
   * @return {String} StaAttentionFacilitatorSchedule endpoint name.
   */
  resource() {
    return 'attention_facilitator_schedule'
  }

  /**
   * 
   */
  toCreate() {
    return this.reduce([
      'university_period_id',
      'attention_type_id',
      'weekday',
      'attention_init_time',
      'attention_end_time',
    ])
  }

  /**
   * 
   */
  toUpdate() {
    return this.reduce([
      'university_period_id',
      'attention_type_id',
      'id',
      'weekday',
      'attention_init_time',
      'attention_end_time',
    ])
  }
}