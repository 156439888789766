// required modules
import Model from '@/models/Model'

/**
 * This class describes a TtsTutorshipSchedule model.
 * @class
 */
export default class TtsTutorshipScheduleModel extends Model {

  /**
   * 
   */
  get block_description() {
    return this.prop('block.description', '')
  }

  /**
   * 
   */
  get block_end_datetime() {
    return `${ this.weekday_parsed.split('-').reverse().join('-') } ${ this.prop('block.end_time', '00:00') }`
  }

  /**
   * 
   */
  get block_end_time() {
    return this.prop('block.end_time', '00:00')
  }

  /**
   * 
   */
  get block_init_datetime() {
    return `${ this.weekday_parsed } ${ this.block_init_time }`
  }

  /**
   * 
   */
  get block_init_datetime_reversed() {
    return `${ this.weekday_reversed } ${ this.block_init_time }`
  }
  
  /**
   * 
   */
  get block_init_time() {
    return this.prop('block.init_time', '00:00')
  }

  /**
   * 
   */
  get campus_description() {
    return this.prop('campus.description', '')
  }

  /**
   * 
   */
  get classroom_description() {
    return this.prop('classroom.description', '')
  }

  /**
   * 
   */
  get period_identifier() {
    return this.prop('period.identifier', '')
  }

  /**
   * Define the TtsTutorshipSchedule resource.
   * @return {String} TtsTutorshipSchedule endpoint name.
   */
  resource() {
    return 'tutoring_schedule'
  }

  /**
   * 
   */
  toCreate() {
    return this.reduce([
      'weekday',
      'university_time_block_id',
      'tutorship_id',
      'tutorship_parallel_id',
      'tutor_id',
      'university_classroom_id',
      'careers',
      'event_room',
    ])
  }

  /**
   * 
   */
  toUpdate() {
    let clone = this.clone()
    clone.careers = clone.careers.map(e => e.id)
    return clone.reduce([
      'id',
      'weekday',
      'university_time_block_id',
      'tutorship_id',
      'tutorship_parallel_id',
      'tutor_id',
      'university_classroom_id',
      'careers',
      'event_room',
    ])
  }

  /**
   * 
   */
  get today() {
    return new Date(Date.now())
  }

  /**
   * 
   */
  get today_parsed() {
    return this.today.toLocaleDateString('es-CL', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    })
  }

  /**
   * 
   */
  get today_reversed() {
    return this.today_parsed.split('-').reverse().join('-')
  }

  /**
   * 
   */
  get tutor_name_case() {
    return `${ this.prop('tutor.first_names', '') } ${ this.prop('tutor.last_name1', '')  }`
  }

  /**
   * 
   */
  get tutorship_description() {
    return this.prop('tutorship.description', '')
  }

  /**
   * 
   */
  get parallel_description() {
    return this.prop('parallel.description', '-')
  }

  /**
   * 
   */
  get weekday_date() {

    //
    const todayNumber = Date.now()
    const todayDate   = new Date(todayNumber)
    const todayDay    = todayDate.getDay()
    const weekDay     = this.weekday
    const weekDate    = todayDate.setDate(todayDate.getDate() + weekDay - todayDay)

    //
    return todayDate
  }

  /**
   * 
   */
  get weekday_parsed() {
    return this.weekday_date.toLocaleDateString('es-CL', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    })
  }

  /**
   * 
   */
  get weekday_reversed() {
    return this.weekday_parsed.split('-').reverse().join('-')
  }

  /**
   * 
   */
  get weekday_name() {
    return [
      'Lunes',
      'Martes',
      'Miércoles',
      'Jueves',
      'Viernes',
      'Sábado',
      'Domingo',
    ][this.weekday-1]
  }
}