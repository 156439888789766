// components
import TtsTutorshipFields from '@/views/Tutorship/TtsTutorship/fields'
import TtsTutorshipService from '@/services/api/Tutorship/TtsTutorship'
// component
export default {
  name: 'tts-tutorship-update',
  computed: {

    /**
     * Gets the TtsTutorship update form props.
     *
     * @return {Object} The update form props.
     */
    formProps() {
      return {
        items: TtsTutorshipFields.toUpdate(),
      }
    }
  },
  data() {
    return {
      ttsTutorship: {
        value: 'test',
      },
    }
  },
  methods: {

    /**
     * Returns an update TtsTutorship success process.
     *
     * @param  {Object}  event - the event success payload.
     */
    onSuccessUpdate(event) {
      this.onSuccess(event)
    },

    /**
     * Returns a reset TtsTutorship form.
     *
     * @return {Promise} A form reset promise.
     */
    onReset() {
      return this.toWait(true).then(this.toRefresh).then(this.toWait)
    },

    /**
     * Returns a submit TtsTutorship form.
     *
     * @return {Promise} A form submit promise.
     */
    onSubmit() {
      return this.toWait(true).then(this.toUpdate).then(this.toWait)
    },

    /**
     * Returns a refresh TtsTutorship form.
     *
     * @return {Promise} A reset promise.
     */
    toRefresh() {
      return TtsTutorshipService.model().find(this.getParam('id')).then(ttsTutorship => {
        this.ttsTutorship = ttsTutorship//.reset()
      })
    },

    /**
     * Returns an update TtsTutorship promise.
     *
     * @param  {Object}  model - the tts-tutorship model
     * @return {Promise} A update promise.
     */
    toUpdate() {
      return TtsTutorshipService.update(this.ttsTutorship).then(this.onSuccessUpdate).catch(this.onError)
    },
  },
  mounted() {
    this.onReset()
  },
}